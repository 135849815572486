import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';
import { ProjectService } from '../services/project.service';
import { SubscriptionLike } from 'rxjs';
import { Project } from '../services/project.type';
import { UserAccessService } from '../services/user-access';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  public jiraIntegrationAccess: boolean = this._userAccessService.getAccess('setting', 'jiraIntergation');

  private sub: SubscriptionLike;
  @ViewChild(BasePageComponent) basePage: BasePageComponent;

  constructor(private _projectService: ProjectService, private _userAccessService: UserAccessService,) { }

  ngOnInit(): void {
    this.sub = this._projectService.eventEmitterChangeProject.subscribe((data: Project) => {
      if (data && this.basePage) this.basePage.selectProject(data);
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
