const statusCreate = 'status/create', statusUpdate = 'status/update', statusDelete = 'status/delete';
export class StatusesControllers {
    constructor(api) {
        this.api = api;
    }
    //create status
    create(payload) {
        return this.api.postRequest(statusCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update status
    update(payload) {
        return this.api.postRequest(statusUpdate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete status
    delete(payload) {
        return this.api.postRequest(statusDelete, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
}
