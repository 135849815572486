import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params } from '@angular/router';
import { TestCase } from 'src/app/services/test-case.type';
import { TestStepService } from 'src/app/services/test-step.service';
import { TestStep } from 'src/app/services/test-step.type';
import { TestCaseService } from "../../services/test-case.service";
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-test-cases-step',
  templateUrl: './card-test-cases-step.component.html',
  styleUrls: ['./card-test-cases-step.component.scss']
})
export class CardTestCasesStepComponent implements OnInit {
  public addTestStepAccess: boolean = this._userAccessService.getAccess('testStep', 'testStepCreateLevel');

  @ViewChildren('panel') private panelSteps: QueryList<MatExpansionPanelTitle>;
  public _testCase: TestCase;
  private _testSteps: TestStep[];
  public testSteps: TestStep[];

  public showAddNewTestStepForm: boolean = false;
  public testStepForm: FormGroup;
  public isLoading = true;

  @Input() public queryParams: Params;
  @Input() set testCase(data: TestCase) {
    if (data) {
      this._testCase = data;
      this.getTestStep(data.id);
      this.closeTestStepForm(true);
    }
  }

  @Output() closeEvent = new EventEmitter()
  @Output() changeUrl = new EventEmitter<any>();

  constructor(
    private testStepService: TestStepService,
    private snackBar: MatSnackBar,
    private testCaseService: TestCaseService,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void { }

  searchPath(param: Params) {
    let index: number;
    let find = this.testSteps.find((elem: TestStep, i: number) => {
      if (+elem.id === +param['ts']) index = i;
      return +elem.id === +param['ts']
    });
    if (find) {
      let sub = this.panelSteps.changes.subscribe(res => {
        res.forEach((elem: MatExpansionPanel, i: number) => { if (i === index) elem.expanded = true });
        if (sub) sub.unsubscribe();
      })
    };

    window.history.pushState({}, null, `${window.location.origin}${window.location.pathname}?project=${param['project']}&type=tcs&creationMode=${param['creationMode']}&sub=${param['sub']}&level=${param['level']}&tc=${param['tc']}&ts=${param['ts']}`);
  }

  getTestStep(id: string) {
    this.isLoading = true;
    this.testStepService.getTestSteps(id).subscribe(res => {
      this.testSteps = this.createTestStepForm(res);
      this._testSteps = res;
      this.isLoading = false;

      if (this.queryParams?.ts) this.searchPath(this.queryParams)
    })
  }

  createTestStepForm(testStep: TestStep[]): TestStep[] {
    return testStep.map(step => {
      step['form'] = this.createForm(step);
      return step
    })
  }

  createForm(testStep: TestStep) {
    return new FormGroup({
      id: new FormControl(testStep.id),
      description: new FormControl(testStep.description, [Validators.required]),
      expectedResult: new FormControl(testStep.expectedResult, [Validators.required]),
    })
  }

  createTestStep(testStep: TestStep) {
    this.closeTestStepForm(true);
    this.testStepService.createTestStep(this._testCase.id, testStep).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Step was succefully created', 'OK', { duration: 3000 })
        this.getTestStep(this._testCase.id)
        this.testCaseService.updateTestCase(this._testCase)
      }
    })
  }

  updateStep(testStep: TestStep) {
    this.testStepService.saveStep(testStep).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Step was succefully edited', 'OK', { duration: 3000 })
        this.getTestStep(this._testCase.id)
        this.testCaseService.updateTestCase(this._testCase);
      }
    })
  }

  onDeleteStep(testStep: TestStep) {
    this.testStepService.deleteTestStep(testStep.id).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Step was succefully deleted', 'OK', { duration: 3000 })
        this.getTestStep(this._testCase.id)
        this.testCaseService.updateTestCase(this._testCase);
      }
    })
  }

  onAddNewTestStep() {
    this.testStepForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
      expectedResult: new FormControl('', [Validators.required]),
    })
    this.showAddNewTestStepForm = true;
  }

  closeTestStepForm(res: boolean) {
    if (res) {
      this.testStepForm = null;
      this.showAddNewTestStepForm = false;
    }
  }

  chooseStep(step: TestStep, panel: MatExpansionPanel) {
    if (this.queryParams && this.queryParams.project && this.queryParams.sub && this.queryParams.creationMode && this.queryParams.level && this.queryParams.tc) {
      if (panel.expanded) window.history.pushState({}, null, `${window.location.origin}/test-cases-page/main?project=${this.queryParams.project}&type=tcs&creationMode=${this.queryParams.creationMode}&sub=${this.queryParams.sub}&level=${this.queryParams.level || 0}&tc=${this.queryParams.tc}&ts=${step.id}`);
      else window.history.pushState({}, null, `${window.location.origin}/test-cases-page/main?project=${this.queryParams.project}&type=tcs&creationMode=${this.queryParams.creationMode}&sub=${this.queryParams.sub}&level=${this.queryParams.level || 0}&tc=${this.queryParams.tc}`);
    }
    this.changeUrl.emit()
  }
}
