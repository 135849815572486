import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Core } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor() { }

  getDateForChartCreated(param): Observable<any[]> {
    return from(Core.dashboard.testsCreated(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return res
      else return []
    }))
  }


  getDateForChartComplited(param): Observable<any[]> {
    return from(Core.dashboard.testsComplited(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return res
      else return []
    }))
  }

  getDateForChartChanged(param): Observable<any[]> {
    return from(Core.dashboard.testsChanged(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return res
      else return []
    }))
  }

  // getDateForChartEscalated(param): Observable<any[]> {
  //   return from(Core.dashboard.testsEscalated(param).catch(error => console.error(error))).pipe(map(res => {
  //     if (res) return res
  //     else return []
  //   }))
  // }

  getDateForChartEscalated(param): Observable<any[]> {
    return from(Core.dashboard.testsEscalated(param).catch(error => console.error(error))).pipe(
        map((res: any) => {
          if (res) {
            return res;
          } else {
            return [];
          }
        })
    );
  }

  getDateForChartFailures(param): Observable<any[]> {
    return from(Core.dashboard.testsFailures(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return res
      else return []
    }))
  }

}


