import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { DialogCreateTestSuitComponent } from 'src/app/dialogs/dialog-create-test-suit/dialog-create-test-suit.component';
import { DialogEditComponent } from 'src/app/dialogs/dialog-edit/dialog-edit.component';
import { DialogRunComponent } from 'src/app/dialogs/dialog-run/dialog-run.component';
import { DialogTestCasesSelectComponent } from 'src/app/dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { TestSuit, TestSuitFolders } from 'src/app/services/test-suit.type';
import { Core } from 'src/app/services/core.service';
import { UserService } from '../../services/user.service';
import { User } from '../../services/user.type';
import { Params } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component';
import { TodoItemFlatNode } from 'src/app/dialogs/shared/dialog-shared-suit-plan-select/shared-suit-plan-select.component';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-test-suit',
  templateUrl: './card-test-suit.component.html',
  styleUrls: ['./card-test-suit.component.scss']
})
export class CardTestSuitComponent implements OnInit {
  public guestAccessAction: boolean = this._userAccessService.getLevelAccessUser === 4;
  public addAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitCreateLevel');
  public editAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitEditLevel');
  public deleteAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitDeleteLevel');
  public addTestCaseAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitAddCaseLevel');
  public addRunFromSuitAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitCreateRunLevel');

  @ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;
  public search: string;

  public testSuits: TestSuit[];
  private _testSuits: TestSuit[];
  private _testSuitsCase: TestCase[];
  public selectedValue: TestSuit;
  private users = [];
  private assignedTo: User;
  public showSection: boolean = true;

  public pending: boolean = false;
  public activeTestSuit: TestSuit = null;

  private sortType: string;
  private sortTypesData = {
    'Creation Date': 'createDate',
    'Modification Date': 'modifiedDate',
    'Description': 'description',
    'Created By': 'creator_id',
    'Name': 'title',
  };

  @Output() showTestSuitCase = new EventEmitter<TestSuit>()
  @Output() updateTestSuitCases = new EventEmitter<TestSuit>();
  @Output() updateTestRun = new EventEmitter();
  @Output() collapseEvent = new EventEmitter<string>();
  @Output() showCaseEvent = new EventEmitter();

  @Input() showCase: boolean = false;
  @Input() showSuit: boolean = true;
  @Input() shortForm: boolean = false;
  @Input() queryParams: Params;
  @Output() sortSelectData = Object.keys(this.sortTypesData);
  @Output() baseSortValue = this.testSuitService.sortType || this.sortSelectData[0];

  constructor(
    private testSuitService: TestSuitService,
    private testCaseService: TestCaseService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private clipboard: Clipboard,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void {
    this.getTestSuit();
    this.setSelectionList();
    this.getUser();
  }

  searchPath(param: Params) {
    let find = this.testSuits.find(elem => +elem.id === +param.id && +elem.projectId === +param.project)
    if (find) {
      this.selectedValue = find;
      this.selectionList.options.changes.subscribe(res => {
        res.forEach(elem => { if (elem.value === find) elem.selected = true })
      });
      this.showTestSuitCase.emit(find);
    }
  }

  setSelectionList() {
    this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true })
    });
  }

  getTestSuit() {
    this.testSuitService.getTestSuits({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe((testSuits: TestSuit[]) => {
      this.testSuits = this.mapSelectedValue(testSuits);
      this.sortTestSuitByEvent()
      this._testSuits = testSuits;
      if (this.queryParams?.type === 'ts') this.searchPath(this.queryParams);
    })
  }

  mapSelectedValue(testSuit: TestSuit[]): TestSuit[] {
    if (this.selectedValue) {
      return testSuit.map(val => { val.id === this.selectedValue.id ? val.isOpen = true : val.isOpen = false; return val })
    } else {
      return testSuit
    }
  }

  getTestSelectedCases(id: string) {
    return this.testCaseService.getTestCasesByTestSuit({ ts_id: id }).pipe(
      map((testCases: TestCase[]) => {
        testCases.map(val => { val.filteredByPerson = '1'; val.filteredByStatus = '1'; })
        this._testSuitsCase = testCases;
        return testCases;
      })
    );
  }

  selectionChange(event: MatSelectionListChange) {
    event.options.forEach(item => {
      this.showTestSuitCase.emit(item.value);
      this.selectedValue = item.value;
    })
    this.showCaseEvent.emit()
  }

  async onSelectEvent(item: TestSuit) {
    this.pending = true;
    this.activeTestSuit = item;

    const testSuits = await this.setCaseToSuit(JSON.parse(JSON.stringify(this._testSuits)));
    const selectedSuits = await this.testSuitService.foldersList({ ts_id: item.id }).toPromise();
    const selectedSuitsTemplate = this.testSuitService.mapSuitFoldersTemplate(selectedSuits);

    this.pending = false;
    const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
      width: '650px',
      data: { testSuits: testSuits, selectedSuits: selectedSuitsTemplate, title: 'SelectFromSuit' },
      panelClass: 'custom-modalbox'
    });
    dialogRef.afterClosed().subscribe((result: TodoItemFlatNode[]) => {
      if (result) {
        let afterSelect: TodoItemFlatNode[] = [...result].filter(elem => {
          let find = elem.parents.map(item => ({ id: item.id, title: item.title })).find(fItem => fItem.id === item.id && fItem.title === item.title);
          if (elem.isCase && find && elem.level === 1) return true;
          if (elem.isCase && !find) return true;
        });

        let deletedTestCase: any[] = [];
        selectedSuitsTemplate.forEach(template => {
          if (template.level === 0 && template[0]?.cases) deletedTestCase.push(...template[0]?.cases);
        });

        afterSelect.forEach(nodeAfter => {
          deletedTestCase.forEach((item: TestCase, i) => {
            if (+item.tesSuitRelationId === +nodeAfter.tesSuitRelationId) deletedTestCase.splice(i, 1);
          })
        });

        this.testSuitService.updateTestCasesList({
          test_suit_id: item.id,
          project_id: item.projectId,
          test_cases: result.filter(c => c.isCase).map(c => ({ tc_id: c.id, ts_id: (c.elem as TestCase).tsId })),
          deleted_test_cases: deletedTestCase.map(c => ({ tc_id: c.id, ts_id: c.tsId }))
        }).subscribe((res: { valid: boolean, independent_cases?: TestCase[], msg?: string }) => {
          if (res.valid === true && res.independent_cases) {
            this.dialog.open(DialogConfirmComponent, {
              width: '650px',
              data: { dialogTitle: 'delete', name: res.independent_cases.map(elem => elem.title).join(', '), errorMsg: 'Unesigned cases' }
            }).afterClosed().subscribe((answ: boolean) => {
              if (answ) {
                this.testSuitService.updateTestCasesList({
                  test_suit_id: item.id,
                  project_id: item.projectId,
                  test_cases: result.filter(c => c.isCase).map(c => ({ tc_id: c.id, ts_id: (c.elem as TestCase).tsId })),
                  deleted_test_cases: deletedTestCase.map(c => ({ tc_id: c.id, ts_id: c.tsId, delete_anyway: 1 }))
                }).subscribe((res: { valid: boolean, tc_id?: string, msg?: string }) => {
                  if (res.valid) this.updateAfterActionSelect();
                  else this.updateAfterActionSelect(false);
                })
              }
            })
          } else if (res.valid === true) this.updateAfterActionSelect();
          else this.updateAfterActionSelect(false);
        })
      }
    });
  }

  updateAfterActionSelect(flag = true) {
    this.getTestSuit();
    this.activeTestSuit = null;
    this.updateTestSuitCases.emit(this.selectedValue)
    if (flag) this.snackBar.open('Test Case was successfully selected', "OK", { duration: 3000 });
    else this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 });
  }

  onEditEvent(item: TestSuit) {
    const dialogRef = this.dialog.open(DialogEditComponent, {
      width: '650px',
      data: { dialogTitle: 'Edit Test Suits', title: item.title, description: item.description }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const testSuit = this._testSuits.find(val => val.id === item.id)
        this.testSuitService.saveTestSuit({ testSuit: { ...testSuit, ...res } }).toPromise()
          .then(res => {
            if (res) {
              this.snackBar.open('Test Suit was successfully edited', "OK", { duration: 3000 });
              this.getTestSuit();
            }
          })
      }
    })
  }

  toTestRun(item: TestSuit) {
    const dialogRef = this.dialog.open(DialogRunComponent, {
      width: '650px',
      data: { title: item.title, type: 'suit', testSuit: item }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open('Test Run was successfully created', "OK", { duration: 3000 })
        this.updateTestRun.emit()
      }
    })
  }

  onDeleteEvent(item: TestSuit) {
    this.testSuitService.deleteTestSuit({ id: item.id }).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Suit was successfully deleted', "OK", { duration: 3000 });
        this.getTestSuit();
      }
    })
  }

  onAddEvent() {
    this.dialog.open(DialogCreateTestSuitComponent, {
      width: '650px',
      data: { testSuits: this._testSuits }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open('Test Suit was successfully added', "OK", { duration: 3000 });
        this.getTestSuit();
      }
    })
  }

  setCaseToSuit(testSuits: TestSuit[]): Promise<TestSuitFolders[]> {
    return Promise.all(testSuits.map((s => {
      return new Promise<TestSuitFolders>((resolve) => {
        this.testSuitService.foldersList({ ts_id: s.id }).subscribe((folder: TestSuitFolders) => {
          resolve(folder);
        });
      });
    })))
  }

  refreshSelectionList() {
    this.selectionList.deselectAll();
  }

  getUser() {
    this.userService.getUsers().subscribe(v => {
      this.users = v;
    })
  }

  getUserName(id) {
    if (this.users) this.assignedTo = this.users.find(user => user.id === id);
    if (this.assignedTo) return this.assignedTo.firstName;
    return ''
  }

  copyLink() {
    if (window.location.href) {
      let queryParamArr = window.location.href.split('?')[1].split('&');
      let params = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

      if (!params['tc']) this.clipboard.copy(window.location.href);
      else this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=${params['type']}&id=${params['id']}`);

      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
    }
  }

  getTestSuitInfo(id) {
    return this._testSuits.find((item) => item.id === id)
  }

  sortTestSuitByEvent(event?) {
    this.sortType = this.testSuitService.sortType
    if (event) {
      this.sortType = event.value;
      this.testSuitService.sortType = event.value;
    }

    let sortedTestSuit = this.testSuits

    if (this.sortType) {
      switch (this.sortType) {
        case 'Creation Date':
          sortByCreationDate();
          break;
        case 'Modification Date':
          sortByModificationDate();
          break;
        case 'Description':
          sortByDescription();
          break;
        case 'Created By':
          sortByCreator();
          break;
        case 'Name':
          sortByName();
          break;
      }
    } else {
      sortByCreationDate()
    }

    function sortByCreationDate() {
      sortedTestSuit.sort(function (a, b) {
        return +new Date(b.createdDate) - +new Date(a.createdDate);
      });
    }

    function sortByModificationDate() {
      sortedTestSuit.sort(function (a, b) {
        return +new Date(b.modifiedDate) - +new Date(a.modifiedDate);
      });
    }

    function sortByDescription() {
      sortedTestSuit.sort(function (a, b) {
        if (a.description?.toUpperCase() < b.description?.toUpperCase()) {
          return -1;
        }
        if (a.description?.toUpperCase() > b.description?.toUpperCase()) {
          return 1;
        }
        return 0;
      });
    }

    function sortByName() {
      sortedTestSuit.sort(function (a, b) {

        if (a.title.toUpperCase() < b.title.toUpperCase()) {
          return -1;
        }
        if (a.title.toUpperCase() > b.title.toUpperCase()) {
          return 1;
        }
        return 0;
      });
    }

    function sortByCreator() {
      sortedTestSuit.sort(function (a, b) {
        let nameA = a.creator_name;
        let nameB = b.creator_name;
        if (!a.creator_name) {
          return 1;
        }
        if (!b.creator_name) {
          return -1;
        }
        return nameA.localeCompare(nameB);
      });
    }

  }

  onImportEvent(event) {
    let formData = new FormData();
    formData.set('file', event.target.files[0])
    formData.set('ts_id', this.selectedValue.id)


    this.testSuitService.importTestRail(formData)
  }

}
