import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { User } from './user.type';
import { UserService } from './user.service';
import { TransferDataService } from './transfer-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccessService implements OnInit {

  constructor(
    private _authService: AuthenticationService,
    private _userService: UserService,
    private transferDataService: TransferDataService
  ) { }

  ngOnInit(): void { }

  public getRootAccessUser$: Observable<boolean> = of(this._userService.mapUser(this._authService.getUser())).pipe(
    map((user: User) => (+user.roleId <= 1 ? true : false))
  );
  public getLeadAccessUser$: Observable<boolean> = of(this._userService.mapUser(this._authService.getUser())).pipe(
    map((user: User) => (+user.roleId <= 2 ? true : false))
  );
  public getUserAccessUser$: Observable<boolean> = of(this._userService.mapUser(this._authService.getUser())).pipe(
    map((user: User) => (+user.roleId <= 3 ? true : false))
  );
  public getGuestAccessUser$: Observable<boolean> = of(this._userService.mapUser(this._authService.getUser())).pipe(
    map((user: User) => (+user.roleId <= 4 ? true : false))
  );

  public getLevelAccessUser: number = +this._userService.mapUser(this._authService.getUser()).roleId;

  public getAccess(type, action): boolean {
    return (this.getLevelAccessUser <= +this.transferDataService.accessLevelForAction[type][action]);
  }
}
