import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';
import { ProjectService } from '../services/project.service';
import { Project } from '../services/project.type';
import { CardProjectsUsersComponent } from './card-projects-users/card-projects-users.component';
import { CardProjectsComponent } from './card-projects/card-projects.component';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent implements OnInit {

  @ViewChild(BasePageComponent) private basePageComponent: BasePageComponent
  @ViewChild(CardProjectsUsersComponent) private cardUsersComponent: CardProjectsUsersComponent
  @ViewChild(CardProjectsComponent) private cardProjectsComponent: CardProjectsComponent

  @Output() closeEvent = new EventEmitter();

  public showProjectsUser: boolean = false;
  public selectedProject: Project

  constructor(
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
  }

  showProjectUser(project: Project) {
    this.showProjectsUser = true;
    this.selectedProject = project;
  }

  closeProjectUser() {
    this.showProjectsUser = false;
    this.selectedProject = null;
  }

  changeCurrentProject() {
    this.basePageComponent.getData();
  }

  updateUsers(project: Project) {
    this.cardUsersComponent?.getData(project);
  }

  deleteCurrentProject() {
    this.projectService.setSelectedProjectToStorage(null);
  }

  updateProjects() {
    this.cardProjectsComponent.getProjects()
  }
}
