<div class="main-page__row main-page__row--tab">
  <div class="main-page__column sticky" [class.main-page__column-50]="showCase">
    <app-card-unesigned-cases #cardTestCases (showTestStep)="onShowTestCase($event)" [queryParams]="queryParams"
      (closeTestStep)="closeTestStep($event)" (changeUrl)="changeUrl($event)"></app-card-unesigned-cases>
  </div>

  <ng-container *ngIf="showCase">
    <div class="main-page__column main-page__column-50 sticky">
      <app-card-unesigned-step [testCase]="selectedTestCase" [queryParams]="queryParams"
        [queryParamsStep]="queryParamsStep" (closeEvent)="closeTestStep(true)"></app-card-unesigned-step>
    </div>
  </ng-container>
</div>