import { Component, OnInit, ViewChild, } from '@angular/core';
import { CardUnesignedCasesComponent } from './card-unesigned-cases/card-unesigned-cases.component';
import { TestCaseService } from '../services/test-case.service';
import { TestCase } from '../services/test-case.type';
import { ActivatedRoute, Params } from '@angular/router';
import { ProjectService } from '../services/project.service';


@Component({
  selector: 'app-unesigned-cases-page',
  templateUrl: './unesigned-cases-page.component.html',
  styleUrls: ['./unesigned-cases-page.component.scss']
})
export class UnesignedCasesPageComponent implements OnInit {
  @ViewChild(CardUnesignedCasesComponent) unesignedCasesComponent: CardUnesignedCasesComponent;

  public showCase: boolean;
  public selectedTestCase: TestCase;
  public queryParams: Params;
  public queryParamsStep: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private testCaseService: TestCaseService
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.testCaseService.setActiveComponent(null);
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(res => {
      if (window.location.pathname === '/test-cases-page/unesigned-cases') {
        this.queryParams = res;
      }
    })

    if (this.unesignedCasesComponent) this.testCaseService.setActiveComponent(this.unesignedCasesComponent, 'CardUnesignedCasesComponent');
  }

  changeUrl(url) {
    let param: Params;
    let queryParamArr = url.split('?')[1]?.split('&');
    if (queryParamArr) param = queryParamArr.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

    if (param?.ts) this.queryParamsStep = param;
  }

  onShowTestCase(testCase: TestCase) {
    this.selectedTestCase = testCase;
    this.showCase = true;
  }

  closeTestStep(flag) {
    if (flag) {
      if (this.unesignedCasesComponent) this.unesignedCasesComponent.selectedValue = null;
      this.selectedTestCase = null;
      this.showCase = false;
    }
  }
}