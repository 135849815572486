<div class="dialog-title">
  <h1 mat-dialog-title>{{data.action}} Jira integration setting</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <div *ngIf="data.action === 'Create'">
    <form [formGroup]="craeteJiraForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Project</mat-label>
        <mat-select formControlName="projectId">
          <mat-option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.projectId.hasError('required')">
          Project is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
        <mat-label>Project key (from Jira)</mat-label>
        <input matInput formControlName="projectKey">
        <mat-error *ngIf="f.projectKey.hasError('required')">
          Project key is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
        <mat-label>Project url (from Jira)</mat-label>
        <input matInput formControlName="projectUrl">
        <mat-error *ngIf="f.projectUrl.hasError('required')">
          Project url is required
        </mat-error>
        <mat-error *ngIf="f.projectUrl.hasError('pattern')">
          This field must be an url
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
        <mat-label>User email (from Jira)</mat-label>
        <input matInput formControlName="userEmail">
        <mat-error *ngIf="f.userEmail.hasError('required')">
          User email is required
        </mat-error>
        <mat-error *ngIf="f.userEmail.hasError('email')">
          This field must be an email
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Token (from Jira)</mat-label>
        <input matInput formControlName="token">
        <mat-error *ngIf="f.token.hasError('required')">
          Token is required
        </mat-error>
      </mat-form-field>
      <div class="dialog-actions" mat-dialog-actions>
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary" style="background: #2d64ba" (click)="createSetting()">Create</button>
      </div>
    </form>
  </div>

  <div *ngIf="data.action === 'Update'">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Project</mat-label>
      <mat-select [disabled]="true" [value]="data.data.project_id">
        <mat-option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
      <mat-label>Project key (from Jira)</mat-label>
      <input matInput [value]="data.data.external_project_key" [disabled]="true">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
      <mat-label>Project url (from Jira)</mat-label>
      <input matInput [value]="data.data.basic_url" [disabled]="true">
    </mat-form-field>

    <form [formGroup]="editJiraForm">
      <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
        <mat-label>User email (from Jira)</mat-label>
        <input matInput formControlName="userEmail">
        <mat-error *ngIf="f.userEmail.hasError('required')">
          User email is required
        </mat-error>
        <mat-error *ngIf="f.userEmail.hasError('email')">
          This field must be an email
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Token (from Jira)</mat-label>
        <input matInput formControlName="token">
        <mat-error *ngIf="f.token.hasError('required')">
          Token is required
        </mat-error>
      </mat-form-field>
      <div class="dialog-actions" mat-dialog-actions>
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary" style="background: #2d64ba" (click)="createSetting()">Update</button>
      </div>
    </form>
  </div>
</div>