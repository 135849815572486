export class TestCase {
  id: string;
  testSuitId: string;
  tsId: string;
  tpId?: string;
  testSuits: any;
  testSuitFolderId: string;
  title: string;
  description: string;
  createdDate: string;
  modifiedDate: string;
  testCaseId: string;
  relationId: string;
  status: string;
  assignedTo: string;
  projectId: string;
  projectName: string;
  testSuitName: string;
  filteredByStatus: string;
  filteredByPerson: string;
  tesSuitRelationId: string;
  active: boolean;
  loading: boolean;
  executedPercentage: string;
  comment: string;
  folderId: string;
  outerBugtrackingLink: string;
  defectTitle: string;
  defectCreated: string;
  isOpen: boolean;
  creator_id: string;
  isShow?: boolean;
  copyCount?: number;
  independent: string;
  oldTcId: string;
}

export class TodoItemNode {
  id: string;
  children: TodoItemNode[];
  item: string;
  open: boolean;
  collapse?: boolean;
  expandable: boolean;
  tsId?: string;
  relationId?: string;
}

export class TodoItemFlatNode {
  id: string;
  tsId: string;
  relationId: string;
  item: string;
  level: number;
  open: boolean;
  collapse?: boolean;
  expandable: boolean;
}
