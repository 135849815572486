<div #container class="test-case-wrap">
<mat-card *ngIf="testCase" style="padding: 0; margin: 0px !important;" class="test-case">
  <mat-card-content>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <button (click)="closeTestCaseDetail($event)" mat-icon-button class="close-test-case-details-sidenav">
            <mat-icon>close</mat-icon>
          </button>
          <mat-panel-title>
            {{testCase.title}}
          </mat-panel-title>
          <mat-panel-description>
            <span>
              TEST CASE ID - {{testCase.id || testCase.testCaseId}}
            </span>
          </mat-panel-description>
          <div class="icons-wrap">
            <span class="material-icons pencil">
              create
            </span>
            <button [disabled]="!editTestCaseForm.valid" mat-icon-button class="material-icons save" (click)="saveEdit()">
              <mat-icon>save</mat-icon>
            </button>
           <span class="material-icons close">
              close
         </span>
          </div>
        </mat-expansion-panel-header>
        <form [formGroup]="editTestCaseForm">
          <mat-form-field class="dropdown-input" appearance="outline" floatLabel="auto">
            <mat-label>Title</mat-label>
            <input formControlName="title" matInput>
          </mat-form-field>
          <mat-form-field class="dropdown-input" appearance="outline" floatLabel="auto">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description">
          </mat-form-field>
        </form>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>

<mat-card style="padding: 0; background-color: #fafafa; box-shadow: none" class="test-steps-wrap">
  <form [formGroup]="testStepsForm" *ngIf="testSteps">
    <div formArrayName="steps" *ngFor="let testStep of testStepsForm.get('steps')['controls']; let i = index; let last = last" class="test-step" [style.display]="showSteps ? 'block' : 'none'">
      <mat-accordion  (keydown.control.enter)="ctrlEnter(testStep, i)">
        <mat-expansion-panel [expanded]="extendedLast ? last : false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{  
                (testStep.value.description.length>50) ? 
                (testStep.value.description | slice:0:50) + '...' :
                (testStep.value.description) 
              }}
            </mat-panel-title>
            <mat-panel-description>
              <span>
                TEST STEP ID - {{testStep.value.id}}
              </span>
            </mat-panel-description>
            <div class="icons-wrap">
              <span class="material-icons expand">
                expand_more
              </span>
              <button mat-icon-button class="material-icons save" (click)="saveEditStep(testStep, i)" [disabled]="!testStep.valid">
                <mat-icon>save</mat-icon>
              </button>
              <span class="material-icons" (click)="deleteStep(last, testStep, i)">delete</span>
              <span class="material-icons close">
                close
              </span>
            </div>
          </mat-expansion-panel-header>
          <div *ngIf="testCase">
            <div [formGroupName]="i">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Description</mat-label>
                  <textarea #textAreaFocus class="noscroll-textarea" formControlName="description" [name]="'test_step_description_' + i"
                            matInput>{{testStep.description}}</textarea>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="auto" class="full-width">
                <mat-label>Expected result</mat-label>
                  <textarea class="noscroll-textarea" formControlName="expectedResult" [name]="'test_step_expected_result_' + i"
                            matInput>{{testStep.expectedResult}}</textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
  <div class="add-test-step-button-wrapper">
    <button mat-raised-button 
            color="primary"
            *ngIf="this.router.url.includes('case')"
            (click)="createStep()"><mat-icon>add</mat-icon>New Test Step</button>
  </div>
</mat-card>
</div>
