<div class="container">
  <div class="dialog-title">
    <h1 mat-dialog-title>Select Test Cases</h1>
  </div>

  <div class="dialog-content" *ngIf="data && data.msg">
    <div class="dialog-content__title">{{data.msg}}</div>
  </div>

  <div class="dialog-actions padding-15" mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="null" cdkFocusInitial>CANCEL</button>
    <button mat-raised-button color="primary" style="background: #2d64ba" [mat-dialog-close]="{action: true}"
      cdkFocusInitial>Merge</button>
    <button mat-raised-button color="primary" style="background: #2d64ba" [mat-dialog-close]="{action: false}"
      cdkFocusInitial>Add duplicate</button>
  </div>
</div>