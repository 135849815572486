<div class="dialog-title">
  <h1 mat-dialog-title>Delete Test Run</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <span>Attention ! This action cannot be undone. We strongly advice to not delete test run <span
      class="accent">{{data.name}}</span> rather move them to Archive.</span>
</div>

<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" style="background: #2d64ba" (click)="save({action: 0})">Archive</button>
  <button mat-raised-button color="primary" style="background: #2d64ba" (click)="save({action:1})">Delete any
    way</button>
</div>