import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { Status } from '../services/status.type';
import { DialogCreateStatusComponent } from '../dialogs/dialog-create-status/dialog-create-status.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditStatusComponent } from '../dialogs/dialog-edit-status/dialog-edit-status.component';
import { AbstractTableComponent } from '../abstract-table/abstract-table.component';
import { Core } from '../services/core.service';


@Component({
  selector: 'app-manage-statuses',
  templateUrl: './manage-statuses.component.html',
  styleUrls: ['./manage-statuses.component.scss']
})
export class ManageStatusesComponent implements OnInit {
  private currentProject: string = JSON.parse(JSON.stringify(Core.localStorageService.getItem('selected_project'))) || {};

  public data: Status[];
  public filterValue: string = '';
  public displayedColumns: string[] = ['id', 'name', 'color', 'statusState', 'isDefault', 'actions-status'];

  public statuses: Status[] = [];

  @ViewChild('abstractTable') private abstractTable: AbstractTableComponent

  constructor(
    private projectService: ProjectService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.projectService.getProjectStatuses(this.currentProject).subscribe(res => {
      this.statuses = res;
      this.data = this.statuses
    });
  }

  openDialogCreateStatus(): void {
    const dialogRef = this.dialog.open(DialogCreateStatusComponent, {
      width: '650px',
      data: { projectId: this.currentProject }
    });

    dialogRef.afterClosed().subscribe(() => { });
  }

  openDialogEditStatus(status: Status): void {
    const dialogRef = this.dialog.open(DialogEditStatusComponent, {
      width: '650px',
      data: { status }
    });

    dialogRef.afterClosed().subscribe((valid) => {
      if (valid)
        location.reload();
    });
  }

  deleteStatus(status: Status): void {
    this.projectService.deleteStatus(status.id).subscribe(valid => {
      if (valid)
        this.statuses.splice(this.statuses.findIndex(s => {
          return s.id === status.id;
        }), 1);
      this.abstractTable.data = this.statuses
    })
  }

}
