<mat-sidenav-container style="height: 100vh;">
  <mat-sidenav mode="side" #sidenav id="sidenav" [style.background]="selectOpen==true?'#1f519e':'#2d64ba'" opened
    style="background: #3f51b5; border: none !important;" [@openCloseSidenav]="isMenuOpen ? 'open': 'closed'">
    <div class="grid-container">
      <div class="logo-wrap">
        <div class="item-button">
          <button mat-button (click)="onToolbarMenuToggle()"><mat-icon *ngIf="!isMenuOpen">menu</mat-icon><mat-icon
              *ngIf="isMenuOpen">menu_open</mat-icon></button>
        </div>
        <div class="item-span">
          <span *ngIf="isMenuOpen">TESTYY</span>
        </div>
      </div>
      <div class="item-select" *ngIf="isMenuOpen">
        <!--        <select [(ngModel)]="selectedProject" (ngModelChange)="onProjectSelection()">-->
        <!--          <option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</option>-->
        <!--        </select>-->
        <span class="selected-projects">{{selectedProjectName}}</span>
        <button mat-icon-button style="float: right; margin-top: -6%;" (click)="selectOpen = !selectOpen">
          <mat-icon
            [ngStyle]="{'transform': selectOpen == true ? 'rotate(180deg)' : 'rotate(0deg)'}">keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!selectOpen" class="sidenav-content">
      <div class="creation-mode-container" (mouseenter)="isOpen = true" (mouseleave)="isOpen = false" cdkOverlayOrigin
        #trigger="cdkOverlayOrigin">
        <span *ngIf="isMenuOpen">Creation mode</span>
        <mat-slide-toggle [checked]="creationMode" (change)="onCreationMode()">
        </mat-slide-toggle>
        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
          [cdkConnectedOverlayPositions]="positions" class="tooltip-wrap">
          <div class="creation-mode-tooltip" *ngIf="!isMenuOpen"> Creation Mode {{ creationMode ? 'On':'Off'}} </div>
        </ng-template>
      </div>
      <mat-nav-list class="main-navigation">
        <a mat-list-item [routerLink]="['/dashboard']" routerLinkActive="active"
          [disableRipple]="true"><mat-icon>dashboard</mat-icon>
          <span *ngIf="isMenuOpen">
            Dashboard
          </span>
        </a>
        <a mat-list-item routerLink="/projects" routerLinkActive="active" [disableRipple]="true"
          [ngClass]="{'active': router.url.includes('project')}"><mat-icon>folder</mat-icon>
          <span *ngIf="isMenuOpen">
            Projects
          </span>
        </a>
        <a mat-list-item routerLink="/test-runs" routerLinkActive="active" [disableRipple]="true"
          routerLinkActiveOptions="{exact: true}"
          [ngClass]="{'active': router.url.includes('run')}"><mat-icon>directions_run</mat-icon>
          <span *ngIf="isMenuOpen">
            Test runs
          </span>
        </a>
        <a mat-list-item routerLink="/test-planes" routerLinkActive="active" [disableRipple]="true"
          [ngClass]="{'active': router.url.includes('plan')}"><mat-icon>assignment</mat-icon>
          <span *ngIf="isMenuOpen">
            Test planes
          </span>
        </a>
        <a mat-list-item routerLink="/test-suits" routerLinkActive="active" [disableRipple]="true"
          [ngClass]="{'active': router.url.includes('suit')}"><mat-icon>dns</mat-icon>
          <span *ngIf="isMenuOpen">
            Test suits
          </span>
        </a>
        <a mat-list-item routerLink="/test-cases" routerLinkActive="active" [disableRipple]="true"
          [ngClass]="{'active': router.url.includes('case')}"><mat-icon>work</mat-icon>
          <span *ngIf="isMenuOpen">
            Test cases
          </span>
        </a>
        <a mat-list-item routerLink="/settings" routerLinkActive="active" [disableRipple]="true"
          [ngClass]="{'active': router.url.includes('manage')}"><mat-icon>settings</mat-icon>
          <span *ngIf="isMenuOpen">
            Settings
          </span>
        </a>
      </mat-nav-list>
      <div class="item-bottom" mat-list-item>
        <div class="item-user">
          <button mat-icon-button class="user-wrapper">
            <mat-icon>person</mat-icon>
            <span *ngIf="isMenuOpen" class="user-name">{{ user$["first_name"] }} {{user$["last_name"]}}</span>
            <span *ngIf="isMenuOpen" class="user-name-visible">{{ user$["first_name"] }} {{user$["last_name"][0]}}.
            </span>
          </button>
          <button mat-icon-button aria-label="Logout" *ngIf="isMenuOpen" (click)="auth.logOut()">
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="project-select-container" *ngIf="selectOpen">
      <mat-list>
        <mat-list-item *ngFor="let option of projects" (click)="selectProject(option)"><span
            class="project-title">{{option.title}}</span></mat-list-item>
      </mat-list>
    </div>



    <!--    <mat-form-field *ngIf="isMenuOpen">-->
    <!--      <mat-label><span class="toolbar-select"></span></mat-label>-->
    <!--      <mat-select>-->
    <!--        <mat-option value="option">-->
    <!--          <button type="button" mat-icon-button (click)="auth.logOut()">-->
    <!--            <span><mat-icon>exit_to_app</mat-icon>LOGOUT</span>-->
    <!--          </button>-->
    <!--        </mat-option>-->
    <!--      </mat-select>-->
    <!--    </mat-form-field>-->

  </mat-sidenav>

  <mat-sidenav-content [@openCloseSidenavContent]="isMenuOpen ? 'open': 'closed'">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
