<div class="dialog-title">
  <h1 mat-dialog-title>Choose Project</h1>
</div>
<div class="dialog-content" mat-dialog-content>

  <form [formGroup]="chooseProjectForm">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Project</mat-label>
      <mat-select formControlName="project">
        <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '35px', background: '#D6DBDF', margin: '5px 0px 0px 0px' }" count="4" appearance="line"></ngx-skeleton-loader>
        <mat-option *ngIf="isLoading" style="display: none"></mat-option>
        <mat-option *ngFor="let option of projects" [value]="option.id">
          {{ option.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.project.hasError('required')">
        Project is required
      </mat-error>
    </mat-form-field>
  </form>

</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button [disabled]="chooseProjectForm.invalid" color="primary" style="background: #2d64ba"
    (click)="chooseProject()">Choose</button>
</div>