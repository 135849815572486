<div class="main-wrap">
  <header class="main-header">
    <p class="logo">TESTYY</p>
    <div class="creation-mode">
      <h4 class="creation-mode__title">Creation mode</h4>
      <mat-slide-toggle
                      [checked]="creationMode"
                      (change)="onCreationMode()"
                        >
      </mat-slide-toggle>
    </div>
  </header>
  <mat-tab-group animationDuration="0ms" class="main-navigation">
    <mat-tab label="Dashboard">Content 1</mat-tab>
    <mat-tab label="Projects">Content 2</mat-tab>
    <mat-tab label="Test Runs">
      <app-test-runs-tab [testRuns]="testRuns"></app-test-runs-tab>
    </mat-tab>
  </mat-tab-group>
</div>
