
<div class="table-main-container">
  <mat-card class="table-run-details-filters" *ngIf="statuses || users">
    <div class="active-filters">
      <div class="assigned-to" *ngIf="selectedPersonName.length > 0">Assigned to {{selectedPersonName}}</div>
      <ul class="chosen-task-statuses">
        <li *ngFor="let status of selectedStatuses">{{status.name}}</li>
      </ul>
    </div>
    <div class="filters-conatiner">
      <div class="filter-wrapper">
        <div class="filter-header" (click) ="SetStatusSelect.open()">
          <div class="title">By Status</div>
          <div class="selected-count" *ngIf="selectedStatuses && selectedStatuses.length > 0">{{selectedStatuses.length}}</div>
          <mat-icon [ngStyle]="{'transform': filterOpened.SetStatusSelect == true ? 'rotate(180deg)' : 'rotate(0deg)'}" >keyboard_arrow_down</mat-icon>
        </div>
        <mat-form-field class="filter">
          <mat-select #SetStatusSelect [(ngModel)]="selectedStatuses" (openedChange) = "this.filterOpened.SetStatusSelect = !this.filterOpened.SetStatusSelect" (ngModelChange)="applyFilter('byStatus')"  multiple disableOptionCentering >
            <div class="filter__top"><button mat-flat-button color="primary" (click)="selectAllSatuses()">Select All</button><button mat-flat-button color="warn" (click)="clearAllSatuses()">Clear All</button></div>
            <mat-option *ngFor="let status of statuses" [value]="status">{{ status.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filter-wrapper">
        <div class="filter-header" (click) ="SetPersonSelect.open()">
          <div class="title">By Person</div>
          <div class="selected-count" *ngIf="selectedPerson && selectedPerson.length > 0">{{selectedPerson.length}}</div>
          <mat-icon [ngStyle]="{'transform': filterOpened.SetPersonSelect == true ? 'rotate(180deg)' : 'rotate(0deg)'}">keyboard_arrow_down</mat-icon>
        </div>
        <mat-form-field class="filter by-person-filter">
          <mat-select  #SetPersonSelect [(ngModel)]="selectedPerson" (selectionChange)="applyFilter('byPerson')"  (openedChange) = "this.filterOpened.SetPersonSelect = !this.filterOpened.SetPersonSelect" multiple disableOptionCentering>
            <div class="filter__top"><button mat-flat-button color="primary" (click)="selectAllUsers()">Select All</button><button mat-flat-button color="warn" (click)="clearAllUsers()">Clear All</button></div>
            <mat-option *ngFor="let user of users" [value]="user">{{ user.firstName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <mat-card class="table-card test-run-details-table" [class.no-top]="!(statuses || users)">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="full-width">
        <ng-container matColumnDef="select">
          <mat-header-cell mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()"
                          >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)"
                          [disabled]="row.defectCreated === 'Yes'">
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
          <mat-header-cell mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase"> {{testCase.testCaseId}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="defectsPageId">
          <mat-header-cell mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase"> {{testCase.id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>title</mat-header-cell>
          <mat-cell *matCellDef="let testCase" (click)="openTestCaseDetail(testCase)" class="cursor-pointer"> {{testCase.title}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>description</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase"> {{testCase.description}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="testSuitName">
          <mat-header-cell *matHeaderCellDef>Test Suit Title</mat-header-cell>
          <mat-cell *matCellDef="let testCase"> {{testCase.testSuitName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell mat-header-cell *matHeaderCellDef>status</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase">
            <mat-form-field class="custom-select-wrap">
              <mat-select class="custom-select" [ngModel]="testCase.status" (ngModelChange)="onStatusChange($event, testCase)" disableOptionCentering>
                <mat-option *ngFor="let status of statuses" [value]="status['id']">
                  {{status.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assigned_to">
          <mat-header-cell mat-header-cell *matHeaderCellDef>assigned to</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase">
            <mat-form-field class="custom-select-wrap">
              <mat-select class="custom-select" [(ngModel)]="testCase.assignedTo" (ngModelChange)="onUserChange(testCase, testCase.assignedTo)" disableOptionCentering>
                <mat-option *ngFor="let user of users" [value]="user.id">
                  {{user.firstName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="BugTrackingURL">
          <mat-header-cell *matHeaderCellDef>Bug Tracking URL</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase">{{testCase.outerBugtrackingLink || 'no URL'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="defectCreated">
          <mat-header-cell *matHeaderCellDef>Defect Created</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase">{{testCase.defectCreated}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase"> {{testCase.createdDate}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="modifiedDate">
          <mat-header-cell *matHeaderCellDef>Modified Date</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let testCase"> {{testCase.modifiedDate}} </mat-cell>
        </ng-container>
        <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedColumns;" [class.active] = "row.active"></mat-row>
      </table>
    </div>
    <mat-paginator [length]="1000" [pageIndex]="0" [pageSize]="15" [pageSizeOptions]="[15, 30, 50, 100]">
    </mat-paginator>
  </mat-card>
</div>