<app-sidenav>
  <mat-card id="panel">
    <span>Projects</span>
    <mat-form-field appearance="outline" class="main-header-search">
      <label>
        <input matInput [(ngModel)]="filterValue" placeholder="Search">
      </label>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="openCreateProjectDialog()"><mat-icon>add</mat-icon>New Project</button>
  </mat-card>

  <mat-card class="table-card">
    <app-abstract-table [filter]="filterValue" 
                        [data]="data"
                        [columns]="displayedColumns"
                        (abstractRowEvent)="navigateToDetail($event)">
    </app-abstract-table>
  </mat-card> 
</app-sidenav>
