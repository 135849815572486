import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { DialogTestCaseFailComponent } from 'src/app/dialogs/dialog-test-case-fail/dialog-test-case-fail.component';
import { DialogViewAttachmentsComponent } from 'src/app/dialogs/dialog-view-attachments/dialog-view-attachments.component';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { Attachment } from 'src/app/services/attachment.type';
import { ProjectService } from 'src/app/services/project.service';
import { Status } from 'src/app/services/status.type';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestStepService } from 'src/app/services/test-step.service';
import { TestStep } from 'src/app/services/test-step.type';
import { UserService } from 'src/app/services/user.service';
import { Core } from 'src/app/services/core.service';
import { Params } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Clipboard } from '@angular/cdk/clipboard';
import { TestRun } from '../../services/test-run.type';
import { Host } from 'src/app/services/core.service';
import { forkJoin } from 'rxjs';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-test-run-step',
  templateUrl: './card-test-run-step.component.html',
  styleUrls: ['./card-test-run-step.component.scss']
})
export class CardTestRunStepComponent implements OnInit {
  public editAccessAction: boolean = this._userAccessService.getAccess('testStep', 'testStepEditLevel');

  public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
  public activeIdsPendingArr: number[] = [];

  private _statuses: Status[];
  public statuses: Status[];

  @Input() public testRunSteps: TestStep[];
  @Input() public shortRun: boolean = false;
  @Input() public shortCases: boolean = false;
  @Input() queryParams: Params;
  @Input() selectedRun: TestRun;
  @ViewChildren('panel') private panelSteps: QueryList<MatExpansionPanel>;

  private _testRunSteps: TestStep[];
  private _activeTestRunStep: TestStep;
  private _testCase: TestCase;
  public isLoading = true;

  @Input() set testCase(data: TestCase) {
    if (data) {
      this._testCase = data;
      this.getTestCaseSteps(data.relationId);
    }
  };

  @Output() updateTestRuns = new EventEmitter();
  @Output() closeEvent = new EventEmitter();



  constructor(
    private testStepService: TestStepService,
    private testCasesService: TestCaseService,
    private testRunService: TestRunService,
    private projectService: ProjectService,
    private userService: UserService,
    public api: ApiBaseService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void { }

  searchPath(param: Params) {
    let index: number;
    let find = this.testRunSteps.find((elem: TestStep, i: number) => {
      if (+elem.id === +param.ts) index = i;
      return +elem.id === +param.ts
    });
    if (find) {
      let sub = this.panelSteps.changes.subscribe(res => {
        res.forEach((elem: MatExpansionPanel, i: number) => { if (i === index) elem.expanded = true });
        if (sub) sub.unsubscribe();
      })
    }
  }

  getStatuses() {
    let id = this.currentProject
    if (isNaN(+this.currentProject)) {
      id = this.projectService.getPrivatProject[0].id
    }
    return this.testCasesService.getTestCaseStatuses({ project_id: id }).pipe(
      map(val => {
        this._statuses = val;
        this.statuses = val;
        return val;
      })
    )
  }

  getTestCaseSteps(relationId: string) {
    this.isLoading = true;
    this.testRunSteps = [];
    this.testStepService.getTestStepsByRelationId({ relation_id: relationId }).subscribe(async (steps: TestStep[]) => {
      this._testRunSteps = steps;
      await this.getStatuses().toPromise();
      this.testRunSteps = this.mapTestCaseSteps(steps);

      if (this.queryParams?.ts) this.searchPath(this.queryParams);

      this.getAttachments();
      this.isLoading = false;
    })
  }

  getAttachments(): void {
    this.activeIdsPendingArr = this._testRunSteps.map(elem => +elem.id);
    this._testRunSteps.map(ts => {
      this.userService.testStepAttachments({ test_run_history_id: this._testCase.relationId || this.testCase.relationId, step_id: ts.id }).subscribe(r => {
        ts.attachments = r;
        ts.attachments.map((attachment: Attachment) => {
          if (attachment.fileType.includes('text')) {
            this.previewTextFile(attachment);
          }
        })

        this.testRunSteps.forEach(step => {
          if (+step.id === +ts.id) step.attachments = ts.attachments;
        })

        this.activeIdsPendingArr = [];
      });
    });
  }

  getAttachmentStep(testStep): void {
    this.userService.testStepAttachments({ test_run_history_id: this._testCase.relationId || this.testCase.relationId, step_id: testStep.id }).subscribe(r => {
      testStep.attachments = r;
      testStep.attachments.map((attachment: Attachment) => {
        if (attachment.fileType.includes('text')) {
          this.previewTextFile(attachment);
        }
      })

      this.testRunSteps.forEach(step => {
        if (+step.id === +testStep.id) step.attachments = testStep.attachments;
      })

      this.activeIdsPendingArr = [];
    });
  }

  previewTextFile(attachment: Attachment): void {
    fetch(Host + attachment.relativePath)
      .then(response => response.text())
      .then(data => {
        attachment.fileData = data;
      });
  }

  setStatusPassed($event, testStep) {
    $event.stopPropagation();
    this.trackedStep(testStep);

    const status = this._statuses.find(val => val.name === 'Pass');
    testStep.status = status.id;
    testStep.statusObject = status;
    testStep.statusText = 'Test step passed';

    this.updateTestStepstatus(testStep);
  }

  updateTestStepstatus(testStep: TestStep) {
    this.testStepService.updateTestStepStatus({
      relation_id: this._testCase.relationId,
      step_id: testStep.id,
      status: testStep.status,
      comment: testStep.comment,
      create_defect: testStep.createDefect,
      tr_id: this.selectedRun.id,
    }).subscribe(result => {
      if (result) {
        if (testStep.status === '1029') {
          this.testRunService.updateTestRunTestCaseStatus({ relation_id: this._testCase.relationId, status: testStep.status, project_id: this.currentProject, comment: testStep.comment, }).toPromise().then(res => {
            this.updateTestRunAndCase(this._testCase.relationId)
          })
        } else this.updateTestRunAndCase(this._testCase.relationId)
      }
      else this.snackBar.open('Error happened while updating the status of Test Step', 'OK', { duration: 3000 });
    })
  }

  updateTestRunAndCase(relationId: string) {
    this.updateTestRuns.emit(true)
    this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
  }

  openFailedStepDialog(testStep, currentSatus) {
    const dialogRef = this.dialog.open(DialogTestCaseFailComponent, {
      width: '650px',
      data: {
        testSteps: this._testRunSteps,
        failedOnTestStep: testStep,
      }
    })

    dialogRef.afterClosed().subscribe(dataFromDialog => {
      if (dataFromDialog !== null) {
        testStep.statusObject = currentSatus;
        testStep.status = currentSatus.id;
        testStep.statusText = 'Test step failed';
        testStep.createDefect = dataFromDialog.createDefect;
        testStep.comment = dataFromDialog.comment
        this.updateTestStepstatus(testStep);
      }
    })
  }

  openDialogViewAttachment(testStep, $event) {
    $event.stopPropagation();
    this.trackedStep(testStep);

    this.dialog.open(DialogViewAttachmentsComponent, {
      width: '650px', data: { testStep }
    }).afterClosed().subscribe(res => {
      this.activeIdsPendingArr = [+testStep.id]
      if (res) {
        forkJoin(
          res.map((file) => {
            const formData = new FormData();
            formData.append(SEARCH_ITEM_CREATE_CONST.formDataFileKey, file, `${file.name}`);
            return this.userService.addAttachments(formData)
          })
        ).subscribe(res => {
          let message = [];
          res?.forEach((elem: { valid: number, data?: { relative_path: string }, error_msg: string }) => {
            if (elem.data) message.push(`${elem.data.relative_path.split('/').reverse()[1] + '.' + elem.data.relative_path.split('/').reverse()[0]} was successfully uploaded`);
            else message.push(elem.error_msg);
          });
          this.snackBar.open(message.join(', '), "OK", { duration: 3000 });
          this.getAttachmentStep(testStep);
        })
      } else this.activeIdsPendingArr = [];
    })
  }

  trackedStep(step: TestStep) {
    if (this._activeTestRunStep && this._activeTestRunStep === step) return
    else {
      this._activeTestRunStep = step;
      this.userService.setCurrentStep({ user_id: JSON.parse(Core.localStorageService.getItem('user')).user.id, test_run_history_id: this._testCase.relationId || this.testCase.relationId, step_id: step.id }).subscribe();
    }
  }

  updateStep(testStep: TestStep) {
    const currentStatus = this._statuses.filter(status => status.id === testStep.status)

    if (currentStatus[0].statusState === '-1') {
      this.openFailedStepDialog(testStep, currentStatus[0])
    } else {
      testStep.statusObject = currentStatus[0];
      testStep.status = currentStatus[0].id;

      if (testStep.statusObject.statusState === '1') {
        testStep.statusText = 'Test step passed';
        this.updateTestStepstatus(testStep);
      } else {
        this.updateTestStepstatus(testStep);
      }
    }
  }

  mapTestCaseSteps(steps) {
    // if testStep doesn't have any status, we set it to neutral
    steps.map(step => {
      step.showUndo = false;
      if (step.status == null) {
        step.status = this._statuses.find(val => +val.statusState === 0 && +val.isDefault === 1).id;
      }
    })
    // mapping status_states to TestSteps
    steps.map(step => {
      this._statuses.map(status => {
        if (status.id === step.status) {
          step.statusObject = status;
          switch (step.statusObject.statusState) {
            case '-1':
              step.statusText = 'Test step failed';
              break;
            case '1':
              step.statusText = 'Test step passed';
              break;
          }
        }
      })
    })
    steps = steps.map(elem => {
      let comment = elem.comment || '';
      return { ...elem, commentValue: comment }
    })
    return steps;
  }

  copyLink(step: TestStep) {
    if (window.location.href) {
      let queryParamArr = window.location.href.split('?')[1].split('&');
      let params = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

      if (params) this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=${params['type']}&id=${params['id']}&tc=${params['tc']}&ts=${step.id}`);
      else this.clipboard.copy(window.location.href);

      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
    }
  }
}

const SEARCH_ITEM_CREATE_CONST = {
  controlNameAttachmentFileCreateForm: 'newFileAttachment',
  controlNameImageFileCreateForm: 'newFileImage',
  controlNameExtraFields: 'extraFields',
  controlNameExperience: 'experience',
  controlNameContacts: 'contacts',
  formDataSearchItemIdKey: 'candidate_id',
  formDataFileKey: 'file',
  errors: {
    contactItemEmail1BySearchItemEmail1Exist: 'contactItemEmail1Exist',
    searchItemEmail1ByContactItemEmail1Exist: 'searchItemEmail1Exist',
  },
};
