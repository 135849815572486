<div class="table-container">
  <mat-table mat-table [dataSource]="dataSource" matSort >

    <ng-container *ngFor="let column of dataColumns; index as i;" [matColumnDef]='column.value'>

      <ng-container *ngIf="column.value === 'select'">
        <mat-header-cell mat-header-cell *matHeaderCellDef >
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row" >
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.value === 'trStatus'">
        <mat-header-cell mat-header-cell *matHeaderCellDef>status</mat-header-cell>
        <mat-cell  mat-cell *matCellDef="let row" >
          <button mat-button class="button-with-tooltip" (mouseenter)="row.isOpen = true" (mouseleave)="row.isOpen = false" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <mat-icon class="test-run-status-icon">info</mat-icon>
          </button>
          <ng-template
              #tooltipWithStatuses
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="row.isOpen"
              [cdkConnectedOverlayPositions] ="positions">
            <app-custom-tooltip [StatusesCount] = "row.statuses"></app-custom-tooltip>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.value === 'defects'">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Defects page</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row"><button mat-icon-button (click)="onDefectsEvent(row)" ><mat-icon>bug_report</mat-icon></button></mat-cell>
      </ng-container>

      <ng-container *ngIf="column.value === 'actions'">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngFor="let action of actionsOptions">
            <button mat-button (click)="onActionsEvent(action, row)">
              <span class="material-icons">{{action}}</span>
            </button>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.value === 'color'">
        <mat-header-cell *matHeaderCellDef >{{column.name}}</mat-header-cell>
        <mat-cell *matCellDef="let row"><span [ngStyle]="{ 'color': row[column.value] }">{{row[column.value]}}</span></mat-cell>
      </ng-container>

      <ng-container *ngIf="column.value === 'statusState'">
        <mat-header-cell *matHeaderCellDef >{{column.name}}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row[column.value] === '1' ? 'Positive' : ''}}
          {{ row[column.value] === '0' ? 'Neutral' : ''}}
          {{ row[column.value] === '-1' ? 'Negative' : ''}}
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="column.value === 'isDefault'">
        <mat-header-cell *matHeaderCellDef>{{column.name}}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row[column.value] === '1' ? 'True' : 'False'}}</mat-cell>
      </ng-container>

      <ng-container *ngIf="column.value === 'actions-status'">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button (click)="onActionsEvent('edit',row)" mat-button><span class="material-icons">edit</span></button>
          <button (click)="onActionsEvent('delete',row)" *ngIf="row['isDefault'] === '0'" mat-button><span class="material-icons">delete</span></button>
        </mat-cell>
      </ng-container>

      <ng-container >

        <ng-container *ngIf="sorted">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{column.name}}</mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="column.clicked && isClickabledRow ? onRowEvent(row): ''" 
                    [ngClass]="{'cursor-pointer': column.clicked && isClickabledRow ? true: false}">
            {{ row[column.value] }}
          </mat-cell>
        </ng-container>

        <mat-header-cell *matHeaderCellDef>{{column.name}}</mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="column.clicked && isClickabledRow ? onRowEvent(row): ''" 
                  [ngClass]="{'cursor-pointer': column.clicked && isClickabledRow ? true: false}">
          {{ row[column.value] }}
        </mat-cell>
        
      </ng-container>

    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row  *matRowDef="let row; columns: displayedColumns" [class.active] = "row['active']"></mat-row>
  </mat-table>
</div>
<mat-paginator  [length]="paginationOptions.length" 
                [pageIndex]="paginationOptions.pageIndex" 
                [pageSize]="paginationOptions.pageSize" 
                [pageSizeOptions]="paginationOptions.pageSizeOptions">
</mat-paginator>
