import { Injectable } from '@angular/core';
import { TestRun } from './test-run.type';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TestCase } from './test-case.type';
import { Core } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class TestRunService {
  private TestRunes: TestRun[];
  public selectTestRun: TestRun;
  public testRunsSub = new BehaviorSubject<TestRun[]>([]);
  public selectedValue = '';
  public sortType: string;

  constructor() { }

  getTestRuns(param): Observable<TestRun[]> {
    if (param.project_id === 'null' || param.project_id === 'all') param = {}
    return from(Core.testRun.list(param).catch(error => console.log(error))).pipe(map(res => {
      if (res) {
        this.TestRunes = this.mapTestRuns(res.test_runs);
        this.testRunsSub.next(this.TestRunes);
        return this.TestRunes;
      } else return []
    }))
  }

  getFailedCasesInTestRun(testRunId: string): Observable<TestCase[]> {
    return from(Core.testRun.failedTestCase({ test_run_id: testRunId }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestCases(res.data);
      else return [];
    }))
  }

  saveTestRun(testRun: TestRun): Observable<TestRun> {
    return from(Core.testRun.update({ testRun }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestRun(res.test_run);
      else return null;
    }))
  }

  updateTestRunTestCaseStatus(body): Observable<any> {
    return from(Core.testCase.updateStatus(body).catch(error => console.error(error))).pipe(map(res => {
      if (res) return res
      else return false;
    }))
  }

  asssignNewUserToTestCase(body): Observable<boolean> {
    return from(Core.testRun.assignUser(body).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false;
    }))
  }

  createTestRun(testRun: TestRun, testCases: any[]): Observable<boolean> {
    return from(Core.testRun.create({
      project_id: testRun.projectId, title: testRun.title,
      description: testRun.description,
      test_cases: testCases
    }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return res;
      else return false;
    }))
  }

  updateTestCasesList(param): Observable<boolean> {
    return from(Core.testRun.updateTestCasesList(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))

  }

  getTestRun(params): Observable<TestRun> {
    return from(Core.testRun.readList(params).catch(error => console.error(error))).pipe(map(res => {
      console.log('read', res)
      if (res) return this.mapTestRun(res.test_run)
      else return null
    }))
  }

  addTestCases(param): Observable<boolean> {
    return from(Core.testRun.addTestCases(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  updateRelation(param): Observable<boolean> {
    return from(Core.testRun.updateRelation(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  archive(param): Observable<boolean> {
    return from(Core.testRun.archive(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  deleteTestRun(params): Observable<boolean> {
    return from(Core.testRun.delete(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  mapTestRuns(raw: any[]): TestRun[] {
    return raw.map((val: any) => this.mapTestRun(val));
  }


  mapTestRun(raw: any): TestRun {
    const result: TestRun = new TestRun();

    result.id = raw.id;
    result.title = raw.title;
    result.description = raw.description;
    result.status = raw.status;
    result.projectId = raw.project_id;
    result.projectName = raw.project_name;
    result.userName = raw.name;
    result.createdDate = raw.created_date;
    result.modifiedDate = raw.modified_date;
    result.statuses = raw.statuses;
    result.archive = raw.archive;
    result.creator_id = raw.creator_id;
    result.testCasesCount = raw.testCasesCount;
    if (raw.testCases !== undefined) {
      result.testCases = raw.testCases;
    };

    return result;
  }

  reRun(params): Observable<TestRun> {
    return from(Core.testRun.reRun(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestRun(res.testRun)
      else return null
    }))
  }

  mapTestCases(raw: any): TestCase[] {
    return raw.map(item => { return this.mapTestCase(item) })
  }

  mapTestCase(raw: any): TestCase {
    const result: TestCase = new TestCase();

    result.id = raw.id;
    result.relationId = raw.relation_id;
    result.executedPercentage = raw.executed_percentage;
    result.status = raw.status;
    result.comment = raw.comment;
    result.assignedTo = raw.assigned_to;
    result.folderId = raw.folder_id;
    result.title = raw.title;
    result.outerBugtrackingLink = raw.outer_bugtracking_link;

    return result;
  }

  selectedArchivedType(item) {
    this.selectedValue = item;
  }
}
