export class User {
  id: string;
  login: string;
  password: string;
  firstName: string;
  lastName: string;
  createdDate: string;
  modifiedDate: string;
  roleId: string;
  companyId: string;
  accountType: string;
}
