import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { TestSuitFoldersTemplate } from '../../services/test-suit.type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestCase } from 'src/app/services/test-case.type';
import { SharedSuitPlanSelectComponent, TodoItemFlatNode } from '../shared/dialog-shared-suit-plan-select/shared-suit-plan-select.component';

export interface DialogTestCasesSelectData {
  testSuits: TestSuitFoldersTemplate[];
  testPlans?: TestPlan[];
  selectedSuits: TestSuitFoldersTemplate[] | TestCase[];
  selectedPlans: TestCase[];
  title: string
}

@Component({
  selector: 'app-dialog-test-cases-select',
  templateUrl: 'dialog-test-cases-select.component.html',
  styleUrls: ['./dialog-test-cases-select.component.scss'],
})
export class DialogTestCasesSelectComponent implements OnInit {
  @ViewChild('shared') public sharedComponent: SharedSuitPlanSelectComponent;
  private casheList: TodoItemFlatNode[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogTestCasesSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTestCasesSelectData,
  ) { }

  ngOnInit() { }

  public checkSelectFromSuit(): boolean {
    if (!this.casheList.length && this.sharedComponent) this.casheList = this.sharedComponent.checklistSelection.selected;
    if (this.casheList.length && this.sharedComponent) {
      return (this.sharedComponent.checklistSelection.selected.length === this.casheList.length)
    };
    return true
  }

  closeEventAction(event) {
    if (event) {
      this.dialogRef.close(!this.sharedComponent.testPlans ? this.sharedComponent.checklistSelection.selected :
        { selectedSuits: this.sharedComponent.checklistSelection.selected, selectedPlans: this.sharedComponent.checklistSelectionPlans.selected });
    }
    else this.dialogRef.close(null);
  }
}
