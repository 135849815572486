import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-abstract-table',
  templateUrl: './abstract-table.component.html',
  styleUrls: ['./abstract-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AbstractTableComponent implements OnInit, AfterViewInit {

  public dataSource: MatTableDataSource<any>;
  private sort: MatSort;

  public displayedColumns: string[];
  public dataColumns: object[];
  public actionsOptions: string[];
  public paginationOptions = {
    length: 1000,
    pageIndex: 0,
    pageSize: 15,
    pageSizeOptions: [15, 30, 50, 100]
  };
  public isClickabledRow = true;

  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -10
    },
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'bottom'
    },
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'top'
    },
  ];

  @Input('selection') public selection = new SelectionModel<any>(true, []);

  @Input() set columns(data: string[]) {
    this.displayedColumns = data
    // IF FIRST COLUMN === 'select' => set cursor pointer and clicked from 2 col to 3 col else from 1col to 2col
    const step:number = data[0] === 'select' ? 1: 0;
    this.dataColumns = data.map((val, ind) => {
      return {
        name: this.startCase(val),
        value: val,
        clicked: 2 + step > ind ? true: false
      }
    })
  }

  @Input() set data(data: any[]) {
    if(data instanceof Array) {
      this.applyDataSource(data);
    }
  }

  @Input() set filter(str: string) {
    this.applySearchFilter(str)
  }

  @Input() set paginationOpt(option) {
    this.paginationOptions = option
  }

  @Input() set actions(actions:string[]) {
    this.actionsOptions = actions
  }

  @Input() set clickabledRow(flag: boolean) {
    this.isClickabledRow = flag
  }

  @Input() sorted: boolean;

  @Output() abstractRowEvent = new EventEmitter();
  @Output() abstractDefectsEvent = new EventEmitter();
  @Output() abstractActionEditEvent = new EventEmitter();
  @Output() abstractActionDeleteEvent = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  constructor(
  ) { }


  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.dataSource instanceof MatTableDataSource) {
      this.dataSource.paginator = this.paginator;
      if(this.sorted) {
        this.dataSource.sort = this.sort
      }
    }
  }

  startCase(string) {
    return string.replace(/([A-Z])/g, ' $1').replace(/^./, (str) =>  str.toUpperCase() )
  }


  applyDataSource(data: any[]) {
    if(data && data.length) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      if(this.sorted) {
        this.dataSource.sort = this.sort
      }
    }
  }

  applySearchFilter(filterValue: string) {
    if (this.dataSource instanceof MatTableDataSource) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  isAllSelected() {
    if (this.dataSource instanceof MatTableDataSource) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  masterToggle() {
    if (this.dataSource instanceof MatTableDataSource){
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onRowEvent(row) {
    this.abstractRowEvent.emit(row)
  }

  onDefectsEvent(row) {
    this.abstractDefectsEvent.emit(row)
  }

  onActionsEvent(action: string, row) {
    switch (action) {
      case 'edit':
        this.onActionsEditEvents(row)
        break;
      case 'delete':
        this.onActionsDeleteEvents(row);
        break;
      default:
        break;
    }
  }

  onActionsDeleteEvents(row) {
    this.abstractActionDeleteEvent.emit(row)
  }

  onActionsEditEvents(row) {
    this.abstractActionEditEvent.emit(row)
  }

}
