import { Injectable, EventEmitter } from '@angular/core';
import { Project } from './project.type';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { Status } from './status.type';
import { TestCaseService } from './test-case.service';
import { Core } from './core.service';
import { CardManageStatusesComponent } from '../settings-page/card-manage-statuses/card-manage-statuses.component';
import { JiraIntegrationPageComponent } from '../jira-integration-page/jira-integration-page.component';
import { CardJiraIntegrationComponent } from '../jira-integration-page/card-jira-integration/card-jira-integration.component';
import {CardUsersComponent} from "../settings-page/card-users/card-users.component";


@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private projects: Project[];

  private selectedProjectNameType = new BehaviorSubject<string>('');
  selectedProjectName = this.selectedProjectNameType.asObservable();

  public eventEmitterChangeProject: EventEmitter<Project> = new EventEmitter<Project>();
  private settingActiveComponent: { type: string, data: CardManageStatusesComponent | CardJiraIntegrationComponent | CardUsersComponent } = null;

  constructor(private userService: UserService, private testCaseService: TestCaseService) { }

  changeSelectedProjectName(name: string): void {
    this.selectedProjectNameType.next(name);
  }

  setSelectedProjectToStorage(projectId: string | null): void {
    Core.localStorageService.setItem('selected_project', JSON.stringify(projectId))
  }

  getSelectedProjectFromStorage() {
    return JSON.parse(Core.localStorageService.getItem('selected_project'))
  }

  get getPrivatProject(): Project[] {
    return this.projects;
  }

  getProjects(): Observable<Project[]> {
    return from(Core.projects.list({}).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        this.projects = this.mapProjects(res.projects);
        return this.projects
      } else return []
    }))
  }

  getProject(params): Observable<Project> {
    return from(Core.projects.readList(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapProject(res.project)
    }))
  }

  getProjectStatuses(projectId: string): Observable<Status[]> {
    let param;
    if (projectId === 'null' || projectId === 'all') param = {}
    else param = { project_id: projectId }
    return from(Core.projects.statusesList(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.testCaseService.mapTestCaseStatuses(res.test_case_statuses)
      else return []
    }))
  }

  getAllProjectsStatuses(): Observable<Status[]> {
    return from(Core.projects.statusesList({}).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.testCaseService.mapTestCaseStatuses(res.test_case_statuses)
      else return []
    }))
  }

  createStatus(param): Observable<Status> {
    return from(Core.statuses.create(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.testCaseService.mapTestCaseStatus(res.status)
    }))
  }

  updateStatus(param): Observable<boolean> {
    return from(Core.statuses.update(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  deleteStatus(id): Observable<boolean> {
    return from(Core.statuses.delete({ id }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  createProject(param): Observable<boolean> {
    return from(Core.projects.create(param).catch((error) => console.log(error))).pipe(map(res => {
      if (res) {
        this.setSelectedProjectToStorage(res.project.id);
        return true
      } else return false
    }))
  }

  updateProjectAccess(projectId: number, userIds: string[]): Observable<boolean> {
    return from(Core.projects.updateAccess({ project_id: projectId, users_ids: userIds }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  deleteProject(id): Observable<boolean> {
    return from(Core.projects.delete({ id }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  saveProject(project: Project): Observable<Project> {
    return from(Core.projects.update({ project }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapProject(res.project)
      else return null
    }))
  }

  emitEvent(project: Project) {
    this.eventEmitterChangeProject.emit(project);
  }

  setSettingActiveComponent(data: CardManageStatusesComponent | CardJiraIntegrationComponent | CardUsersComponent, type: string = null) {
    this.settingActiveComponent = { type, data };
  }

  getSettingActiveComponent(): { type: string, data: CardManageStatusesComponent | CardJiraIntegrationComponent | CardUsersComponent } {
    return this.settingActiveComponent;
  }

  mapProjects(raw: any[]): Project[] {
    return raw.map((val: any) => this.mapProject(val));
  }

  mapProject(raw: any): Project {
    const result: Project = new Project();

    result.id = raw.id;
    result.title = raw.title;
    result.description = raw.description;
    result.createdDate = raw.created_date;
    result.modifiedDate = raw.modified_date;
    result.creator_id = raw.creator_id;
    result.creator_name = raw.creator_name;

    if (raw.users !== undefined) {
      result.users = this.userService.mapUsers(raw.users);
    };

    return result;
  }
}
