import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { UserAccessService } from '../services/user-access';

@Injectable({
  providedIn: 'root'
})
export class JiraIntergrationGuard implements CanActivate {

  constructor(
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _userAccessService: UserAccessService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this._userAccessService.getAccess('setting', 'jiraIntergation')) {
      return true
    } else {
      this.router.navigate(['/settings-page']);
      return false
    }
  }

}
