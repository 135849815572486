import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, SubscriptionLike, of } from 'rxjs';
import { DialogCreateTestCaseComponent } from 'src/app/dialogs/dialog-create-test-case/dialog-create-test-case.component';
import { ProjectService } from 'src/app/services/project.service';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { Clipboard } from '@angular/cdk/clipboard';
import { Params } from '@angular/router';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-unesigned-cases',
  templateUrl: './card-unesigned-cases.component.html',
  styleUrls: ['./card-unesigned-cases.component.scss']
})
export class CardUnesignedCasesComponent implements OnInit, OnDestroy {
  public addAccessAction: boolean = this._userAccessService.getAccess('testCase', 'testCaseCreateLevel');

  @ViewChild(MatSelectionList, { static: true }) public selectionList: MatSelectionList;
  public testCases: TestCase[];
  public isLoading: boolean = false;
  public selectedValue: TestCase;
  public deletedItem: TestCase;
  public pending: boolean;
  public params: Params;

  private selectionListSubscriber: SubscriptionLike;

  @Input() public queryParams: Params;
  @Output() showTestStep = new EventEmitter<TestCase>();

  constructor(
    private testCaseService: TestCaseService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void {
    this.getTestCases();
  }

  ngOnDestroy(): void {
    this.selectionListSubscriber?.unsubscribe();
  }

  public getTestCases() {
    this.testCaseService.getUnesignedTestCases(this.projectService.getSelectedProjectFromStorage()).subscribe(res => {
      if (res) {
        this.isLoading = true;
        this.testCases = res;
        this.searchPath();
        this.setSelectionList();
      }
    });
  }

  searchPath() {
    if (!this.queryParams) {
      let queryParamArr = window.location.href.split('?')[1]?.split('&');
      queryParamArr ? this.params = queryParamArr.map(elem => elem?.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {}) : this.params = null;
    } else this.params = this.queryParams;

    let find = this.testCases.find(elem => +elem.id === +this.params?.id);
    if (find && this.params) {
      let sub = this.selectionList.options.changes.subscribe(res => {
        res.forEach(elem => { if (+elem.value.id === +this.params.id) elem.selected = true });
        if (sub) sub.unsubscribe();
      });
      this.showTestStep.emit(find);
    }
  }

  setSelectionList() {
    this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true });
    });
  }

  selectionChange(event: MatSelectionListChange) {
    event.options.forEach(item => {
      this.selectedValue = item.value;
      window.history.pushState({}, null, `${window.location.origin}/test-cases-page/unesigned-cases?project=${item.value.projectId}&type=tcs&id=${item.value.id}`);
      this.showTestStep.emit(item.value);
      this.selectedValue = item.value;
    })
  }

  public onAddEvent() {
    this.dialog.open(DialogCreateTestCaseComponent, {
      width: '650px',
      data: { info: 'unesigned', title: 'Unesigned Test Case' }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open('Unesigned case was successfully added', "OK", { duration: 3000 });
        this.getTestCases();
      }
    })
  }

  // onEditEvent(testCase: TestCase) {
  //   this.dialog.open(DialogEditComponent, {
  //     width: '650px',
  //     data: { dialogTitle: 'Edit Test Cases', title: testCase.title, description: testCase.description }
  //   }).afterClosed().subscribe(res => {
  //     if (res) {
  //       this.testCaseService.saveTestCase({ ...testCase, ...res }).subscribe(res => {
  //         if (res) {
  //           this.snackBar.open('Cases was successfully edited', 'OK', { duration: 3000 });
  //           this.getTestCases();
  //         }
  //       });
  //     }
  //   })
  // }

  // public onDeleteCaseEvent(testCase: TestCase) {
  //   this.deletedItem = testCase;
  //   this.dialog.open(DialogConfirmComponent, {
  //     width: '650px',
  //     data: { dialogTitle: 'delete', name: testCase.title }
  //   }).afterClosed().subscribe((res: boolean) => {
  //     if (res && testCase) {
  //       this.pending = true;
  //       // this.testCaseService.deleteTestCase(testCase.tesSuitRelationId).subscribe((res) => {
  //       //   if (res) {
  //       // this.pending = false;
  //       // this.deletedItem = null;
  //       // this.snackBar.open('Cases was successfully deleted', 'OK', { duration: 3000 });
  //       //   this.getTestCases();
  //       // }
  //       // });
  //     } else this.deletedItem = null;
  //   })
  // }

  copyLink() {
    if (this.params) {
      if (!this.params['ts']) this.clipboard.copy(window.location.href);
      else this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${this.params['project']}&type=${this.params['type']}&id=${this.params['id']}`);
      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
    }
  }
}
