import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { FormControl, FormGroup } from '@angular/forms';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-projects-info',
  templateUrl: './card-projects-info.component.html',
  styleUrls: ['./card-projects-info.component.scss']
})

export class CardProjectsInfoComponent implements OnInit, OnChanges {
  public editAccessAction: boolean = this._userAccessService.getAccess('project', 'projectEditLevel');

  constructor(
    private projectService: ProjectService,
    private _userAccessService: UserAccessService,
  ) { }

  public isDisabledInputs: boolean = true;
  public textForEditButton: string = 'Edit';
  public editProjectForm: FormGroup;
  @Input() project;

  @Output() closeEvent = new EventEmitter();
  @Output() updateProjects = new EventEmitter();

  ngOnInit(): void {
    this.editProjectForm = new FormGroup({
      "title": new FormControl({ value: this.project.title, disabled: this.isDisabledInputs }),
      "description": new FormControl({ value: this.project.description, disabled: this.isDisabledInputs }),
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project && this.editProjectForm) {
      this.editProjectForm.patchValue({
        title: this.project.title,
        description: this.project.description
      });
      this.textForEditButton = 'Edit';
      this.editProjectForm.disable()
    }
  }

  editProject() {
    this.isDisabledInputs = !this.isDisabledInputs;

    switch (this.textForEditButton) {
      case 'Edit':
        this.textForEditButton = 'Save';
        this.editProjectForm.enable()
        break;
      case 'Save':
        this.projectService.saveProject({ ...this.project, title: this.editProjectForm.get('title').value, description: this.editProjectForm.get('description').value }).subscribe(v => {
          this.editProjectForm.disable()
          this.textForEditButton = 'Edit';
          this.updateProjects.emit();
        })
        break;
    }
  }

}
