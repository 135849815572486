<div class="dialog-title">
  <h1 mat-dialog-title>Create New Test Run</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Title</mat-label>
    <input matInput [(ngModel)]="title">
  </mat-form-field>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" style="background: #2d64ba" (click)="createAction()">Create</button>
</div>
