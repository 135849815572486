import { Component, OnInit, ViewChild } from '@angular/core';
import { TestRunService } from '../../../services/test-run.service';
import { ProjectService } from '../../../services/project.service';
import { TestRun } from '../../../services/test-run.type';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-table-last-runs',
  templateUrl: './table-last-runs.component.html',
  styleUrls: ['./table-last-runs.component.scss']
})
export class TableLastRunsComponent implements OnInit {

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;
  public sizeOptions: number[] = [5, 10, 20];
  public dataSource = new MatTableDataSource<TestRun>();
  public currentProject;
  public testRuns: TestRun[] = [];
  public isLoading = true;

  displayedColumns: string[] = ['Project', 'Test Run', 'Created Date', 'Pass/From', 'Created By'];

  constructor(
    private testRunService: TestRunService,
    private projectService: ProjectService,
  ) {
  }

  ngOnInit() {
    this.currentProject = this.projectService.getSelectedProjectFromStorage();
    this.getData();
    this.dataSource.paginator = this.paginator;
  }


  getData() {
    this.testRunService.getTestRuns({ project_id: this.currentProject }).subscribe(v => {
      v.forEach(item => {
        Object.defineProperty(item, 'passCount', {
          value: item.statuses.find(status => status['name'] === 'Pass') || 0
        });
      });
      this.dataSource.data = v.sort((a, b) => a.modifiedDate < b.modifiedDate ? 1 : -1);
      this.isLoading = false;
    });
  }

  updateData(project) {
    this.currentProject = project;
    this.getData();
  }

  getRange(value: number): number[] {
    return Array(value).fill(0).map((x, i) => i + 1);
  }

}
