<h3 class="table-title">Table of Activity</h3>

<div class="table-container">
  <div class="table-pagination">
    <div class="mat-select-container">
      <div class="mat-select-item">
        <mat-form-field appearance="outline">
          <mat-label>Select users</mat-label>
          <mat-select [(ngModel)]="selectedUser" (selectionChange)="getDataByUser($event)">
            <mat-option [value]="'all'">All users</mat-option>
            <mat-option *ngFor="let user of users" [value]="user.firstName">
              {{user.firstName | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="mat-select-item">
        <mat-form-field appearance="outline">
          <mat-label>Select activity</mat-label>
          <mat-select [(ngModel)]="selectedActivity" (ngModelChange)="getDataByActivity($event)">
            <mat-option *ngFor="let activity of activityType" [value]="activity">
              {{activity | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-paginator #paginator [length]="tableData.data.length" [pageSizeOptions]="paginationSize"
      aria-label="Select page"></mat-paginator>
  </div>

  <table mat-table [dataSource]="tableData" class="table mat-elevation-z8">

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef> Project </th>
      <td mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>

    <ng-container matColumnDef="by">
      <th mat-header-cell *matHeaderCellDef> Activity By</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Activity Type</th>
      <td mat-cell *matCellDef="let element"> {{element.action_type | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="in">
      <th mat-header-cell *matHeaderCellDef> Activity in</th>
      <td mat-cell *matCellDef="let element"> {{element.description | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Title</th>
      <td mat-cell *matCellDef="let element">
        <p class="activity-item-title" (click)="navigateTo(element.ref)">{{ element.item_title }}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <ng-container *ngIf="isLoading">
    <ul class="loader-ul">
      <li *ngFor="let item of getRange(paginationSize[0])" class="loader-item">
        <ngx-skeleton-loader [theme]="{ height: '40px', background: '#D6DBDF', margin: '0px 0px 0px 0px' }" [count]="1"
          appearance="line"></ngx-skeleton-loader>
      </li>
    </ul>
  </ng-container>