import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CardManageStatusesComponent } from './card-manage-statuses/card-manage-statuses.component';
import { ProjectService } from '../services/project.service';
import {CardUsersComponent} from "./card-users/card-users.component";

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CardUsersComponent) usersComponent: CardUsersComponent;

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.projectService.setSettingActiveComponent(null);
  }

  ngAfterViewInit(): void {
    if (this.usersComponent) this.projectService.setSettingActiveComponent(this.usersComponent, 'CardUsersComponent');
  }
}
