<app-sidenav class="test-runs-details-page-test">
  <mat-card *ngIf="testRun" [class.no-underline]="!editMode" id="panel" class="sidenav-opened">
    <div class="sidenav-opened__header">
      <button mat-icon-button class="base-button" (click)="goToTestRuns()">
        <mat-icon style="color: #000;">arrow_back_ios</mat-icon>
      </button>
      <mat-panel-title class="details">
        <span class="details__title" *ngIf="testRun && testRun.title">{{testRun.title}} - <span class="color-red details__title">Defects</span>
        <span *ngIf="!testRun || !testRun.title"><mat-spinner [diameter]="20"></mat-spinner></span>
        </span>
        <span class="details__id" *ngIf="testRun && testRun.id">
          TEST RUN ID - {{testRun.id}}
        </span>
        <span *ngIf="!testRun || !testRun.id"><mat-spinner [diameter]="15"></mat-spinner></span>
      </mat-panel-title>
      <div [ngStyle]="{'display': (componentShowHide === 'hide' || componentShowHide === 'start') ? 'none' : 'block'}" class="resize-toggle-group-wrap">
        <mat-button-toggle-group class="resize-toggle-group">
          <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'large'}" (click)="onResize('large')"><mat-icon svgIcon="cell-divide-in-left-area"></mat-icon></mat-button-toggle>
          <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'medium'}" (click)="onResize('medium')"><mat-icon svgIcon="cell-divide-in-center-area"></mat-icon></mat-button-toggle>
          <mat-button-toggle class="resize-toggle mat-button-toggle-checked" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'small'}" (click)="onResize('small')"><mat-icon svgIcon="cell-divide-in-right-area"></mat-icon></mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <button mat-raised-button (click)="createDefects()" [disabled] = "selectedTestCases.length === 0"><mat-icon>add</mat-icon>Create Defects</button>
    </div>
  </mat-card>
  <div class="main-container">
      <app-test-run-details-table
                                [data]="testCases"
                                [displayedColumns] = "displayedColumns"
                                [statuses] = "statuses"
                                [users] = "users"
                                (openSideComponent) = openDefectDetail($event);
                                (selectionChange) = "updateSelectedCases($event)"
                                style ="display: flex; flex-grow: 1"
                                ></app-test-run-details-table>
    <div class="test-run-details-sidebar" [@changeSideComponentSize]="componentShowHide" (@changeSideComponentSize.done)="doneAnimation()">
      <app-test-run-details-sidebar [testCase]="testCase"
                                    [goBackURLId]="testRun.id"
                                    [failedTestSteps] ="testSteps"
                                    [defectsPage] = "defectsPage"
                                    [defaultStatuses] = "defaultStatuses"
                                    [currentProjectId] = "currentProjectId"
                                    [statuses] = "allStatuses"
                                    (Resize)="onResize($event)"
      ></app-test-run-details-sidebar>
    </div>
  </div><!-- End of main container-->
</app-sidenav>

