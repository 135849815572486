export class Attachment {
  id: string;
  userTrackingId: string;
  relativePath: string;
  createdAbsolutePath: string;
  fileType: string;
  fileDataType: string;
  fileData: string;
  fullPath?: string;
}
