<app-base-card [title]="'Users'" [icon]="'person'" [loader]="true" [showAdd]="addUserAccessAction"
  (closeEvent)="closeEvent.emit()" (addEvent)="onSelectUsers()">

  <mat-list>
    <mat-list-item *ngFor="let item of projectUsers" class="list-card__item item-list">
      <div class="item-list__body">
        <div class="item-list__title">{{item.firstName}} {{item.lastName}}</div>
      </div>
    </mat-list-item>
  </mat-list>
</app-base-card>