import { TestCaseService } from './../../services/test-case.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { TestRun } from '../../services/test-run.type';
import { TestRunService } from '../../services/test-run.service';
import { ProjectService } from '../../services/project.service';
import { TestSuit } from '../../services/test-suit.type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TestPlan } from 'src/app/services/test-plan.type';
import { ChecklistDatabase, SharedSuitPlanSelectComponent } from '../shared/dialog-shared-suit-plan-select/shared-suit-plan-select.component';

export interface DialogData {
  testSuits: TestSuit[];
  testPlans: TestPlan[];
}

@Component({
  selector: 'app-dialog-create-project',
  templateUrl: 'dialog-create-test-run.component.html',
  styleUrls: ['./dialog-create-test-run.component.scss']
})
export class DialogCreateTestRunComponent implements OnInit {
  @ViewChild('shared') public sharedComponent: SharedSuitPlanSelectComponent;

  testRun: TestRun = new TestRun();
  projects: Project[];
  nextStep = false;
  testSuits: TestSuit[] = [];
  testPlans: TestPlan[] = [];
  selectedTestCases: any[] = [];
  selectedTestPlans: any[] = [];

  craeteTestRunsForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateTestRunComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
    private testRunService: TestRunService,
    private _database: ChecklistDatabase,
    private testCaseService: TestCaseService
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.projectService.getProjects().subscribe(response => {
      this.projects = response;
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  initForm() {
    this.craeteTestRunsForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      project: new FormControl(this.projectService.getSelectedProjectFromStorage(), [Validators.required]),
    })
  }

  get f() {
    return this.craeteTestRunsForm.controls
  }

  createRun() {
    this.selectedTestCases = this.mapSelectedTestCases(this.sharedComponent?.checklistSelection.selected);
    this.selectedTestPlans = this.mapSelectedTestPlans(this.sharedComponent?.checklistSelectionPlans.selected);

    this.testRun.title = this.craeteTestRunsForm.value.title;
    this.testRun.description = this.craeteTestRunsForm.value.description;
    this.testRun.projectId = this.craeteTestRunsForm.value.project;

    this.testRunService.createTestRun(this.testRun, this.selectedTestCases).subscribe(res => {
      this.dialogRef.close({ addSuits: res, toAddPlans: this.selectedTestPlans })
    });
  }

  mapSelectedTestCases(treeData): any {
    const selectedTestCases = [];
    treeData.forEach((value) => {
      if (value.isCase) selectedTestCases.push({ test_case_id: value.elem.id, test_suit_id: value.elem.tsId });
    });
    return selectedTestCases;
  }

  mapSelectedTestPlans(treeData): any {
    const selectedTestPlans = [];
    treeData.forEach((value) => {
      if (value.level === 0) selectedTestPlans.push(value.id);
    });
    return selectedTestPlans;
  }

}