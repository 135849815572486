import {Component, Inject} from '@angular/core';
import {User} from '../../services/user.type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  users: User[],
  selectedOptions: any[]
}

@Component({
  selector: 'app-dialog-users-select',
  templateUrl: 'dialog-users-select.component.html',
  styleUrls: ['./dialog-users-select.component.scss'],
})
export class DialogUsersSelectComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogUsersSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onCancel(): void {
    this.dialogRef.close();
  }

}
