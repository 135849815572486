import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-select',
  templateUrl: './dialog-select.component.html',
  styleUrls: ['./dialog-select.component.scss']
})
export class DialogSelectComponent implements OnInit {

  public selectForm: FormGroup;
  public title: string;
  public lists: any[];
  public showName:string;
  public item: any;
  public value: string

  constructor(
    public dialogRef: MatDialogRef<DialogSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string, 
      item: any, 
      lists: any[], 
      value: any,
      showName: string
    },
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.lists = this.data.lists;
    this.showName = this.data.showName;
    this.item = this.data.item;
    this.value = this.data.value;
    this.initForm();
  }

  initForm() {
    this.selectForm = new FormGroup({
      option: new FormControl(this.data.item[this.data.value], [Validators.required])
    })
  }

  onCancel(){
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.dialogRef.close(this.item)
  }
}
