import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params } from '@angular/router';
import { Core } from 'src/app/services/core.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestStepService } from 'src/app/services/test-step.service';
import { TestStep } from 'src/app/services/test-step.type';
import { Clipboard } from '@angular/cdk/clipboard';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-unesigned-step',
  templateUrl: './card-unesigned-step.component.html',
  styleUrls: ['./card-unesigned-step.component.scss']
})
export class CardUnesignedStepComponent implements OnInit {
  public addAccessAction: boolean = this._userAccessService.getAccess('testStep', 'testStepCreateLevel');

  @ViewChildren('panel') private panelSteps: QueryList<MatExpansionPanelTitle>;

  private _testCase: TestCase;
  private _testSteps: TestStep[];
  public testSteps: TestStep[];

  public showAddNewTestStepForm: boolean = false;
  public testStepForm: FormGroup;
  @Input() public queryParams: Params;
  @Input() public queryParamsStep: Params;

  public isLoading = true;

  @Input() set testCase(data: TestCase) {
    if (data) {
      this._testCase = data;
      this.getTestStep(data.id);
      this.closeTestStepForm(true);
    }
  }

  @Output() closeEvent = new EventEmitter()

  constructor(
    private testStepService: TestStepService,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private _userAccessService: UserAccessService
  ) { }

  ngOnInit(): void { }

  searchPath(param: Params) {
    let index: number;
    let find = this.testSteps.find((elem: TestStep, i: number) => {
      if (+elem.id === +param['ts']) index = i;
      return +elem.id === +param['ts']
    });
    if (find) {
      let sub = this.panelSteps.changes.subscribe(res => {
        res.forEach((elem: MatExpansionPanel, i: number) => { if (i === index) elem.expanded = true });
        if (sub) sub.unsubscribe();
      })
    }

    window.history.pushState({}, null, `${window.location.origin}/test-cases-page/unesigned-cases?project=${param.project}&type=tcs&id=${param.id}&ts=${param.ts}`);
  }

  copyLink() {
    if (window.location.href) {
      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
      this.clipboard.copy(window.location.href);
    }
  }

  getTestStep(id: string) {
    this.testSteps = [];
    this.isLoading = true;
    this.testStepService.getTestSteps(id).subscribe(res => {
      this.testSteps = this.createTestStepForm(res);
      this._testSteps = res;
      this.isLoading = false;

      if (this.queryParamsStep?.ts) this.searchPath(this.queryParamsStep)
      else {
        let queryParamArr = window.location.href.split('?')[1].split('&');
        queryParamArr.length >= 4 ? queryParamArr.length = 4 : queryParamArr = null;
        let params = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});
        if (params) this.searchPath(params);
      }
    })
  }

  createTestStepForm(testStep: TestStep[]): TestStep[] {
    return testStep.map(step => {
      step['form'] = this.createForm(step);
      return step
    })
  }

  createForm(testStep: TestStep) {
    return new FormGroup({
      id: new FormControl(testStep.id),
      description: new FormControl(testStep.description, [Validators.required]),
      expectedResult: new FormControl(testStep.expectedResult, [Validators.required]),
    })
  }

  createTestStep(testStep: TestStep) {
    this.closeTestStepForm(true);
    this.testStepService.createTestStep(this._testCase.id, testStep).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Step was succefully created', 'OK', { duration: 3000 })
        this.getTestStep(this._testCase.id)
      }
    })
  }

  updateStep(testStep: TestStep) {
    this.testStepService.saveStep(testStep).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Step was succefully edited', 'OK', { duration: 3000 })
        this.getTestStep(this._testCase.id)
      }
    })
  }

  onDeleteStep(testStep: TestStep) {
    this.testStepService.deleteTestStep(testStep.id).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Step was succefully deleted', 'OK', { duration: 3000 })
        this.getTestStep(this._testCase.id)
      }
    })
  }

  onAddNewTestStep() {
    this.testStepForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
      expectedResult: new FormControl('', [Validators.required]),
    })
    this.showAddNewTestStepForm = true;
  }

  closeTestStepForm(res: boolean) {
    if (res) {
      this.testStepForm = null;
      this.showAddNewTestStepForm = false;
    }
  }

  chooseStep(step: TestStep, panel: MatExpansionPanel) {
    let queryParamArr = window.location.href.split('?')[1]?.split('&');
    queryParamArr?.length >= 3 ? queryParamArr.length = 3 : queryParamArr = null;

    if (queryParamArr && !this.queryParams.type) this.queryParams = queryParamArr.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});
    if (queryParamArr && panel.expanded) window.history.pushState({}, null, `${window.location.origin}/test-cases-page/unesigned-cases?${queryParamArr.join('&')}&ts=${step.id}`);
    else if (queryParamArr && !panel.expanded) window.history.pushState({}, null, `${window.location.origin}/test-cases-page/unesigned-cases?${queryParamArr.join('&')}`);
    else window.history.pushState({}, null, `${window.location.origin}/test-cases-page/unesigned-cases?project=${this._testCase.projectId}&type=tcs&id=${this._testCase.id}&ts=${step.id}`);
  }
}
