<!--Main column with runs, plans and suits-->
<div class="main-column">
  <app-main-list
    [data]="data[appTypes.testRun].data"
    [currentType]="appTypes.testRun"
    canRun = "0"
    (itemClicked)="onItemClicked($event, appTypes.testRun)"
    (createNewItemEvent)="createNewItem($event, appTypes.testRun)">
  </app-main-list>
  <app-main-list
    [data]="data[this.appTypes.testPlan].data"
    [currentType]="appTypes.testPlan"
    canRun = "1"
    (itemClicked)="onItemClicked($event, appTypes.testPlan)"
    (createNewItemEvent)="createNewItem($event, appTypes.testPlan)">
  </app-main-list>
  <app-main-list
    [data]="data[this.appTypes.testSuit].data"
    [currentType]="appTypes.testSuit"
    canRun = "1"
    (itemClicked)="onItemClicked($event, appTypes.testSuit)"
    (createNewItemEvent)="createNewItem($event, appTypes.testSuit)">
  </app-main-list>
</div>  

<!--Secondary columns renderend based on what element was clicked in main column-->
<ng-container *ngFor="let item of nesting">
  <app-main-list
    [data]="data[item].data"
    [currentType]="item"
    canRun = "0"
    canClose="1"
    (itemClicked)="onItemClicked($event, item)"
    (closeEvent)="onClose($event)"
    *ngIf="data[item].data?.length && !data[item].creationMode"
    (createNewItemEvent)="createNewItem($event, item)">
  </app-main-list>
  <app-test-cases-creation-mode *ngIf="item === appTypes.testCase && data[item].creationMode">
  </app-test-cases-creation-mode>
</ng-container>
