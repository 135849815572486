import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {ManageProjectsDatasource} from '../manage-projects/manage-projects.datasource';
import {ProjectService} from '../services/project.service';
import {Router} from '@angular/router';
import {Project} from '../services/project.type';

@Component({
  selector: 'app-manage-projects',
  templateUrl: './manage-projects.component.html',
  styleUrls: ['./manage-projects.component.scss']
})
export class ManageProjectsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataSource: ManageProjectsDatasource;

  constructor(private projectService: ProjectService, private router: Router) { }

  displayedColumns = ['id', 'title', 'description', 'createdDate', 'modifiedDate'];

  ngOnInit() {
    this.dataSource = new ManageProjectsDatasource(this.paginator, this.sort, this.projectService);
  }

  navigateToDetail(row: Project) {
    this.router.navigate(['project', row.id]);
  }

}
