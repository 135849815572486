import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ElementRef, ViewChildren, QueryList, Input, ViewContainerRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, Observable, of, SubscriptionLike } from 'rxjs';
import { DialogCreateTestCaseComponent } from 'src/app/dialogs/dialog-create-test-case/dialog-create-test-case.component';
import { DialogEditComponent } from 'src/app/dialogs/dialog-edit/dialog-edit.component';
import { DataService } from 'src/app/services/data.service';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestPlanService } from 'src/app/services/test-plan.service';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestRun } from 'src/app/services/test-run.type';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { TestSuit, TestSuitFoldersTemplate } from 'src/app/services/test-suit.type';
import { Core } from '../../services/core.service';
import { User } from '../../services/user.type';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { TestSuitFolders } from 'src/app/services/test-suit.type';
import { ProjectService } from 'src/app/services/project.service';
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component';
import { DialogSelectSuitComponent } from "../../dialogs/dialog-select-suit/dialog-select-suit.component";
import { DialogCaseConnectionsComponent } from "../../dialogs/dialog-case-connections/dialog-case-connections.component";
import { UserAccessService } from 'src/app/services/user-access';


@Component({
	selector: 'app-card-test-cases',
	templateUrl: './card-test-cases.component.html',
	styleUrls: ['./card-test-cases.component.scss']
})
export class CardTestCasesComponent implements OnInit, AfterViewInit, OnDestroy {
	public addTestCaseAccess: boolean = this._userAccessService.getAccess('testCase', 'testCaseCreateLevel');
	public editTestCaseAccess: boolean = this._userAccessService.getAccess('testCase', 'testCaseEditLevel');
	public deleteTestCaseAccess: boolean = this._userAccessService.getAccess('testCase', 'testCaseDeleteLevel');
	public testSuitAddTestCaseAccess: boolean = this._userAccessService.getAccess('testSuit', 'testSuitAddCaseLevel');

	@ViewChildren('suit') suitsList: QueryList<any>;
	@ViewChild('main', { read: ViewContainerRef }) container: ViewContainerRef;

	@ViewChild(MatSelectionList, { static: true }) public selectionList: MatSelectionList;
	@ViewChild('formCaseTitle') formForCaseTitle: ElementRef;

	private checkedCases: TestCase[] = [];
	public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
	public currentSuit: string = JSON.parse(Core.localStorageService.getItem('chosenTestSuit'));
	public testSuits: TestSuit[] = this.testSuitService.getCacheSuits();
	public testCases: TestSuitFoldersTemplate[];
	public testSuitFolders: TestSuitFolders;
	public testSuitCacheCases: TestSuitFoldersTemplate[];

	public selectedValue: TestCase;
	public search: string;
	public deletedItems: TestCase[];
	public pending: boolean;
	public activeSuit: number;

	public url: string;
	@Input() queryParams: Params;

	public cashTreeDataAllItem = null;
	public isLoading = true;

	/* CREATION MODE */
	public selectedTestsControl: FormControl = new FormControl();
	public selectedSuitControlForCreate: FormControl = new FormControl();
	public createCaseCreationMode: FormGroup;
	public selectedTests: TestRun[] | TestSuitFoldersTemplate[] | TestPlan[] = [];
	public selectedSuit: TestSuit;
	public creationMode: boolean = false;

	/* CREATION MODE */
	@Output() showTestStep = new EventEmitter<TestCase>();
	@Output() changeUrl = new EventEmitter<any>();
	@Output() closeTestStep = new EventEmitter<boolean>();

	private subscriberTestCases: SubscriptionLike;
	private subscriberTestRun: SubscriptionLike;
	private subscriberTestPlan: SubscriptionLike;
	private subscriberTestSuit: SubscriptionLike;
	private subscriberDataService: SubscriptionLike;
	private updateCaseSubscription: SubscriptionLike;
	private selectionListSubscriber: SubscriptionLike;
	private users = [];
	private assignedTo: User;
	private subscriptions: SubscriptionLike[] = [];
	private subAfterInit: SubscriptionLike;

	constructor(
		private projectService: ProjectService,
		private testCaseService: TestCaseService,
		private testSuitService: TestSuitService,
		private testPlanService: TestPlanService,
		private testRunService: TestRunService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private dataService: DataService,
		private userService: UserService,
		private clipboard: Clipboard,
		private router: Router,
		private route: ActivatedRoute,
		private _userAccessService: UserAccessService,
	) { }

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	ngAfterViewInit(): void {
		this.subAfterInit = this.suitsList.changes.subscribe(res => {
			let find = this.treeControl.dataNodes.find(item => +this.activeSuit === +item.id);
			if (find) this.scrollTo(find);
			this.subAfterInit.unsubscribe();
		})
	}

	ngOnInit(): void {
		this.getUsers();
		this.getTestCases();

		this.subscriberDataService = this.dataService.creationModeType.subscribe(res => {
			this.creationMode = res;
			if (res) {
				this.openCreationMode();
			} else {
				this.closeCreationMode();
			}
		})
		this.subscriptions.push(this.subscriberDataService);

		if (!this.testSuits) this.testSuitService.getTestSuits({}).subscribe(res => {
			this.testSuits = res
		})

		this.updateCaseSubscription = this.testCaseService.eventUpdateTestCase$.subscribe((testCase: TestCase) => {
			if (this.selectedValue) {
				this.testCaseService.getTestCase({
					id: this.selectedValue.id,
					project_id: this.currentProject
				}).subscribe(v => {
					if (v) this.selectedValue.modifiedDate = v.modifiedDate
				})
			}
		})
		this.subscriptions.push(this.updateCaseSubscription)
	}

	searchPath(param: Params) {
		if (this.queryParams) {
			if (!this.url) this.url = window.location.href;
			this.changeUrl.emit(this.url)

			if (this.testCases?.length) {
				let allTestCases: TestCase[] = [];

				if (param.creationMode) {
					param.creationMode === 'true' ? this.creationMode = true : false;
					this.dataService.changeCreationMode(this.creationMode);
					let queryParamArr = window.location.href?.split('?')[1]?.split('&');
					param = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});
				}

				if (param.sub) {
					let suits = this.testCases.filter(elem => +elem.suit.id === +param.sub && (+elem.parents[0]?.projectId || +elem.suit.projectId === +param.project));
					if (suits) {
						suits.forEach(elem => {
							allTestCases = [...allTestCases, ...elem.cases]
						});
						this.testCases.forEach(elem => {
							if (this.queryParams && +elem.suit.id === +this.queryParams.sub && +elem.level === +this.queryParams.level) {
								elem.show = true;
								this.activeSuit = +elem.suit.id;
								this.getSuitCaseForPush();
								this.toggleSuit(elem, true, false);
							}
						});

						if (param.tc) {
							let find = allTestCases.find(elem => +elem.tesSuitRelationId === +param.tc);
							if (find) {
								let sub = this.selectionList.options.changes.subscribe(res => {
									res.forEach(elem => {
										if (+elem.value.tesSuitRelationId === +param.tc) {
											elem.selected = true;
										}
									});
									if (sub) sub.unsubscribe();
								});
								this.showTestStep.emit(find);
							}
						}
					}
				}
			}
		} else {
			let suit = this.testCases.find(elem => +elem.suit.id === +this.currentSuit);
			if (suit) {
				suit.show = true;
				this.activeSuit = +suit.suit.id;
				this.getSuitCaseForPush();
				this.toggleSuit(suit, true);
			}
		}
	}

	copyLink(key: string, item = null) {
		this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
		switch (key) {
			case 'suit':
				this.clipboard.copy(`${window.location.origin}/test-cases-page/main?project=${item.elem.projectId}&type=tcs&creationMode=${this.creationMode}&sub=${item.elem.id}&level=${item.level}`);
				break;
			case 'case':
				this.clipboard.copy(window.location.href);
				break;
		}
	}

	public get pushButtonDisabled(): boolean {
		return !this.selectedTestsControl.value || this.selectedTestsControl.value?.length === 0 || this.checkedCases.length === 0
	}

	public get createCaseButtonDisabled(): boolean {
		return this.createCaseCreationMode.invalid || !this.selectedSuitControlForCreate.value
	}

	public get deleteButtonDisabled(): boolean {
		return this.checkedCases.length > 0 ? false : true
	}

	setSelectionList() {
		this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
			this.selectionList.deselectAll();
			s.options.forEach(item => {
				item.selected = true
			})
		});
		this.subscriptions.push(this.selectionListSubscriber)
	}

	mapSelectedValue(testCaseFolders: TestSuitFoldersTemplate[]): TestSuitFoldersTemplate[] {
		if (this.selectedValue) {
			return testCaseFolders.map(elem => {
				elem.cases = elem.cases.map(item => {
					if (+item.tesSuitRelationId === +this.selectedValue.tesSuitRelationId) {
						item.isOpen = true
						this.showTestStep.emit(item);
					} else item.isOpen = false;
					return item
				})
				return elem
			})
		} else return testCaseFolders;
	}

	closeCreationMode() {
		this.closeTestStep.emit(true);
		this.setSelectionList();
	}

	async openCreationMode() {
		this.getSelectedTests('testSuit');
		this.getSuitCaseForPush();
		this.selectionList.selectedOptions.selected.length ? this.selectionList.deselectAll() : '';
		this.closeTestStep.emit(true);

		this.initFormCreationMode();
	}

	getTestCases(update = true, flag = true) {
		if (this.testCases) this.testSuitCacheCases = this.testCases;
		let params = {}
		const projectId = this.projectService.getSelectedProjectFromStorage();
		if (projectId !== 'all' && projectId !== 'null') params = { project_id: projectId };

		this.subscriberTestCases = this.testSuitService.foldersList(params).subscribe((res) => {
			this.testSuitFolders = res;
			let casesObj = res.reduce((acc, key) => [...acc, this.mapSuitFoldersTemplate(key)], []);
			this.testCases = casesObj.reduce((acc, item) => [...acc, ...item], []);
			this.testCases = this.mapSelectedValue(this.testCases);

			this.cashTreeDataAllItem = this.treeControl?.dataNodes.find(elem => elem.id === 'all');
			if (!this.dataSource) this.onInitTree();
			this.checkTreeList();

			this.isLoading = false;

			if (!this.testSuitCacheCases) this.searchPath(this.queryParams);
			this.setSelectionList();
		});
		this.subscriptions.push(this.subscriberTestCases);

		if (this.creationMode && update) {
			this.getSuitCaseForPush();
			this.getSelectedTests('testSuit');
		}
	}

	public mapSuitFoldersTemplate(testFolder: TestSuitFoldersTemplate, arr = [], level = 0, par: any[] = []): TestSuitFoldersTemplate[] {
		let suitCases = arr;
		let parSuits = [...par, testFolder.suit]
		suitCases.push({ ...testFolder, show: false, level, parents: par });
		if (testFolder.sub_suits?.length > 0) {
			testFolder.sub_suits.forEach(elem => this.mapSuitFoldersTemplate(elem[0], suitCases, level + 1, parSuits));
		}
		return suitCases
	}

	getSuitCaseForPush() {
		this.currentSuit ? this.selectedSuitControlForCreate.setValue(this.currentSuit.toString()) :
			this.activeSuit ? this.selectedSuitControlForCreate.setValue(this.activeSuit.toString()) :
				this.selectedSuitControlForCreate.reset();
		this.setFocus();
	}

	selectionChange(event: MatSelectionListChange) {
		event.options.forEach(item => {
			this.showTestStep.emit(item.value);
			this.selectedValue = item.value;
			this.activeSuit = item.value.tsId;
			this.addQueryParamsCase(this.selectedValue, this.activeSuit)
		})
	}

	onAddEvent(node = null) {
		this.dialog.open(DialogCreateTestCaseComponent, {
			width: '650px',
			data: { testSuitId: node ? node.elem.id : null, suitLevel: node ? node.level : null, additional: this.treeControl }
		}).afterClosed().subscribe(res => {
			if (res) {
				this.snackBar.open('Cases was successfully added', "OK", { duration: 3000 });
				this.getTestCases();
			}
		})
	}

	onEditEvent(testCase: TestCase) {
		this.dialog.open(DialogEditComponent, {
			width: '650px',
			data: { dialogTitle: 'Edit Test Cases', title: testCase.title, description: testCase.description }
		}).afterClosed().subscribe(res => {
			if (res) {
				let suitLevel = this.treeControl.dataNodes.find(node => +node.id === +testCase.testSuitId)?.level;
				this.testCaseService.saveTestCase({ ...testCase, ...res }, suitLevel).subscribe(res => {
					if (res) {
						this.snackBar.open('Cases was successfully edited', 'OK', { duration: 3000 });
						this.getTestCases();
					} else this.snackBar.open('Something went wrong. Try later', 'OK', { duration: 3000 });
				});
			}
		})
	}

	onDeleteEvent(testCase: TestCase) {
		this.deletedItems = [testCase];
		this.dialog.open(DialogConfirmComponent, {
			width: '650px',
			data: { dialogTitle: 'delete', name: testCase.title }
		}).afterClosed().subscribe((res: boolean) => {
			if (res && testCase) {
				this.pending = true;
				this.testSuitService.deleteTestCasefromTestSuit({
					test_suit_relation_id: testCase.tesSuitRelationId
				}).subscribe((res) => {
					if (res.valid && res.msg === 'item delete_anyway not set') {
						this.dialog.open(DialogConfirmComponent, {
							width: '650px',
							data: { dialogTitle: 'delete', name: testCase.title, errorMsg: 'Unesigned cases' }
						}).afterClosed().subscribe((answ: boolean) => {
							if (answ) {
								this.testSuitService.deleteTestCasefromTestSuit({
									test_suit_relation_id: testCase.tesSuitRelationId,
									delete_anyway: 1
								}).subscribe((res) => {
									if (res.valid) this._successDeleteFunc();
									else this._successDeleteFunc(true, true, false);
								})
							} else this._successDeleteFunc(false, false);
						})
					} else if (res.valid) this._successDeleteFunc();
					else this._successDeleteFunc(true, true, false);
				});
			} else this.deletedItems = [];
		})
	}

	onDeleteGroupEvent() {
		if (this.checkedCases.length > 0) {
			this.deletedItems = this.checkedCases;
			this.dialog.open(DialogConfirmComponent, {
				width: '650px',
				data: { dialogTitle: 'delete', name: this.checkedCases.map(elem => elem.title) }
			}).afterClosed().subscribe((res: boolean) => {
				if (res) {
					this.pending = true;
					forkJoin(
						this.checkedCases.map((test) => this.testSuitService.deleteTestCasefromTestSuit({
							test_suit_relation_id: test.tesSuitRelationId
						}))
					).subscribe(res => {
						if (res) {
							if (res.filter(elem => elem.msg)) {
								let warningCases: TestCase[] = [];
								this.checkedCases.forEach((elem, i) => {
									if (res[i] && res[i].msg) warningCases.push(elem)
								});

								this.dialog.open(DialogConfirmComponent, {
									width: '650px',
									data: {
										dialogTitle: 'delete',
										name: warningCases.map(elem => elem.title).join(', '),
										errorMsg: 'Unesigned cases'
									}
								}).afterClosed().subscribe((answ: boolean) => {
									if (answ) {
										forkJoin(
											this.checkedCases.map((test) => this.testSuitService.deleteTestCasefromTestSuit({
												test_suit_relation_id: test.tesSuitRelationId,
												delete_anyway: 1
											}))
										).subscribe((res) => {
											if (res.every(elem => elem.valid)) this._successDeleteFunc();
											else this._successDeleteFunc(true, true, false);
										})
									} else this._successDeleteFunc(true, false);
								});
							} else this._successDeleteFunc();
						} else this._successDeleteFunc(true, true, false);
					})
				} else this.deletedItems = [];
			})
		}
	}

	private _successDeleteFunc(flag = true, msg = true, success = true) {
		this.pending = false;
		this.deletedItems = [];
		this.checkedCases = [];
		if (flag) this.getTestCases(false);

		if (msg) {
			if (success) this.snackBar.open('Test Case was successfully deleted', "OK", { duration: 3000 });
			else this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 });
		}
	}

	/* CREATION MODE */

	onChangeIntoEvent(event: MatButtonToggleChange) {
		this.getSelectedTests(event.value);
		this.selectedTestsControl.reset();
	}

	initFormCreationMode() {
		this.createCaseCreationMode = new FormGroup({
			title: new FormControl(null, [Validators.required]),
			description: new FormControl(null, [Validators.required])
		})
	}

	get f() {
		return this.createCaseCreationMode.controls
	}

	onPushIntoEvent(key: string) {
		const value = this.selectedTestsControl.value;
		switch (key) {
			case 'testSuit':
				this.addTestSuitCase(value).subscribe(res => {
					if (res.every(res => res.valid === true)) this.onPush('Test Suit')
				})
				break;
			case 'testPlan':
				this.addTestPlanCase(value).subscribe(res => {
					if (res.every(res => res === true)) this.onPush('Test Plan')
				})
				break;
			case 'testRun':
				this.addTestRunCase(value).subscribe(res => {
					if (res.every(res => res === true)) this.onPush('Test Run')
				})
				break;
		}
	}

	onPush(str: string): void {
		this.checkedCases = [];
		this.getTestCases(false);
		this.getSelectedTests('testSuit')
		this.snackBar.open(`${str} was successfully updated`, 'OK', { duration: 3000 })
	}

	checkItems(value: any) {
		if (this.selectedTests) return;
		else this.getSelectedTests(value);
	}

	async getSelectedTests(key: string) {
		switch (key) {
			case 'testSuit':
				this.selectedTests = this.testCases;
				let find = this.selectedTests?.find(item => +item.suit.id === +this.currentSuit);
				if (find) this.selectedTestsControl.setValue([find]);
				break;
			case 'testPlan':
				this.subscriberTestPlan = this.getTestPlans().subscribe(res => this.selectedTests = res);
				this.subscriptions.push(this.subscriberTestPlan)
				break;
			case 'testRun':
				this.subscriberTestRun = this.getTestRuns().subscribe(res => this.selectedTests = res);
				this.subscriptions.push(this.subscriberTestRun)
				break;
		}
	}

	getTestSuits() {
		return this.testCases
	}

	addTestSuitCase(selectedTestSuit: TestSuitFoldersTemplate[]): Observable<{ valid: boolean }[]> {
		return forkJoin(
			selectedTestSuit.map((test) => {
				return this.testSuitService.updateTestCasesList({
					test_suit_id: test.suit.id,
					project_id: test.suit.projectId,
					test_cases: this.checkedCases.map(c => ({ tc_id: c.id, ts_id: c.tsId })),
					deleted_test_cases: []
				})
			})
		)
	}

	getTestPlans() {
		return this.testPlanService.getTestPlans({ project_id: this.currentProject })
	}

	addTestPlanCase(selectedTestPlan: TestPlan[]): Observable<boolean[]> {
		return forkJoin(
			selectedTestPlan.map((plan) => {
				return this.testPlanService.updateTestCasesList({
					test_plan_id: plan.id,
					project_id: plan.projectId,
					test_cases: this.checkedCases.map(c => ({ tc_id: c.id, ts_id: c.tsId })),
					deleted_test_cases: []
				})
			})
		)
	}

	getTestRuns() {
		return this.testRunService.getTestRuns({ project_id: this.currentProject })
	}

	addTestRunCase(selectedTestRun: TestRun[]): Observable<boolean[]> {
		return forkJoin(
			selectedTestRun.map((run) => {
				return this.testRunService.updateTestCasesList({
					test_run_id: run.id,
					project_id: run.projectId,
					test_cases: this.checkedCases.map(c => ({ tc_id: c.id, ts_id: c.tsId })),
					deleted_test_cases: []
				})
			})
		)
	}

	createCaseToSuit() {
		let testCase: TestCase = new TestCase();
		testCase = { ...testCase, ...this.createCaseCreationMode.value };
		testCase.testSuitId = this.selectedSuitControlForCreate.value;
		testCase.tsId = this.selectedSuitControlForCreate.value;
		let suitLevel = this.treeControl.dataNodes?.find(node => +node.id === +testCase.testSuitId)?.level;

		this.testCaseService.createTestCase(testCase, [], null, suitLevel).subscribe(res => {
			if (res) {
				this.snackBar.open('Test Case was successfully added', 'OK', { duration: 3000 });
				of(this.getTestCases(false)).subscribe(res => {
					this.createCaseCreationMode.reset();
					this.setFocus();
				});
			}
		})
	}

	setFocus() {
		(this.formForCaseTitle && this.selectedSuitControlForCreate.value) ? this.formForCaseTitle.nativeElement.focus() : '';
	}

	checkedItem(event, option) {
		if (event.checked === true) this.checkedCases.push(option.value)
		else this.checkedCases.splice(this.checkedCases.indexOf(option.value), 1)
	}

	getUsers() {
		this.userService.getUsers().subscribe(v => {
			this.users = v;
		})
	}

	getUserName(id) {
		if (this.users) this.assignedTo = this.users.find(user => user.id === id);
		if (this.assignedTo) return this.assignedTo.firstName;
		return ''
	}

	/* CREATION MODE */


	//tree
	flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
	nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
	treeControl: FlatTreeControl<TodoItemFlatNode>;
	treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
	dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
	checklistSelection = new SelectionModel<TodoItemFlatNode>(true);


	formDataSource(treedata): void {
		const TREE_DATA = treedata;
		const dataChange = new BehaviorSubject<TodoItemNode[]>([]);
		const data = this.buildFileTree(TREE_DATA, 0);
		dataChange.next(data);
		dataChange.subscribe(r => {
			this.dataSource.data = r;
		});
	}

	buildFileTree(obj: any, level: number, parents: TestSuit[] = []): TodoItemNode[] {
		const tree = Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
			if (accumulator.map(elem => ({
				id: elem.elem.id,
				level: elem.level
			})).filter(elem => elem.id === obj[key].suit.id && elem.level === level).length > 0) return accumulator;
			let value = obj[key];

			const node = new TodoItemNode();
			node.title = value.suit.title;
			node.id = value.suit.id;
			node.elem = value.suit;
			node.tesSuitRelationId = value.tesSuitRelationId;
			node.level = level;
			node.open = false;
			node.parents = parents;
			node.color = value.color || null;

			if (value.sub_suits.length > 0) {
				node.children = value.sub_suits.map(res => {
					let parentSuits = [...parents, value.suit];
					return this.buildFileTree(res, level + 1, parentSuits)[0];
				});
			} else node.children = [];

			return accumulator.concat(node);
		}, []);
		return tree;
	}

	onInitTree(): void {
		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

		this.formDataSource(this.testSuitFolders);
	}

	getLevel = (node: TodoItemFlatNode) => node.level;
	isExpandable = (node: TodoItemFlatNode) => node.expandable;
	getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
	hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
	transformer = (node: TodoItemNode, level: number): TodoItemFlatNode => {
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode = existingNode && existingNode.title === node.title
			? existingNode
			: new TodoItemFlatNode();

		flatNode.title = node.title ? node.title : node[0].title;
		flatNode.id = node.id ? node.id : node[0].id;
		flatNode.level = level;
		flatNode.color = node.color;
		flatNode.elem = node.elem ? node.elem : node[0].elem;
		flatNode.open = node.open != undefined ? node.open : node[0].open;
		flatNode.parents = node.parents ? node.parents : node[0].parents;
		flatNode.expandable = node.children ? !!node.children.length : !!node[0].children.length;
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	};

	todoItemSelectionToggle(node: TodoItemFlatNode, folders): void {
		this.addQueryParams(node);
		node.open = !node.open;
		node.open ? this.treeControl.expand(node) : this.treeControl.collapse(node);

		let find = folders.find(elem => elem.suit === node.elem);
		if (find) {
			find.show = node.open;

			if (!node.open) {
				folders.forEach(elem => {
					if (elem.parents.includes(node.elem)) elem.show = false;
				});
				this.treeControl.dataNodes.forEach(nodeElem => {
					if (nodeElem.parents.includes(node.elem)) {
						nodeElem.open = false;
						this.treeControl.collapse(nodeElem)
					}
				});
			}
		}
	}

	toggleSuit(suitIsOpen, flag: boolean = false, changeUrl = true): void {
		if (changeUrl) this.addQueryParams(suitIsOpen);
		suitIsOpen.show = flag ? true : !suitIsOpen.show;

		this.treeControl.dataNodes.forEach(nodeElem => {
			if (suitIsOpen.suit === nodeElem.elem) {
				nodeElem.open = suitIsOpen.show;
				this.treeControl.toggle(nodeElem);

				if (suitIsOpen.show) {
					this.treeControl.dataNodes.forEach(nodeItem => {
						if (suitIsOpen.parents.includes(nodeItem.elem)) {
							nodeItem.open = true;
							this.treeControl.expand(nodeItem);
						};
					});
					this.testCases.forEach((item: TestSuitFoldersTemplate) => {
						if (suitIsOpen.parents.includes(item.suit)) item.show = true;
					});
				} else {
					this.treeControl.dataNodes.forEach(nodeItem => {
						if (nodeItem.parents.includes(nodeElem.elem)) {
							nodeItem.open = false;
							this.treeControl.collapse(nodeItem);
						};
					});
					this.testCases.forEach((item: TestSuitFoldersTemplate) => {
						if (item.parents.includes(suitIsOpen.suit)) item.show = false;
					});
				};

				(suitIsOpen.show && !flag) ? this.scrollTo(nodeElem) : null;
			}
		});
	}

	addQueryParams(element) {
		let projectId = element.parents[0]?.projectId || element.elem?.projectId || element.suit?.projectId;
		window.history.pushState({}, null, `${window.location.origin}/test-cases-page/main?project=${projectId}&type=tcs&creationMode=${this.creationMode}&sub=${element.elem?.id || element.suit?.id}&level=${element.level}`);

		this.closeTestStep.emit(true);
		this.changeUrl.emit();
	}

	addQueryParamsCase(testCase, suitId) {
		const activeSuit = this.testCases.find(elem => +elem.suit.id === +suitId && elem.cases.includes(testCase));
		let projectId = activeSuit.parents[0]?.projectId || activeSuit.suit?.projectId;
		window.history.pushState({}, null, `${window.location.origin}/test-cases-page/main?project=${projectId}&type=tcs&creationMode=${this.creationMode}&sub=${suitId}&level=${activeSuit.level || 0}&tc=${testCase.tesSuitRelationId}`);

		this.changeUrl.emit();
	}

	scrollTo(node, flag: boolean = true, elemTo: TestCase = null) {
		if (node && !node.open) this.todoItemSelectionToggle(node, this.testCases);

		this.suitsList?.forEach(elem => {
			if (node && node.open && flag) {
				if (elem.nativeElement?.dataset.value === `${node.title + node.id + node.level}` && this.container) {
					const containerScrollTop = elem.nativeElement.offsetTop - this.container.element.nativeElement.offsetTop;
					this.container.element.nativeElement.scrollTop = containerScrollTop;
					this.activeSuit = node.id;
				}
				;
			} else if (this.selectedValue || elemTo) {
				let selectedSuit = this.testCases.filter(elem => elem.suit.id === (elemTo ? (elemTo.tsId || elemTo.testSuitId) : this.selectedValue.tsId));
				selectedSuit.forEach(elemItem => {
					elemItem.cases.forEach(caseItem => {
						if (+caseItem?.id === +elemTo?.id || +this.selectedValue?.id) {
							let nodeSelect = this.treeControl.dataNodes.find(elem => elem.elem === elemItem.suit);
							if (elem.nativeElement?.dataset.value === `${nodeSelect.title + nodeSelect.id + nodeSelect.level}` && this.container) {
								const containerScrollTop = elem.nativeElement.offsetTop - this.container.element.nativeElement.offsetTop;
								this.container.element.nativeElement.scrollTop = containerScrollTop;
								this.activeSuit = +nodeSelect.id;
							}
							;
						}
						;
					});
				});
			}
			;
		});
	}

	checkIsOpen(): boolean {
		return this.treeControl?.dataNodes?.some(elem => elem.open);
	}

	openAll(flag: boolean) {
		this.treeControl.dataNodes.forEach(elem => {
			elem.open = flag;
			flag ? this.treeControl.expand(elem) : this.treeControl.collapse(elem);
		});
		this.testCases.forEach((elem: TestSuitFoldersTemplate) => {
			elem.show = flag;
		});
	}

	checkTreeList() {
		this.formDataSource(this.testSuitFolders);

		if (this.testSuitCacheCases) {
			this.testSuitCacheCases.forEach((elem: TestSuitFoldersTemplate) => {
				this.treeControl.dataNodes.forEach(nodeItem => {
					const equal = `${nodeItem.elem.id + nodeItem.level}` === `${elem.suit.id + elem.level}`;
					if (equal && nodeItem.open !== elem.show) this.todoItemSelectionToggle(nodeItem, this.testCases);
				});
			});
		}
	}

	// tree

	dependentCopyTestCase(testCase: TestCase) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.width = '650px';
		dialogConfig.height = 'auto';
		this.dialog.open(DialogSelectSuitComponent, dialogConfig).afterClosed().subscribe(v => {
			if (v) {
				this.testSuitService.updateTestCasesList({
					test_suit_id: v[0].id,
					test_cases: [{ "tc_id": testCase.id }],
					deleted_test_cases: [],
				}
				).subscribe(v => {
					this.getTestCases(false);
				})
			}
		});
	}

	async independentCopyTestCase(item) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.width = '650px';
		dialogConfig.height = 'auto';
		this.dialog.open(DialogSelectSuitComponent, dialogConfig).afterClosed().subscribe(v => {
			if (v) {
				this.testCaseService.independentCopy({ "tc_id": item.id, "ts_id": v[0].id }).subscribe(res => {
					this.getTestCases(true);
				})
			}
		});
	}

	showLinksCase(testCase: TestCase) {
		this.dialog.open(DialogCaseConnectionsComponent, {
			width: '650px',
			data: {
				currentTestCase: testCase,
				location: window.location.href,
				additional: this.treeControl
			}
		}).afterClosed().subscribe(v => {
			if (v) {
				const queryParams = { ...this.route.snapshot.queryParams }
				this.testCases.forEach(item => {
					if (item.suit.id === v.suitId) {
						this.router.navigate(['./test-cases-page', 'main'], { queryParams: { ...queryParams, sub: v.suitId, level: item.level } }).then(r => {
							this.searchPath(this.queryParams)
							this.treeControl.dataNodes.forEach(nodeItem => {
								const equal = `${nodeItem.elem.id + nodeItem.level}` === `${item.suit.id + item.level}`;
								if (equal) this.scrollTo(nodeItem);
							});
						})
					}
				})
			}

		})
	}

}



class TodoItemNode {
	id: string;
	elem: TestSuit;
	children: TodoItemNode[];
	parents: TestSuit[]
	title: string;
	tesSuitRelationId: string;
	level: number;
	open: boolean;
	color: string;
}

class TodoItemFlatNode {
	id: string;
	elem: TestSuit;
	parents: TestSuit[]
	title: string;
	level: number;
	expandable: boolean;
	tesSuitRelationId: string;
	open: boolean;
	color: string;
}
