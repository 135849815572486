import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe'
})
export class SearchCustomPipe implements PipeTransform {
  transform(value: any[], searchValue: string): any[] {
    if (value && value.length !== 0) {
      if (value[0].title && searchValue) {
        return value.filter((v) => v.title?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) > -1);
      } else if (value[0].name && searchValue) {
        return value.filter((v) => v.name?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) > -1);
      } else if (value[0].firstName && searchValue) {
        let set = new Set();
        let users = [];
        [...value.filter((v) => v.firstName?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) > -1), ...value.filter((v) => v.lastName?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) > -1)].forEach(item => { set.add(item) });
        set.forEach(item => users.push(item))
        return users
      }
    }

    return value
  }
}

