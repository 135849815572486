import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestRun } from 'src/app/services/test-run.type';
import { Chart, ChartConfiguration, ChartType } from 'chart.js';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { ProjectService } from 'src/app/services/project.service';
import { Core } from 'src/app/services/core.service';


@Component({
  selector: 'app-base-chart-by-runs',
  templateUrl: './base-chart-by-runs.component.html',
  styleUrls: ['./base-chart-by-runs.component.scss']
})
export class BaseChartByRunsComponent implements OnInit {
  @Input() title: string = 'Chart';
  @Input() currentProject;

  public minDate: Date;
  public dateRange: Date[];
  public maxDate: Date;
  public maxEndDate: Date;
  public dateForm: FormGroup;

  public testRuns: TestRun[];
  public projectsWithRuns: any[] = [];
  public selectedControl: FormControl = new FormControl();
  public pending: boolean = false;

  public lineChartType: ChartType = 'line';
  public lineChartData: ChartConfiguration['data'];
  public lineChartOptions: ChartConfiguration['options'];

  @Input() width: number = innerWidth / 2 - 50;
  @Input() height: number = innerWidth / 8;

  @Input() data: number[] = [];
  @Input() labels: string[] = [];

  @Output() paramEvent = new EventEmitter<{}>()


  constructor(
    private fb: FormBuilder,
    private testRunService: TestRunService,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    Chart.register(Annotation);
    this.createChart()
    this.initData();

    this.selectedControl.valueChanges.subscribe(res => {
      if (this.selectedControl.value?.includes('all') && res?.length === this.testRuns.length) {
        this.selectedControl.setValue([...this.selectedControl.value.filter(elem => elem !== 'all')]);
      } else if (!this.selectedControl.value?.includes('all') && res?.length === this.testRuns.length) this._selectAll();
    })
  }

  initData() {
    let arr = [];
    let now = new Date();
    this.minDate = new Date(now.setDate(now.getDate() - 6));
    this.maxDate = this.maxEndDate = new Date();
    this.initForm();

    this.projectService.getProjects().subscribe(res => {

      let project = [...res];
      if (!isNaN(+this.currentProject)) project = project.filter(elem => +elem.id === +this.currentProject);
      this.projectsWithRuns = [...project];

      this.projectsWithRuns.forEach(item => { item.runs = [] });
      this.testRunService.getTestRuns({}).subscribe(res => {

        let runs = [...res];
        if (!isNaN(+this.currentProject)) runs = runs.filter(elem => +elem.projectId === +this.currentProject);
        this.testRuns = [...runs];

        this.testRuns.forEach(item => {
          let find = this.projectsWithRuns.find(project => project.id === item.projectId);
          if (find) find.runs.push({ ...item, show: true });
        })
        this.projectsWithRuns.forEach(item => {
          if (item.runs.length > 0) arr.push(...item.runs);
        })
        this.projectsWithRuns = this.projectsWithRuns.filter(project => project.runs.length);
        this.selectedControl.setValue([...arr]);
        this.updateChart();
      });
    })
  }

  searchRuns(value) {
    let arr = []
    this.projectsWithRuns.forEach(project => {
      if (project.runs.length > 0) arr.push(...project.runs);
      arr.forEach(elem => elem.show = false);
      let newArr = arr.filter((v) => v.title?.toLowerCase().indexOf(value.trim().toLowerCase()) > -1);
      newArr.forEach(elem => elem.show = true);
    })
  }

  initForm() {
    this.dateForm = this.fb.group({
      start: [new Date(this.minDate), [Validators.required]],
      end: [new Date(this.maxEndDate), [Validators.required]]
    })

    this.dateForm.valueChanges.subscribe(res => {
      this.dateRange = res;
    })
  }

  formatDate(input: HTMLInputElement): void {
    input.maxLength = 10;
    if (input.value.length === 2 || input.value.length === 3 || input.value.length === 5) {
      input.value = `${input.value}/`;
    };
  }

  public get disabledBtn(): boolean {
    if (this.dateForm.valid && this.selectedControl.value && this.selectedControl.value?.filter(elem => elem !== 'all').length !== 0) return false
    return true
  }

  updateChart(): void {
    this.pending = true;
    const param = {
      selected_runs: this.selectedControl.value.filter(item => item !== 'all').map(item => { return { test_run_id: item.id } }),
      start_date: this.dateFormat(this.dateForm.value.start),
      end_date: this.dateFormat(this.dateForm.value.end)
    }
    this.paramEvent.emit(param);
  }

  dateFormat(date: Date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
  }

  createChart(labels = this.labels, data = this.data): void {
    this.lineChartData = {
      datasets: [
        {
          data: data,
          backgroundColor: 'rgba(181, 190, 200, .3)',
          borderColor: 'rgb(31, 81, 158)',
          pointBackgroundColor: 'rgb(31, 81, 158)',
          pointBorderColor: 'rgba(181, 190, 200)',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: '#fff',
          fill: 'origin',
        }
      ],
      labels: labels
    };

    this.lineChartOptions = {
      elements: { line: { tension: 0.5 } },
      scales: { y: { position: 'left', } },
      hover: { mode: 'nearest', intersect: true },
      plugins: { legend: { display: false }, }
    };
  }

  toggleAll() {
    if (this.selectedControl.value?.length >= this.testRuns?.length) this.selectedControl.setValue([]);
    else this._selectAll();
  }

  private _selectAll() {
    let arr = [];
    this.projectsWithRuns.forEach(item => {
      if (item.runs.length > 0) arr.push(...item.runs)
    })
    this.selectedControl.setValue([...arr, 'all'])
  }

  public isShow(runs) {
    return runs.some(elem => elem.show);
  }
}
