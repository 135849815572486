import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TestStep } from '../../services/test-step.type';
import { ApiBaseService } from '../../services/api-base.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Host } from 'src/app/services/core.service';
import { UserService } from 'src/app/services/user.service';
import { Attachment } from 'src/app/services/attachment.type';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserAccessService } from 'src/app/services/user-access';

export interface DialogData {
  testStep: TestStep
}

@Component({
  selector: 'app-dialog-view-attachments',
  templateUrl: 'dialog-view-attachments.component.html',
  styleUrls: ['./dialog-view-attachments.component.scss']
})
export class DialogViewAttachmentsComponent implements OnInit {
  public editAccessAction: boolean = this._userAccessService.getAccess('testStep', 'testStepEditLevel');

  public attachmentFromStep: TestStep;
  public previewAttachmentList: File[] = [];
  @ViewChild('hiddenfileinput') _input: ElementRef;
  public newAttachments: File[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogViewAttachmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public userService: UserService,
    public api: ApiBaseService,
    private _userAccessService: UserAccessService
  ) { }

  ngOnInit(): void {
    this.attachmentFromStep = { ...this.data.testStep };
    this.attachmentFromStep.attachments = { ...this.data.testStep }.attachments.map(attachment => ({ ...attachment, fullPath: Host + attachment.relativePath }));
  }

  loadFile(event) {
    if (event.target.files.length) {
      this.newAttachments = [...this.newAttachments, ...event.target.files];
      if (this._input) this._input.nativeElement.value = '';
    }
  }

  onAddAction() {
    this.dialogRef.close(this.newAttachments);
  }

  deleteAttachment(attachment: Attachment, i: number) {
    this.newAttachments.splice(i, 1);
  }

  previewAttachment(attachment: File) {
    let find = this.previewAttachmentList.indexOf(attachment);
    if (+find !== -1) this.previewAttachmentList.splice(find, 1);
    else this.previewAttachmentList.push(attachment);
  }

  getUrl(attachment) {
    return URL.createObjectURL(attachment);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
