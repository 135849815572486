import { Component, ContentChild, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Core } from '../services/core.service';
import { DataService } from '../services/data.service';
import { ProjectService } from '../services/project.service';
import { Project } from '../services/project.type';
import { UserService } from '../services/user.service';
import { User } from '../services/user.type';
import { CardTestCasesComponent } from '../test-cases-page/card-test-cases/card-test-cases.component';
import { CardTestPlanComponent } from '../test-page/card-test-plan/card-test-plan.component';
import { CardTestRunComponent } from '../test-page/card-test-run/card-test-run.component';
import { CardTestSuitComponent } from '../test-page/card-test-suit/card-test-suit.component';
import { CardDashboardComponent } from '../dashboard-page/card-dashboard/card-dashboard/card-dashboard.component';
import { TestCaseService } from '../services/test-case.service';
import { CardUnesignedCasesComponent } from '../unesigned-cases-page/card-unesigned-cases/card-unesigned-cases.component';
import { CardManageStatusesComponent } from '../settings-page/card-manage-statuses/card-manage-statuses.component';
import { CardJiraIntegrationComponent } from '../jira-integration-page/card-jira-integration/card-jira-integration.component';
import { UserAccessService } from '../services/user-access';
import { ChartDiagramsComponent } from '../dashboard-page/card-dashboard/chart-diagrams/chart-diagrams.component';


@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent implements OnInit {
  public guestAccessAction: boolean = this._userAccessService.getLevelAccessUser === 4;

  @ContentChild('cartWithCharts') private dashboardCard: CardDashboardComponent;
  @ContentChild('testRunCard') private testRunCard: CardTestRunComponent;
  @ContentChild('testPlanCard') private testPlanCard: CardTestPlanComponent;
  @ContentChild('testSuitCard') private testSuitCard: CardTestSuitComponent;
  @ContentChild(CardManageStatusesComponent) private statusesCard: CardManageStatusesComponent;
  @ContentChild('cardTestCases') private testCases: CardTestCasesComponent;

  public currentProject: string;
  public currentProjectId: string;
  public currentProjectName: string;

  public projects: Project[];
  public selectOpen: boolean = false;
  public creationMode: boolean = false;
  public user: User;

  public searchInputValue: string = '';
  public selectedProject: Project;
  public projectsForMenu: Project[];

  constructor(
    public auth: AuthenticationService,
    private projectService: ProjectService,
    private testCaseService: TestCaseService,
    public router: Router,
    private dataService: DataService,
    private userService: UserService,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void {
    this.getData();
    this.user = this.userService.mapUser(this.auth.getUser());
    this.dataService.creationModeType.subscribe(res => {
      if (res) { this.router.navigateByUrl('test-cases-page') };
      this.creationMode = res;
    })

    this.currentProject = this.projectService.getSelectedProjectFromStorage();
  }

  getData() {
    this.getProjects().subscribe(res => {
      this.projects = res;
      this.projectsForMenu = res;
      this.projects.push({ id: 'all', title: 'All', description: '', createdDate: '', modifiedDate: '', users: [], creator_id: '', creator_name: '' });
      const prevProject = this.projectService.getSelectedProjectFromStorage();
      this.currentProject = this.getSelectedProject();
      this.currentProjectName = this.getSelectedProjectName(this.currentProject);

      if (!this.currentProject) this.selectedProject = { id: 'all', title: 'All', description: '', createdDate: '', modifiedDate: '', users: [], creator_id: '', creator_name: '' };
      if (this.currentProject !== 'all' && this.currentProject !== 'null' && +this.currentProject !== +prevProject) this.selectProject(this.projects.find(elem => +elem.id === +this.currentProject), false);
    });
  }

  selectProject(project: Project, flag = true) {
    this.currentProjectName = project.title;
    this.currentProject = project.id;

    this.selectedProject = this.projectsForMenu.find((elem: Project) => +elem.id === +project.id);
    if (!this.selectedProject) this.selectedProject = this.projectsForMenu.find((elem: Project) => elem.id === 'all');
    this.setSelectedProject(this.selectedProject.id);

    let key: string = this.router.url.split('?')[0].slice(1).split('/')[0].slice();
    switch (key) {
      case 'dashboard-page': this.updateDashboardPage(); break;
      case 'test-page': this.updateTestPage(); break;
      case 'test-cases-page': this.updateTestCasePage(); break;
      case 'settings-page': this.updateSettingPage(); break;
    }

    if (flag) this.router.navigate([`${this.router.url.split('?')[0]}`]);
  }

  updateDashboardPage() {
    if (this.dashboardCard) {
      this.dashboardCard.currentProject = this.currentProject;
      this.dashboardCard.updateData();
    }
  }

  updateTestPage() {
    this.testPlanCard.getTestPlans();
    this.testRunCard.getData();
    this.testSuitCard.getTestSuit();

    this.testPlanCard.selectedValue = null;
    this.testRunCard.selectedValue = null;
    this.testSuitCard.selectedValue = null;
  }

  updateTestCasePage() {
    const activeComponent = this.testCaseService.getActiveComponent();
    if (activeComponent.type === 'CardTestCasesComponent') {
      (activeComponent.data as CardTestCasesComponent).currentProject = this.currentProject;
      (activeComponent.data as CardTestCasesComponent).getTestCases(true, false);
      if (this.creationMode) {
        (activeComponent.data as CardTestCasesComponent).currentSuit = null;
        Core.localStorageService.setItem('chosenTestSuit', JSON.stringify(null));
      }
    } else if (activeComponent.type === 'CardUnesignedCasesComponent') {
      (activeComponent.data as CardUnesignedCasesComponent).getTestCases();
    }
  }

  updateSettingPage() {
    const settingActiveComponent = this.projectService.getSettingActiveComponent();
    if (settingActiveComponent.type === 'CardManageStatusesComponent') {
      (settingActiveComponent.data as CardManageStatusesComponent).isLoading = false;
      (settingActiveComponent.data as CardManageStatusesComponent).currentProject = this.currentProject;
      (settingActiveComponent.data as CardManageStatusesComponent).getStatuses();
    } else if (settingActiveComponent.type === 'CardJiraIntegrationComponent') {
      (settingActiveComponent.data as CardJiraIntegrationComponent).isLoading = false;
      (settingActiveComponent.data as CardJiraIntegrationComponent).getJiraIntegrationSettings();
    }
  }

  setSelectedProject(id: string) {
    this.projectService.setSelectedProjectToStorage(id);
  }

  getSelectedProject() {
    const value = this.projectService.getSelectedProjectFromStorage();

    let queryParamArr = window.location.href.split('?')[1]?.split('&');
    let params: any = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

    if (params?.project) {
      this.setSelectedProject(params.project);
      return this.projectService.getSelectedProjectFromStorage();
    }
    else if (value !== 'null' || value !== 'all') return value;
    else {
      this.projects.length > 0 ? this.setSelectedProject('all') : '';
      return this.projectService.getSelectedProjectFromStorage();
    }
  }

  getSelectedProjectName(id: string) {
    this.selectedProject = this.projects.find(item => item.id === id);
    if (id === 'all' || id === 'null') return 'all';
    const find = this.projects.find(val => val.id === id);
    return find ? find.title : 'all';
  }

  getProjects() {
    return this.projectService.getProjects();
  }

  onCreationMode(event: MatSlideToggleChange) {
    this.dataService.changeCreationMode(event.checked);

    const activeComponent = this.testCaseService.getActiveComponent();
    if (activeComponent && activeComponent.type === 'CardTestCasesComponent') (activeComponent.data as CardTestCasesComponent).currentSuit = null;

    Core.localStorageService.setItem('chosenTestSuit', JSON.stringify(null));
  }

  logOut() {
    this.auth.logOut();
  }

  resetSearch() {
    this.searchInputValue = '';
    this.projectsForMenu = this.projects;
  }

  searchProjectMenu() {
    this.projectsForMenu = this.projects?.filter(item => item.title.toUpperCase().includes(this.searchInputValue.toUpperCase()));
  }

}
