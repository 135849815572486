<app-sidenav>
  <mat-card>
    <button mat-raised-button color="primary" (click)="createProject()"><mat-icon>add_box</mat-icon>CREATE</button>
  </mat-card>
  <mat-card>
    <mat-form-field appearance="standard">
      <mat-label>TITLE</mat-label>
      <input matInput [(ngModel)]="project.title">
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>DESCRIPTION</mat-label>
      <input matInput [(ngModel)]="project.description">
    </mat-form-field>
  </mat-card>
</app-sidenav>
