import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSelect } from "@angular/material/select";
import { TestCaseService } from "../../services/test-case.service";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseCardComponent implements OnInit {
  public guestAccessAction$: Observable<boolean> = of(this._userAccessService.getLevelAccessUser).pipe(map(res => +res === 4));

  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('sortSelect') sortSelect: MatSelect;
  @ViewChild('archiveSelect') archiveSelect: MatSelect;

  public isRightSectionOpen: boolean = false;
  public pending: boolean = false;

  @Input() title: string;
  @Input() loader: boolean;
  @Input() casesFromRun: boolean = false;
  @Input() icon: string;
  @Input() showAdd: boolean;
  @Input() showSearch: boolean = false;
  @Input() showFooter: boolean = false;
  @Input() showClose: boolean = false;
  @Input() showCollapse: boolean = false;
  @Input() collapseTitle: boolean = false;
  @Input() showGap: boolean = true;
  @Input() showEdit: boolean = false;
  @Input() showPadding: boolean = false;

  @Input() showPage: boolean = true;
  @Input() showSection: boolean = true;
  @Input() shortForm: boolean = false;

  //Select settings
  @Input() showSelect;
  @Input() selectList;
  @Input() baseSelectedValue;
  @Input() selectText: string;
  @Input() showUpload: boolean;
  @Input() editButtonText: string;

  //Sort settings
  @Input() showSort;
  @Input() sortText;
  @Input() sortList;
  @Input() baseSortValue;
  @Input() sortSelectData;


  @Output() addEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() collapseEvent = new EventEmitter();
  @Output() collapseTitleEvent = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  @Output() selectEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() selectArchiveEvent = new EventEmitter<string>();
  @Output() selectSortEvent = new EventEmitter<string>();
  @Output() importEvent = new EventEmitter<File>();


  constructor(
    private testCaseService: TestCaseService,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void {
    if (this.title === undefined) throw new Error('Title is required');
    if (this.icon === undefined) throw new Error('Icon is required');
    if (this.showAdd === undefined) throw new Error('Show add variable is required');
    this.testCaseService.isRightSectionOpen$.subscribe(v => {
      this.isRightSectionOpen = v
    })
  }

  onAddEvent() {
    this.addEvent.emit();
    if (this.title === 'Test Run' || this.title === 'Test Plan' || (this.title === 'Users' && this.loader) || this.casesFromRun) this.pending = true;
  }

  onCloseEvent() {
    this.closeEvent.emit();
  }

  onCollapseEvent() {
    this.collapseEvent.emit();
  }

  onCollapseTitleEvent() {
    this.collapseTitleEvent.emit();
  }

  searchElement(elem: string) {
    this.searchEvent.emit(elem);
  }

  onSelectArchiveType(item) {
    this.selectArchiveEvent.emit(item)
  }

  onEditEvent() {
    this.editEvent.emit()
  }

  onSelectSortType(item) {
    this.selectSortEvent.emit(item)
  }

  onImportEvent(item) {
    this.importEvent.emit(item)
    this.fileInputRef.nativeElement.value = '';
  }
}
