<app-sidenav>
  <mat-card id="panel">
    <span>Manage Statuses</span>
    <mat-form-field appearance="outline" class="main-header-search">
      <label>
        <input matInput [(ngModel)]="filterValue"  placeholder="Search">
      </label>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="openDialogCreateStatus()"><mat-icon>add</mat-icon>New Status</button>
  </mat-card>
  <mat-card class="table-card">
    <app-abstract-table #abstractTable
                        [filter]="filterValue" 
                        [data]="data"
                        [clickabledRow]="false"
                        [sorted]="true"
                        [columns]="displayedColumns"
                        [actions]="['edit', 'delete']"
                        (abstractActionEditEvent)="openDialogEditStatus($event)"
                        (abstractActionDeleteEvent)="deleteStatus($event)">
    </app-abstract-table>
  </mat-card>
</app-sidenav>
