<div class="dialog-title">
  <h1 mat-dialog-title>Create New Test Suit</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <div *ngIf="!nextStep">
    <form [formGroup]="craeteTestSuitForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title">
        <mat-error *ngIf="f.title.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
        <mat-error *ngIf="f.description.hasError('required')">
          Description is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Project</mat-label>
        <mat-select formControlName="project">
          <mat-option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.project.hasError('required')">
          Project is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="dialog-actions" mat-dialog-actions>
    <button mat-stroked-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button [disabled]="!craeteTestSuitForm.valid" color="primary" style="background: #2d64ba"
      (click)="createSuit()">Create</button>
    <button mat-raised-button [disabled]="!craeteTestSuitForm.valid" color="primary" style="background: #2d64ba"
      (click)="createAndAddTestCases()">Create & Add Test Cases</button>
  </div>