const suitsList = 'testSuit/list', suitsRead = 'testSuit/read', suitsCreate = 'testSuit/create', suitsUpdate = 'testSuit/update', suitsDelete = 'testSuit/delete', addTestCases = 'testSuit/addTestCases', deleteTestCase = 'testSuit/deleteTestCase', createRunFromSuit = 'testSuit/createTestRun', updateTestCasesList = 'testSuit/updateTestCasesList', updateRelation = 'testSuit/updateRelation', testPlansSuitList = 'testSuit/testPlansSuitList', importTestRail = '/testSuit/importSuits', foldersList = 'testSuit/getSuitFolders', addSuitToSuit = 'testSuit/addSubSuitToSuit', deleteSuitFromSuit = 'testSuit/deleteSubSuitFromSuit', foldersPlanList = 'testSuit/getPlaneFolders', foldersRunList = 'testSuit/getRunFolders';

export class TestSuitControllers {
    constructor(api) {
        this.api = api;
    }
    //get testSuits list
    list(payload) {
        return this.api.postRequest(suitsList, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //read testSuit list
    readList(payload) {
        return this.api.postRequest(suitsRead, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //create testRSuit
    create(payload) {
        return this.api.postRequest(suitsCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update testSuit
    update(payload) {
        return this.api.postRequest(suitsUpdate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete testSuit
    delete(payload) {
        return this.api.postRequest(suitsDelete, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //add testCase
    addTestCase(payload) {
        return this.api.postRequest(addTestCases, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update testCase
    updateTestCase(payload) {
        return this.api.postRequest(updateTestCasesList, payload).then(res => {
            if (res)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //delete testCase
    deleteTestCase(payload) {
        return this.api.postRequest(deleteTestCase, payload).then(res => {
            return res.data;
        });
    }
    // update relationelete testCase
    updateRelation(payload) {
        return this.api.postRequest(updateRelation, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //creaste TestRun from TestSuit testCase
    createTestRunFromSuit(payload) {
        return this.api.postRequest(createRunFromSuit, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //testPlanSuitList
    testPlanSuitList(payload) {
        return this.api.postRequest(testPlansSuitList, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }

    importTestRail(payload) {
        return this.api.postRequest(importTestRail, payload).then(res => {
                        if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //listFolders
    foldersList(payload) {
        return this.api.postRequest(foldersList, payload).then(res => {
            return res.data;
        });
    }
    //addSuitToSuit
    addSuitToSuit(payload) {
        return this.api.postRequest(addSuitToSuit, payload).then(res => {
            return res.data;
        });
    }
    //deleteSuitFromSuit
    deleteSuitFromSuit(payload) {
        return this.api.postRequest(deleteSuitFromSuit, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //listPlansFolders
    foldersPlansList(payload) {
        return this.api.postRequest(foldersPlanList, payload).then(res => {
            return res.data;
        });
    }
    //listRunsFolders
    foldersRunsList(payload) {
        return this.api.postRequest(foldersRunList, payload).then(res => {
            return res.data;
        });
    }
}
