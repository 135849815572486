<app-base-card [title]="'Test Run'" [icon]="'directions_run'" [showAdd]="addAccessAction" [collapseTitle]="true"
  (addEvent)="onAddEvent()" [showCollapse]="selectedValue && showCase" (collapseEvent)="collapseEvent.emit('TestRun')"
  (collapseTitleEvent)="this.showSection = !this.showSection" [showSection]="showSection" [showSearch]="true"
  [showSelect]="showSelect" [showSort]="true" [selectList]="archiveList" [baseSelectedValue]="selectedTypeValue"
  [selectText]="baseCardSelectText" [sortText]="'Sort By'" [baseSortValue]="baseSortValue"
  [sortSelectData]="sortSelectData" (searchEvent)="search=$event" (selectArchiveEvent)="selectArchivedType($event)"
  [shortForm]="shortForm" [showPage]="showRun" (selectSortEvent)="sortTestRunByEvent($event)">
  <mat-selection-list #selectionList (selectionChange)="selectionChange($event)">
    <mat-list-option #option *ngFor="let item of (testRun | searchPipe: search)" [value]="item"
      class="list-card__item item-list" [selected]="item.isOpen" [routerLink]="'./'"
      [queryParams]="{project: item.projectId, type: 'tr', id: item.id}">
      <div class="item-list__body" [ngClass]="{archiveTestRun: item['archive'] === 'true'}">
        <div class="item-list__title flex">
          <div [style.opacity]="(pending && activeTestRun === item) ? .2 : 1">{{item.title}}</div>
          <div *ngIf="pending && activeTestRun === item" class="loading">
            <img src="../../../assets/image/loading.gif" alt="pending">
          </div>
        </div>
        <div class="item-list__counter">
          <ng-container *ngIf="(!isRightSectionOpen) || item.statuses.length <= 1  ">
            <ng-container *ngFor="let status of item.statuses">
              <div class="item-list__quantity" [ngStyle]="{'background-color': getColor(status)}"
                [matTooltip]="status.name">{{status.count | quantity}}</div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="(showDetailsPage || isRightSectionOpen) && item.statuses.length > 1">
            <div class="item-list__menu">
              <button class="btn-statuses" mat-icon-button [matMenuTriggerFor]="menu" stopPropagation><span
                  class="btn-statuses__text">All</span></button>
              <mat-menu class="statuses-list" #menu="matMenu">
                <ng-container *ngFor="let status of item.statuses">
                  <div class="item-list__quantity" [ngStyle]="{'background-color': getColor(status)}"
                    [matTooltip]="status.name">{{status.count | quantity}}</div>
                </ng-container>
              </mat-menu>
            </div>
          </ng-container>
        </div>
        <div class="item-list__action margin-40" *ngIf="!guestAccessAction">
          <div class="item-list__menu">
            <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation
              [disabled]="pending && activeTestRun === item">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button [disabled]="!addCaseAccessAction" mat-menu-item (click)="onSelectEvent(item)">
                <span>Select test cases</span>
              </button>
              <button [disabled]="!reRunAccessAction" mat-menu-item (click)="onReRunEvent(item)">
                <span>Re run</span>
              </button>
              <button [disabled]="!editAccessAction" mat-menu-item (click)="onEditEvent(item)">
                <span>Edit</span>
              </button>
              <button [disabled]="!deleteAccessAction" mat-menu-item (click)="onDeleteEvent(item)">
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div [class]="isRightSectionOpen ? 'item-list__right hidden-menu' : 'item-list__right'">
          <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
            *ngIf="selectedValue === item" stopPropagation>
            <mat-icon>link</mat-icon>
          </button>

          <button *ngIf="archiveAccessAction" mat-icon-button
            matTooltip="{{item['archive'] === 'false' ? 'Archive this Test Run' : 'Unarchive this Test Run'}}"
            (click)="changeArchiveType(item)">
            <mat-icon *ngIf="item['archive'] === 'false'">archive</mat-icon>
            <mat-icon *ngIf="item['archive'] !== 'false'">unarchive</mat-icon>
          </button>

          <button class="item-list__body-details" mat-icon-button (click)="showDetails(item)" matTooltip="Show Details"
            stopPropagation [routerLink]="'./'"
            [queryParams]="{project: item.projectId, type: 'tr', id: item.id, details: true}">
            <mat-icon>details</mat-icon>
          </button>

          <button *ngIf="!guestAccessAction" class="item-list__body-defect" mat-icon-button (click)="showDefects(item)"
            matTooltip="Show Defects" stopPropagation [routerLink]="'./'"
            [queryParams]="{project: item.projectId, type: 'tr', id: item.id, defect: true}">
            <mat-icon>bug_report</mat-icon>
          </button>

          <div *ngIf="qrAccessAction" class="item-list__qr" (click)="openQrDialog(item)">
            <button mat-icon-button>
              <mat-icon _ngcontent-rdb-c218="" role="img"
                class="mat-icon notranslate qr-code-icon ng-tns-c218-0 material-icons mat-icon-no-color"
                aria-hidden="true">qr_code
              </mat-icon>
            </button>
          </div>

          <button mat-icon-button [matMenuTriggerFor]="Infomenu" stopPropagation>
            <mat-icon>info</mat-icon>
          </button>
          <mat-menu #Infomenu="matMenu" class="info-menu">
            <div class="info-menu-item" mat-menu-item>
              <span>Created Date: {{item.createdDate}}</span>
            </div>
            <div class="info-menu-item" mat-menu-item>
              <span>Modified Date: {{item.modifiedDate}}</span>
            </div>
            <div class="info-menu-item" mat-menu-item>
              <span>Create By: {{item.userName}}</span>
            </div>
          </mat-menu>

        </div>

        <div [class]="isRightSectionOpen ? 'media-right-menu' : 'media-right-menu hidden-menu'">
          <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
            *ngIf="selectedValue === item" stopPropagation>
            <mat-icon>link</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="mediaTestRunSelect" stopPropagation>
            <mat-icon>settings</mat-icon>
          </button>

          <mat-menu #mediaTestRunSelect>
            <button mat-icon-button [matMenuTriggerFor]="Infomenu" stopPropagation>
              <mat-icon>info</mat-icon>
            </button>
            <mat-menu #Infomenu="matMenu" class="info-menu">
              <div class="info-menu-item" mat-menu-item>
                <span>Created Date: {{item.createdDate}}</span>
              </div>
              <div class="info-menu-item" mat-menu-item>
                <span>Modified Date: {{item.modifiedDate}}</span>
              </div>
              <div class="info-menu-item" mat-menu-item>
                <span>Create By: {{item.userName}}</span>
              </div>
            </mat-menu>
            <button *ngIf="qrAccessAction" mat-icon-button (click)="openQrDialog(item)">
              <mat-icon _ngcontent-rdb-c218="" role="img"
                class="mat-icon notranslate qr-code-icon ng-tns-c218-0 material-icons mat-icon-no-color"
                aria-hidden="true">qr_code
              </mat-icon>
            </button>
            <button *ngIf="!guestAccessAction" class="item-list__body-defect" mat-icon-button
              (click)="showDefects(item)" matTooltip="Show Defects" [routerLink]="'./'"
              [queryParams]="{project: item.projectId, type: 'tr', id: item.id, defect: true}">
              <mat-icon>bug_report</mat-icon>
            </button>
            <button class="item-list__body-details" mat-icon-button (click)="showDetails(item)"
              matTooltip="Show Details" [routerLink]="'./'"
              [queryParams]="{project: item.projectId, type: 'tr', id: item.id, details: true}">
              <mat-icon>details</mat-icon>
            </button>
            <button *ngIf="archiveAccessAction" mat-icon-button
              matTooltip="{{item['archive'] === 'false' ? 'Archive this Test Run' : 'Unarchive this Test Run'}}"
              (click)="changeArchiveType(item)">
              <mat-icon *ngIf="item['archive'] === 'false'">archive</mat-icon>
              <mat-icon *ngIf="item['archive'] !== 'false'">unarchive</mat-icon>
            </button>
          </mat-menu>

        </div>
      </div>

    </mat-list-option>
  </mat-selection-list>

</app-base-card>