<div class="dialog-title">
  <h1 mat-dialog-title>View attachments</h1>
</div>
<div class="dialog-content" mat-dialog-content style="padding-bottom: 1%">
  <mat-accordion>
    <div *ngFor="let attachment of attachmentFromStep.attachments">
      <mat-expansion-panel *ngIf="attachment.fileType && attachment.fileType.includes('image')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>subject</mat-icon>
            <span>Screenshot</span>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <img [src]="attachment.fullPath" style="width: 100%">
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="attachment.fileType === 'text/plain'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>subject</mat-icon>
            <span>Text</span>
          </mat-panel-title>
          <mat-panel-description>
            File type: {{attachment.fileDataType}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Data</mat-label>
          <textarea matInput>{{attachment.fileData}}</textarea>
        </mat-form-field>
      </mat-expansion-panel>
    </div>

    <div *ngIf="data.testStep.attachments.length === 0 && newAttachments.length === 0">
      <div class="margin">Attachments not found...</div>
    </div>

    <div class="margin" *ngIf="newAttachments.length > 0">
      <div class="title">Files to add to step:</div>
      <div *ngFor="let attachment of newAttachments; let i = index">
        <div class="flex flex--width-100">
          <div class="flex">
            <mat-icon class="icon">attach_file</mat-icon>
            <div>{{attachment.name}}</div>
          </div>
          <div class="flex">
            <mat-icon *ngIf="attachment.type.includes('image')" class="icon icon-action"
              [style.color]="previewAttachmentList.includes(attachment) ? '#cedefa' : ''"
              (click)="previewAttachment(attachment)">perm_media</mat-icon>
            <mat-icon class="icon icon-action" (click)="deleteAttachment(attachment, i)">delete</mat-icon>
          </div>
        </div>
        <div *ngIf="previewAttachmentList.includes(attachment)">
          <img class="preview" [src]="getUrl(attachment)" alt="preview">
        </div>
      </div>
    </div>
  </mat-accordion>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button (click)="hiddenfileinput.click()" *ngIf="editAccessAction">
    <div class="attachment__button">
      <span class="attachment__button-container">
        <mat-icon class="icon">insert_drive_file</mat-icon>
        <div>Upload file</div>
      </span>
    </div>
    <input style="display: none" type="file" (change)="loadFile($event)" multiple #hiddenfileinput />
  </button>
  <button *ngIf="editAccessAction" mat-raised-button color="primary" style="background: #2d64ba"
    [disabled]="newAttachments.length === 0" (click)="onAddAction()">Save</button>
</div>