import { Component, Input, OnInit } from '@angular/core';
import { TestStep } from 'src/app/services/test-step.type';
import { Observable, of } from 'rxjs';
import { UserAccessService } from 'src/app/services/user-access';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-test-step-run-form',
  templateUrl: './test-step-run-form.component.html',
  styleUrls: ['./test-step-run-form.component.scss']
})
export class TestStepRunFormComponent implements OnInit {
  public editAccessAction: boolean = this._userAccessService.getAccess('testStep', 'testStepEditLevel');

  @Input() public testStep: TestStep;
  public commentSize: boolean = false;

  constructor(
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void { }

  textHeightFunc(text: HTMLTextAreaElement): string {
    return `${text.scrollHeight}px`;
  }
}
