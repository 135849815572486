import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { TestPlan } from '../../services/test-plan.type';
import { ProjectService } from '../../services/project.service';
import { TestPlanService } from '../../services/test-plan.service';
import { TestSuitFoldersTemplate } from '../../services/test-suit.type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedSuitPlanSelectComponent, TodoItemFlatNode } from '../shared/dialog-shared-suit-plan-select/shared-suit-plan-select.component';

export interface DialogData {
  testSuits: TestSuitFoldersTemplate[];
}

@Component({
  selector: 'app-dialog-create-test-plan',
  templateUrl: 'dialog-create-test-plan.component.html',
  styleUrls: ['./dialog-create-test-plan.component.scss']
})
export class DialogCreateTestPlanComponent implements OnInit {
  @ViewChild('shared') public sharedComponent: SharedSuitPlanSelectComponent;

  projects: Project[];
  testPlan: TestPlan = new TestPlan();
  nextStep = false;
  testSuits: TestSuitFoldersTemplate[] = [];
  selectedTestCases: any[] = [];

  craeteTestPlanForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateTestPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
    private testPlanService: TestPlanService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.projectService.getProjects().subscribe(response => {
      this.projects = response;
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  initForm() {
    this.craeteTestPlanForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      project: new FormControl(this.projectService.getSelectedProjectFromStorage(), [Validators.required]),
    });
  }

  get f() {
    return this.craeteTestPlanForm.controls;
  }

  createSuit(): void {
    this.selectedTestCases = this.mapSelectedTestCases(this.sharedComponent?.checklistSelection.selected);
    this.testPlan.title = this.craeteTestPlanForm.value.title;
    this.testPlan.description = this.craeteTestPlanForm.value.description;
    this.testPlan.projectId = this.craeteTestPlanForm.value.project;

    this.testPlanService.createTestPlan(this.testPlan, this.selectedTestCases).subscribe(res => {
      this.dialogRef.close(res)
    });
  }

  mapSelectedTestCases(treeData: TodoItemFlatNode[]): any {
    const selectedTestCases = [];
    treeData.forEach((value: TodoItemFlatNode) => {
      if (value.isCase) selectedTestCases.push(value.tesSuitRelationId);
    });
    return selectedTestCases;
  }
}
