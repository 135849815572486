import { Component, Input, OnInit } from '@angular/core';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestRun } from 'src/app/services/test-run.type';
import { TestStepService } from 'src/app/services/test-step.service';
import { TestPlanService } from 'src/app/services/test-plan.service';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { TestStep } from 'src/app/services/test-step.type';
import { appTypes } from '../../services/app-types';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestSuit } from 'src/app/services/test-suit.type';
import { Status } from 'src/app/services/status.type';
import { DefaultStatuses } from '../../services/default-statuses.type';
import { Core } from 'src/app/services/core.service';

@Component({
  selector: 'app-test-runs-tab',
  templateUrl: './test-runs-tab.component.html',
  styleUrls: ['./test-runs-tab.component.scss']
})
export class TestRunsTabComponent implements OnInit {

  public creationMode = true;
  private currentProject = JSON.parse(Core.localStorageService.getItem('selected_project')) || {};
  public testCases: TestCase[];
  public testSteps: TestStep[];
  public appTypes = appTypes;
  @Input() testRuns: TestRun[];
  public nesting = [];
  public data: any = {};
  defaultStatuses = new DefaultStatuses();
  public statuses: Status[] = [];

  constructor(
    private testRunService: TestRunService,
    private testCaseService: TestCaseService,
    private testStepService: TestStepService,
    private testPlanService: TestPlanService,
    private testSuitService: TestSuitService
  ) { }

  ngOnInit(): void {
    this.data[this.appTypes.testRun] = {};
    this.data[this.appTypes.testRun].data = [];
    this.data[this.appTypes.testRun].creationMode = false;

    this.data[this.appTypes.testPlan] = {};
    this.data[this.appTypes.testPlan].data = [];
    this.data[this.appTypes.testPlan].creationMode = false;

    this.data[this.appTypes.testSuit] = {};
    this.data[this.appTypes.testSuit].data = [];
    this.data[this.appTypes.testSuit].creationMode = false;
    this.getTestRuns();
    this.getTestPlans();
    this.getTestSuits();
  }

  getTestRuns() {
    this.testRunService.getTestRuns(+this.currentProject).subscribe((testRuns: TestRun[]) => {
      this.data[this.appTypes.testRun].data = testRuns;
    })
  }

  getTestPlans() {
    this.testPlanService.getTestPlans(+this.currentProject).subscribe((testPlans: TestPlan[]) => {
      this.data[this.appTypes.testPlan].data = testPlans;
    })
  }

  getTestSuits() {
    this.testSuitService.getTestSuits(+this.currentProject).subscribe((testSuits: TestSuit[]) => {
      this.data[this.appTypes.testSuit].data = testSuits;
    })
  }

  onItemClicked(item: any, type: string) {
    if (type === this.appTypes.testRun) {
      this.getTestRunCases(item.id);
      this.data[this.appTypes.testPlan].data.map(testPlanItem => testPlanItem.isOpen = false);
      this.data[this.appTypes.testSuit].data.map(testSuitItem => testSuitItem.isOpen = false);
    }
    if (type === this.appTypes.testPlan) {
      this.getTestPlanSuits(item.id);
      this.data[this.appTypes.testRun].data.map(testRunItem => testRunItem.isOpen = false);
      this.data[this.appTypes.testSuit].data.map(testSuitItem => testSuitItem.isOpen = false);
    }
    if (type === this.appTypes.testSuit) {
      this.getTestSuitCases(item.id);
      this.data[this.appTypes.testRun].data.map(testRunItem => testRunItem.isOpen = false);
      this.data[this.appTypes.testPlan].data.map(testPlanItem => testPlanItem.isOpen = false);
    }
    if (type === this.appTypes.testCase) {
      if (item.relationId) this.getTestCaseSteps(item.relationId, true);
      else this.getTestCaseSteps(item.testCaseId || item.id, false);
    }
  }

  reinitTestCases(testCases: TestCase[]) {
    this.data[this.appTypes.testCase] = {};
    this.data[this.appTypes.testCase].data = testCases;
    this.nesting = [];
    this.nesting.push(this.appTypes.testCase);
  }

  getTestRunCases(testRunId: string) {
    this.testCaseService.getTestCasesByTestRun({ tr_id: testRunId })
      .subscribe((testCases: TestCase[]) => { this.reinitTestCases(testCases) })
  }

  getTestPlanSuits(id: string) {
    this.testPlanService.getTestCasesList(id)
      .subscribe((testCases: TestCase[]) => { this.reinitTestCases(testCases) })
  }

  getTestSuitCases(id: string) {
    this.testCaseService.getTestCasesByTestSuit({ ts_id: id })
      .subscribe((testCases: TestCase[]) => { this.reinitTestCases(testCases) })
  }

  getTestCaseSteps(id: string, fromTestRun: boolean) {
    if (fromTestRun) {
      this.testStepService.getTestStepsByRelationId({ relation_id: id }).subscribe((testSteps: TestStep[]) => {
        this.data[this.appTypes.testStep] = {};
        this.data[this.appTypes.testStep].data = testSteps;
        if (this.nesting.indexOf(this.appTypes.testStep) < 0) {
          this.nesting.push(this.appTypes.testStep)
        };
      })
    }
    else {
      this.testStepService.getTestSteps(id).subscribe((testSteps: TestStep[]) => {
        this.data[this.appTypes.testStep] = {};
        this.data[this.appTypes.testStep].data = testSteps;
        if (this.nesting.indexOf(this.appTypes.testStep) < 0) this.nesting.push(this.appTypes.testStep);
      })
    }
  }

  createNewItem(title: string, type: string) {
    if (type === this.appTypes.testRun) this.createTestRun(title);
    // if (type === this.appTypes.testCase)
  }

  createTestRun(title: string) {
    const testRun = new TestRun();
    testRun.title = title;
    testRun.isOpen = true;
    // To create test run you need to add at least one test case

    this.data[this.appTypes.testRun].data.unshift(testRun);
    this.data[this.appTypes.testCase].creationMode = true;

    // this.testRunService.createTestRun(testRun, []).subscribe((result) => {
    //   if (result) this.getTestRuns();
    // })
  }

  onClose(type: string) {
    const position = this.nesting.indexOf(type);
    this.nesting = this.nesting.slice(0, position);

    if (this.nesting.length === 0) {
      this.data[this.appTypes.testRun].data.map(testRunItem => testRunItem.isOpen = false);
      this.data[this.appTypes.testPlan].data.map(testPlanItem => testPlanItem.isOpen = false);
      this.data[this.appTypes.testSuit].data.map(testSuitItem => testSuitItem.isOpen = false);
    }
  }

}
