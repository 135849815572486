import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status } from '../../services/status.type';
import { ProjectService } from '../../services/project.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { newStateValidator } from './validators';


export interface DialogData {
  projectId: string
}

@Component({
  selector: 'app-dialog-create-status',
  templateUrl: 'dialog-create-status.component.html',
  styleUrls: ['./dialog-create-status.component.scss']
})
export class DialogCreateStatusComponent implements OnInit {

  public status: Status = new Status();
  public statusForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.status.color = '#353D45';
  }

  initForm() {
    this.statusForm = this.fb.group({
      name: ['', [Validators.required]],
      statusState: ['', [Validators.required]],
      color: ['', [Validators.required]],
    })

    this.projectService.getAllProjectsStatuses().subscribe(res => {
      let statuses = res;
      this.statusForm.setValidators(newStateValidator(statuses, null))
    })
  }

  get f() {
    return this.statusForm.controls
  }

  setColor(color) {
    this.f.color.setValue(color);
  }

  createAction(): void {
    this.projectService.createStatus({
      name: this.statusForm.value.name,
      color: this.statusForm.value.color,
      project_id: this.projectService.getSelectedProjectFromStorage(),
      status_state: this.statusForm.value.statusState,
    }).subscribe(res => {
      this.dialogRef.close(res)
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
