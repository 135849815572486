<app-sidenav>
  <mat-card>
    <div class="table-container">
      <div class="loading-shade" *ngIf="dataSource.loading$ | async">
      </div>

      <mat-table mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef nmat-sort-header>Id</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header>title</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.title}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header>description</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>createdDate</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.createdDate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="modifiedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>modifiedDate</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.modifiedDate}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetail(row)"></mat-row>
      </mat-table>
    </div>
    <mat-paginator #paginator [length]="1000" [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
  </mat-card>
</app-sidenav>
