import { Component, OnInit } from '@angular/core';
import { TestStep } from '../services/test-step.type';
import { TestSuit } from '../services/test-suit.type';
import { TestCase } from '../services/test-case.type';
import { TestSuitService } from '../services/test-suit.service';
import { TestCaseService } from '../services/test-case.service';
import { Router } from '@angular/router';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-add-new-test-case',
  templateUrl: './add-new-test-case.component.html',
  styleUrls: ['./add-new-test-case.component.scss']
})
export class AddNewTestCaseComponent implements OnInit {

  testSteps: TestStep[] = [];
  testCase: TestCase = new TestCase();
  testStep: TestStep = new TestStep();
  testSuits: TestSuit[] = [];

  constructor(private testSuitService: TestSuitService, private testCaseService: TestCaseService, private router: Router) { }

  ngOnInit(): void {
    this.testSuitService.getTestSuits({ "auth_cookie": { "auth_user_id": "1" }, "project_id": Core.localStorageService.getItem('selected_project') }).subscribe(res => {
      this.testSuits = res;
    });
  }

  createStep() {
    this.testSteps.push(this.testStep);
    this.testStep = new TestStep();
  }

  deleteStep(testStep) {
    const index = this.testSteps.indexOf(testStep);
    this.testSteps.splice(index, 1);
  }

  createCase() {
    // this.testCaseService.createTestCase(this.testCase, this.testSteps).subscribe(res => {
    //   this.router.navigate(['test-cases']);
    // });
  }

}
