<app-base-card [title]="'Test Step Defects'" [icon]="'dns'" [showAdd]="false" [showClose]="true"
  (closeEvent)="closeEvent.emit()">

  <mat-accordion [multi]="shortCases && shortRun">
    <mat-expansion-panel #panel class="list-card__item item-list item-list-accordion"
      [expanded]="shortCases && shortRun">
      <mat-expansion-panel-header>
        <mat-panel-title class="item-list-accordion__title">
          {{testCase.defectTitle}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="item-list-accordion__info info-item-list">
        <div class="info-item-list__wrap">
          <div class="info-item-list__title">Comment</div>
          <div class="info-item-list__sub-title">{{testCase.comment}}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <ng-container *ngIf="showFailedTest">
    <mat-accordion>
      <div *ngFor="let attachment of failedOnStep.attachments">
        <mat-expansion-panel *ngIf="attachment.fileType && attachment.fileType.includes('image')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>subject</mat-icon>
              <span>Screenshot</span>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <img [src]="api.getApi() + attachment.relativePath" class="width-100">
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="attachment.fileType === 'text/plain'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>subject</mat-icon>
              <span>Text</span>
            </mat-panel-title>
            <mat-panel-description>
              File type: {{attachment.fileDataType}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field class="width-100" appearance="outline">
            <mat-label>Data</mat-label>
            <textarea matInput>{{attachment.fileData}}</textarea>
          </mat-form-field>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </ng-container>

  <div class="card__footer">
    <button mat-raised-button color="primary" (click)="onCreateDefect()"><mat-icon>add</mat-icon>Create Defect</button>
  </div>

</app-base-card>