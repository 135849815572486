import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, Input } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogCreateTestPlanComponent } from 'src/app/dialogs/dialog-create-test-plan/dialog-create-test-plan.component';
import { DialogEditComponent } from 'src/app/dialogs/dialog-edit/dialog-edit.component';
import { DialogRunComponent } from 'src/app/dialogs/dialog-run/dialog-run.component';
import { DialogTestCasesSelectComponent } from 'src/app/dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { TestCase } from 'src/app/services/test-case.type';
import { TestPlanService } from 'src/app/services/test-plan.service';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { TestSuit, TestSuitFolders } from 'src/app/services/test-suit.type';
import { Core } from 'src/app/services/core.service';
import { UserService } from '../../services/user.service';
import { User } from '../../services/user.type';
import { Params } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { TodoItemFlatNode } from 'src/app/dialogs/shared/dialog-shared-suit-plan-select/shared-suit-plan-select.component';
import { BaseCardComponent } from 'src/app/app-shared-component/base-card/base-card.component';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
	selector: 'app-card-test-plan',
	templateUrl: './card-test-plan.component.html',
	styleUrls: ['./card-test-plan.component.scss']
})
export class CardTestPlanComponent implements OnInit, OnDestroy {
	public guestAccessAction: boolean = this._userAccessService.getLevelAccessUser === 4;
	public addTestPlanAccess: boolean = this._userAccessService.getAccess('testPlan', 'testPlanCreateLevel');
	public editTestPlanAccess: boolean = this._userAccessService.getAccess('testPlan', 'testPlanEditLevel');
	public deleteTestPlanAccess: boolean = this._userAccessService.getAccess('testPlan', 'testPlanDeleteLevel');
	public addCaseTestPlanAccess: boolean = this._userAccessService.getAccess('testPlan', 'testPlanAddCaseLevel');
	public createRunFromTestPlanAccess: boolean = this._userAccessService.getAccess('testPlan', 'testPlanCreateRunLevel');

	@ViewChild(BaseCardComponent) private baseCard: BaseCardComponent;
	@ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;

	public search: string;

	public testPlan: TestPlan[];
	public sortTestPlan: TestPlan[];
	private _testPlan: TestPlan[];
	private _testSuits: TestSuit[];
	private _testPlansCase: TestCase[];
	public selectedValue: TestPlan;
	public showSection: boolean = true;

	private selectionListSubscriber: SubscriptionLike;
	private users = [];
	private assignedTo: User;

	public activeTestPlan: TestPlan = null;
	public pending: boolean = false;

	private sortType: string;
	private sortTypesData = {
		'Creation Date': 'createDate',
		'Modification Date': 'modifiedDate',
		'Description': 'description',
		'Created By': 'creator_id',
		'Name': 'title',
	};

	@Output() showTestPlanCase = new EventEmitter<TestPlan>();
	@Output() updateTestPlanCases = new EventEmitter<TestPlan>();
	@Output() updateTestRun = new EventEmitter();
	@Output() collapseEvent = new EventEmitter<string>();
	@Output() showCaseEvent = new EventEmitter();

	@Output() sortSelectData = Object.keys(this.sortTypesData);
	@Output() baseSortValue = this.testPlanService.sortType || this.sortSelectData[0];

	@Input() showCase: boolean = false;
	@Input() showPlan: boolean = true;
	@Input() shortForm: boolean = false;
	@Input() queryParams: Params;


	constructor(
		private testPlanService: TestPlanService,
		private testSuitService: TestSuitService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private userService: UserService,
		private clipboard: Clipboard,
		private _userAccessService: UserAccessService,
	) {
	}

	ngOnDestroy(): void {
		this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : '';
	}

	ngOnInit(): void {
		this.setSelectionList();
		this.getData();
	}

	searchPath(param: Params) {
		let find = this.testPlan.find(elem => +elem.id === +param.id && +elem.projectId === +param.project);
		if (find) {
			this.selectedValue = find;
			this.selectionList.options.changes.subscribe(res => {
				res.forEach(elem => {
					if (elem.value === find) {
						elem.selected = true;
					}
				});
			});
			this.showTestPlanCase.emit(find);
		}
	}

	getData() {
		this.getTestPlans();
		this.getTestSuits();
		this.getUser();
	}

	setSelectionList() {
		this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
			this.selectionList.deselectAll();
			s.options.forEach(item => {
				item.selected = true;
			});
		});
	}

	refreshSelectionList() {
		this.selectionList.deselectAll();
	}

	getTestPlans() {
		this.testPlanService.getTestPlans({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe((testPlans: TestPlan[]) => {
			this.testPlan = this.mapSelectedValue(testPlans);
			this._testPlan = testPlans;
			this.sortTestPlanByEvent();
			if (this.queryParams?.type === 'tp') {
				this.searchPath(this.queryParams);
			}
		});
	}

	getTestPlanInfo(id) {
		return this._testPlan.find(item => item.id === id)
	}

	mapSelectedValue(testPlan: TestPlan[]): TestPlan[] {
		if (this.selectedValue) {
			return testPlan.map(val => {
				val.id === this.selectedValue.id ? val.isOpen = true : val.isOpen = false;
				return val;
			});
		} else {
			return testPlan;
		}
	}

	getTestSuits() {
		this.testSuitService.getTestSuits({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe((testSuits: TestSuit[]) => {
			this._testSuits = testSuits;
		});
	}

	getTestPlanCases(id: string) {
		return this.testPlanService.getTestCasesList(id).pipe(
			map((testCases: TestCase[]) => {
				this._testPlansCase = testCases;
				return testCases;
			})
		);
	}

	selectionChange(event: MatSelectionListChange) {
		event.options.forEach(item => {
			this.showTestPlanCase.emit(item.value);
			this.selectedValue = item.value;
		});
		this.showCaseEvent.emit();
	}

	async onSelectEvent(item: TestPlan) {
		this.activeTestPlan = item;
		this.pending = true;
		let foldersSuit = [];
		const testSuits = await this.setCaseToSuit();
		testSuits.forEach(elem => { foldersSuit.push([elem]) });
		const selectedSuits = await this.testPlanService.foldersPlansList({ tp_id: item.id }).toPromise();
		let casesObj = selectedSuits.reduce((acc, key) => [...acc, this.testPlanService.mapSuitFoldersTemplate(key)], []);
		const selectedSuitsTemplate = casesObj.reduce((acc, item) => [...acc, ...item], []);

		this.pending = false;
		const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
			width: '650px',
			data: { testSuits: foldersSuit, selectedSuits: selectedSuitsTemplate, title: 'SelectFromPlan' },
			panelClass: 'custom-modalbox'
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				let beforeSelect: any[] = casesObj.reduce((ac, elem) => [...ac, ...elem], []);
				let afterSelect: TodoItemFlatNode[] = [...result].filter(elem => elem.isCase);
				let deletedTestCase: any[] = [];

				beforeSelect.forEach(before => {
					if (before.cases) deletedTestCase.push(...before.cases);
				});

				afterSelect.forEach(nodeAfter => {
					deletedTestCase.forEach((item, i) => {
						if (+item.tesSuitRelationId === +nodeAfter.tesSuitRelationId) deletedTestCase.splice(i, 1);
					})
				});

				this.testPlanService.updateTestCasesList({
					project_id: item.projectId,
					test_plan_id: item.id,
					test_cases: afterSelect.map(c => { return { tc_id: (c.elem as TestCase).id, ts_id: (c.elem as TestCase).testSuitId }; }),
					deleted_test_cases: deletedTestCase.map(c => ({ tc_id: c.testCaseId, ts_id: c.tsId }))
				}).subscribe(res => {
					if (res) {
						this.snackBar.open('Test Cases was successfully selected', "OK", { duration: 3000 });
						this.getTestPlans();
						this.activeTestPlan = null;
						this.updateTestPlanCases.emit(this.selectedValue);
					};
				});
			};
		});
	}

	onEditEvent(item: TestPlan) {
		const dialogRef = this.dialog.open(DialogEditComponent, {
			width: '650px',
			data: { title: item.title, description: item.description }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const testPlan = this._testPlan.find(val => val.id === item.id);
				this.testPlanService.saveTestPlan(Object.assign(testPlan, res)).toPromise().then(res => {
					if (res) {
						this.snackBar.open('Test Plan was successfully edited', 'OK', { duration: 3000 });
						this.getTestPlans();
					}
				});
			}
		});
	}

	toTestRun(item: TestPlan) {
		const dialogRef = this.dialog.open(DialogRunComponent, {
			width: '650px',
			data: { title: item.title, type: 'plan', testPlan: item }
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.snackBar.open('Test Run was successfully created', 'OK', { duration: 3000 });
				this.updateTestRun.emit();
			}
		});
	}

	onDeleteEvent(item: TestPlan) {
		this.testPlanService.deleteTestPlan({ id: item.id }).subscribe(() => {
			this.getTestPlans();
		});
	}

	async onAddEvent() {
		let folders = [];
		const testSuits = await this.setCaseToSuit();
		testSuits.forEach(elem => { folders.push([elem]) });

		if (this.baseCard) this.baseCard.pending = false;
		this.dialog.open(DialogCreateTestPlanComponent, {
			width: '650px',
			data: { testSuits: folders }
		}).afterClosed().subscribe(res => {
			if (res) {
				this.snackBar.open('Test Plan was successfully added', "OK", { duration: 3000 });
				this.getTestPlans();
			}
		})
	}

	setCaseToSuit(): Promise<TestSuitFolders[]> {
		return new Promise<TestSuitFolders[]>((resolve) => {
			let params = {};
			if (this.selectedValue) params = { project_id: this.selectedValue.projectId };
			else if (Core.localStorageService.getItem('selected_project') !== 'all' || 'null') params = { project_id: Core.localStorageService.getItem('selected_project') };

			this.testSuitService.foldersList(params).subscribe((folder: TestSuitFolders[]) => {
				resolve(folder);
			});
		});
	}

	getUser() {
		this.userService.getUsers().subscribe(v => {
			this.users = v;
		});
	}

	getUserName(id) {
		if (this.users) this.assignedTo = this.users.find(user => user.id === id);
		if (this.assignedTo) return this.assignedTo.firstName;
		return ''
	}

	copyLink() {
		if (window.location.href) {
			let queryParamArr = window.location.href.split('?')[1].split('&');
			let params = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

			if (!params['tcp']) {
				this.clipboard.copy(window.location.href);
			} else {
				this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=${params['type']}&id=${params['id']}`);
			}

			this.snackBar.open('Link was successfully copied', 'OK', { duration: 3000 });
		}
	}

	sortTestPlanByEvent(event?) {
		this.sortType = this.testPlanService.sortType;
		if (event) {
			this.sortType = event.value;
			this.testPlanService.sortType = event.value;
		}

		let sortedTestPlans = this.testPlan

		if (this.sortType) {
			switch (this.sortType) {
				case 'Creation Date':
					sortByCreationDate();
					break;
				case 'Modification Date':
					sortByModificationDate();
					break;
				case 'Description':
					sortByDescription();
					break;
				case 'Created By':
					sortByCreator();
					break;
				case 'Name':
					sortByName();
					break;
			}
		} else {
			sortByCreationDate()
		}

		function sortByCreationDate() {
			sortedTestPlans.sort(function (a, b) {
				return +new Date(b.createdDate) - +new Date(a.createdDate);
			});
		}

		function sortByModificationDate() {
			sortedTestPlans.sort(function (a, b) {
				return +new Date(b.modifiedDate) - +new Date(a.modifiedDate);
			});
		}

		function sortByDescription() {
			sortedTestPlans.sort(function (a, b) {

				if (a.description?.toUpperCase() < b?.description.toUpperCase()) {
					return -1;
				}
				if (a.description?.toUpperCase() > b?.description.toUpperCase()) {
					return 1;
				}
				return 0;
			});
		}

		function sortByName() {
			sortedTestPlans.sort(function (a, b) {

				if (a.title.toUpperCase() < b.title.toUpperCase()) {
					return -1;
				}
				if (a.title.toUpperCase() > b.title.toUpperCase()) {
					return 1;
				}
				return 0;
			});
		}

		function sortByCreator() {
			sortedTestPlans.sort(function (a, b) {
				let nameA = a.creator_name;
				let nameB = b.creator_name;
				if (!a.creator_name) {
					return 1;
				}
				if (!b.creator_name) {
					return -1;
				}
				return nameA.localeCompare(nameB);
			});
		}
	}
}
