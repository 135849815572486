<div class="dialog-title">
  <h1 mat-dialog-title>Create New Test Plan</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <div *ngIf="!nextStep">
    <form [formGroup]="craeteTestPlanForm">
      <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title">
        <mat-error *ngIf="f.title.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description">
        <mat-error *ngIf="f.description.hasError('required')">
          Description is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Project</mat-label>
        <mat-select formControlName="project">
          <mat-option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.project.hasError('required')">
          Project is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="nextStep">
    <app-shared-suit-plan-select #shared [testSuits]="data.testSuits"
      [title]="'TestPlan'"></app-shared-suit-plan-select>
  </div>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button *ngIf="nextStep"
    [disabled]="!(sharedComponent ? sharedComponent.checklistSelection.selected.length === 0 : true)" mat-raised-button
    color="primary" style="background: #2d64ba" (click)="createSuit()">Skip &
    Create</button>
  <button *ngIf="nextStep"
    [disabled]="sharedComponent ? sharedComponent?.checklistSelection.selected.length === 0 : true" mat-raised-button
    color="primary" style="background: #2d64ba" (click)="createSuit()">Create</button>
  <button *ngIf="!nextStep" [disabled]="!craeteTestPlanForm.valid" mat-raised-button color="primary"
    style="background: #2d64ba" (click)="nextStep = true">Next</button>
</div>