import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthenticationService } from '../services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TestSuitService } from '../services/test-suit.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: UntypedFormControl;
  password: UntypedFormControl;
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  hide = true;
  companyDomainError = false;
  companyDomain = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public matcher: ErrorStateMatcher,
    private authenticationService: AuthenticationService,
    private testSuitService: TestSuitService,
    private snackBar: MatSnackBar

  ) { }

  ngOnInit() {
    this.username = new UntypedFormControl('', [
      Validators.required,
    ]);
    this.password = new UntypedFormControl('', [
      Validators.required,
    ]);
    this.loginForm = this.formBuilder.group({
      username: this.username,
      password: this.password
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  onSubmit() {
    let param = {};
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.companyDomainError) {
      param = { login: this.username.value, password: this.password.value, company_domain: this.companyDomain };
    } else {
      param = { login: this.username.value, password: this.password.value };
    }

    this.authenticationService.login(param).subscribe((result: any) => {
      if (+result.valid === 1) {
        this.authenticationService.getUserStaticData().subscribe(res => {
          this.router.navigate([this.returnUrl]);
          this.testSuitService.getTestSuits({}).subscribe();
        })
      } else {
        this.snackBar.open(result.message || result.error_msg, 'OK', {
          duration: 3000,
        });
        if (result.error_msg === 'missing company')
          this.companyDomainError = true;
        this.loading = false;
      }
    })
  }
}
