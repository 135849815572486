import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DialogCreateTestPlanComponent } from '../dialogs/dialog-create-test-plan/dialog-create-test-plan.component';
import { DialogCreateTestRunComponent } from '../dialogs/dialog-create-test-run/dialog-create-test-run.component';
import { DialogCreateTestSuitComponent } from '../dialogs/dialog-create-test-suit/dialog-create-test-suit.component';
import { DialogEditComponent } from '../dialogs/dialog-edit/dialog-edit.component';
import { DialogRunComponent } from '../dialogs/dialog-run/dialog-run.component';
import { DialogSelectComponent } from '../dialogs/dialog-select/dialog-select.component';
import { DialogTestCaseFailComponent } from '../dialogs/dialog-test-case-fail/dialog-test-case-fail.component';
import { DialogTestCasesSelectComponent } from '../dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { appTypes } from '../services/app-types';
import { DefaultStatuses } from '../services/default-statuses.type';
import { ProjectService } from '../services/project.service';
import { Project } from '../services/project.type';
import { Status } from '../services/status.type';
import { TestCaseService } from '../services/test-case.service';
import { TestCase } from '../services/test-case.type';
import { TestPlanService } from '../services/test-plan.service';
import { TestPlan } from '../services/test-plan.type';
import { TestRunService } from '../services/test-run.service';
import { TestRun } from '../services/test-run.type';
import { TestStepService } from '../services/test-step.service';
import { TestStep } from '../services/test-step.type';
import { TestSuitService } from '../services/test-suit.service';
import { TestSuit } from '../services/test-suit.type';
import { UserService } from '../services/user.service';
import { User } from '../services/user.type';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-run-page',
  templateUrl: './test-run-page.component.html',
  styleUrls: ['./test-run-page.component.scss']
})
export class TestRunPageComponent implements OnInit {

  public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
  public creationMode = true;

  public testRuns: TestRun[];
  private testRunsCase: TestCase[];
  public testPlans: TestPlan[];
  private testPlansCase: TestCase[];
  public testSuits: TestSuit[];
  private testSuitsCase: TestCase[];
  private users: User[]

  public appTypes = appTypes;
  public nesting = [];
  public data: any = {};
  defaultStatuses = new DefaultStatuses();
  public statuses: Status[] = [];
  public createTestCase: boolean = false;
  public projects: Project[];

  private selectedPlan: string;
  private selectedItem: TestCase;

  constructor(
    private testRunService: TestRunService,
    private testCaseService: TestCaseService,
    private testStepService: TestStepService,
    private testPlanService: TestPlanService,
    private testSuitService: TestSuitService,
    private projectService: ProjectService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.setDefaultData();
    this.getData();
  }

  getData() {
    this.getTestRuns();
    this.getTestPlans();
    this.getTestSuits();
  }

  setDefaultData() {
    this.data[this.appTypes.testRun] = {};
    this.data[this.appTypes.testRun].data = [];
    this.data[this.appTypes.testRun].creationMode = false;

    this.data[this.appTypes.testPlan] = {};
    this.data[this.appTypes.testPlan].data = [];
    this.data[this.appTypes.testPlan].creationMode = false;

    this.data[this.appTypes.testSuit] = {};
    this.data[this.appTypes.testSuit].data = [];
    this.data[this.appTypes.testSuit].creationMode = false;
  }

  getTestRuns() {
    this.testRunService.getTestRuns({ project_id: +this.currentProject }).subscribe((testRuns: TestRun[]) => {
      this.testRuns = testRuns;
      this.data[this.appTypes.testRun].data = testRuns;
    })
  }

  getTestPlans() {
    this.testPlanService.getTestPlans({ project_id: +this.currentProject }).subscribe((testPlans: TestPlan[]) => {
      this.testPlans = testPlans
      this.data[this.appTypes.testPlan].data = testPlans;
    })
  }

  getTestSuits() {
    this.testSuitService.getTestSuits({ project_id: +this.currentProject }).subscribe((testSuits: TestSuit[]) => {
      this.testSuits = testSuits
      this.data[this.appTypes.testSuit].data = testSuits;
    })
  }

  onItemClicked(item: any, type: string) {
    if (type === this.appTypes.testRun) {
      this.selectedPlan = this.appTypes.testRun;
      this.selectedItem = item;
      this.getTestRunCases(item.id).subscribe(res => this.reinitTestCases(res));
      this.createTestCase = false;
      this.data[this.appTypes.testPlan].data.map(testPlanItem => testPlanItem.isOpen = false);
      this.data[this.appTypes.testSuit].data.map(testSuitItem => testSuitItem.isOpen = false);
    }
    if (type === this.appTypes.testPlan) {
      this.selectedPlan = this.appTypes.testPlan;
      this.selectedItem = item;
      this.getTestPlanCases(item.id).subscribe(res => this.reinitTestCases(res));
      this.createTestCase = true
      this.data[this.appTypes.testRun].data.map(testRunItem => testRunItem.isOpen = false);
      this.data[this.appTypes.testSuit].data.map(testSuitItem => testSuitItem.isOpen = false);
    }
    if (type === this.appTypes.testSuit) {
      this.selectedPlan = this.appTypes.testSuit;
      this.selectedItem = item;
      this.getTestSuitCases(item.id).subscribe(res => this.reinitTestCases(res));
      this.createTestCase = true
      this.data[this.appTypes.testRun].data.map(testRunItem => testRunItem.isOpen = false);
      this.data[this.appTypes.testPlan].data.map(testPlanItem => testPlanItem.isOpen = false);
    }
    if (type === this.appTypes.testCase) {
      if (item.relationId) {
        this.getTestCaseSteps(item.relationId, true)
      } else {
        this.getTestCaseSteps(item.testCaseId || item.id, false)
      }
    }
  }

  reinitTestCases(testCases: TestCase[]) {
    this.data[this.appTypes.testCase] = {};
    this.data[this.appTypes.testCase].data = testCases;
    this.nesting = [];
    this.nesting.push(this.appTypes.testCase);
  }

  getTestRunCases(testRunId: string) {
    return this.testCaseService.getTestCasesByTestRun({ tr_id: testRunId }).pipe(
      map((testCases: TestCase[]) => {
        testCases.map(val => { val.filteredByPerson = '1'; val.filteredByStatus = '1'; })
        this.testRunsCase = testCases;
        return testCases;
      })
    )
  }

  getTestPlanCases(id: string) {
    return this.testPlanService.getTestCasesList(id).pipe(
      map((testCases: TestCase[]) => {
        this.testPlansCase = testCases;
        return testCases
      })
    )
  }

  getTestSuitCases(id: string) {
    return this.testCaseService.getTestCasesByTestSuit({ ts_id: id }).pipe(
      map((testCases: TestCase[]) => {
        this.testSuitsCase = testCases
        return testCases;
      })
    )
  }

  getTestCaseSteps(id: string, fromTestRun: boolean) {
    if (fromTestRun) {
      this.testStepService.getTestStepsByRelationId({ relation_id: id }).subscribe((testSteps: TestStep[]) => {
        this.data[this.appTypes.testStep] = {};
        this.data[this.appTypes.testStep].data = testSteps;
        if (this.nesting.indexOf(this.appTypes.testStep) < 0) {
          this.nesting.push(this.appTypes.testStep)
        };
      })
    }
    else {
      this.testStepService.getTestSteps(id).subscribe((testSteps: TestStep[]) => {
        this.data[this.appTypes.testStep] = {};
        this.data[this.appTypes.testStep].data = testSteps;
        if (this.nesting.indexOf(this.appTypes.testStep) < 0) this.nesting.push(this.appTypes.testStep);
      })
    }
  }

  updateTestCaseStatus(relationId, status, projectId) {
    return this.testRunService.updateTestRunTestCaseStatus({
      relation_id: relationId,
      status: status,
      project_id: projectId
    })
  }

  onClose(type: string) {
    const position = this.nesting.indexOf(type);
    this.nesting = this.nesting.slice(0, position);

    if (this.nesting.length === 0) {
      this.data[this.appTypes.testRun].data.map(testRunItem => testRunItem.isOpen = false);
      this.data[this.appTypes.testPlan].data.map(testPlanItem => testPlanItem.isOpen = false);
      this.data[this.appTypes.testSuit].data.map(testSuitItem => testSuitItem.isOpen = false);
    }
  }

  createNewItem(type: string) {
    if (type.toLowerCase() === this.appTypes.testRun.toLowerCase()) {
      this.dialog.open(DialogCreateTestRunComponent, {
        width: '650px',
        data: { testSuits: this.testSuits }
      });
    } else if (type.toLowerCase() === this.appTypes.testPlan.toLowerCase()) {
      this.dialog.open(DialogCreateTestPlanComponent, {
        width: '650px',
        data: { testSuits: this.testSuits }
      });
    } else {
      this.dialog.open(DialogCreateTestSuitComponent, {
        width: '650px',
        data: { testSuits: this.testSuits }
      });
    }
  }

  onEditEvent(item, type: string) {
    if (type.toLowerCase() === this.appTypes.testRun.toLowerCase()) {
      const dialogRef = this.dialog.open(DialogEditComponent, {
        width: '650px',
        data: { title: item.title, description: item.description }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          const testRun = this.testRuns.find(val => val.id === item.id);
          this.testRunService.saveTestRun(Object.assign(testRun, res)).toPromise().then(res => this.snackBar.open('Test Run is edited', "OK", { duration: 3000 }))
        }
      })
    } else if (type.toLowerCase() === this.appTypes.testPlan.toLowerCase()) {
      const dialogRef = this.dialog.open(DialogEditComponent, {
        width: '650px',
        data: { title: item.title, description: item.description }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          const testPlan = this.testPlans.find(val => val.id === item.id)
          this.testPlanService.saveTestPlan(Object.assign(testPlan, res)).toPromise().then(res => this.snackBar.open('Test Plan is edited', "OK", { duration: 3000 }))
        }
      })
    } else {
      const dialogRef = this.dialog.open(DialogEditComponent, {
        width: '650px',
        data: { dialogTitle: 'Edit Test Run', title: item.title, description: item.description }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          const testSuit = this.testSuits.find(val => val.id === item.id)
          this.testSuitService.saveTestSuit({ testSuit: Object.assign(testSuit, res) }).toPromise().then(res => this.snackBar.open('Test Suit is edited', "OK", { duration: 3000 }));
        }
      })
    }
  }

  ondDeleteEvent(item, type: string) {
    if (type.toLowerCase() === this.appTypes.testRun.toLowerCase()) {
      this.testRunService.deleteTestRun({ id: item.id }).subscribe(() => {
        this.getTestRuns();
        this.onClose(this.appTypes.testCase)
      })
    } else if (type.toLowerCase() === this.appTypes.testPlan.toLowerCase()) {
      this.testPlanService.deleteTestPlan({ id: item.id }).subscribe(() => {
        this.getTestPlans();
        this.onClose(this.appTypes.testCase)
      });
    } else {
      this.testSuitService.deleteTestSuit({ id: item.id }).subscribe(() => {
        this.getTestSuits();
        this.onClose(this.appTypes.testCase);
      })
    }
  }

  async onSelectEvent(item, type: string) {
    if (type.toLowerCase() === this.appTypes.testRun.toLowerCase()) {
      const testSuits = await this.setCaseToSuit(JSON.parse(JSON.stringify(this.testSuits)));
      const testRuns = await this.getTestRunCases(item.id).toPromise();
      const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
        width: '650px',
        data: { testSuits: testSuits, selectedTestCases: testRuns, title: 'SelectFromSuit' }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.testRunService.updateTestCasesList({
            test_run_id: item.id,
            test_cases: result.filter(c => { return c.level === 1; }).map(c => { return { tc_id: c.id, ts_id: c.tsId }; })
          }).subscribe(() => { location.reload() })
        }
      });
    } else if (type.toLowerCase() === this.appTypes.testPlan.toLowerCase()) {
      const testSuits = await this.setCaseToSuit(JSON.parse(JSON.stringify(this.testSuits)));
      const testRuns = await this.getTestPlanCases(item.id).toPromise();
      const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
        width: '650px',
        data: { testSuits: testSuits, selectedTestCases: testRuns, title: 'SelectFromSuit' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.testPlanService.updateTestCasesList({
            test_plan_id: item.id,
            test_cases: result.filter(c => { return c.level === 1; }).map(c => { return { tc_id: c.id, ts_id: c.tsId }; })
          }).subscribe(() => { location.reload() })
        }
      });
    } else {
      this.dialog.open(DialogCreateTestSuitComponent, {
        width: '650px',
        data: { testSuits: this.testSuits }
      });
    }
  }

  onReRunEvent(item, type: string) {
    if (type.toLowerCase() === this.appTypes.testRun.toLowerCase()) {
      this.testRunService.reRun({ tr_id: item.id }).subscribe(run => {
        this.getTestRuns();
        this.onClose(this.appTypes.testCase)
      });
    }
  }

  async onChangeStatusEvent(item) {
    this.projects = await this.projectService.getProjects().toPromise()
    const id = !isNaN(+this.currentProject) ? this.currentProject : this.projects[0].id
    const statuses = await this.testCaseService.getTestCaseStatuses({ project_id: id }).toPromise()
    this.statuses = statuses;
    this.dialog.open(DialogSelectComponent, {
      width: '650px',
      data: {
        title: 'Change Status',
        item: item,
        lists: statuses,
        value: 'status',
        showName: 'name'
      }
    }).afterClosed().subscribe(async (res: TestCase) => {
      if (res) {
        const status = statuses.find(val => val.id === res.status && +val.statusState === -1)
        if (status) {
          this.openOnTestCaseFailDialog(res)
          return
        }
        await this.updateTestCaseStatus(res.relationId, res.status, res.projectId).toPromise()
        this.getTestRuns();
      }
    })
  }

  async onAssignedToEvent(item) {
    this.users = await this.userService.getUsers().toPromise()
    this.dialog.open(DialogSelectComponent, {
      width: '650px',
      data: {
        title: 'Change Status',
        item: item,
        lists: this.users,
        value: 'assignedTo',
        showName: 'firstName'
      }
    }).afterClosed().subscribe((res: TestCase) => {
      if (res) {
        this.testRunService.asssignNewUserToTestCase({ relation_id: res.relationId, new_user_id: res.assignedTo }).subscribe(result => {
          if (result) {
            this.snackBar.open(`Test Case '${res.title}' assingee was updated`, 'OK', { duration: 3000 });
          } else {
            this.snackBar.open('Error happened while updating the assingee', 'OK', { duration: 5000 });
          }
        })
      }
    })
  }

  onCreateTestRunEvent(item: TestCase) {
    if (this.selectedPlan === this.appTypes.testSuit) {
      this.dialog.open(DialogRunComponent, {
        width: '650px',
        data: { type: 'suit', testSuit: this.selectedItem }
      }).afterClosed().subscribe(res => {
        if (res) {
          this.getTestRuns()
        }
      });
    } else if (this.selectedPlan === this.appTypes.testPlan) {
      this.dialog.open(DialogRunComponent, {
        width: '650px',
        data: { type: 'plan', testPlan: this.selectedItem }
      }).afterClosed().subscribe(res => {
        if (res) {
          this.getTestRuns();
        }
      });
    }
  }

  setCaseToSuit(testSuits: TestSuit[]): Promise<TestSuit[]> {
    return Promise.all(testSuits.map((s => {
      return new Promise<TestSuit>((resolve) => {
        this.testCaseService.getTestCasesByTestSuit({ ts_id: s.id }).subscribe(cases => {
          cases.map(c => c.tsId = s.id);
          s.testCases = cases;
          resolve(s);
        });
      });
    })))
  }

  openOnTestCaseFailDialog(testCase: TestCase) {
    this.testStepService.getTestStepsByRelationId({ relation_id: testCase.relationId }).subscribe(res => {
      if (res.length === 0) {
        this.snackBar.open('You cannot fail Test Case with no Test Steps', 'OK', { duration: 3000 });
        return
      }
      const steps = this.mapTestCaseSteps(res);
      this.dialog.open(DialogTestCaseFailComponent, {
        width: '650px',
        data: {
          testSteps: steps
        }
      }).afterClosed().subscribe(dataFromDialog => {
        if (dataFromDialog !== null) {
          this.failTestCase(dataFromDialog, testCase.relationId, testCase.projectId);
        }
      })
    })
  }

  failTestCase(dataFromDialog, relationId, currentProjectId): void {
    this.testRunService.updateTestRunTestCaseStatus({
      relation_id: relationId,
      status: this.statuses.find(val => +val.statusState === -1 && +val.isDefault === 1).id,
      failed_on_step: dataFromDialog.failedOnTestStep,
      project_id: currentProjectId,
      create_defect: dataFromDialog.createDefect,
      comment: dataFromDialog.comment
    }).subscribe((result) => {
      if (result.valid === true) {
        const snakBar = this.snackBar.open('Test Case status was successfully updated', 'OK', { duration: 3000 });
        snakBar.afterDismissed().subscribe((closed) => {
          this.failTestStep(dataFromDialog, relationId);
        })
      }
      else
        this.snackBar.open(result.error_msg, 'OK', { duration: 3000 });
    });
  }


  failTestStep(dataFromDialog, relationId): void {
    this.testStepService.updateTestStepStatus({
      relation_id: relationId,
      step_id: dataFromDialog.failedOnTestStep,
      status: this.defaultStatuses.defaultNegativeStatus.id
    }).subscribe((result) => {
      if (result) {
        this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
      }
      else
        this.snackBar.open('Error happened while updating the status of Test Step', 'OK', { duration: 3000 });
    })
  }

  mapTestCaseSteps(steps) {

    steps.map(step => {
      step.showUndo = false;
      if (step.status == null) {
        step.status = this.statuses.find(val => +val.statusState === 0 && +val.isDefault === 1).id;
      }
    })

    steps.map(step => {
      this.statuses.map(status => {
        if (status.id === step.status) {
          step.statusObject = status;
          switch (step.statusObject.statusState) {
            case '-1':
              step.statusText = 'Test step failed';
              break;
            case '1':
              step.statusText = 'Test step passed';
              break;
          }
        }
      })
    })
    return steps;
  }
}
