<div class="dialog-title">
  <h1 mat-dialog-title>Create New Project</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <div *ngIf="!nextStep">
    <form [formGroup]="craeteProjectForm" >
      <mat-form-field appearance="outline" floatLabel="always" class="dialog-content__field">
        <mat-label>Title</mat-label>
        <label>
          <input matInput formControlName="title">
        </label>
        <mat-error *ngIf="f.title.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Description</mat-label>
        <label>
          <textarea  matInput formControlName="description"></textarea>
        </label>
        <mat-error *ngIf="f.description.hasError('required')">
          Description is required
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button [disabled]="!craeteProjectForm.valid" color="primary" style="background: #2d64ba" (click)="createProject()">Create</button>
</div>
