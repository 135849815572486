import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DataService } from './services/data.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  private subscriber: SubscriptionLike

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private dataService: DataService, private router: Router) {
    iconRegistry.addSvgIcon(
      'cell-divide-in-left-area',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/1.svg'));
    iconRegistry.addSvgIcon(
      'cell-divide-in-center-area',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/2.svg'));
    iconRegistry.addSvgIcon(
      'cell-divide-in-right-area',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/3.svg'));
  }
  ngOnDestroy(): void {
    this.subscriber ? this.subscriber.unsubscribe() : ''
  }

  ngOnInit(): void {
    this.subscriber = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(res => {
      if (this.dataService.getCreationMode && this.router.url !== '/test-cases-page/main') {
        this.dataService.changeCreationMode(false)
      }
    })
  }

}