import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, map, refCount, publishReplay } from 'rxjs/operators';
import { TestSuitService } from '../services/test-suit.service';
import { TestSuit } from '../services/test-suit.type';
import { DialogTestCasesSelectComponent } from '../dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { TestStep } from '../services/test-step.type';
import { TestCase } from '../services/test-case.type';
import { TestCaseService } from '../services/test-case.service';
import { TestStepService } from '../services/test-step.service';
import { DialogRunComponent } from '../dialogs/dialog-run/dialog-run.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { compileNgModule } from '@angular/compiler';
import { AbstractTableComponent } from '../abstract-table/abstract-table.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-suit-details',
  templateUrl: './test-suit-details.component.html',
  styleUrls: ['./test-suit-details.component.scss'],
  animations: [
    trigger('changeSideComponentSize', [
      state('hide', style({ width: '0%' })),
      state('start', style({ width: '0%' })),
      state('small', style({ width: '45%' })),
      state('medium', style({ width: '50%' })),
      state('large', style({ width: '55%' })),
      transition('hide => small', animate('300ms ease-out')),
      transition('start => small', animate('300ms  ease-out')),
      transition('small => medium', animate('300ms ease-out')),
      transition('small => large', animate('300ms ease-out')),
      transition('medium => large', animate('300ms ease-out')),
      transition('large => medium', animate('300ms ease-out')),
      transition('medium => small', animate('300ms ease-out')),
      transition('large => hide', animate('300ms ease-out')),
      transition('large => small', animate('300ms ease-out')),
      transition('medium => hide', animate('300ms ease-out')),
      transition('small => hide', animate('300ms  ease-out'))
    ]),
  ]
})
export class TestSuitDetailsComponent implements OnInit, OnDestroy {

  public data: TestCase[];
  public displayedColumns = ['id', 'title', 'createdDate', 'modifiedDate'];

  testSuit: TestSuit = new TestSuit();
  testCase: TestCase = new TestCase();
  testSteps: TestStep[] = [];
  testSuits: TestSuit[] = [];
  editMode: boolean;
  tesSuitRelationId: string;
  componentShowHide = 'start';

  editTestSuitForm: UntypedFormGroup;

  @ViewChild('abstractTable') abstractTable: AbstractTableComponent

  constructor(
    private route: ActivatedRoute,
    private testSuitService: TestSuitService,
    public dialog: MatDialog,
    private router: Router,
    private testCaseService: TestCaseService,
    private testStepService: TestStepService,
  ) { }

  ngOnInit() {
    // Setting init data if sidebar was opened
    const initData = Core.localStorageService.getItem('testCases');
    if (initData) {
      const testCases = JSON.parse(initData);
      this.data = testCases
      this.testSuit = JSON.parse(Core.localStorageService.getItem('testSuit'));
    }

    const tuple = this.route.paramMap.pipe(
      map(params => {
        this.tesSuitRelationId = params.get('testCaseId')
        return this.testSuitService.getTestSuit({ id: params.get('id') });
      }), publishReplay(1), refCount()
    );

    const testSuit$ = tuple.pipe(switchMap(t => t));
    testSuit$.subscribe(async suit => {
      this.testSuit = suit;
      this.initForm()
      // Fetching Test Cases of this Suit
      await this.getCurrentSuitCases();
      // Open Side Bar in case test case was selected
      if (this.tesSuitRelationId) this.openSideComponent();
    });
  }

  initForm() {
    this.editTestSuitForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.testSuit.title, [Validators.required]),
      description: new UntypedFormControl(this.testSuit.description, [Validators.required]),
    })
  }

  get f() {
    return this.editTestSuitForm.controls
  }

  getAllTestSuitsTestCases() {
    return new Promise<TestSuit[]>(resolve => {
      this.testSuitService.getTestSuits({}).subscribe(async suits => {
        this.testSuits = await this.getSuitCases(suits);
        resolve(suits)
      });
    })
  }
  async openSideComponent() {
    // Getting current project id from current test suit
    const projectId = this.testSuit.projectId
    let testCaseId = '';
    // Fetching current testCase using test case id and project id
    this.testSuit.testCases.map((testCase: TestCase) => {
      if (testCase.tesSuitRelationId === this.tesSuitRelationId) {
        testCaseId = testCase.id;
      }
    })
    this.testCaseService.getTestCase({ id: testCaseId, project_id: projectId })
      .subscribe((testCase: TestCase) => {
        this.testCase = testCase;

        this.componentShowHide = 'small';

        // Setting Active Row
        this.setActiveRow(this.tesSuitRelationId);

        this.testStepService.getTestSteps(this.testCase.id).subscribe(result => {
          this.testSteps = result;
        });
      })
  }

  getCurrentSuitCases() {
    return new Promise<TestCase[]>(resolve => {
      this.testCaseService.getTestCasesByTestSuit({ ts_id: this.testSuit.id }).subscribe(cases => {
        cases.map(c => c.testSuitId = this.testSuit.id);
        this.testSuit.testCases = cases;
        this.abstractTable.data = cases
        resolve(cases);
      })
    })
  }

  getSuitCases(testSuits: TestSuit[]): Promise<TestSuit[]> {
    return Promise.all(testSuits.map((s => {
      return new Promise<TestSuit>((resolve) => {
        this.testCaseService.getTestCasesByTestSuit({ ts_id: s.id }).subscribe(cases => {
          cases.map(c => c.testSuitId = s.id);
          s.testCases = cases;
          resolve(s);
        });
      });
    })))
  }

  onResize(size: string): void {
    this.componentShowHide = size;
  }

  saveTestSuit(): void {
    this.editMode = false;

    this.testSuit.title = this.editTestSuitForm.value.title;
    this.testSuit.description = this.editTestSuitForm.value.description;

    this.testSuitService.saveTestSuit({ testSuit: this.testSuit }).subscribe(() => { });
  }

  async openSelectCasesDialog() {
    this.testSuits = await this.getAllTestSuitsTestCases();
    const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
      width: '650px',
      data: { testSuits: this.testSuits, selectedTestCases: this.testSuit.testCases }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.testSuitService.updateTestCasesList({
          test_suit_id: this.testSuit.id,
          test_cases: result.filter(c => {
            return c.level === 1;
          }).map(c => c.id).filter((v, i, s) => s.indexOf(v) === i)
        }).subscribe(() => { location.reload() })
      }
    });
  }

  deleteSuit(): void {
    this.testSuitService.deleteTestSuit({ id: this.testSuit.id }).subscribe(() => {
      this.router.navigate(['test-suits']).then(() => { });
    })
  }

  openTestCaseDetail(testCase: TestCase): void {
    this.router.navigate([`test-suit/${this.testSuit.id}/${testCase.tesSuitRelationId}`]);

    // Saving tesCases in local strorage to deisplay on init
    Core.localStorageService.setItem('testCases', JSON.stringify(this.abstractTable.dataSource.data.slice(0, 15)));
    Core.localStorageService.setItem('testSuit', JSON.stringify(this.testSuit));

    // Setting Active Row
    this.setActiveRow(testCase.tesSuitRelationId);
  }

  openRunDialog(): void {
    this.dialog.open(DialogRunComponent, {
      width: '650px',
      data: { type: 'suit', testSuit: this.testSuit }
    });
  }

  goToTestSuits() {
    this.router.navigate(['test-suits']).then(() => { });
  }

  setActiveRow(tesSuitRelationId: string) {
    this.abstractTable.dataSource.data.map((testCase: TestCase) => {
      testCase.active = (testCase.tesSuitRelationId === tesSuitRelationId) ? true : false;
    })
  }

  doneAnimation() {
    // Once sideBar was closed, we redirect the user onto test-cases URL
    if (this.router.url.includes('suit') && this.componentShowHide === 'hide') {
      this.router.navigate([`test-suit/${this.testSuit.id}`]);
    }
  }

  ngOnDestroy() {
    if (!this.router.url.includes('suit')) {
      Core.localStorageService.removeItem('testCases');
      Core.localStorageService.removeItem('testSuit');
    }
  }
}
