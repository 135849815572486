const listIntegration = 'jira/listIntegration', createIntegration = 'jira/createIntegration', updateIntegration = 'jira/updateIntegration', deleteIntegration = 'jira/removeIntegration';
export class JiraControllers {
    constructor(api) {
        this.api = api;
    }

    //get all settings
    list(payload) {
        return this.api.postRequest(listIntegration, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }

    //create settings
    create(payload) {
        return this.api.postRequest(createIntegration, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    
    //update settings
    update(payload) {
        return this.api.postRequest(updateIntegration, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }

    //delete settings
    delete(payload) {
        return this.api.postRequest(deleteIntegration, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }
}
