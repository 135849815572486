import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ProjectService } from '../../../services/project.service';
import { map, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})

export class ActivityTableComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    private userServices: UserService,
    private router: Router,
  ) {
  }

  public data = [];
  public tableData = new MatTableDataSource<any>();

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;
  @Input() public currentProject;

  public users = [];
  public activityType = ['all'];
  public selected = 'option2';
  public selectedActivity = '';
  public selectedUser = '';
  public isLoading = true;

  ngOnInit() {
    this.getData();
    this.tableData.paginator = this.paginator;
  }

  displayedColumns: string[] = ['project', 'by', 'type', 'in', 'symbol'];
  paginationSize = [5, 10, 20,];

  async getData() {
    if (!this.currentProject || this.currentProject === 'null') {
      this.currentProject = 'all';
    }
    this.userServices.getUserActivity({ id: this.currentProject }).subscribe(v => {
      this.tableData.data = v;
      this.data = v;

      v.forEach(item => {
        if (!this.activityType.includes(item.action_type)) {
          this.activityType.push(item.action_type);
        }
      });
    });

    if (this.currentProject === 'all' || this.currentProject === 'null') {
      let allProjects = [];
      this.projectService.getProjects().pipe(
        map(array => {
          return array.map(item => item.id
          );
        }),
        switchMap((v) => {
          allProjects = v;
          return this.userServices.getUserByProject(allProjects);
        })
      ).subscribe((v) => {
        this.users = v;
        this.isLoading = false;
      });
    } else {
      this.userServices.getUserByProject(this.currentProject).subscribe(v => {
        this.users = v;
        this.isLoading = false;
      });
    }
  }

  getDataByUser(event) {
    const dataByUserName = [];

    this.data.forEach(item => {
      if (item.name === event.value || event.value === 'all') {
        dataByUserName.push(item);
      }
    });

    this.tableData.data = dataByUserName;
  }

  getDataByActivity(event) {
    let dataByActivity = [];

    if (!this.selectedActivity) {
      this.tableData.data = this.data;
      return;
    }

    if (this.selectedUser) {
      if (this.selectedActivity === 'all') {
        this.data.forEach(item => {
          if (item.name === this.selectedUser) {
            dataByActivity.push(item);
          }
        });
      }

      this.data.forEach(item => {
        if (item.name === this.selectedUser && item.action_type === this.selectedActivity) {
          dataByActivity.push(item);
        }
      });
    } else {
      if (this.selectedActivity === 'all') {
        dataByActivity = this.data;
      }
      this.data.forEach(item => {
        if (item.action_type === this.selectedActivity) {
          dataByActivity.push(item);
        }
      });
    }

    this.tableData.data = dataByActivity;
  }

  navigateTo(ref) {
    if (ref) {
      this.router.navigateByUrl(ref);
    }
  }

  updateData(projectId) {
    this.currentProject = projectId;
    this.getData();
  }

  getRange(value: number): number[] {
    return Array(value).fill(0).map((x, i) => i + 1);
  }

}
