import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CardManageStatusesComponent} from "../card-manage-statuses/card-manage-statuses.component";
import {ProjectService} from "../../services/project.service";

@Component({
  selector: 'app-card-manage-page',
  templateUrl: './card-manage-page.component.html',
  styleUrls: ['./card-manage-page.component.scss']
})
export class CardManagePageComponent implements AfterViewInit {
  @ViewChild(CardManageStatusesComponent) cardManageStatusesComponent: CardManageStatusesComponent;

  constructor(
      private projectService: ProjectService,
  ) {
  }

  ngAfterViewInit(): void {
    if (this.cardManageStatusesComponent) this.projectService.setSettingActiveComponent(this.cardManageStatusesComponent, 'CardManageStatusesComponent');
  }

}
