import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TestPlanService } from '../services/test-plan.service';
import { TestPlan } from '../services/test-plan.type';
import { DialogCreateTestPlanComponent } from '../dialogs/dialog-create-test-plan/dialog-create-test-plan.component';
import { MatDialog } from '@angular/material/dialog';
import { TestSuitService } from '../services/test-suit.service';
import { TestSuit } from '../services/test-suit.type';
import { TestCaseService } from '../services/test-case.service';
import { Core } from '../services/core.service';


@Component({
  selector: 'app-test-plans',
  templateUrl: './test-plans.component.html',
  styleUrls: ['./test-plans.component.scss']
})
export class TestPlansComponent implements OnInit {

  public data: TestPlan[];
  public filterValue: string = '';
  public displayedColumns: string[];

  TestPlans: TestPlan[];
  projects = JSON.parse(Core.localStorageService.getItem('projects'));
  testSuits: TestSuit[];
  editMode: boolean;

  constructor(
    private testPlanService: TestPlanService,
    private router: Router,
    public dialog: MatDialog,
    private testSuitService: TestSuitService,
    private testCaseService: TestCaseService,
  ) { }


  ngOnInit() {

    if (Core.localStorageService.getItem('selected_project') === 'all') {
      this.displayedColumns = ['id', 'title', 'description', 'projectName', 'createdDate', 'modifiedDate'];
      this.testSuitService.getTestSuits({}).subscribe(testSuits => {
        this.testSuits = testSuits;
      });
      // If all projects chosen, we pass no parameters to receive all available test plans
      this.testPlanService.getTestPlans({}).subscribe(testPlans => {
        this.TestPlans = testPlans;
        this.getProjectName();
        this.data = testPlans
      })
    } else {
      this.displayedColumns = ['id', 'title', 'description', 'createdDate', 'modifiedDate'];
      this.testSuitService.getTestSuits({}).subscribe(testSuits => {
        this.testSuits = testSuits;
      });
      // If particular project chosen, we pass it's id as parameter of request to receive test plans in this project
      this.testPlanService.getTestPlans({ project_id: Core.localStorageService.getItem('selected_project') }).subscribe(testPlans => {
        this.TestPlans = testPlans;
        this.getProjectName();
        this.data = testPlans
      })
    }
  }

  openCreateDialog(): void {
    this.dialog.open(DialogCreateTestPlanComponent, {
      width: '650px',
      data: { testSuits: this.testSuits }
    });
  }


  navigateToDetail(row: TestPlan) {
    this.router.navigate(['test-plan', row.id]).then(() => { });
  }

  getProjectName() {
    this.TestPlans.map(plan => {
      this.projects.map(project => {
        if (project.id === plan.projectId) {
          plan.projectName = project.title;
        }
      })
    })
  }

  onCreationMode(): void {
    this.router.navigate(['test-cases']).then(() => { });
  }

}
