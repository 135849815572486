<ng-container *ngIf="showPage">
  <div [class]="(title !== 'Test Case' && title !== 'Test Cases') ? 'card' : 'card card--height'">
    <div [class]="(collapseTitle || shortForm) ? 'card__header header-card cursor' : 'card__header header-card'"
      (click)="shortForm ? onCollapseEvent() : onCollapseTitleEvent()">
      <div class="header-card__body">
        <div [ngClass]="!shortForm ? 'header-card__icon' : 'header-card__icon vertical'">
          <mat-icon>{{icon}}</mat-icon>
        </div>
        <div class="header-card__title" *ngIf="!shortForm">
          {{title}}
        </div>
        <button mat-icon-button *ngIf="collapseTitle && !shortForm" class="collapse-title"
          (click)="onCollapseTitleEvent()" stopPropagation>
          <mat-icon *ngIf="showSection">expand_more</mat-icon>
          <mat-icon *ngIf="!showSection">expand_less</mat-icon>
        </button>
        <div class="header-card__close" *ngIf="!shortForm">
          <ng-container *ngIf="showCollapse">
            <button mat-icon-button (click)="onCollapseEvent()" stopPropagation>
              <mat-icon>chevron_left</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="showClose">
            <button mat-icon-button (click)="onCloseEvent()" stopPropagation>
              <mat-icon>close</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!shortForm && showSection">

      <div
        [class]="(title !== 'Test Case' && title !== 'Test Cases') ? 'card__body body-card' : 'card__body body-card body-card--height'">
        <ng-container *ngIf="showGap">
          <div
            [class]="!showPadding ? 'body-card__add-new-item new-item-card' : 'body-card__add-new-item body-card__add-new-item--padding new-item-card'">
            <div class="new-item-card__row">

              <div class="new-item-card--left">

                <ng-container *ngIf="showSelect">
                  <div [class]="isRightSectionOpen ? 'short-select-item select-item' : 'select-item'">
                    <mat-form-field appearance="outline">
                      <mat-label>{{selectText}}</mat-label>
                      <mat-select [(ngModel)]="baseSelectedValue" name="item" #archiveSelect
                        (selectionChange)="onSelectArchiveType($event.value)">
                        <mat-option *ngFor="let item of selectList" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </ng-container>

                <ng-container *ngIf="showSort">
                  <div [class]="isRightSectionOpen ? 'short-select-item select-item' : 'select-item'">
                    <mat-form-field appearance="outline">
                      <mat-label>{{sortText}}</mat-label>
                      <mat-select [(ngModel)]="baseSortValue" class="select-item-mat-select" name="item" #sortSelect
                        (selectionChange)="onSelectSortType($event)">
                        <mat-option *ngFor="let item of sortSelectData" [value]="item">{{item}}</mat-option>
                        <mat-option *ngFor="let item of sortList" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </ng-container>
                <ng-container *ngIf="showAdd">
                  <div class="new-item-card__wrap flex" (click)="!pending ? onAddEvent() : null">
                    <mat-icon class="new-item-card__icon">add</mat-icon>
                    <div [class]="isRightSectionOpen ? 'hidden-item new-item-card__text' : 'new-item-card__text' ">Add
                      New {{title}}</div>
                    <div *ngIf="pending" class="loading">
                      <img src="../../../assets/image/loading.gif" alt="pending">
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="showEdit">
                  <div class="new-item-card__wrap" (click)="onEditEvent()">
                    <mat-icon class="new-item-card__icon">{{editButtonText | lowercase}}</mat-icon>
                    <div class="new-item-card__text">{{editButtonText || 'Edit'}}</div>
                  </div>
                </ng-container>

              </div>

              <ng-container *ngIf="showSearch">
                <mat-form-field class="search" appearance="outline" floatLabel="auto">
                  <mat-label>Search</mat-label>
                  <label>
                    <input #search matInput class="changeCaret" (input)="searchElement(search.value)"
                      (focus)="closeEvent.emit()">
                  </label>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="showUpload">
                <div class="upload-card__wrap">
                  <input hidden (change)="onImportEvent($event)" #fileInput type="file" id="file">
                  <button [disabled]="guestAccessAction$ | async" class="import-button" type="button" mat-stroked-button
                    (click)="fileInput.click()">
                    <mat-icon class="new-item-card__icon">note_add</mat-icon>
                    Choose File
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <div
          [class]="(title !== 'Test Case' && title !== 'Test Cases') ? 'body-card__list list-card' : 'body-card__list list-card list-card--height'">
          <ng-content></ng-content>
        </div>
      </div>
      <ng-content select=".card__footer"></ng-content>
    </ng-container>

    <ng-container *ngIf="shortForm">
      <div [ngClass]="(title !== 'Test Case' && title !== 'Test Cases') ? 'block' : 'block block--dark'"
        (click)="onCollapseEvent()">
        <ng-container *ngIf="showCollapse">
          <button class="block-btn" mat-icon-button>
            <mat-icon *ngIf="shortForm">chevron_right</mat-icon>
          </button>
        </ng-container>
        <div class="block-title">{{title}}</div>
      </div>
    </ng-container>
  </div>
</ng-container>