import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { TestStep } from '../../services/test-step.type';
import { TestCase, TodoItemFlatNode } from '../../services/test-case.type';
import { TestSuit } from '../../services/test-suit.type';
import { TestSuitService } from '../../services/test-suit.service';
import { TestCaseService } from '../../services/test-case.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Core } from 'src/app/services/core.service';
import { ProjectService } from 'src/app/services/project.service';
import { FlatTreeControl } from '@angular/cdk/tree';

export interface DialogData {
  testSuitId: number;
  suitLevel: number;
  title?: string;
  info?: string;
  additional: FlatTreeControl<TodoItemFlatNode>;
}

@Component({
  selector: 'app-dialog-create-test-case',
  templateUrl: 'dialog-create-test-case.component.html',
  styleUrls: ['./dialog-create-test-case.component.scss']
})
export class DialogCreateTestCaseComponent implements OnInit {
  projects: Project[];
  currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
  defaultTitle: string = 'Test Case'

  testSteps: TestStep[] = [new TestStep()];
  testCase: TestCase = new TestCase();
  testSuits: TestSuit[] = [];

  craeteTestCaseForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateTestCaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private testSuitService: TestSuitService,
    private testCaseService: TestCaseService,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.projectService.getProjects().subscribe(response => {
      this.projects = response;

      let param: {};
      if (this.currentProject === 'null' || this.currentProject === 'all') param = {}
      else param = { project_id: this.currentProject }
      this.testSuitService.getTestSuits(param).subscribe(res => {
        this.testSuits = res;
      });
    });
  }

  initForm() {
    if (!this.data?.info) {
      this.craeteTestCaseForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        testSuitId: new FormControl(this.data?.testSuitId ? this.data?.testSuitId : '', [Validators.required]),
        testStep: new FormArray([this.createItem()])
      })
    } else if (this.data?.info === 'unesigned') {
      let projectId = this.projectService.getSelectedProjectFromStorage();
      this.craeteTestCaseForm = new FormGroup({
        project: new FormControl((projectId === 'all' || projectId === 'null') ? null : projectId, [Validators.required]),
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        testStep: new FormArray([])
      })
    }
  }

  createItem(): FormGroup {
    return new FormGroup({
      description: new FormControl('', [Validators.required]),
      expectedResult: new FormControl('', [Validators.required])
    });
  }

  get f() {
    return this.craeteTestCaseForm.controls
  }

  get testStepsForm(): FormArray {
    return this.craeteTestCaseForm.get('testStep') as FormArray;
  }

  createStep() {
    this.testStepsForm.push(this.createItem())
  }

  deleteStep(testStep) {
    this.testStepsForm.removeAt(testStep)
  }

  createCase() {
    this.testCase.title = this.craeteTestCaseForm.value.title;
    this.testCase.description = this.craeteTestCaseForm.value.description;
    this.testCase.testSuitId = this.craeteTestCaseForm.value.testSuitId;
    this.testCase.tsId = this.craeteTestCaseForm.value.testSuitId;

    this.testSteps = this.testStepsForm.value;
    const currentProject = this.data?.info ? this.craeteTestCaseForm.value.project : null;
    let suitLevel = null;
    if (this.data.suitLevel) suitLevel = this.data.suitLevel;
    else suitLevel = this.data.additional?.dataNodes?.find(node => +node.id === +this.craeteTestCaseForm.value.testSuitId)?.level;

    this.testCaseService.createTestCase(this.testCase, this.testSteps, currentProject, suitLevel).subscribe(res => {
      this.dialogRef.close(res)
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
