import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogSelectComponent } from 'src/app/dialogs/dialog-select/dialog-select.component';
import { DialogTestCaseFailComponent } from 'src/app/dialogs/dialog-test-case-fail/dialog-test-case-fail.component';
import { ProjectService } from 'src/app/services/project.service';
import { Status } from 'src/app/services/status.type';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestRun } from 'src/app/services/test-run.type';
import { TestStepService } from 'src/app/services/test-step.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/services/user.type';
import { Core } from 'src/app/services/core.service';
import { Params } from '@angular/router';
import { Observable, SubscriptionLike, forkJoin, of } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { TestSuitFolders } from 'src/app/services/test-suit.type';
import { _isClickEvent } from 'chart.js/dist/helpers/helpers.core';
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component';
import { map, tap } from 'rxjs/operators';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { DialogTestCasesSelectComponent } from 'src/app/dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestPlanService } from 'src/app/services/test-plan.service';
import { BaseCardComponent } from 'src/app/app-shared-component/base-card/base-card.component';
import { UserAccessService } from 'src/app/services/user-access';

@Component({
  selector: 'app-card-test-run-case',
  templateUrl: './card-test-run-case.component.html',
  styleUrls: ['./card-test-run-case.component.scss'],
})
export class CardTestRunCaseComponent implements OnInit, OnDestroy {
  public guestAccessAction: boolean = this._userAccessService.getLevelAccessUser === 4;
  public addAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunAddLevel');
  public assignedAccessAction: boolean = this._userAccessService.getAccess('testCase', 'testCaseAssignToLevel');
  public changeStatusAccessAction: boolean = this._userAccessService.getAccess('testCase', 'testCaseChangeStatusLevel');
  public deleteCaseAccessAction: boolean = this._userAccessService.getAccess('testCase', 'testCaseDeleteLevel');

  @ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;
  @ViewChild(BaseCardComponent) private baseCard: BaseCardComponent;

  public testRunCases: TestCase[] = [];
  public _testRunsCase: TestCase[];
  private testRunId: string;
  public activeExistTestCaseSuits: TestCase[];
  public activeExistTestCasePlans: TestCase[];

  public isLoading = true;
  public pending: boolean = false;
  public deletedItem: TestCase;

  private allStatuses: Status[];
  private statuses: Status[];
  public statusItem: Status;
  public assigneTo: User;
  private users: User[];
  public userName: string;

  public selectedValue: TestCase;
  private selectionListSubscriber: SubscriptionLike;

  @Input() showStep: boolean = false;
  @Input() shortForm: boolean = false;
  @Input() shortFormRun: boolean = false;
  @Input() queryParams: Params;

  @Input() set testRun(data: TestRun) {
    if (data) {
      this.getTestRunCase(data.id)
    }
  };

  @Output() updateTestRuns = new EventEmitter<boolean>();
  @Output() updateTestRunSteps = new EventEmitter<TestCase>();
  @Output() showTestStep = new EventEmitter<TestCase>();
  @Output() closeEvent = new EventEmitter();
  @Output() closeStepEvent = new EventEmitter();
  @Output() hideCollapseRun = new EventEmitter();
  @Output() collapseEvent = new EventEmitter<string>();


  constructor(
    private testCaseService: TestCaseService,
    private projectService: ProjectService,
    private testStepService: TestStepService,
    private testRunService: TestRunService,
    private testSuitService: TestSuitService,
    private testPlanService: TestPlanService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private _userAccessService: UserAccessService
  ) { }

  ngOnInit(): void {
    this.setSelectionList();
    this.testCaseService.updateRightSectionOpen(true);
  }

  ngOnDestroy(): void {
    this.hideCollapseRun.emit();
    this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : '';
    this.testCaseService.updateRightSectionOpen(false);
  }

  searchPath(param: Params) {
    let queryParamArr = window.location.href.split('?')[1]?.split('&');
    if (queryParamArr) param = queryParamArr.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});
    let find = this.testRunCases.find(elem => +elem.testCaseId === +param.tc);

    if (find) {
      this.selectedValue = find;
      this.selectionList.options.changes.subscribe(res => {
        res.forEach(elem => { if (+elem.value.testCaseId === +param.tc) { elem.selected = true; } });
      });

      this.showTestStep.emit(find);
    }
  }

  setSelectionList() {
    this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true });
    });

    this.userService.getUsers().subscribe(res => {
      this.users = res;
    });
    this.testCaseService.getTestCaseStatuses({ project_id: this.projectService.getSelectedProjectFromStorage() }).subscribe(res => {
      this.allStatuses = res;
    });
  }

  getTestRunCase(testRunId: string, flag = true) {
    this.testRunId = testRunId;
    this.isLoading = true;
    this.testRunCases = [];
    this.testCaseService.getTestCasesByTestRun({ tr_id: testRunId }).subscribe(res => {
      this.testRunCases = res;
      this.isLoading = false;
      this.getUserName(res);

      if (flag && this.queryParams?.tc) this.searchPath(this.queryParams);
    })
  }

  getUserName(testRun) {
    this.userService.getUsers().subscribe(users => {
      this.userName = users?.find(item => item.id === testRun[0]?.assignedTo)?.firstName;
    })
  }

  mapSelectedValue(testCase: TestCase[]): TestCase[] {
    if (this.selectedValue) {
      return testCase.map(val => { val.relationId === this.selectedValue.relationId ? val.isOpen = true : val.isOpen = false; return val })
    } else {
      return testCase
    }
  }

  selectionChange(event: MatSelectionListChange) {
    event.options.forEach(item => {
      this.showTestStep.emit(item.value);
      this.selectedValue = item.value;
    })
  }

  selectedFromDetails(testCase: TestCase) {
    this.showTestStep.emit(testCase);
    this.selectedValue = testCase;
  }

  getStatusItem(status: string) {
    if (this.allStatuses) this.statusItem = this.allStatuses.find(item => +item.id === +status);
    if (this.statusItem) return { name: this.statusItem.name, color: this.statusItem.color }
    return { name: 'Change Status', color: '#FFFFFF' };
  }

  getAssigneTo(number: string) {
    if (this.users) this.assigneTo = this.users.find(item => item.id === number);
    if (this.assigneTo) return this.assigneTo.firstName;
    return 'assignment_ind';
  }

  onDeleteCaseEvent(item: TestCase) {
    this.deletedItem = item;
    this.dialog.open(DialogConfirmComponent, {
      width: '650px',
      data: { dialogTitle: 'delete', name: item.title }
    }).afterClosed().subscribe((res: boolean) => {
      if (res && item && this.testRunId) {
        this.pending = true;
        this.testCaseService.getTestCasesByTestRun({ tr_id: this.testRunId }).subscribe(result => {
          if (result) {
            this.testRunService.updateTestCasesList({
              test_run_id: this.testRunId,
              project_id: item.projectId,
              test_cases: [],
              deleted_test_cases: [{ tc_id: item.testCaseId, ts_id: item.tsId }]
            }).subscribe(res => {
              if (res) {
                this.pending = false;
                this.deletedItem = null;
                this.selectionList.deselectAll();
                this.updateTestRuns.emit(true);
                this.closeStepEvent.emit();
                this.snackBar.open('Test Case was successfully deleted', "OK", { duration: 3000 });
              }
            })
          }
        })
      } else this.deletedItem = null;
    })
  }

  async onAssignedToEvent(item: TestCase) {
    let cashItemAssigned = item.assignedTo;
    let users = await this.userService.getUserByProject(item.projectId).toPromise();
    this.dialog.open(DialogSelectComponent, {
      width: '650px',
      data: {
        title: 'Assigned to',
        item: item,
        lists: users,
        value: 'assignedTo',
        showName: 'firstName'
      }
    }).afterClosed().subscribe((res: TestCase) => {
      if (res) {
        this.testRunService.asssignNewUserToTestCase({ relation_id: res.relationId, new_user_id: res.assignedTo, tr_id: this.testRunId }).subscribe(result => {
          if (result) {
            this.snackBar.open(`Test Case '${res.title}' assingee was updated`, 'OK', { duration: 3000 });
          } else {
            this.snackBar.open('Error happened while updating the assingee', 'OK', { duration: 5000 });
          }
        })
      } else item.assignedTo = cashItemAssigned;
    })
  }

  // CHANGE STATUSES
  async onChangeStatusEvent(item) {
    let cashItemStatus = item.status;
    const projects = await this.projectService.getProjects().toPromise()
    const currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
    const id = !isNaN(+currentProject) ? currentProject : projects[0].id
    const statuses = await this.testCaseService.getTestCaseStatuses({ project_id: id }).toPromise();
    this.statuses = statuses;
    this.dialog.open(DialogSelectComponent, {
      width: '650px',
      data: {
        title: 'Change Status',
        item: item,
        lists: statuses,
        value: 'status',
        showName: 'name'
      }
    }).afterClosed().subscribe(async (res: TestCase) => {
      if (res) {
        const status = statuses.find(val => val.id === res.status && +val.statusState === -1)
        if (status) {
          this.openOnTestCaseFailDialog(res)
          return
        }
        this.updateTestCaseStatus(res.relationId, res.status, res.projectId).toPromise()
          .then(res => {
            if (res) {
              this.updateTestRuns.emit(true);
              this.snackBar.open('Test Case status was successfully updated', 'OK', { duration: 3000 });
            }
          })
      } else item.status = cashItemStatus;
    })
  }

  updateTestCaseStatus(relationId, status, projectId) {
    return this.testRunService.updateTestRunTestCaseStatus({
      relation_id: relationId,
      status: status,
      project_id: projectId,
      tr_id: this.testRunId,
    })
  }

  openOnTestCaseFailDialog(testCase: TestCase) {
    this.testStepService.getTestStepsByRelationId({ relation_id: testCase.relationId }).subscribe(res => {
      if (res.length === 0) {
        this.snackBar.open('You cannot fail Test Case with no Test Steps', 'OK', { duration: 3000 });
        return
      }
      const steps = this.mapTestCaseSteps(res);
      this.dialog.open(DialogTestCaseFailComponent, {
        width: '650px',
        data: {
          testSteps: steps
        }
      }).afterClosed().subscribe(dataFromDialog => {
        if (dataFromDialog !== null) {
          this.failTestCase(dataFromDialog, testCase);
        }
      })
    })
  }

  failTestCase(dataFromDialog, testCase): void {
    this.testRunService.updateTestRunTestCaseStatus({
      relation_id: testCase.relationId,
      status: this.statuses.find(val => +val.statusState === -1 && +val.isDefault === 1).id,
      failed_on_step: dataFromDialog.failedOnTestStep,
      project_id: this.projectService.getSelectedProjectFromStorage(),
      create_defect: dataFromDialog.createDefect,
      comment: dataFromDialog.comment
    }).subscribe((result) => {
      if (result.valid) {
        const snakBar = this.snackBar.open('Test Case status was successfully updated', 'OK', { duration: 3000 });
        this.updateTestRuns.emit(true);
        snakBar.afterDismissed().subscribe((closed) => {
          this.failTestStep(dataFromDialog, testCase.relationId);
        })
      }
      else {
        this.snackBar.open(result.error_msg, 'OK', { duration: 3000 });
      }
    });
  }

  failTestStep(dataFromDialog, relationId): void {
    this.testStepService.updateTestStepStatus({
      relation_id: relationId,
      step_id: dataFromDialog.failedOnTestStep,
      status: this.statuses.find(status => status.statusState === '-1' && status.isDefault === '1').id,
      comment: dataFromDialog.comment,
    }).subscribe((result) => {
      if (result) {
        this.updateTestRunSteps.emit(this.selectedValue);
        this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
      }
      else
        this.snackBar.open('Error happened while updating the status of Test Step', 'OK', { duration: 3000 });
    })
  }

  mapTestCaseSteps(steps) {
    steps.map(step => {
      step.showUndo = false;
      if (step.status == null) {
        step.status = this.statuses.find(val => +val.statusState === 0 && +val.isDefault === 1).id;
      }
    })

    steps.map(step => {
      this.statuses.map(status => {
        if (status.id === step.status) {
          step.statusObject = status;
          switch (step.statusObject.statusState) {
            case '-1':
              step.statusText = 'Test step failed';
              break;
            case '1':
              step.statusText = 'Test step passed';
              break;
          }
        }
      })
    })
    return steps;
  }

  copyLink() {
    if (window.location.href) {
      this.clipboard.copy(window.location.href);
      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
    }
  }

  getTestRunCases(testRunId: string) {
    return this.testCaseService.getTestCasesByTestRun({ tr_id: testRunId }).pipe(
      map((testCases: TestCase[]) => {
        testCases.map(val => {
          val.filteredByPerson = '1';
          val.filteredByStatus = '1';
        })
        this._testRunsCase = testCases;
        return testCases;
      })
    )
  }

  async onAddEvent() {
    this.pending = true;
    let params;
    let testSuits = [];

    const projectId = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
    if (projectId !== 'all' && projectId !== 'null') params = { project_id: projectId };
    const testSuitsArr = await this.testSuitService.foldersList(params).toPromise();
    testSuitsArr?.forEach(elem => testSuits.push([elem]));

    const plans = await this.getAllPlans();
    const testPlans = await this.setCaseToPlans(plans);
    let testCaseRuns = await this.getTestRunCases(this.testRunId).toPromise();

    this.activeExistTestCaseSuits = testCaseRuns.filter(elem => !elem.tpId);
    this.activeExistTestCasePlans = testCaseRuns.filter(elem => elem.tpId);

    if (this.baseCard) this.baseCard.pending = false;
    const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
      width: '850px',
      data: { testSuits: testSuits, testPlans: testPlans, selectedSuits: this.activeExistTestCaseSuits, selectedPlans: this.activeExistTestCasePlans, title: 'SelectFromRun' },
      panelClass: 'custom-modalbox'
    })
    let sub = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._addToRunAction(result, projectId)
        sub?.unsubscribe();
      }
    })
  }

  private _addToRunAction(result, projectId) {
    let plans = [...result.selectedPlans].filter(elem => elem.level === 0).map(elem => elem.id);
    forkJoin([
      this.testRunService.updateTestCasesList({
        test_run_id: this.testRunId,
        project_id: projectId,
        test_cases: result.selectedSuits.filter(c => c.isCase).map(c => ({ tc_id: c.elem.id, ts_id: c.elem.tsId, tp_id: c.elem.tpId })),
        deleted_test_cases: []
      }),
      plans.length ? this.testPlanService.plansToRun({ tr_id: this.testRunId, tp_ids: plans }) : of({ valid: 1, msg: 'true' })
    ]).subscribe(res => {
      if (res[0] === true && res[1].valid === 1) {
        if (res[1].msg === 'true') this.snackBar.open('Test Cases was successfully selected', "OK", { duration: 3000 });
        else this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 });
      }
      this.updateTestRuns.emit(true);
    })
  }

  getAllPlans(): Promise<TestPlan[]> {
    return new Promise<TestPlan[]>((resolve) => {
      this.testPlanService.getTestPlans({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe(cases => {
        resolve(cases);
      });
    });
  }

  setCaseToPlans(testPlans): Promise<TestSuitFolders[]> {
    return Promise.all(testPlans.map((s => {
      return new Promise<any>((resolve) => {
        this.testPlanService.foldersPlansList({ tp_id: s.id }).subscribe((folder: TestSuitFolders) => {
          resolve({ ...s, sub_suits: folder, });
        });
      });
    })))
  }
}
