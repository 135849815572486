import axios from "axios";
export class ApiController {
    constructor(apiUrl, localStorageService) {
        this.localStorageService = localStorageService;
        this.createInstance(apiUrl);
    }
    createInstance(apiUrl) {
        this.instance = axios.create({ baseURL: apiUrl });
        const instance = this.instance;
        const localStorage = this.localStorageService;
        this.instance.interceptors.response.use((response) => {
            return response;
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const refToken = localStorage.getItem('TestyRefreshToken');
                return instance.post('authentication/refresh', {
                    "refreshToken": refToken
                })
                    .then(res => {
                    if (res.status === 200) {
                        if (res.data.valid === true) {
                            // 1) put token to LocalStorage
                            localStorage.setItem('TestyToken', res.data.token);
                            // 2) Change Authorization header
                            instance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
                            originalRequest.headers['Authorization'] = 'Bearer ' + res.data.token;
                            // 3) return originalRequest object with Axios.
                            return axios(originalRequest);
                        }
                        else {
                            // if refresh token not valid, we logout the user
                            localStorage.removeItem('TestyToken');
                            localStorage.removeItem('TestyRefreshToken');
                            localStorage.removeItem('user');
                            location.reload();
                        }
                    }
                });
            }
            // return Error object with Promise
            return Promise.reject(error);
        });
        this.instance.interceptors.request.use(async (config) => {
            const token = await localStorage.getItem('TestyToken');
            if (token && config.url !== 'authentication/refresh' && config.url !== 'authentication/login') {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        }, error => {
            Promise.reject(error);
        });
    }
    postRequest(url, params) {
        return this.instance.post(url, params)
            .then(response => response)
            .catch(error => error);
    }
    setAuthorization(token, refreshToken) {
        this.localStorageService.setItem('TestyToken', token);
        this.localStorageService.setItem('TestyRefreshToken', refreshToken);
    }
    isValid() {
        return this.localStorageService.getItem('TestyToken') !== null && this.localStorageService.getItem('TestyToken') !== 'undefined';
    }
    invalidate() {
        this.localStorageService.clear();
    }
    refreshToken() {
        const refToken = localStorage.getItem('TestyRefreshToken');
        return this.instance.post('authentication/refresh', { refreshToken: refToken }).then(res => {
            if (res.data.valid === 1) {
                localStorage.setItem('TestyToken', res.data.token);
            }
            else {
                this.invalidate();
            }
        });
    }
}
