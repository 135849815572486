import { Component, OnInit, OnDestroy } from '@angular/core';
import { TestRun } from '../services/test-run.type';
import { ActivatedRoute, Router } from '@angular/router';
import { TestRunService } from '../services/test-run.service';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { TestCaseService } from '../services/test-case.service';
import { TestStepService } from '../services/test-step.service';
import { User } from '../services/user.type';
import { TestCase } from '../services/test-case.type';
import { TestStep } from '../services/test-step.type';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TestSuit } from '../services/test-suit.type';
import { Status } from '../services/status.type';
import { AuthenticationService } from '../services/authentication.service';
import { DefaultStatuses } from '../services/default-statuses.type';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-run-defecs-page',
  templateUrl: './test-run-defecs-page.component.html',
  styleUrls: ['./test-run-defecs-page.component.scss'],
  animations: [
    trigger('changeSideComponentSize', [
      state('hide', style({ width: '0%' })),
      state('start', style({ width: '0%' })),
      state('small', style({ width: '45%' })),
      state('medium', style({ width: '50%' })),
      state('large', style({ width: '55%' })),
      transition('hide => small', animate('300ms ease-out')),
      transition('start => small', animate('300ms  ease-out')),
      transition('small => medium', animate('300ms ease-out')),
      transition('small => large', animate('300ms ease-out')),
      transition('medium => large', animate('300ms ease-out')),
      transition('large => medium', animate('300ms ease-out')),
      transition('medium => small', animate('300ms ease-out')),
      transition('large => hide', animate('300ms ease-out')),
      transition('large => small', animate('300ms ease-out')),
      transition('medium => hide', animate('300ms ease-out')),
      transition('small => hide', animate('300ms  ease-out'))
    ]),
  ]
})
export class TestRunDefecsPageComponent implements OnInit, OnDestroy {
  testRun: TestRun = new TestRun();
  testCase: TestCase = new TestCase();
  testCases: TestCase[] = [];
  testSteps: TestStep[] = [];
  testSuits: TestSuit[] = [];
  editMode: boolean;
  saving = false;
  statuses: Status[] = [];
  allStatuses: Status[] = [];
  users: User[] = [];
  relationId: string;
  testRunId: string;
  testCaseId: string;
  currentProjectId: string;
  currentTestCaseId: string;
  selectedTestCases: TestCase[] = [];
  defectsPage = true;
  defaultStatuses = new DefaultStatuses();
  componentShowHide = 'start';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testRunService: TestRunService,
    private userService: UserService,
    private testCasesService: TestCaseService,
    public dialog: MatDialog,
    public auth: AuthenticationService,
    private testStepService: TestStepService,
    private snackBar: MatSnackBar
  ) { }

  displayedColumns: string[] = ['select', 'defectsPageId', 'title', 'status', 'assigned_to', 'BugTrackingURL', 'defectCreated'];

  ngOnInit() {
    // Setting init data if sidebar was opened
    const initData = Core.localStorageService.getItem('testCases');
    if (initData) {
      this.testCases = JSON.parse(initData);
      this.testRun = JSON.parse(Core.localStorageService.getItem('testRun'));
    }

    const tuple = this.route.paramMap.pipe(
      map(params => {
        this.testRunId = params.get('id');
        this.currentTestCaseId = params.get('testCaseId');
        return this.testRunService.getTestRun({
          id: params.get('id'),
          testCaseId: params.get('testCaseId')
        });
      }), publishReplay(1), refCount()
    );


    const testRun$ = tuple.pipe(switchMap(t => t));
    testRun$.subscribe(async (testRun: TestRun) => {
      // Fetching current Test Run
      this.testRun = testRun;
      this.currentProjectId = this.testRun.projectId;

      // Getting Project Statuses
      this.allStatuses = await this.getProjectStatuses();
      this.statuses = this.filterNegativeStatuses(this.allStatuses);

      this.testCases = await this.getFailedTestCases(testRun.id);

      // Getting users
      this.users = await this.getUsers();

      if (this.currentTestCaseId) this.sideComponentInit();
    })
  }

  sideComponentInit() {
    this.testCases.map(async (testCase: TestCase) => {
      if (testCase.id === this.currentTestCaseId) {
        // Setting Test Case to be passed into side component
        this.testCase = testCase;

        // Getting Test Steps of this Test Case to be passed into side component
        this.testSteps = await this.getTestCaseSteps(this.testCase.relationId);
      }
    });
    this.componentShowHide = 'small';

    // Setting Active Row
    this.setActiveRow(this.testCase.id);
  }

  getTestCaseSteps(relationId: string): Promise<TestStep[]> {
    return new Promise<TestStep[]>(resolve => {
      this.testStepService.getTestStepsByRelationId({ relation_id: relationId }).subscribe((steps: TestStep[]) => {
        resolve(steps);
      })
    })
  }

  getProjectStatuses(): Promise<Status[]> {
    return new Promise<Status[]>(resolve => {
      // Getting this project statuses
      this.testCasesService.getTestCaseStatuses({ project_id: this.currentProjectId }).subscribe((statuses: Status[]) => {
        this.mapStatuses(statuses)
        resolve(statuses);
      });
    })
  }

  filterNegativeStatuses(statuses: Status[]): Status[] {
    return statuses.filter((status: Status) => {
      if (status.statusState === '-1') {
        return true;
      } else {
        return false;
      }
    })
  }

  mapStatuses(statuses: Status[]): Status[] {
    return statuses.map(status => {
      if (status.statusState === '0' && status.isDefault === '1') {
        this.defaultStatuses.defaultNeutralStatus = status;
      }
      if (status.statusState === '-1' && status.isDefault === '1') {
        this.defaultStatuses.defaultNegativeStatus = status;
      }
      if (status.statusState === '1' && status.isDefault === '1') {
        this.defaultStatuses.defaultPositiveStatus = status;
      }
      status.quantity = 0;
      return status
    })
  }

  getUsers(): Promise<User[]> {
    return new Promise<User[]>(resolve => {
      this.userService.getUsers().subscribe((users: User[]) => {
        // Pushing pseudo user Unassigned into the array
        const unassigned = new User();
        unassigned.id = '-1';
        unassigned.firstName = 'Unassigned'
        users = [unassigned, ...users];

        resolve(users);
      });
    })
  }

  getFailedTestCases(trId: string): Promise<TestCase[]> {
    return new Promise<TestCase[]>(resolve => {
      this.testRunService.getFailedCasesInTestRun(trId).subscribe((testCases: TestCase[]) => {
        testCases.map(testCase => {
          // For future filtering
          testCase.filteredByPerson = '1';
          testCase.filteredByStatus = '1';

          // Defect already created?
          testCase.defectCreated = testCase.outerBugtrackingLink ? 'Yes' : 'No'
        })
        resolve(testCases);
      })
    })
  }

  onResize(size: string): void {
    this.componentShowHide = size;
  }

  openDefectDetail(testCase: TestCase): void {
    this.router.navigate([`test-run-defects/${this.testRunId}/${testCase.id}`]);
    if (this.componentShowHide === 'start' || 'hide') {
      this.onResize('small');
    }
    // Saving tesCases in local strorage to deisplay on init
    Core.localStorageService.setItem('testCases', JSON.stringify(this.testCases.slice(0, 15)));
    Core.localStorageService.setItem('testRun', JSON.stringify(this.testRun));
    // Setting Active Row
    this.setActiveRow(testCase.id);
  }

  goToTestRuns() {
    this.router.navigate(['test-runs']).then(() => { });
  }

  createDefects(): void {
    this.selectedTestCases.map((testCase: TestCase) => {
      // need id of step for all cases
    })
  }

  updateSelectedCases($event) {
    this.selectedTestCases = $event;
  }

  updateTestCaseStatus(testStep: TestStep, comment: string, createDefect: string, relationId: string) {
    this.testRunService.updateTestRunTestCaseStatus({
      relation_id: relationId,
      status: this.defaultStatuses.defaultNegativeStatus.id,
      failed_on_step: testStep.id,
      project_id: this.currentProjectId,
      comment,
      create_defect: createDefect
    }).subscribe(result => {
      if (result.valid)
        this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
      else
        this.snackBar.open(result.error_msg, 'OK', { duration: 3000 });
    });
  }

  setActiveRow(id: string) {
    this.testCases.map((testCase: TestCase) => {
      testCase.active = (testCase.id === id) ? true : false;
    })
  }

  doneAnimation() {
    // Once sideBar was closed, we redirect the user onto test-cases URL
    if (this.router.url.includes('defect') && this.componentShowHide === 'hide') {
      this.router.navigate([`test-run-defects/${this.testRun.id}`]);
    }
  }

  ngOnDestroy() {
    if (!this.router.url.includes('defect')) {
      Core.localStorageService.removeItem('testCases');
      Core.localStorageService.removeItem('testRun');
    }
  }
}
