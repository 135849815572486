import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Core } from './core.service';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {

  constructor(private router: Router) { }

  login(param): Observable<any> {
    return from(Core.authentication.login(param).catch(error => console.error(error)))
  }

  logOut(): void {
    Core.api.invalidate()
    this.router.navigate(['/login']);
  }

  isValid(): boolean {
    return Core.api.isValid()
  }

  getUser() {
    return JSON.parse(Core.localStorageService.getItem('user')).user;
  }

  getUserStaticData() {
    return from(Core.users.getUserStaticData({}).catch(error => console.error(error))).pipe(map(res => {
      if (res) Core.localStorageService.setItem('user', JSON.stringify({ user: res.user }))
      else return false
    }))
  }

  refreshToken(): Promise<any> {
    return Core.api.refreshToken()
  }
}