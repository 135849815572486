<div class="dialog-title">
  <h1 mat-dialog-title>Create New {{data?.title || defaultTitle}}</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <h2>General Information</h2>
  <form [formGroup]="craeteTestCaseForm">
    <mat-form-field *ngIf="data?.info === 'unesigned'" appearance="outline" floatLabel="always">
      <mat-label>Project</mat-label>
      <mat-select formControlName="project">
        <mat-option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</mat-option>
      </mat-select>
      <mat-error *ngIf="f.project.hasError('required')">
        Project is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title">
      <mat-error *ngIf="f.title.hasError('required')">
        Title is required
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!data?.testSuitId && !data?.info" appearance="outline" floatLabel="always">
      <mat-label>Test Suit</mat-label>
      <mat-select formControlName="testSuitId">
        <mat-option *ngFor="let option of testSuits" [value]="option.id">{{ option.title }}</mat-option>
      </mat-select>
      <mat-error *ngIf="f.testSuitId.hasError('required')">
        Test Suit is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
      <mat-error *ngIf="f.description.hasError('required')">
        Description is required
      </mat-error>
    </mat-form-field>
    <h2>Create Test Step</h2>
    <div class="test-step-container" formArrayName="testStep"
      *ngFor="let item of craeteTestCaseForm.get('testStep')['controls']; let i = index;">
      <h3>
        Test Step {{i + 1}}
        <button (click)="deleteStep(i)" mat-icon-button style="float: right">
          <mat-icon>close</mat-icon>
        </button>
      </h3>
      <div [formGroupName]="i">
        <mat-form-field appearance="outline" floatLabel="auto">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <mat-error
            *ngIf="craeteTestCaseForm.get('testStep')['controls'][i].controls.description.hasError('required')">
            Description is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="auto">
          <mat-label>Expected Result</mat-label>
          <textarea matInput formControlName="expectedResult"></textarea>
          <mat-error
            *ngIf="craeteTestCaseForm.get('testStep')['controls'][i].controls.expectedResult.hasError('required')">
            Description is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <button mat-raised-button color="primary" style="background: #2d64ba; width: 100%;"
    (click)="createStep()"><mat-icon>add</mat-icon>New Test Step</button>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button [disabled]="!craeteTestCaseForm.valid" color="primary" style="background: #2d64ba"
    (click)="createCase()">Create</button>
</div>