import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Core } from './core.service';
import { createJiraIntegrationSettingParams, JiraIntegrationSetting } from './jira-integration.type';

@Injectable({
  providedIn: 'root'
})
export class JiraIntegrationService {

  constructor() { }

  listJiraIntegrationSettings(param): Observable<{ valid: boolean, data?: JiraIntegrationSetting[], msg?: string }> {
    if (param.project_id === 'null' || param.project_id === 'all') param = {};
    return from(Core.jira.list(param).catch(error => console.error(error))).pipe(map(res => {
      if (res['valid'] === 0) return { valid: false, msg: res.error_msg };
      else if (res.length) return { valid: true, data: res };
    }))
  }

  createJiraIntegrationSetting(params: createJiraIntegrationSettingParams): Observable<{ valid: boolean, msg?: string }> {
    return from(Core.jira.create(params).catch(error => console.error(error))).pipe(map(res => {
      if (+res.valid === 1) return { valid: true }
      else return { valid: false, msg: res.error_msg }
    }))
  }

  updateJiraIntegrationSetting(params): Observable<{ valid: boolean, data?: JiraIntegrationSetting[], msg?: string }> {
    return from(Core.jira.update(params).catch(error => console.error(error))).pipe(map(res => {
      if (+res.valid === 1) return { valid: true, data: res.data }
      else return { valid: false, msg: res.error_msg }
    }))
  }

  deleteJiraIntegrationSetting(params: { project_id: string | number }): Observable<{ valid: boolean, error_msg?: string }> {
    return from(Core.jira.delete(params).catch(error => console.error(error))).pipe(map(res => {
      if (res.valid) return res;
      else return res;
    }))
  }
}
