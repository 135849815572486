const caseListAll = 'testCase/all', caseListByTestRun = 'testCase/testRunCasesList', caseListByTestPlan = 'testCase/testPlaneCasesList', caseListByTestSuit = 'testSuit/testCasesList', caseRead = 'testCase/read', caseCreate = 'testCase/create', caseUpdate = 'testCase/update', caseDelete = 'testCase/delete', updateStatus = 'testCase/updateTestCaseStatus', unesignedList = 'testCase/unsignedList', independentCopyCase = 'testCase/independentCopyCase', showCaseLinks = 'testCase/getAllRelationsForCase';
export class TestCaseControllers {
    constructor(api) {
        this.api = api;
    }
    //get test cases all
    allList(payload) {
        return this.api.postRequest(caseListAll, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    // get test case list by testRun
    listByTestRun(payload) {
        return this.api.postRequest(caseListByTestRun, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    // get test case list by testSuit
    listByTestSuit(payload) {
        return this.api.postRequest(caseListByTestSuit, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    // get test case list by testPlan
    listByTestPlan(payload) {
        return this.api.postRequest(caseListByTestPlan, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    // read test
    read(payload) {
        return this.api.postRequest(caseRead, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //create case
    create(payload) {
        return this.api.postRequest(caseCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update case
    update(payload) {
        return this.api.postRequest(caseUpdate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete case
    delete(payload) {
        return this.api.postRequest(caseDelete, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update StatusCase
    updateStatus(payload) {
        return this.api.postRequest(updateStatus, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update StatusCase
    unesignedList(payload) {
        return this.api.postRequest(unesignedList, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }

    independentCopy(payload) {
        return this.api.postRequest(independentCopyCase, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }

    getAllRelations(payload) {
        return this.api.postRequest(showCaseLinks, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }

}
