import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { CardJiraIntegrationComponent } from './card-jira-integration/card-jira-integration.component';


@Component({
  selector: 'app-jira-integration-page',
  templateUrl: './jira-integration-page.component.html',
  styleUrls: ['./jira-integration-page.component.scss']
})
export class JiraIntegrationPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CardJiraIntegrationComponent) jiraIntegrationComponent: CardJiraIntegrationComponent;

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.projectService.setSettingActiveComponent(null);
  }

  ngAfterViewInit(): void {
    if (this.jiraIntegrationComponent) this.projectService.setSettingActiveComponent(this.jiraIntegrationComponent, 'CardJiraIntegrationComponent');
  }
}
