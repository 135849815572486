<app-base-page>
  <div class="main-page settings">
    <nav mat-tab-nav-bar #matTabNav>
      <a class="nav" mat-tab-link [routerLink]="'main'" routerLinkActive="active">Main</a>
      <a class="nav" mat-tab-link [routerLink]="'jira-integration'" routerLinkActive="active"
        *ngIf="jiraIntegrationAccess">Jira
        integration</a>
      <a class="nav" mat-tab-link [routerLink]="'statuses'" routerLinkActive="active">Statuses</a>
    </nav>
    <router-outlet></router-outlet>
  </div>
</app-base-page>