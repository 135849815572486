import { Attachment } from './attachment.type';
import { Status } from './status.type';

export class TestStep {
  comment: string;
  id: string;
  testCaseId: string;
  order: string;
  expectedResult: string;
  description: string;
  createdDate: string;
  modifiedDate: string;
  status: string;
  statusObject: Status;
  previousStatus: object;
  showUndo: boolean;
  statusText: string;
  attachments: Attachment[];
  isOpen: string;
  createDefect?: string;
}
