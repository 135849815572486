<app-sidenav>
  <mat-card>
    <button mat-button *ngIf="!editMode && !saving" (click)="beginEdit()">EDIT</button>
    <button mat-button *ngIf="editMode || saving" (click)="saveEdit()" [disabled]="saving">SAVE</button>
    <mat-spinner class="inline-progress" *ngIf="saving" [diameter]="20"></mat-spinner>
    <button mat-button (click)="deleteCase()">DELETE</button>
  </mat-card>
  <mat-card *ngIf="testCase" [class.no-underline]="!editMode" style="padding: 0">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="uppercase">
            {{testCase.title}}
          </mat-panel-title>
          <mat-panel-description>
                <span>
                  TEST CASE ID - {{testCase.id}}
                </span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-form-field appearance="fill" floatLabel="always" class="full-width">
          <input matInput placeholder="Title" [(ngModel)]="testCase.title" [readonly]="!editMode">
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill" floatLabel="always" class="full-width">
          <textarea matInput rows="5" placeholder="Description" [(ngModel)]="testCase.description" [readonly]="!editMode"></textarea>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
  <mat-card style="padding: 0; background-color: #fafafa; box-shadow: none">
    <div *ngFor="let testStep of testSteps; let i = index;">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="uppercase">
              {{ (testStep.description.length>50)? (testStep.description | slice:0:50)+'...':(testStep.description) }}
            </mat-panel-title>
            <mat-panel-description>
            <span>
              TEST STEP ID - {{testStep.id}}
            </span>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div *ngIf="testCase" [class.no-underline]="!editMode">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Description</mat-label>
              <textarea matInput [name]="'test_step_description_' + i" [(ngModel)]="testStep.description" [readonly]="!editMode">{{testStep.description}}</textarea>
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Expected result</mat-label>
              <textarea matInput [name]="'test_step_expected_result_' + i" [(ngModel)]="testStep.expectedResult" [readonly]="!editMode">{{testStep.expectedResult}}</textarea>
            </mat-form-field>
            <button mat-icon-button (click)="deleteStep(testStep)">
              DELETE STEP
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="new-step-div">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              CREATE TEST STEP
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Description</mat-label>
            <textarea matInput [(ngModel)]="testStep.description"></textarea>
          </mat-form-field>
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Expected result</mat-label>
            <textarea matInput [(ngModel)]="testStep.expectedResult"></textarea>
          </mat-form-field>
          <button mat-icon-button (click)="createStep()">
            ADD STEP
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card>
</app-sidenav>
