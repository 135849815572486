import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../services/user.type';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  user: User
}

@Component({
  selector: 'app-dialog-edit-user',
  templateUrl: 'dialog-edit-user.component.html',
  styleUrls: ['./dialog-edit-user.component.scss']
})
export class DialogEditUserComponent implements OnInit{

  hiddenPassword = true;

  userForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogEditUserComponent>,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {}
  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      firstName: new FormControl(this.data.user.firstName, [Validators.required]),
      lastName: new FormControl(this.data.user.lastName, [Validators.required]),
    })
  }

  get f() {
    return this.userForm.controls
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onUpdateUser():void {
    this.userService.updateUser({
      id: this.data.user.id,
      first_name: this.userForm.value.firstName,
      last_name: this.userForm.value.lastName,
      login: this.data.user.login,
      password: this.userForm.value.password
    }).subscribe((valid) => {this.dialogRef.close(valid)});
  }
}
