export class Status {
  id: string;
  name: string;
  color: string;
  isDefault: string;
  ProjectId: string;
  createdDate: string;
  sortOrder: string;
  statusState: string;
  quantity: number;
}