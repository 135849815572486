<h1 mat-dialog-title><mat-icon>chrome_reader_mode</mat-icon>SELECT TEST SUITS</h1>
<div mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox class="checklist-leaf-node"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <mat-icon>folder</mat-icon>
      <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-checkbox [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">CANCEL</button>
  <button mat-button [mat-dialog-close]="this.checklistSelection.selected" cdkFocusInitial>SELECT</button>
</div>
