<div class="dialog-title">
  <h1 mat-dialog-title>Create New Status</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <form [formGroup]="statusForm">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
      <mat-error *ngIf="f.name.hasError('required')">
        Name is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Status State</mat-label>
      <input matInput formControlName="statusState">
      <mat-error *ngIf="f.statusState.hasError('required')">
        Status State is required
      </mat-error>
      <mat-error *ngIf="f.statusState.hasError('anotherStatusForThisName')">
        Name has already existed for another Status State
      </mat-error>
      <mat-error *ngIf="f.statusState.hasError('invalidDataForStatusState')">
        Status State can be 0, 1, -1
      </mat-error>
    </mat-form-field>
  </form>
  <input [style.background]="status.color" class="color-picker-input"
    [cpPresetColors]="['#353D45', '#2D64BA', '#FF8800', '#06C270', '#FF3B3B']" [(colorPicker)]="status.color"
    (colorPickerChange)="setColor($event)" readonly />
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button [disabled]="statusForm.invalid" color="primary" style="background: #2d64ba"
    (click)="createAction()">Create</button>
</div>