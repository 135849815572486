<app-base-card [title]="'Test Run Details'" [icon]="'details'" [showAdd]="false" [showClose]="true"
  (closeEvent)="closeEvent.emit()">
  <div class="container-details">
    <div class="main">
      <div class="card-details-container">
        <div class="card-details-container__header">
          <div class="card-details-container__title">{{testRun.title}}</div>
          <div class="selected-action" [style.width.px]="guestAccessAction ? '60' : '100'">
            <button *ngIf="guestAccessAction" mat-menu-item (click)="copyLink()">
              <mat-icon>link</mat-icon>
            </button>
            <button *ngIf="!guestAccessAction" (menuClosed)="selectOpen = false;" (menuOpened)="selectOpen = true"
              mat-button [matMenuTriggerFor]="menu" [disabled]="pending">
              <div class="item-list__title flex" [style.height.px]="46">
                <div [style.opacity]="pending ? .2 : 1">Action</div>
                <div *ngIf="pending" class="loading">
                  <img src="../../../assets/image/loading.gif" alt="pending">
                </div>
              </div>
              <mat-icon class="icon"
                [ngStyle]="{'transform': selectOpen == true ? 'rotate(180deg)' : 'rotate(0deg)'}">keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="reRunEvent()" [disabled]="!addLevelAccessAction">
                <span>Rerun</span>
              </button>
              <button mat-menu-item (click)="editEvent()" [disabled]="!editLevelAccessAction">
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteEvent()" [disabled]="!deleteLevelAccessAction">
                <span>Delete</span>
              </button>
              <button mat-menu-item (click)="addTestCaseEvent()" [disabled]="!addCaseAccessAction">
                <span>Add new test case</span>
              </button>
              <button mat-menu-item (click)="copyLink()">
                <span>Copy link</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div class="card-details-container__content"
          [style.height.px]="data.data[0] === 0 ? '100' : shortFormRun ? '600' : null">
          <div class="canvas">
            <ng-container *ngIf="data.data.length; then canvas; else spin"></ng-container>
            <ng-template #canvas>
              <div class="main">
                <ng-container *ngIf="data.data[0] !== 0; then canvasShow; else empty"></ng-container>
                <ng-template #canvasShow>
                  <canvas baseChart class="chart" [data]="pieChartData" [options]="pieChartOptions"
                    [type]="pieChartType" [legend]="false"></canvas>
                </ng-template>
                <ng-template #empty>
                  <span class="empty">Test cases not found...</span>
                </ng-template>
                <div *ngIf="data.info" class="legend">
                  <div #legEl class="legend-item" *ngFor="let legend of data.data, let i = index">
                    <div class="legend-item__rect" [style.background-color]="data.colors[i]"></div>
                    <div class="legend-item__text">
                      <span class="legend-item__text-main">{{data.data[i]}} {{data.labels[i]}}</span>
                      <span class="legend-item__text-details">{{data.info[i].per}}%
                        set to {{data.info[i].name}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="data.passed" class="info">
                <span class="info__per">{{data.passed.per}}%</span>
                <span class="info__status">passed</span>
                <span class="info__tested">{{data.unTest.count}} / {{data.all}} untested ({{data.unTest.per}}%)</span>
              </div>
            </ng-template>
            <ng-template #spin>
              <div class="loading">
                <img src="../../../../assets/image/loading.gif" alt="pending">
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="card-details-container" [style.display]="dataSource?.data.length !== 0 ? 'block' : 'none'">
        <div class="card-details-container__title">Test Cases</div>
        <mat-card class="table-card">
          <div class="action-list">
            <div class="item-list__action" *ngIf="!guestAccessAction">
              <div class="item-list__menu" *ngIf="deleteLevelAccessAction">
                <button mat-icon-button [matMenuTriggerFor]="menu2">
                  <mat-icon>delete_outline</mat-icon>
                </button>
                <mat-menu #menu2="matMenu">
                  <button mat-menu-item [disabled]="!selection.hasValue()" (click)="deleteTestCasesEvent()">
                    <span>Delete cases</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <mat-paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="5"
              [pageSizeOptions]="[1, 3, 5, 10]">
            </mat-paginator>
          </div>
          <div class="table-container">
            <table mat-table [dataSource]="dataSource">
              <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row mat-row *matRowDef="let row; columns: displayedColumns;" [class.active]="row.active"></mat-row>
              <ng-container matColumnDef="select">
                <mat-header-cell mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="id">
                <mat-header-cell mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let testCase">{{testCase.testCaseId}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
                <mat-cell *matCellDef="let testCase" (click)="this.openCaseComponent.emit(testCase)"
                  class="cursor-pointer" [routerLink]="'./'"
                  [queryParams]="{project: testRun.projectId, type: 'tr', id: testRun.id, tc: testCase.testCaseId}">
                  <div>{{testCase.title | shortName: 15}}
                    <span *ngIf="testCase.copyCount > 0"
                      class="item-list__title item-list__title--plan">({{testCase.copyCount}})
                    </span>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="status">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let testCase">
                  <mat-form-field class="custom-select-wrap">
                    <mat-select class="custom-select" [ngModel]="testCase.status" [disabled]="!assignedAccessAction"
                      (ngModelChange)="onStatusChange($event, testCase)" disableOptionCentering>
                      <mat-option *ngFor="let status of statuses" [value]="status.id">
                        {{status.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="assigned_to">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Assigned to</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let testCase">
                  <mat-form-field class="custom-select-wrap">
                    <mat-select class="custom-select" [(ngModel)]="testCase.assignedTo"
                      [disabled]="!changeStatusAccessAction"
                      (ngModelChange)="onUserChange(testCase, testCase.assignedTo)" disableOptionCentering>
                      <mat-option *ngFor="let user of users" [value]="user.id">
                        {{user.firstName}} {{user.lastName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-cell>
              </ng-container>
            </table>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</app-base-card>