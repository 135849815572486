import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionLike } from 'rxjs';
import { DialogCreateUserComponent } from 'src/app/dialogs/dialog-create-user/dialog-create-user.component';
import { DialogEditUserComponent } from 'src/app/dialogs/dialog-edit-user/dialog-edit-user.component';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/services/user.type';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-users',
  templateUrl: './card-users.component.html',
  styleUrls: ['./card-users.component.scss']
})
export class CardUsersComponent implements OnInit, OnDestroy {
  public addUserAccess: boolean = this._userAccessService.getAccess('users', 'addUserLevel');
  public editUserAccess: boolean = this._userAccessService.getAccess('users', 'editUserLevel');

  @ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;
  public search: string;

  private _users: User[];
  public users: User[];

  private userSubscriber: SubscriptionLike;
  private selectionListSubscriber: SubscriptionLike;
  public isLoading = true;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnDestroy(): void {
    this.userSubscriber ? this.userSubscriber.unsubscribe() : ''
    this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : ''
  }

  ngOnInit(): void {
    this.getUsers()
  }

  getUsers() {
    this.userSubscriber = this.userService.getUsers().subscribe(res => {
      this.users = res;
      this._users = this.users;
      this.isLoading = false;
    })
  }

  onAddEvent() {
    this.dialog.open(DialogCreateUserComponent, {
      width: '650px'
    }).afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open('A new user has been created', 'OK', { duration: 3000 });
        this.getUsers();
      }
    });
  }

  onEditEvent(user: User) {
    this.dialog.open(DialogEditUserComponent, {
      width: '650px',
      data: { user }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open('User was successfully edited', 'OK', { duration: 3000 });
        this.getUsers();
      }
    });
  }

  onDeleteEvent(user: User) {
    this.userService.deleteUser(user.id).subscribe(res => {
      if (res) {
        this.snackBar.open('User was successfully deleted', 'OK', { duration: 3000 });
        this.getUsers();
      }
    })
  }

}
