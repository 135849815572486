<app-base-card [title]="'Test Case'" [icon]="'date_range'" [showAdd]="addTestCaseAccess" [showSearch]="true"
  (addEvent)="onAddEvent()" (searchEvent)="search = $event" (closeEvent)="this.closeTestStep.emit(true)">
  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
    appearance="line"></ngx-skeleton-loader>
  <ng-container *ngIf="creationMode">
    <div class="creation-mode-create">
      <form [formGroup]="createCaseCreationMode" (submit)="createCaseToSuit()"
        [ngClass]="selectedValue ? 'short-form':''">
        <div class="creation-mode-create__select select-creation-mode">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Suit</mat-label>
            <mat-select [formControl]="selectedSuitControlForCreate">
              <mat-option *ngFor="let suitItem of testCases" [value]="suitItem.suit.id">{{
                suitItem.suit.title
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline" floatLabel="always"
          class="creation-mode-create__field creation-mode-create__field--first">
          <mat-label>Title</mat-label>
          <input #formCaseTitle matInput formControlName="title">
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="creation-mode-create__field">
          <mat-label>Description</mat-label>
          <input matInput formControlName="description">
        </mat-form-field>
        <div class="creation-mode-create__button-push">
          <button mat-flat-button color="primary" [disabled]="createCaseButtonDisabled">
            <span *ngIf="!selectedValue">CREATE</span>
            <span *ngIf="selectedValue"><mat-icon>create</mat-icon></span>
          </button>
        </div>
      </form>
    </div>
  </ng-container>

  <div class="main-flex" [style.height.%]="creationMode ? 80 : 100">
    <ng-container *ngIf="dataSource && testCases?.length > 0">
      <div class="tree sticky">
        <div class="toogle-visibility" *ngIf="testCases.length > 1">
          <mat-icon *ngIf="checkIsOpen()" (click)="openAll(false)">visibility</mat-icon>
          <mat-icon *ngIf="!checkIsOpen()" (click)="openAll(true)">visibility_off</mat-icon>
        </div>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" [attr.aria-expanded]="node.open">
            <div class="content flex">
              <ng-container *ngIf="node.level === 0">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename"
                  (click)="todoItemSelectionToggle(node, testCases)">
                  <mat-icon *ngIf="!node.open">folder_special</mat-icon>
                  <mat-icon *ngIf="node.open">folder_open</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="node.level !== 0">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename"
                  (click)="todoItemSelectionToggle(node, testCases)" [style.marginLeft.px]="node.level * 5">
                  <div *ngIf="node.level !== 0" class="badge"
                    [style.backgroundColor]="node.open ? node.color : '#0000001a'">
                    {{node.level}}
                  </div>
                </button>
              </ng-container>

              <div class="flex gap">
                <span
                  [class]="(node.open && (+activeSuit === +node.elem.id)) ? 'open open--accent' : node.open ? 'open' :'close'"
                  [matTooltip]="node.title" (click)="scrollTo(node)">
                  {{node.title | shortName: (23 - (node.level * 2))}}</span>
                <ng-container *ngIf="node.expandable">
                  <mat-icon *ngIf="!node.open" matTreeNodeToggle
                    (click)="todoItemSelectionToggle(node, testCases)">keyboard_arrow_right
                  </mat-icon>
                  <mat-icon *ngIf="node.open" matTreeNodeToggle
                    (click)="todoItemSelectionToggle(node, testCases)">keyboard_arrow_left
                  </mat-icon>
                </ng-container>
              </div>
            </div>
            <div class="item-list__menu">
              <button mat-icon-button [matMenuTriggerFor]="menuTree" stopPropagation>
                <mat-icon>add</mat-icon>
              </button>
              <mat-menu class="additions" #menuTree="matMenu">
                <button [disabled]="!addTestCaseAccess" mat-menu-item (click)="onAddEvent(node)">
                  <span>Add new test cases</span>
                </button>
                <button mat-menu-item (click)="copyLink('suit', node)">
                  <span>Copy link</span>
                </button>
              </mat-menu>
            </div>
          </mat-tree-node>
        </mat-tree>
      </div>
    </ng-container>

    <div #main [class]="dataSource ? 'main-container sticky' : 'main-container sticky width-100'">
      <mat-selection-list #selection class="list" (selectionChange)="selectionChange($event)">
        <div *ngFor="let suit of testCases | searchCases: search">
          <div [class]="suit.level ? 'container margin' : 'container'" [style.marginLeft.px]="suit.level * 15"
            [style]="!suit.show ? {'border-bottom' : '1px solid #b5bec8'} : {}">
            <div #suit [attr.data-value]="suit.suit.title + suit.suit.id + suit.level" [attr.data-id]="suit.suit.id"
              [class]="+suit.suit.id === +activeSuit ? 'item-title item-title--accent' : 'item-title'"
              (click)="toggleSuit(suit)">
              <div class="flex">
                <mat-icon *ngIf="suit.level === 0">work</mat-icon>
                <div *ngIf="suit.level !== 0" [style.backgroundColor]="suit.show ? suit.color : '#0000001a'"
                  [class]="suit.show ? 'badge badge--large' : 'badge badge--large'">
                  {{suit.level}}</div>

                <span [matTooltip]="suit.suit.title">{{suit.suit.title | shortName: (60 -
                  suit.level * 2)}}</span>
              </div>
            </div>

            <ng-container *ngIf="suit.show && suit.cases.length">
              <mat-list-option #option *ngFor="let item of suit.cases" [value]="item" [selected]="item.isOpen"
                [class.creation-mode]="creationMode" [class.no-creation-mode]="!creationMode" checkboxPosition="before">
                <mat-checkbox *ngIf="creationMode" class="checkbox" (change)="checkedItem($event, option)"
                  stopPropagation></mat-checkbox>
                <div class="item-list__body">
                  <div class="item-list__title flex">
                    <div [style.opacity]="(pending && deletedItems.includes(item)) ? .2 : 1" [matTooltip]="item.title">
                      {{item.title | shortName:
                      (55 -
                      suit.level * 2)}}</div>
                    <div *ngIf="pending && deletedItems.includes(item)" class="loading">
                      <img src="../../../assets/image/loading.gif" alt="pending">
                    </div>
                  </div>
                  <div class="item-list__action">
                    <div class="item-list__menu">
                      <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation [disabled]="pending">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button [disabled]="!editTestCaseAccess" mat-menu-item (click)="onEditEvent(item)">
                          <span>Edit</span>
                        </button>
                        <button [disabled]="!deleteTestCaseAccess" mat-menu-item (click)="onDeleteEvent(item)">
                          <span>Delete</span>
                        </button>
                        <button mat-menu-item (click)="copyLink('case')" *ngIf="selectionList?._value?.includes(item)">
                          <span>Copy link</span>
                        </button>
                        <button [disabled]="!testSuitAddTestCaseAccess" mat-menu-item
                          (click)="dependentCopyTestCase(item)">
                          <span>Dependent copy</span>
                        </button>
                        <button [disabled]="!testSuitAddTestCaseAccess" mat-menu-item
                          (click)="independentCopyTestCase(item)">
                          <span>Independent copy</span>
                        </button>
                        <button mat-menu-item (click)="showLinksCase(item)">
                          <span>Display all test case links</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                  <div class="item-list__right">
                    <button mat-icon-button *ngIf="item.independent === '0'" (click)="showLinksCase(item)"
                      stopPropagation>
                      <mat-icon>lock</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="Infomenu" stopPropagation>
                      <mat-icon>info</mat-icon>
                    </button>
                    <mat-menu #Infomenu="matMenu" class="info-menu">
                      <div class="info-menu-item" mat-menu-item>
                        <span>Created Date: {{item.createdDate}}</span>
                      </div>
                      <div class="info-menu-item" mat-menu-item>
                        <span>Modified Date: {{item.modifiedDate}}</span>
                      </div>
                      <div class="info-menu-item" mat-menu-item>
                        <span>Create By: {{getUserName(item.creator_id)}}</span>
                      </div>
                    </mat-menu>
                  </div>
                </div>
              </mat-list-option>
            </ng-container>
            <ng-container *ngIf="suit.show && !suit.cases.length">
              <div [class]="suit.parents.length ? 'not-found border-bottom' : 'not-found'">Test cases not
                found...
              </div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="testCases?.length === 0 && !isLoading">
          <div class="not-found">Test suits not found...</div>
        </ng-container>
      </mat-selection-list>
    </div>
  </div>

  <div class="card__footer footer-card" [class.creation-mode]="creationMode">
    <ng-container *ngIf="creationMode">
      <div class="footer-card__creation-mode creation-mode-card-footer">
        <div class="creation-mode-card-footer__body">
          <div class="creation-mode-card-footer__row">

            <div class="creation-mode-card-footer__column">

              <mat-button-toggle-group #group="matButtonToggleGroup"
                class="creation-mode-card-footer__toogle-group-button toogle-group-button"
                (change)="onChangeIntoEvent($event)">
                <mat-button-toggle value="testSuit" [checked]="true">Into Suit</mat-button-toggle>
                <mat-button-toggle value="testPlan">Into Plan</mat-button-toggle>
                <mat-button-toggle value="testRun">Into Run</mat-button-toggle>
              </mat-button-toggle-group>

              <div class="creation-mode-card-footer__select select-creation-mode">
                <mat-form-field appearance="outline">
                  <mat-label style="text-transform: capitalize;">{{group.value}}</mat-label>
                  <mat-select [formControl]="selectedTestsControl" multiple (click)="checkItems(group.value)">
                    <mat-select-trigger>
                      {{selectedTestsControl.value ? (selectedTestsControl.value[0]?.title ?
                      selectedTestsControl.value[0].title :
                      selectedTestsControl.value[0].suit.title ?
                      selectedTestsControl.value[0].suit.title :'') : ''}}
                      <span *ngIf="selectedTestsControl.value?.length > 1" class="example-additional-selection">
                        (+{{selectedTestsControl.value.length - 1}}
                        {{selectedTestsControl.value?.length === 2 ?
                        'other' : 'others'}})
                      </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let test of selectedTests" [value]="test">{{test.title ||
                      test.suit.title}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="creation-mode-card-footer__button-push">
                <button mat-flat-button color="primary" [disabled]="pushButtonDisabled"
                  (click)="onPushIntoEvent(group.value)">
                  <span *ngIf="!selectedValue">PUSH</span>
                  <span *ngIf="selectedValue"><mat-icon>add</mat-icon></span>
                </button>
              </div>

            </div>

            <div class="creation-mode-card-footer__column">
              <button mat-flat-button color="warn" [disabled]="deleteButtonDisabled || !deleteTestCaseAccess"
                (click)="onDeleteGroupEvent()">
                <span *ngIf="!selectedValue">Delete</span>
                <span *ngIf="selectedValue"><mat-icon>delete_outline</mat-icon></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</app-base-card>