import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params } from '@angular/router';
import { TestCase } from 'src/app/services/test-case.type';
import { TestStepService } from 'src/app/services/test-step.service';
import { TestStep } from 'src/app/services/test-step.type';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-test-suit-step',
  templateUrl: './card-test-suit-step.component.html',
  styleUrls: ['./card-test-suit-step.component.scss']
})
export class CardTestSuitStepComponent implements OnInit {
  public addAccessAction: boolean = this._userAccessService.getAccess('testStep', 'testStepCreateLevel');

  public testSuitStep: TestStep[];
  private _testSuitStep: TestStep[];
  private _testCase: TestCase;
  public showAddNewTestStepForm: boolean = false;
  public testStepForm: FormGroup;
  public testSteps: TestStep[];
  private _testSteps: TestStep[];
  public isLoading = true;

  @Input() public shortSuit: boolean = false;
  @Input() public shortCases: boolean = false;
  @Input() public queryParams: Params;

  @ViewChildren('panel') private panelSteps: QueryList<MatExpansionPanel>;

  @Input() set testCase(data: TestCase) {
    if (data) {
      this._testCase = data;
      this.getTestSuitStep(data.id)
    }
  } get testCase() {
    return this._testCase
  }

  @Output() closeEvent = new EventEmitter()

  constructor(
    private testStepService: TestStepService,
    private snackBar: MatSnackBar,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void { }

  searchPath(param: Params) {
    let index: number;
    let find = this.testSuitStep.find((elem: TestStep, i: number) => {
      if (+elem.id === +param.ts) index = i;
      return +elem.id === +param.ts
    });
    if (find) {
      let sub = this.panelSteps.changes.subscribe(res => {
        res.forEach((elem: MatExpansionPanel, i: number) => { if (i === index) elem.expanded = true });
        if (sub) sub.unsubscribe();
      })
    }
  }

  getTestSuitStep(id) {
    this.isLoading = true;
    this.testSuitStep = [];
    this.testStepService.getTestSteps(id).subscribe(res => {
      this.isLoading = false;
      this.testSuitStep = this.createTestStepForm(res);
      this._testSuitStep = res;
      if (this.queryParams.ts) this.searchPath(this.queryParams);
    })
  }

  createTestStepForm(testStep: TestStep[]): TestStep[] {
    return testStep.map(step => {
      step['form'] = this.createForm(step);
      return step
    })
  }

  createForm(testStep: TestStep) {
    return new FormGroup({
      id: new FormControl(testStep.id),
      description: new FormControl(testStep.description, [Validators.required]),
      expectedResult: new FormControl(testStep.expectedResult, [Validators.required])
    })
  }

  reloadTestStep() {
    this.getTestSuitStep(this.testCase.id)
  }

  updateStep(value: TestStep) {
    this.testStepService.saveStep(value).subscribe(res => {
      if (res) {
        this.reloadTestStep();
        this.snackBar.open('Succefully', 'OK', { duration: 3000 })
      } else {
        this.snackBar.open('Error', 'ERROR', { duration: 5000 });
      }
    })
  }

  deleteStep(value: TestStep) {
    this.testStepService.deleteTestStep(value.id).subscribe(res => {
      if (res) {
        this.reloadTestStep();
        this.snackBar.open('Succefully', 'OK', { duration: 3000 })
      } else {
        this.snackBar.open('Error', 'ERROR', { duration: 5000 });
      }
    })
  }

  onAddNewTestStep() {
    this.testStepForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
      expectedResult: new FormControl('', [Validators.required]),
    })
    this.showAddNewTestStepForm = true;
  }

  getTestStep(id: string) {
    this.testStepService.getTestSteps(id).subscribe(res => {
      this.testSuitStep = this.createTestStepForm(res);
      this._testSteps = res;
    })
  }

  createTestStep(testStep: TestStep) {
    this.closeTestStepForm(true);
    this.testStepService.createTestStep(this._testCase.id, testStep).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Step was succefully created', 'OK', { duration: 3000 })
        this.getTestStep(this._testCase.id)
      }
    })
  }

  closeTestStepForm(res: boolean) {
    if (res) {
      this.testStepForm = null;
      this.showAddNewTestStepForm = false;
    }
  }
}
