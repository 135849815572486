import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { ProjectService } from '../../services/project.service';
import { TestSuit } from '../../services/test-suit.type';
import { TestSuitService } from '../../services/test-suit.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface DialogData {
  testParentSuit: TestSuit;
  testParentObjIds: string[];
}

@Component({
  selector: 'app-dialog-create-sub-test-suit',
  templateUrl: 'dialog-create-sub-test-suit.component.html',
  styleUrls: ['./dialog-create-sub-test-suit.component.scss']
})
export class DialogCreateSubTestSuitComponent implements OnInit {

  testSuit: TestSuit = new TestSuit();
  projects: Project[];
  public nextStep: number = 1;
  testSuits: TestSuit[] = [];

  craeteTestSuitForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateSubTestSuitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
    private testSuitService: TestSuitService
  ) { }

  initForm(nextStep) {
    if (nextStep === 2) {
      this.craeteTestSuitForm = new FormGroup({
        suit: new FormControl('', [Validators.required]),
        sortOrder: new FormControl('')
      })
    } else {
      this.craeteTestSuitForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        sortOrder: new FormControl('')
      })
    }
  }

  get f() {
    return this.craeteTestSuitForm.controls
  }

  ngOnInit() {
    this.testSuits = this.testSuitService.getCacheSuits();
    this.projectService.getProjects().subscribe(response => {
      this.projects = response;
    });
  }

  addSuit() {
    let params: { parent, sub_suit_id, sort_order?} = {
      parent: this.data.testParentSuit.id,
      sub_suit_id: this.craeteTestSuitForm.controls.suit.value,
      sort_order: this.craeteTestSuitForm.controls.sortOrder.value
    };
    if (!this.craeteTestSuitForm.controls.sortOrder.value) params = { parent: params.parent, sub_suit_id: params.sub_suit_id };

    this.testSuitService.addSuitToSuit(params).subscribe(res => {
      this.dialogRef.close({ action: 'add', result: res });
    })
  }

  createSubSuit() {
    this.testSuit.title = this.craeteTestSuitForm.value.title;
    this.testSuit.description = this.craeteTestSuitForm.value.description;
    this.testSuit.projectId = this.data.testParentSuit.projectId || (this.data.testParentSuit as any).project_id;

    let params: { testSuit, testCases, parent, sort_order?} = {
      testSuit: this.testSuit,
      testCases: [],
      parent: this.data.testParentSuit.id,
      sort_order: this.craeteTestSuitForm.value.sortOrder
    }
    if (!this.craeteTestSuitForm.controls.sortOrder.value) params = { parent: params.parent, testSuit: params.testSuit, testCases: params.testCases };

    this.testSuitService.createTestSuit(params).subscribe(res => {
      this.dialogRef.close({ action: 'create', result: res });
    })
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
