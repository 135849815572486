import {User} from './user.type';

export class Project {
  id: string;
  title: string;
  description: string;
  createdDate: string;
  modifiedDate: string;
  users: User[];
  isOpen?: boolean;
  creator_id: string;
  creator_name: string;
}
