<mat-card class="card login">
  <mat-card-title class="card-title">TESTYY</mat-card-title>
  <mat-card-content>
    <mat-form-field *ngIf="companyDomainError" style="width: 100%;" appearance="fill">
      <mat-label>Company Domain</mat-label>
      <input matInput [(ngModel)]="companyDomain">
    </mat-form-field>
    <form (ngSubmit)="onSubmit()" class="form-vertical">
      <mat-form-field appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput [formControl]="username" [errorStateMatcher]="matcher" required>
        <mat-error *ngIf="username.hasError('required')">
          Username is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" [formControl]="password" [errorStateMatcher]="matcher"
          required>
        <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="password.hasError('required')">
          Password is required
        </mat-error>
      </mat-form-field>
      <div class="form-group">
        <button type="submit" class="hor-item" [disabled]="loading" mat-button>LOGIN</button>
        <mat-spinner class="hor-item" *ngIf="loading" [diameter]="35"></mat-spinner>
      </div>
    </form>
  </mat-card-content>
</mat-card>