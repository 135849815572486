import { TestCase } from './test-case.type';

export class TestSuit {
  id: string;
  projectId: string;
  title: string;
  description: string;
  createdDate: string;
  modifiedDate: string;
  testCases: TestCase[];
  projectName: string;
  isOpen: boolean;
  creator_id: string;
  creator_name: string;
}

export class TestSuitFolders {
  suit: TestSuit;
  sub_suits: any[];
  cases: TestCase[];
  color?: string;
}

export class TestSuitFoldersTemplate {
  suit: TestSuit;
  sub_suits: any[];
  cases: TestCase[];
  parents: TestSuit[];
  level: number;
  show?: boolean;
}