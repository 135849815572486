<div class="dialog-title">
  <h1 mat-dialog-title>{{data.dialogTitle || 'Edit'}}</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <form [formGroup]="editForm">
    <mat-form-field appearance="outline" floatLabel="auto" class="dialog-field">
      <mat-label>Title</mat-label>
      <input matInput placeholder="Title" formControlName="title">
      <mat-error *ngIf="f.title.hasError('required')">
        Title is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="auto" class="dialog-field">
      <mat-label>Description</mat-label>
      <input matInput placeholder="Description" formControlName="description">
      <mat-error *ngIf="f.description.hasError('required')">
        Description is required
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button [disabled]="!editForm.valid" color="primary" style="background: #2d64ba"
    (click)="save()">Edit</button>
</div>