import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { TestRunService } from '../services/test-run.service';
import { TestRun } from '../services/test-run.type';
import { DialogCreateTestRunComponent } from '../dialogs/dialog-create-test-run/dialog-create-test-run.component';
import { MatDialog } from '@angular/material/dialog';
import { TestSuit } from '../services/test-suit.type';
import { TestSuitService } from '../services/test-suit.service';
import { TestCaseService } from '../services/test-case.service';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-plans',
  templateUrl: './test-runs.component.html',
  styleUrls: ['./test-runs.component.scss']
})
export class TestRunsComponent implements OnInit {

  public data: TestRun[];
  public filterValue: string = '';
  public displayedColumns: string[];

  tableLoading = true;
  expandDiscription = false;
  TestRuns: TestRun[];
  projects = JSON.parse(Core.localStorageService.getItem('projects'));

  testSuits: TestSuit[];
  editMode: boolean;

  constructor(
    private testRunService: TestRunService,
    private router: Router,
    public dialog: MatDialog,
    private testSuitService: TestSuitService,
    private testCaseService: TestCaseService,
  ) { }


  ngOnInit() {
    if (Core.localStorageService.getItem('selected_project') === 'all') {
      this.displayedColumns = ['id', 'title', 'description', 'projectName', 'createdDate', 'modifiedDate', 'trStatus', 'defects'];
      this.testSuitService.getTestSuits({}).subscribe(testSuits => {
        this.testSuits = testSuits;
      });
      // if all project selected, we don't pass any parameter in the body of request and reseive test runs for All projects
      this.testRunService.getTestRuns({}).subscribe(TestRuns => {
        this.TestRuns = TestRuns;
        this.getProjectName();
        this.data = TestRuns
      });
    } else {
      this.displayedColumns = ['id', 'title', 'description', 'createdDate', 'modifiedDate', 'trStatus', 'defects'];
      this.testSuitService.getTestSuits({ project_id: Core.localStorageService.getItem('selected_project') }).subscribe(testSuits => {
        this.testSuits = testSuits;
      });
      // if particular project selected, we pass project id as body of request to get runs for this project
      this.testRunService.getTestRuns({ project_id: Core.localStorageService.getItem('selected_project') }).subscribe(TestRuns => {
        this.TestRuns = TestRuns;
        this.getProjectName();
        this.data = TestRuns
      });
    }
  }

  openCreateDialog(): void {
    const dialogRef = this.dialog.open(DialogCreateTestRunComponent, {
      width: '650px',
      data: { testSuits: this.testSuits }
    });
  }


  navigateToDetail(row: TestRun) {
    this.router.navigate(['test-run', row.id]);
  }

  getProjectName() {
    this.TestRuns.map(run => {
      this.projects.map(project => {
        if (project.id === run.projectId) {
          run.projectName = project.title;
        }
      })
      // Setting isOpen to false for all tooltips
      run.isOpen = false;
    })
  }

  onCreationMode(): void {
    this.router.navigate(['test-cases']).then(() => { });
  }

  goToDefectsPage(testRun: TestRun) {
    this.router.navigate(['test-run-defects', testRun.id]).then(() => { });
  }

}
