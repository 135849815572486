import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params } from '@angular/router';
import { TestCase } from 'src/app/services/test-case.type';
import { TestStepService } from 'src/app/services/test-step.service';
import { TestStep } from 'src/app/services/test-step.type';

@Component({
  selector: 'app-card-test-plan-step',
  templateUrl: './card-test-plan-step.component.html',
  styleUrls: ['./card-test-plan-step.component.scss']
})
export class CardTestPlanStepComponent implements OnInit {

  private _testCase: TestCase;
  public testPlanStep: TestStep[];
  private _testPlanStep: TestStep[];
  public isLoading = true;
  @Input() public shortPlan: boolean = false;
  @Input() public shortCases: boolean = false;
  @Input() queryParams: Params;

  @ViewChildren('panel') private panelSteps: QueryList<MatExpansionPanel>;

  @Input() set testCase(data: TestCase) {
    if (data) {
      this._testCase = data;
      this.getTestPlanStep(data.testCaseId)
    }
  } get testCase() {
    return this._testCase
  }

  @Output() closeEvent = new EventEmitter()

  constructor(
    private testStepService: TestStepService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  searchPath(param: Params) {
    let index: number;
    let find = this.testPlanStep.find((elem: TestStep, i: number) => {
      if (+elem.id === +param.ts) index = i;
      return +elem.id === +param.ts
    });
    if (find) {
      let sub = this.panelSteps.changes.subscribe(res => {
        res.forEach((elem: MatExpansionPanel, i: number) => { if (i === index) elem.expanded = true });
        if (sub) sub.unsubscribe();
      })
    }
  }

  getTestPlanStep(id: string) {
    this.isLoading = true;
    this.testPlanStep = [];
    this.testStepService.getTestSteps(id).subscribe(res => {
      this.isLoading = false
      this.testPlanStep = this.createTestStepForm(res);
      this._testPlanStep = res;
      if (this.queryParams.ts) this.searchPath(this.queryParams);
    })
  }

  createTestStepForm(testStep: TestStep[]): TestStep[] {
    return testStep.map(step => {
      step['form'] = this.createForm(step);
      return step
    })
  }

  createForm(testStep: TestStep) {
    return new FormGroup({
      id: new FormControl(testStep.id),
      description: new FormControl(testStep.description, [Validators.required]),
      expectedResult: new FormControl(testStep.expectedResult, [Validators.required])
    })
  }

  reloadTestStep() {
    this.getTestPlanStep(this._testCase.testCaseId)
  }

  updateStep(value: TestStep) {
    this.testStepService.saveStep(value).subscribe(res => {
      if (res) {
        this.snackBar.open('Succefully', 'OK', { duration: 3000 })
        this.reloadTestStep()
      } else {
        this.snackBar.open('Error', 'ERROR', { duration: 5000 });
      }
    })
  }

  deleteStep(value: TestStep) {
    this.testStepService.deleteTestStep(value.id).subscribe(res => {
      if (res) {
        this.snackBar.open('Succefully', 'OK', { duration: 3000 })
        this.reloadTestStep();
      } else {
        this.snackBar.open('Error', 'ERROR', { duration: 5000 });
      }
    })
  }

}
