export class LocalStorageService {
    constructor(type) {
        this.type = type;
    }
    clear() {
        switch (this.type) {
            case 'web':
                this.clearWeb();
                break;
            case 'mobile':
                this.clearMobile();
                break;
        }
    }
    setItem(itemName, itemData) {
        switch (this.type) {
            case 'web':
                this.setItemWeb(itemName, itemData);
                break;
            case 'mobile':
                this.setItemMobile(itemName, itemData);
                break;
        }
    }
    getItem(itemName) {
        switch (this.type) {
            case 'web':
                return this.getItemWeb(itemName);
            case 'mobile':
                return this.getItemMobile(itemName);
        }
    }
    removeItem(itemName) {
        switch (this.type) {
            case 'web':
                return this.removeItemWeb(itemName);
            case 'mobile':
            // return this.removeItemMobile(itemName);
        }
    }
    // Web local storage methods
    setItemWeb(itemName, itemData) {
        localStorage.setItem(itemName, itemData);
    }
    getItemWeb(itemName) {
        return localStorage.getItem(itemName);
    }
    removeItemWeb(itemName) {
        return localStorage.removeItem(itemName);
    }
    clearWeb() {
        return localStorage.clear();
    }
    clearMobile() {
    }
}
