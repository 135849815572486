<h3 class="table-title">Latest statistics table of Test Runs</h3>

<div class="table-container">
    <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="sizeOptions"
                   aria-label="Select page"></mat-paginator>

    <table mat-table [dataSource]="dataSource" class="test-run-table mat-elevation-z8">

        <!--        <ng-container *ngIf="true">-->
        <!--            <td>-->
        <!--                <ngx-skeleton-loader [theme]="{ height: '30px', background: '#D6DBDF', margin: '10px 0px 0px 0px' }" [count]="1" appearance="line"></ngx-skeleton-loader>-->
        <!--            </td>-->
        <!--        </ng-container>-->

        <ng-container matColumnDef="Project">
            <th mat-header-cell *matHeaderCellDef> Project</th>
            <td mat-cell *matCellDef="let element">
                {{ element.projectName }} </td>
        </ng-container>

        <ng-container matColumnDef="Test Run">
            <th mat-header-cell *matHeaderCellDef> Test Run</th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="Created Date">
            <th mat-header-cell *matHeaderCellDef> Modification Date

            </th>
            <td mat-cell *matCellDef="let element"> {{element.modifiedDate}} </td>
        </ng-container>

        <ng-container matColumnDef="Pass/From">
            <th mat-header-cell *matHeaderCellDef> Pass/From</th>
            <td mat-cell *matCellDef="let element">
                <div>
                    {{ element.passCount.count || 0 }} / {{element.testCasesCount}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Created By">
            <th mat-header-cell *matHeaderCellDef> Created By</th>
            <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <ng-container *ngIf="isLoading">
        <ul class="loader-ul">
            <li *ngFor="let item of getRange(sizeOptions[0])" class="loader-item">
                <ngx-skeleton-loader [theme]="{ height: '40px', background: '#D6DBDF', margin: '0px 0px 0px 0px' }"
                                     [count]="1" appearance="line"></ngx-skeleton-loader>
            </li>
        </ul>
    </ng-container>


</div>
