<app-base-card [title]="'Test Steps'" [icon]="'date_range'" [showAdd]="false" [showClose]="true"
  (closeEvent)="closeEvent.emit()">
  <mat-accordion>
    <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
      appearance="line"></ngx-skeleton-loader>
    <mat-expansion-panel #panel *ngFor="let testStep of testSteps; index as i"
      class="list-card__item item-list item-list-accordion" (click)="chooseStep(testStep, panel)">
      <mat-expansion-panel-header>
        <mat-panel-title class="item-list-accordion__title">
          <span>{{testStep.description}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="item-list-accordion__info info-item-list">
        <app-test-step-form (onUpdateStep)="updateStep($event)" (onDeleteStep)="onDeleteStep($event)"
          [form]="testStep.form" [queryParams]="queryParams"></app-test-step-form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div *ngIf="!isLoading" class="card__footer footer-card footer-card-add-steps">
    <ng-container *ngIf="showAddNewTestStepForm">
      <div class="footer-card__form form-footer-card">
        <div class="form-footer-card__wrap">
          <app-test-step-form [eventAdd]="true" [eventEdit]="false" [eventDelete]="false"
            (onAddStep)="createTestStep($event)" (onCloseForm)="closeTestStepForm($event)" [form]="testStepForm"
            [queryParams]="queryParams"></app-test-step-form>
        </div>
      </div>
    </ng-container>
    <div class="footer-card__buttons">
      <div class="footer-card__button footer-card__button-100">
        <ng-container *ngIf="!showAddNewTestStepForm">
          <button [disabled]="!addAccessAction" mat-raised-button color="primary" (click)="onAddNewTestStep()">Add New
            Test Step</button>
        </ng-container>
      </div>
    </div>
  </div>

</app-base-card>