import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(value: unknown, ...args: number[]): unknown {
    if (typeof value === 'string' && value.length > Math.floor(args[0])) {
      value = value.slice(0, (+args[0] - 4) >= 2 ? (+args[0] - 4) : 2);
      return value + ' ...';
    }
    return value;
  }
}
