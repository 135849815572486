<app-base-card [title]="'Test Suits'" [icon]="'work'" [showAdd]="addAccessAction" [collapseTitle]="true"
  (addEvent)="onAddEvent()" [showCollapse]="selectedValue && showCase" (collapseEvent)="collapseEvent.emit('TestSuit')"
  (collapseTitleEvent)="this.showSection = !this.showSection" [showSection]="showSection" [showSearch]="true"
  [showSort]="true" [sortText]="'Sort By'" [baseSortValue]="baseSortValue" [sortSelectData]="sortSelectData"
  (searchEvent)="search = $event" [shortForm]="shortForm" [showPage]="showSuit"
  (selectSortEvent)="sortTestSuitByEvent($event)">

  <mat-selection-list #selectionList (selectionChange)="selectionChange($event)">
    <mat-list-option #option *ngFor="let item of (testSuits | searchPipe: search)" [value]="item"
      class="list-card__item item-list" [selected]="item.isOpen" [routerLink]="'./'"
      [queryParams]="{ project: item.projectId, type: 'ts', id: item.id}">
      <div class="item-list__body">
        <div class="item-list__title flex">
          <div [style.opacity]="(pending && activeTestSuit === item) ? .2 : 1">{{item.title}}</div>
          <div *ngIf="pending && activeTestSuit === item" class="loading">
            <img src="../../../assets/image/loading.gif" alt="pending">
          </div>
        </div>
        <div class="item-list__action" *ngIf="!guestAccessAction">
          <div class="item-list__menu">
            <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation
              [disabled]="pending && activeTestSuit === item">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button [disabled]="!addTestCaseAccessAction" mat-menu-item (click)="onSelectEvent(item)">
                <span>Select test cases</span>
              </button>
              <button [disabled]="!addRunFromSuitAccessAction" mat-menu-item (click)="toTestRun(item)">
                <span>Create test run</span>
              </button>
              <button [disabled]="!editAccessAction" mat-menu-item (click)="onEditEvent(item)">
                <span>Edit</span>
              </button>
              <button [disabled]="!deleteAccessAction" mat-menu-item (click)="onDeleteEvent(item)">
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="item-list__right">
          <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
            stopPropagation *ngIf="selectedValue === item">
            <mat-icon>link</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="Infomenu" stopPropagation>
            <mat-icon>info</mat-icon>
          </button>
          <mat-menu #Infomenu="matMenu" class="info-menu">
            <div class="info-menu-item" mat-menu-item>
              <span>Created Date: {{item.createdDate}}</span>
            </div>
            <div class="info-menu-item" mat-menu-item>
              <span>Modified Date: {{item.modifiedDate}}</span>
            </div>
            <div class="info-menu-item" mat-menu-item>
              <span>Create By: {{getUserName(item.creator_id)}}</span>
            </div>
          </mat-menu>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>

</app-base-card>