import { Component, Input, OnInit } from '@angular/core';
import { TestCase } from 'src/app/services/test-case.type';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestRun } from 'src/app/services/test-run.type';
import { TestStep } from 'src/app/services/test-step.type';
import { TestSuit } from 'src/app/services/test-suit.type';
import { appTypes } from '../../services/app-types';

@Component({
  selector: 'app-sub-lists',
  templateUrl: './sub-lists.component.html',
  styleUrls: ['./sub-lists.component.scss']
})
export class SubListsComponent implements OnInit {

  @Input() data: TestRun[] | TestPlan[] | TestSuit[] | TestCase[] | TestStep[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
