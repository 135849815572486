<app-base-card [title]="'Project'" [icon]="'description'" [showAdd]="addUserAccessAction" [showSearch]="true"
  (addEvent)="onAddEvent()" (searchEvent)="search = $event">
  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
    appearance="line"></ngx-skeleton-loader>

  <mat-selection-list #selectionList (selectionChange)="selectionChange($event)">
    <mat-list-option #option *ngFor="let item of (projects | searchPipe: search)" [value]="item"
      class="list-card__item item-list" [selected]="item.isOpen">
      <div class="item-list__body">
        <div class="item-list__body-content">
          <div class="item-list__title flex">
            <div [style.opacity]="(pending && pendingItem === item) ? .2 : 1">
              <span>{{item.title}}</span>
            </div>
            <div *ngIf="pending && pendingItem === item" class="loading">
              <img src="../../../assets/image/loading.gif" alt="pending">
            </div>
          </div>
        </div>
        <div class="item-list__action" *ngIf="editAccessAction">
          <div class="item-list__menu">
            <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation [disabled]="pending">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="addUserAccessAction" mat-menu-item (click)="onSelectUsers(item)">
                <span>Select User</span>
              </button>
              <button mat-menu-item (click)="onEditEvent(item)">
                <span>Edit</span>
              </button>
              <button *ngIf="deleteAccessAction" mat-menu-item (click)="onDeleteEvent(item)">
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>

</app-base-card>