<app-base-card [title]="'Test Case'" [casesFromRun]="true" [icon]="'dns'" [showAdd]="addAccessAction" [showClose]="true"
  [shortForm]="shortForm" (closeEvent)="closeEvent.emit()" [showCollapse]="showStep"
  (collapseEvent)="collapseEvent.emit('TestRunCase')" (addEvent)="onAddEvent()">
  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF', margin: '5px 0px 0px 0px' }"
    count="3" appearance="line"></ngx-skeleton-loader>
  <div class="main-container width-100">
    <mat-selection-list #selection class="list" (selectionChange)="selectionChange($event)">
      <mat-list-option #option *ngFor="let item of testRunCases" [value]="item" class="list-card__item item-list"
        [class.item-list-to-do]="item.status === '349'" [class.item-list-block]="item.status === '351'"
        [class.item-list-pass]="item.status === '352'" [class.item-list-fail]="item.status === '353'"
        [selected]="item.isOpen" [routerLink]="'./'"
        [queryParams]="{ project: queryParams.project, type: queryParams.type, id: queryParams.id, tc: item.testCaseId}">
        <div [class]="shortFormRun ? 'item-list__body expanded' : guestAccessAction ? 'relative' : 'item-list__body'">
          <div class="item-list__title flex">
            <div [style.opacity]="(pending && deletedItem === item) ? .2 : 1">
              <span class="additional" [style.paddingRight.px]="15">{{item.testCaseId}}</span>
              <span>{{item.title}}</span>
              <span *ngIf="item.copyCount > 0" class="item-list__title item-list__title--plan">
                ({{item.copyCount}})
              </span>
            </div>
            <div *ngIf="pending && deletedItem === item" class="loading">
              <img src="../../../assets/image/loading.gif" alt="pending">
            </div>
          </div>
          <div class="item-list__action">
            <ng-container *ngIf="!shortFormRun">
              <div class="item-list__right absolute" *ngIf="guestAccessAction">
                <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
                  *ngIf="selectedValue === item && !queryParams.ts" stopPropagation>
                  <mat-icon>link</mat-icon>
                </button>
              </div>
              <div class="item-list__menu" *ngIf="!guestAccessAction">
                <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button [disabled]="!changeStatusAccessAction" mat-menu-item (click)="onChangeStatusEvent(item)">
                    <span>Change Status</span>
                  </button>
                  <button [disabled]="!assignedAccessAction" mat-menu-item (click)="onAssignedToEvent(item)">
                    <span>Assigned To</span>
                  </button>
                  <button [disabled]="!deleteCaseAccessAction" mat-menu-item (click)="onDeleteCaseEvent(item)">
                    <span>Delete</span>
                  </button>
                  <button mat-menu-item (click)="copyLink()" *ngIf="selectedValue === item">
                    <span>Copy link</span>
                  </button>
                </mat-menu>
              </div>
            </ng-container>
            <ng-container *ngIf="shortFormRun">
              <div class="item-list__menu-custom">
                <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
                  *ngIf="selectedValue === item && !queryParams.ts" stopPropagation>
                  <mat-icon>link</mat-icon>
                </button>
                <button [disabled]="!changeStatusAccessAction" mat-icon-button class="item"
                  (click)="onChangeStatusEvent(item)" stopPropagation>
                  <span>{{getStatusItem(item.status).name | shortName:11}}</span>
                  <div class="circle" [style.background]="getStatusItem(item.status).color"></div>
                </button>
                <button [disabled]="!assignedAccessAction" mat-icon-button class="item"
                  (click)="onAssignedToEvent(item)" stopPropagation>
                  <mat-icon *ngIf="item.assignedTo === '-1'">assignment_ind</mat-icon>
                  <span *ngIf="item.assignedTo !== '-1'">{{getAssigneTo(item.assignedTo) | shortName:11}}</span>
                </button>
                <button *ngIf="deleteCaseAccessAction" mat-icon-button class="item small"
                  (click)="onDeleteCaseEvent(item)" stopPropagation>
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
    <div class="not-found" *ngIf="!(isLoading || testRunCases.length)">Test cases not found...</div>
  </div>
</app-base-card>