import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ApiBaseService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = this.getApiUrl();
  }

  request<T>(method: string, body: any): Observable<T> {
    return this.http.post<T>(this.apiUrl + method, body,
      { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }) });
  }

  getApiUrl(): any {
    switch (window.location.hostname) {
      case 'localhost':
        return 'https://api.testyy.org/test_point/';
      case 'www.testyy.org':
        return 'https://api.testyy.org/test_point/';
      case 'demo.testyy.org':
        return 'https://demoapi.testyy.org/test_point/';
      case 'produi.requesthire.com':
        return 'https://prod.requesthire.com/test_point/';
      case 'devui.requesthire.com':
        return 'https://dev.requesthire.com/test_point/';
      case 'ang.requesthire.com':
        return 'https://dev.requesthire.com/test_point/';
    }
  }

  getApi(): any {
    switch (window.location.hostname) {
      case 'localhost':
        return 'https://dev.requesthire.com';
      case 'testyy.org':
        return 'https://api.testyy.org';
      case 'demo.testyy.org':
        return 'https://demoapi.testyy.org';
      case 'produi.requesthire.com':
        return 'https://prod.requesthire.com';
      case 'devui.requesthire.com':
        return 'https://dev.requesthire.com';
      case 'ang.requesthire.com':
        return 'https://dev.requesthire.com';
    }
  }
}

