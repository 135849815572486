<app-base-page>
  <div class="main-page">
    <div class="main-page__row">
      <div class="main-page__column sticky" [class.main-page__column-50]="showProjectsUser">
        <app-card-projects (changeProject)="changeCurrentProject()" (deleteProject)="deleteCurrentProject()"
          (showProjectsUser)="showProjectUser($event)" (updateUsers)="updateUsers($event)"
          (closeInfo)="closeProjectUser()"></app-card-projects>
      </div>
      <ng-container *ngIf="showProjectsUser">
        <div class="main-page__column main-page__column-50 sticky">
          <app-card-projects-info [project]="selectedProject" (closeEvent)="closeProjectUser()"
            (updateProjects)="updateProjects()"></app-card-projects-info>
          <app-card-projects-users [project]="selectedProject"
            (closeEvent)="closeProjectUser()"></app-card-projects-users>
        </div>
      </ng-container>
    </div>
  </div>
</app-base-page>