<mat-list class="main-list">
  <mat-list-item class="main-list__title">
    <mat-icon *ngIf="currentType === this.appTypes.testRun">directions_run</mat-icon>
    <mat-icon *ngIf="currentType === this.appTypes.testSuit">work</mat-icon>
    <mat-icon *ngIf="currentType === this.appTypes.testPlan">date_range</mat-icon> 
    <mat-icon *ngIf="currentType === this.appTypes.testCase">dns</mat-icon> 
    <mat-icon *ngIf="currentType === this.appTypes.testStep">hourglass_top</mat-icon>
    <p>{{currentType}}s</p>
  </mat-list-item>
  <mat-list-item 
    *ngFor="let item of data"
    class="main-list__item">
    <p>{{item.title || item.description}}</p>
  </mat-list-item>
</mat-list>

