<div class="dialog-title">
  <h1 mat-dialog-title>report test Case fail</h1>
</div>
<form [formGroup]="failForm">
  <div class="dialog-content" mat-dialog-content>
    <div class="required-field">
      <mat-form-field appearance="outline" floatLabel="auto" class="full-width">
        <mat-label>Test Step</mat-label>
        <mat-select class="custom-select" formControlName="step">
          <mat-option *ngFor="let step of testSteps" [value]="step.id">{{ step.description }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.step.hasError('required')">
          Step is required
        </mat-error>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" floatLabel="auto" class="full-width">
      <mat-label>Defect Description</mat-label>
      <textarea class="noscroll-textarea" formControlName="description" matInput></textarea>
      <mat-error *ngIf="f.description.hasError('required')">
        Description is required
      </mat-error>
    </mat-form-field>

    <mat-checkbox [checked]="checked" (change)="onChange()">Create defect (Jira)</mat-checkbox>

  </div>
</form>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="null" cdkFocusInitial>cancel</button>
  <button mat-raised-button color="primary" style="background: #2d64ba" [disabled]="failForm.invalid" (click)="onSubmit()"
    cdkFocusInitial>submit</button>
</div>