import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { TestStep } from 'src/app/services/test-step.type';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TestCase } from "../../services/test-case.type";
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-test-step-form',
  templateUrl: './test-step-form.component.html',
  styleUrls: ['./test-step-form.component.scss']
})
export class TestStepFormComponent implements OnInit {
  public editAccessAction: boolean = this._userAccessService.getAccess('testStep', 'testStepEditLevel');
  public deleteAccessAction: boolean = this._userAccessService.getAccess('testStep', 'testStepDeleteLevel');

  public descrSize: boolean = false;
  public expSize: boolean = false;

  @Input() public form: FormGroup;
  @Input() public eventAdd: boolean = false;
  @Input() public eventEdit: boolean = true;
  @Input() public eventDelete: boolean = true;
  @Input() public shortForm: boolean = false;
  @Input() public testStep: TestStep;
  @Input() public queryParams: Params;
  @Input() public currentTestCase: TestCase;

  @Output() onCloseForm = new EventEmitter<boolean>();
  @Output() onAddStep = new EventEmitter<TestStep>();
  @Output() onDeleteStep = new EventEmitter<TestStep>();
  @Output() onUpdateStep = new EventEmitter<TestStep>();

  constructor(
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void {
    if (this.form === undefined) throw new Error('Form Group is required. Pls send form to component');
  }

  textHeightFunc(text: HTMLTextAreaElement): string {
    return `${text.scrollHeight}px`;
  }

  updateStep() {
    const value: TestStep = this.form.value;
    this.onUpdateStep.emit(value);
  }

  deleteStep() {
    const value: TestStep = this.form.value;
    this.onDeleteStep.emit(value);
  }

  addStep() {
    const value: TestStep = this.form.value;
    this.onAddStep.emit(value);
  }

  closeForm() {
    this.onCloseForm.emit(true);
  }


  copyLink() {
    if (window.location.href) {
      let queryParamArr = window.location.href.split('?')[1]?.split('&');
      let params = queryParamArr?.map(elem => elem.split('='))?.reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

      if (!!params['creationMode']) this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=tcs&creationMode=${params['creationMode']}&sub=${params['sub']}&level=${params['level']}&tc=${params['tc']}&ts=${params['ts']}`);
      else if (params['tcp']) this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=${params['type']}&id=${params['id']}&sub=${params['sub']}&level=${params['level']}&tcp=${params['tcp']}&ts=${this.testStep.id}`);
      else if (params['tc']) this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=${params['type']}&id=${params['id']}&sub=${params['sub']}&level=${params['level']}&tc=${params['tc']}&ts=${params['ts']}`);
      else this.clipboard.copy(window.location.href);
      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
    }
  }
}
