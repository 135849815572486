const plansList = 'testPlan/list', plansRead = 'testPlan/read', plansCreate = 'testPlan/create', plansUpdate = 'testPlan/update', plansDelete = 'testPlan/delete', addTestCases = 'testPlan/addTestCases', updateRelation = 'testPlan/updateRelation', createRunFromPlan = 'testPlan/createTestRun', updateTestCasesList = 'testPlan/updateTestCasesList', plansToRun = 'testPlan/addTestRunWithPlan';
export class TestPlanControllers {
    constructor(api) {
        this.api = api;
    }
    //get testRuns list
    list(payload) {
        return this.api.postRequest(plansList, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //read testRun list
    readList(payload) {
        return this.api.postRequest(plansRead, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //create testRun
    create(payload) {
        return this.api.postRequest(plansCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update testRun
    update(payload) {
        return this.api.postRequest(plansUpdate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete testRun
    delete(payload) {
        return this.api.postRequest(plansDelete, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //add testCases
    addTestCases(payload) {
        return this.api.postRequest(addTestCases, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update Relation
    updateRelation(payload) {
        return this.api.postRequest(updateRelation, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //create test run
    createTestRunFromPlan(payload) {
        return this.api.postRequest(createRunFromPlan, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update TestCase list
    updateTestCasesList(payload) {
        return this.api.postRequest(updateTestCasesList, payload).then(res => {
            if (res.data.valid === 'true')
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update TestCase list
    plansToRun(payload) {
        return this.api.postRequest(plansToRun, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
}
