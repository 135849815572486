import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { TestRunService } from '../../../services/test-run.service';
import { ProjectService } from '../../../services/project.service';
import { BaseChartDirective } from 'ng2-charts';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-chart-diagrams',
  templateUrl: './chart-diagrams.component.html',
  styleUrls: ['./chart-diagrams.component.scss']
})

export class ChartDiagramsComponent implements OnInit {
  @ViewChild(BaseChartDirective) public diagramChart: BaseChartDirective | undefined;
  @Input() public currentProject;

  constructor(
    private testRunService: TestRunService,
    private projectService: ProjectService,
  ) { }

  public chart: any;
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  public pieChartLabels = [];
  public pieChartDatasets = [{
    data: [],
    backgroundColor: [],
  }];

  public testRunStatuses = [];
  public statusesListObj = {};
  public titleText = 'Test Runs of this project';

  ngOnInit() {
    this.initData();
  }

  fetchData(project) {
    const statusColor$ = this.projectService.getProjectStatuses(project);
    const data$ = this.testRunService.getTestRuns({ project_id: project });

    forkJoin([statusColor$, data$]).subscribe(results => {
      this.updateData(results[1], results[0]);
    });
  }

  updateData(dataResult, statusColorResult) {
    dataResult.forEach(item => this.testRunStatuses.push(...item.statuses));
    statusColorResult.forEach(elem => this.statusesListObj[elem.name] = 0);

    this.testRunStatuses.forEach(item => {
      this.statusesListObj[item.name] += +item.count;
    });
    this.pieChartDatasets[0].data = Object.values(this.statusesListObj);
    this.pieChartLabels = Object.keys(this.statusesListObj);

    if (this.pieChartDatasets[0].data.some(elem => elem > 0)) {
      this.titleText = 'Test Runs of this project';
      for (let key in this.statusesListObj) {
        this.pieChartDatasets[0].backgroundColor.push(statusColorResult.find(elem => elem.name === key)?.color);
      };
    } else {
      this.titleText = 'This project does not have Test Runs';
      this.pieChartDatasets[0].data.push(100);
      this.pieChartLabels = ['No Data'];
      this.pieChartDatasets[0].backgroundColor = ['#808080'];
    }

    if (this.diagramChart) {
      this.diagramChart.update();
    }
  }

  initData(project = null) {
    this.testRunStatuses = [];
    this.statusesListObj = {};
    this.pieChartDatasets[0].data = [];
    this.pieChartDatasets[0].backgroundColor = [];
    this.pieChartLabels = [];

    if (project) this.currentProject = project;
    else this.currentProject = this.projectService.getSelectedProjectFromStorage();
    this.fetchData(this.currentProject)
  }

}
