import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogCreateJiraIntegrationSettingsComponent } from '../../dialogs/dialog-create-jira-integration-settings/dialog-create-jira-integration-settings.component';
import { JiraIntegrationSetting, createJiraIntegrationSettingParams } from '../../services/jira-integration.type';
import { JiraIntegrationService } from '../../services/jira-integration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from '../../services/project.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DefaultStatuses } from 'src/app/services/default-statuses.type';
import { MatTableDataSource } from '@angular/material/table';
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-card-jira-integration',
  templateUrl: './card-jira-integration.component.html',
  styleUrls: ['./card-jira-integration.component.scss']
})
export class CardJiraIntegrationComponent implements OnInit {
  public jiraIntegrationSettings: JiraIntegrationSetting[];
  public isLoading: boolean = false;

  public dataSource: MatTableDataSource<JiraIntegrationSetting>;
  private paginator: MatPaginator;
  private sort: MatSort;
  public displayedColumns: string[] = ['id', 'project_title', 'service_name', 'basic_url', 'project_key', 'auth_data', 'actions'];
  public defaultStatuses = new DefaultStatuses();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if (ms) this.sort = ms;
    if (this.dataSource) this.dataSource.sort = this.sort;

  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (mp) this.paginator = mp;
    if (this.dataSource) this.dataSource.paginator = this.paginator;
  }

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    private jiraIntegrationService: JiraIntegrationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getJiraIntegrationSettings();
  }

  public getJiraIntegrationSettings() {
    this.jiraIntegrationService.listJiraIntegrationSettings({ project_id: this.projectService.getSelectedProjectFromStorage() }).subscribe(res => {
      if (res.valid) {
        this.jiraIntegrationSettings = res.data;

        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.data.forEach(elem => {
          let authData = (elem.auth_data as string).split(':');
          elem.auth_data = { email: authData[0], token: authData[1] }
        })
      } else {
        this.snackBar.open(res.msg, "OK", { duration: 3000 });
        this.jiraIntegrationSettings = [];
      };
      this.isLoading = true;
    });
  }

  public onAddEvent() {
    this.dialog.open(DialogCreateJiraIntegrationSettingsComponent, {
      width: '650px',
      data: { action: 'Create' }
    }).afterClosed().subscribe((response: createJiraIntegrationSettingParams) => {
      if (response) {
        this.jiraIntegrationService.createJiraIntegrationSetting(response).subscribe(res => {
          if (res.valid) {
            this.snackBar.open('Jira integration setting was successfully created', "OK", { duration: 3000 });
            this.getJiraIntegrationSettings();
          } else this.snackBar.open(res.msg ? res.msg : 'Error', "OK", { duration: 3000 });
        });
      }
    })
  }

  public onEditEvent(setting: JiraIntegrationSetting) {
    this.dialog.open(DialogCreateJiraIntegrationSettingsComponent, {
      width: '650px',
      data: { action: 'Update', data: setting }
    }).afterClosed().subscribe((response: createJiraIntegrationSettingParams) => {
      if (response) {
        this.jiraIntegrationService.updateJiraIntegrationSetting(response).subscribe(res => {
          if (res.valid) {
            this.snackBar.open('Jira integration setting was successfully updated', "OK", { duration: 3000 });
            this.getJiraIntegrationSettings();
          } else this.snackBar.open(res.msg ? res.msg : 'Error', "OK", { duration: 3000 });
        });
      }
    })
  }

  public onDeleteEvent(setting: JiraIntegrationSetting) {
    this.dialog.open(DialogConfirmComponent, {
      width: '650px',
      data: { dialogTitle: 'delete', name: `jira integration setting for ${setting.project_name}` }
    }).afterClosed().subscribe((response: createJiraIntegrationSettingParams) => {
      if (response) {
        this.jiraIntegrationService.deleteJiraIntegrationSetting({ project_id: setting.project_id }).subscribe(res => {
          if (res.valid) {
            this.snackBar.open('Jira integration setting was successfully deleted', "OK", { duration: 3000 });
            this.getJiraIntegrationSettings();
          } else this.snackBar.open(res.error_msg ? res.error_msg : 'Error', "OK", { duration: 3000 });
        });
      }
    })
  }
}
