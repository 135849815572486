import { Injectable } from '@angular/core';
import { TestCase } from './test-case.type';
import { BehaviorSubject, from, Observable, } from 'rxjs';
import { map } from 'rxjs/operators';
import { TestStep } from './test-step.type';
import { Status } from './status.type';
import { Core } from './core.service';
import { CardTestCasesComponent } from '../test-cases-page/card-test-cases/card-test-cases.component';
import { CardUnesignedCasesComponent } from '../unesigned-cases-page/card-unesigned-cases/card-unesigned-cases.component';


@Injectable({
  providedIn: 'root'
})
export class TestCaseService {
  private testCases: TestCase[];
  private activeComponent: { type: string, data: CardTestCasesComponent | CardUnesignedCasesComponent } = null;
  public updateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  eventUpdateTestCase$ = this.updateSubject.asObservable();

  public isRightSectionOpen = new BehaviorSubject<boolean>(false);
  isRightSectionOpen$ = this.isRightSectionOpen.asObservable();

  constructor() { }

  updateTestCase(testCase?: TestCase) {
    this.updateSubject.next(testCase)
  }

  getTestCases(projectId): Observable<TestCase[]> {
    this.testCases = [];
    let param: {};

    if (projectId === 'null' || projectId === 'all') param = {};
    else param = { project_id: projectId };

    return from(Core.testCase.allList(param).catch(error => console.error(error)))
      .pipe(map((val: any) => {
        if (+val.valid === 1) {
          const c = this.mapAllTestCases(val.test_cases);

          if (projectId !== 'null' && projectId !== 'all') {
            c.forEach(elem => {
              +elem.projectId === +projectId ? this.testCases.push(elem) : ''
            })
            return this.testCases;
          } else return c

        } else {
          return [];
        }
      }));
  }


  getTestCasesByTestRun(body): Observable<TestCase[]> {
    return from(Core.testCase.listByTestRun(body).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        this.testCases = this.mapTestCases(res.test_cases);;
        return this.testCases;
      } else return []
    }))
  }

  getTestCasesByTestSuit(body): Observable<TestCase[]> {
    return from(Core.testCase.listByTestSuit(body).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        this.testCases = this.mapTestCases(res.test_cases);
        return this.testCases;
      } else return []
    }))
  }

  getTestCase(body): Observable<TestCase> {
    return from(Core.testCase.read(body).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        return this.mapTestCase(res.test_case)
      } else return null
    }))
  }

  getTestCaseStatuses(body): Observable<Status[]> {
    if (body.project_id === 'all' || body.project_id === 'null') body = {}
    return from(Core.projects.statusesList(body).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestCaseStatuses(res.test_case_statuses);
      else return []
    }))
  }

  saveTestCase(testCase, suitLevel = null): Observable<TestCase> {
    return from(Core.testCase.update({ testCase, suitLevel }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestCase(res.test_case);
      else return null
    }))
  }

  createTestCase(testCase: TestCase, testSteps: TestStep[], project_id = null, suitLevel = null): Observable<TestCase> {
    return from(Core.testCase.create({ testCase, testSteps, project_id, suitLevel }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestCase(res.test_case);
      else return new TestCase();
    }))
  }

  deleteTestCase(params): Observable<boolean> {
    return from(Core.testCase.delete(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  getUnesignedTestCases(projectId): Observable<TestCase[]> {
    let param: {}
    if (projectId === 'null' || projectId === 'all') param = {}
    else param = { project_id: projectId }

    return from(Core.testCase.unesignedList(param).catch(error => console.error(error))).pipe(map(res => {
      if (+res.valid === 1) return res.data.map(item => this.mapTestCase(item));
      else return [];
    }));
  }

  setActiveComponent(data: CardTestCasesComponent | CardUnesignedCasesComponent, type: string = null) {
    this.activeComponent = { type, data };
  }

  getActiveComponent(): { type: string, data: CardTestCasesComponent | CardUnesignedCasesComponent } {
    return this.activeComponent;
  }

  independentCopy(params): Observable<boolean> {
    return from(Core.testCase.independentCopy(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  getAllRelations(body): Observable<TestCase[]> {
    return from(Core.testCase.getAllRelations(body).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        return res.data
      } else return []
    }))
  }

  mapTestCases(raw: any[]): TestCase[] {
    return raw.map((val: any) => this.mapTestCase(val));
  }

  mapAllTestCases(raw: any[]): TestCase[] {
    const testCases = [];
    for (const val in raw) {
      if (raw.hasOwnProperty(val)) {
        raw[val].map(testCase => {
          // Reassigning all keys from tc object into test case for conveniance
          for (const key in testCase.tc) {
            if (testCase.tc.hasOwnProperty(key)) {
              testCase[key] = testCase.tc[key]
            }
          }
          delete testCase.tc;
          testCases.push(testCase);
        })
      }
    }
    // Mapping test Cases
    return testCases.map((res: any) => this.mapTestCase(res));
  }

  mapTestCase(raw: any): TestCase {
    const result: TestCase = new TestCase();

    result.id = raw.id;
    result.testSuitFolderId = raw.test_suit_folder_id;
    result.tsId = raw.ts_id;
    result.tpId = raw.tp_id;
    result.copyCount = raw.copy_count;
    result.testSuitId = raw.test_suit_id;
    result.title = raw.title;
    result.description = raw.description;
    result.createdDate = raw.created_date;
    result.modifiedDate = raw.modified_date;
    result.testCaseId = raw.test_case_id;
    result.status = raw.status;
    result.assignedTo = raw.assigned_to;
    result.projectId = raw.project_id;
    result.relationId = raw.relation_id;
    result.creator_id = raw.creator_id;
    result.tesSuitRelationId = raw.test_suit_relation_id;
    result.independent = raw.independent;
    result.oldTcId = raw.old_tc_id;

    if (raw.testSuits !== undefined) {
      result.testSuits = raw.testSuits;
    };

    return result;
  }

  mapTestCaseStatuses(raw: any[]): Status[] {
    return raw.map((val: any) => this.mapTestCaseStatus(val));
  }

  mapTestCaseStatus(raw: any): Status {
    const result: Status = new Status();

    result.id = raw.id;
    result.name = raw.name;
    result.color = raw.color;
    result.isDefault = raw.is_default;
    result.ProjectId = raw.project_id;
    result.sortOrder = raw.sort_order;
    result.statusState = raw.status_state;

    return result;
  }

  updateRightSectionOpen(value) {
    this.isRightSectionOpen.next(value)
  }

}
