import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-for-testrun',
  templateUrl: './dialog-confirm-for-testrun.component.html',
  styleUrls: ['./dialog-confirm-for-testrun.component.scss']
})
export class DialogConfirmForTestrunComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmForTestrunComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string },
  ) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.dialogRef.close(false)
  }

  save(action) {
    this.dialogRef.close(action);
  }
}
