import { Injectable } from '@angular/core';
import { TestStep } from './test-step.type';
import { Observable, of, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Status } from './status.type';
import { Core } from './core.service';
import { TestPlan } from './test-plan.type';
import { TestSuit } from './test-suit.type';

@Injectable({
  providedIn: 'root'
})
export class TestStepService {
  private test_case_steps: TestStep[];
  public selectTestPlan: TestPlan;
  public selectTestSuit: TestSuit;

  constructor() { }

  getTestSteps(test_case_id: string): Observable<TestStep[]> {
    return from(Core.testStep.list({ test_case_id }).catch(error => console.log(error))).pipe(map(res => {
      if (+res.valid === 1) {
        this.test_case_steps = this.mapTestSteps(res.test_case_steps);
        return this.test_case_steps
      }
      else return []
    }))
  }

  updateTestStepStatus(body): Observable<boolean> {
    return from(Core.testStep.updateStatus(body).catch(error => console.log(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  getTestStepsByRelationId(body): Observable<TestStep[]> {
    return from(Core.testStep.listByRelationId(body).catch(error => console.log(error))).pipe(map(res => {
      if (+res.valid === 1) {
        this.test_case_steps = this.mapTestStepsbyRelationId(res.test_case_steps);
        return this.test_case_steps;
      } else return []
    }))
  }

  createTestStep(testCaseId: string, testStep: TestStep): Observable<TestStep> {
    return from(Core.testStep.create({ testCaseId, testStep }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestStep(res.test_case_step);
      else return null
    }))
  }

  saveStep(testStep: TestStep): Observable<TestStep> {
    return from(Core.testStep.update({
      id: testStep.id,
      expected_result: testStep.expectedResult,
      description: testStep.description,
    }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestStep(res.test_case_step);
      else return null
    }))
  }

  deleteTestStep(id: string): Observable<boolean> {
    return from(Core.testStep.delete({ id }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  getTestStep(id: string): Observable<TestStep> {
    console.log('check step 69')
    if (this.test_case_steps != null) {
      return of(this.test_case_steps.find((val: TestStep) => val.id === id));
    }

    return from(Core.testStep.readList({ id }).catch(error => console.error(error))).pipe(map(res => {
      if (res) this.mapTestStep(res.test_case)
      else return null
    }))
  }

  mapTestSteps(raw: any[]): TestStep[] {
    return raw.map((val: any) => this.mapTestStep(val));
  }

  mapTestStepsbyRelationId(raw: any[]): TestStep[] {
    raw.map((val: any) => {
      for (const key in val.tcs) {
        if (val.tcs.hasOwnProperty(key)) {
          val[key] = val.tcs[key]
        }
      }
      delete val.tcs;
    });
    return raw.map((val: any) => this.mapTestStep(val));
  }

  mapTestStep(raw: any): TestStep {
    const result: TestStep = new TestStep();

    result.comment = raw.comment
    result.id = raw.id;
    result.testCaseId = raw.test_case_id;
    result.order = raw.order;
    result.expectedResult = raw.expected_result;
    result.description = raw.description;
    result.createdDate = raw.created_date;
    result.modifiedDate = raw.modified_date;
    result.statusObject = new Status();
    result.status = raw.status;
    result.attachments = [];

    return result;
  }
}
