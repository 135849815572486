export class AuthenticationController {
    constructor(api) {
        this.api = api;
    }
    login(param) {
        return this.api.postRequest('authentication/login', param)
            .then((res) => {
            this.api.setAuthorization(res.data.token, res.data.refreshToken);
            return res.data;
        });
    }
}
