import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, SubscriptionLike } from 'rxjs';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogCreateProjectComponent } from 'src/app/dialogs/dialog-create-project/dialog-create-project.component';
import { DialogEditComponent } from 'src/app/dialogs/dialog-edit/dialog-edit.component';
import { DialogUsersSelectComponent } from 'src/app/dialogs/dialog-users-select/dialog-users-select.component';
import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/services/project.type';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/services/user.type';
import { UserAccessService } from 'src/app/services/user-access';

@Component({
  selector: 'app-card-projects',
  templateUrl: './card-projects.component.html',
  styleUrls: ['./card-projects.component.scss']
})
export class CardProjectsComponent implements OnInit, OnDestroy {
  public addUserAccessAction: boolean = this._userAccessService.getAccess('project', 'projectAddUserLevel');
  public editAccessAction: boolean = this._userAccessService.getAccess('project', 'projectEditLevel');
  public deleteAccessAction: boolean = this._userAccessService.getAccess('project', 'projectDeleteLevel');

  private _projects: Project[];
  public projects: Project[];
  public search: string;
  public isLoading = true;

  private selectedValue: Project;
  private selectionListSubscriber: SubscriptionLike;

  public currentUser;
  public accessLevel = {};
  public pending: boolean = false;
  public pendingItem: Project;

  @ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;

  @Output() showProjectsUser = new EventEmitter<Project>();
  @Output() changeProject = new EventEmitter();
  @Output() updateUsers = new EventEmitter<Project>();
  @Output() deleteProject = new EventEmitter();
  @Output() closeInfo = new EventEmitter();

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnDestroy(): void {
    this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : '';
  }

  ngOnInit(): void {
    this.getProjects();
    this.setSelectionList();
  }

  setSelectionList() {
    this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true })
    });
  }

  getProjects() {
    this.projectService.getProjects().subscribe(res => {
      this.projects = this.mapSelectedValue(res);
      this._projects = res;
      this.isLoading = false;
    })
  }

  getProjectsUsers(id: string): Observable<User[]> {
    return this.userService.getUserByProject(id)
  }

  mapSelectedValue(projects: Project[]): Project[] {
    if (this.selectedValue) {
      this.selectedValue = projects.find(elem => +elem.id === +this.selectedValue.id);
      if (this.selectedValue) this.showProjectsUser.emit(this.selectedValue);
      return projects.map(val => { val.id === this.selectedValue?.id ? val.isOpen = true : val.isOpen = false; return val })
    } else {
      return projects;
    }
  }

  selectionChange(event: MatSelectionListChange) {
    event.options.forEach(item => {
      this.showProjectsUser.emit(item.value);
      this.selectedValue = item.value
    })
  }

  onAddEvent() {
    this.dialog.open(DialogCreateProjectComponent, {
      width: '650px'
    }).afterClosed().subscribe(async res => {
      if (res) {
        this.snackBar.open('Project is added', "OK", { duration: 3000 });
        this.getProjects()
        this.changeProject.emit()
      }
    })
  }

  onEditEvent(item: Project) {
    this.dialog.open(DialogEditComponent, {
      width: '650px',
      data: { dialogTitle: 'Edit project', title: item.title, description: item.description }
    }).afterClosed().subscribe(res => {
      if (res) {
        const project = this._projects.find(val => +val.id === +item.id);
        this.projectService.saveProject({ ...project, ...res }).subscribe(res => {
          this.snackBar.open('Project is edited', "OK", { duration: 3000 });
          this.getProjects();
        })
      }
    })
  }

  async onSelectUsers(project: Project) {
    this.pendingItem = project;
    this.pending = true;
    const projectUsers = await this.getProjectsUsers(project.id).toPromise();
    const allUsers = await this.userService.getUsers().toPromise();
    this.pending = false;

    this.dialog.open(DialogUsersSelectComponent, {
      width: '650px',
      data: { users: allUsers, selectedOptions: projectUsers.map(val => val.id) },
      panelClass: 'custom-modalbox'
    }).afterClosed().subscribe((res: string[]) => {
      if (res) {
        this.projectService.updateProjectAccess(+project.id, res).subscribe(result => {
          if (result) {
            this.snackBar.open('User was succefully updated', 'OK', { duration: 3000 });
            this.updateUsers.emit(project);
            this.pendingItem = null;
          }
        })
      }
    });
  }

  onDeleteEvent(project: Project) {
    this.projectService.deleteProject(project.id).subscribe((res) => {
      if (res) {
        this.snackBar.open('Project is deleted', "OK", { duration: 3000 });
        this.getProjects();
        this.deleteProject.emit();
        this.closeInfo.emit();
      }
    })
  }

  getProjectInfo(id) {
    return this._projects.find(item => item.id === id)
  }

}
