import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../services/user.service';
import {User} from '../services/user.type';
import { MatDialog } from '@angular/material/dialog';
import {DialogCreateUserComponent} from '../dialogs/dialog-create-user/dialog-create-user.component';
import {DialogEditUserComponent} from '../dialogs/dialog-edit-user/dialog-edit-user.component';
import { AbstractTableComponent } from '../abstract-table/abstract-table.component';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {

  public data: User[];
  public filterValue: string = '';
  public displayedColumns = ['id', 'login', 'firstName', 'lastName', 'createdDate', 'modifiedDate', 'actions'];

  users: User[] = [];

  @ViewChild('abstractTable') private abstractTable: AbstractTableComponent

  constructor(
    private userService: UserService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.userService.getUsers().subscribe(users => {
      this.users = users;
      this.data = users;
    })
  }

  onDeleteUser(user: User): void {
    this.userService.deleteUser(user.id).subscribe(valid => {
      if (valid)
        this.users.splice(this.users.findIndex(u => {
          return u.id === user.id;
        }), 1);
        this.abstractTable.data = this.users
    });
  }

  openDialogEditUser(user: User): void {
    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      width: '650px',
      data: {user}
    });

    dialogRef.afterClosed().subscribe(valid => {
      if (valid)
        location.reload();
    });
  }

  openDialogCreateUser(): void {
    const dialogCreateUser = this.dialog.open(DialogCreateUserComponent, {
      width: '650px'
    });

    dialogCreateUser.afterClosed().subscribe(() => {
      this.userService.getUsers().subscribe((users: User[]) => {
        this.users = users;
        this.abstractTable.data = this.users
      })
    })
  }

}
