const runsList = 'testRun/list', runsRead = 'testRun/read', runsCreate = 'testRun/create', runsUpdate = 'testRun/update', runsDelete = 'testRun/delete', reRun = 'testRun/reRun', addTestCases = 'testRun/addTestCases', updateRelation = 'testRun/updateRelation', faildedTestCase = 'testRun/failedTestCase', updateTestCasesList = 'testRun/updateTestCasesList', assignUser = 'testRun/assignUserToTestCase', archive = 'testRun/updateArchiveStatus';
export class TestRunControllers {
    constructor(api) {
        this.api = api;
    }
    //get testRuns list
    list(payload) {
        return this.api.postRequest(runsList, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //read testRun list
    readList(payload) {
        return this.api.postRequest(runsRead, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //create testRun
    create(payload) {
        return this.api.postRequest(runsCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update testRun
    update(payload) {
        return this.api.postRequest(runsUpdate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete testRun
    delete(payload) {
        return this.api.postRequest(runsDelete, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //reRun testRun
    reRun(payload) {
        return this.api.postRequest(reRun, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //add testCases
    addTestCases(payload) {
        return this.api.postRequest(addTestCases, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update Relation
    updateRelation(payload) {
        return this.api.postRequest(updateRelation, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //failed TestCase
    failedTestCase(payload) {
        return this.api.postRequest(faildedTestCase, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update TestCase list
    updateTestCasesList(payload) {
        return this.api.postRequest(updateTestCasesList, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //assign user
    assignUser(payload) {
        return this.api.postRequest(assignUser, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //Archive Test Run
    archive(payload) {
        return this.api.postRequest(archive, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
}
