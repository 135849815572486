<app-sidenav class="test-runs-details-page-test">
  <mat-card id="panel" class="sidenav-opened"> <!-- Main Header -->
    <div class="sidenav-opened__header">
      <button mat-icon-button class="base-button" (click)="goToTestPlans()">
        <mat-icon style="color: #000;">arrow_back_ios</mat-icon>
      </button>

      <mat-card-title class="details">
        <span class="details__title" *ngIf="testPlan && testPlan.title">{{testPlan.title}}</span>
        <span><mat-spinner *ngIf="!testPlan || !testPlan.title" [diameter]="20"></mat-spinner></span>
        <span class="details__id" *ngIf="testPlan && testPlan.id">
          TEST PLAN ID - {{testPlan.id}} &nbsp;|&nbsp;PROJECT ID - {{testPlan.projectId}}
        </span>
        <span><mat-spinner *ngIf="!testPlan || !testPlan.id" [diameter]="15"></mat-spinner></span>
      </mat-card-title>
      <button mat-icon-button class="base-button" *ngIf="!editMode" (click)="openRunDialog()">
        <mat-icon>directions_run</mat-icon>
      </button>

      <div [ngStyle]="{'display': (componentShowHide === 'hide' || componentShowHide === 'start') ? 'none' : 'block'}" class="resize-toggle-group-wrap">
        <mat-button-toggle-group class="resize-toggle-group">
          <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'large'}" (click)="onResize('large')"><mat-icon svgIcon="cell-divide-in-left-area"></mat-icon></mat-button-toggle>
          <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'medium'}" (click)="onResize('medium')"><mat-icon svgIcon="cell-divide-in-center-area"></mat-icon></mat-button-toggle>
          <mat-button-toggle class="resize-toggle mat-button-toggle-checked" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'small'}" (click)="onResize('small')"><mat-icon svgIcon="cell-divide-in-right-area"></mat-icon></mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <button mat-icon-button [matMenuTriggerFor]="menu" class="base-button" *ngIf="!editMode">
        <mat-icon>more_vert</mat-icon>
      </button>
      <button [disabled]="!editTestPlansForm.valid" id="clear-style" mat-icon-button *ngIf="editMode" (click)="saveTestPlan()" class="save-icon">
        <mat-icon>save</mat-icon>
      </button>
      <mat-icon *ngIf="editMode" (click)="editMode = false">close</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openSelectCasesDialog()">Select Test Cases</button>
        <button mat-menu-item (click)="editMode = true">Edit</button>
        <button mat-menu-item (click)="deleteTestPlan()">Delete</button>
      </mat-menu>
    </div>
    <form [formGroup]="editTestPlansForm" class="edit-mode-dropdown" *ngIf="editMode">
      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="title" [readonly]="!editMode">
         <mat-error *ngIf="f.title.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description"  formControlName="description" [readonly]="!editMode">
         <mat-error *ngIf="f.description.hasError('required')">
          Description is required
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card> <!-- End of Main Header -->

  <div class="main-container">
      <app-test-run-details-table
                                [data]="testCases"
                                [displayedColumns] = "displayedColumns"
                                [currentProjectId] = "currentProjectId"
                                (openSideComponent) = openTestCaseDetail($event);
                                style ="display: flex; flex-grow: 1"
                                ></app-test-run-details-table>
    <div class="test-run-details-sidebar" [@changeSideComponentSize]="componentShowHide" (@changeSideComponentSize.done)="doneAnimation()">
      <app-test-case-details-sidebar [testCase]="testCase"
                                     [testSteps]="testSteps"
                                     [showSteps]="showSteps"
                                     [goBackURLId]="testPlan.id"
                                     (Resize)="onResize($event)"
      ></app-test-case-details-sidebar>
    </div>
  </div><!-- End of main container-->
</app-sidenav>
