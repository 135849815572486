import { Component, Inject, OnInit } from '@angular/core';
import { TestStep } from '../../services/test-step.type'
import { TestCase } from 'src/app/services/test-case.type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-test-case-fail',
  templateUrl: './dialog-test-case-fail.component.html',
  styleUrls: ['./dialog-test-case-fail.component.scss']
})
export class DialogTestCaseFailComponent implements OnInit {

  testSteps: TestStep[];
  createDefect= '0';
  checked = false;
  failedOnTestStep: TestStep;
  testCase: TestCase;
  requiredFieldError = false;
  failForm: FormGroup

  constructor (
    public dialogRef: MatDialogRef<DialogTestCaseFailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTestCaseFailComponent
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close(null);
   })
  }

  ngOnInit(): void {
    this.testSteps = this.data.testSteps;
    this.failedOnTestStep = this.data.failedOnTestStep;
    this.initForm()
  }

  initForm() {
    this.failForm = new FormGroup({
      step: new FormControl(this.failedOnTestStep?.id, [Validators.required]),
      description: new FormControl('', [Validators.required])
    })
  }

  get f() {
    return this.failForm.controls
  }

  onSubmit() {
    // Closing dialog window once TestCase was updated
    this.dialogRef.close({
      createDefect: this.createDefect, 
      comment: this.failForm.value.description, 
      failedOnTestStep: this.failForm.value.step});
  }

  onChange() {
    // Changing checked status
    this.checked = !this.checked;
    if (this.checked === true) {
      // if checkbox was checked we should create defect
      this.createDefect = '1'
    } else {
      // if checkbox was not checked defect is not created
      this.createDefect = '0'
    }
  }
}
