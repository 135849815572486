import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Observable, SubscriptionLike, of } from 'rxjs';
import { DialogChangeProjectComponent } from 'src/app/dialogs/dialog-change-project/dialog-change-project.component';
import { DialogCreateStatusComponent } from 'src/app/dialogs/dialog-create-status/dialog-create-status.component';
import { DialogEditStatusComponent } from 'src/app/dialogs/dialog-edit-status/dialog-edit-status.component';
import { ProjectService } from 'src/app/services/project.service';
import { Status } from 'src/app/services/status.type';
import { Core } from 'src/app/services/core.service';
import { UserAccessService } from 'src/app/services/user-access';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-card-manage-statuses',
  templateUrl: './card-manage-statuses.component.html',
  styleUrls: ['./card-manage-statuses.component.scss']
})
export class CardManageStatusesComponent implements OnInit, OnDestroy {
  public addStatusAccess: boolean = this._userAccessService.getAccess('statuses', 'statusesCreateLevel');
  public editStatusAccess: boolean = this._userAccessService.getAccess('statuses', 'statusesEditLevel');

  @ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;

  public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
  public search: string;

  private _statuses: Status[];
  public statuses: Status[];

  private selectionListSubscriber: SubscriptionLike;
  private projectSubscriber: SubscriptionLike;
  public isLoading: boolean = false;

  constructor(
    private projectService: ProjectService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnDestroy(): void {
    this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : ''
    this.projectSubscriber ? this.projectSubscriber.unsubscribe() : ''
  }

  ngOnInit(): void {
    this.setSelectionList();
    this.getStatuses();
  }

  getStatuses() {
    if (this.currentProject === 'all' || this.currentProject === 'null') {
      this.dialog.open(DialogChangeProjectComponent, {
        width: '650px',
        data: {}
      }).afterClosed().subscribe(res => {
        if (res) {
          this.projectService.emitEvent(res);
        } else this.statuses = []
        this.isLoading = true;
      })
    } else {
      this.projectSubscriber = this.projectService.getProjectStatuses(this.currentProject).subscribe(res => {
        this.statuses = res;
        this.isLoading = true;
      })
    }
  }

  setSelectionList() {
    this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true })
    });
  }

  onAddEvent() {
    this.dialog.open(DialogCreateStatusComponent, {
      width: '650px',
      data: { projectId: this.currentProject }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open('Statuse was successfully added', 'OK', { duration: 3000 });
        this.getStatuses();
      }
    })
  }

  onEditEvent(status: Status) {
    let color = status.color
    this.dialog.open(DialogEditStatusComponent, {
      width: '650px',
      data: { status }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.snackBar.open('Statuse was successfully edited', 'OK', { duration: 3000 });
        this.getStatuses();
      } else { status.color = color }
    })
  }

  onDeleteEvent(status: Status) {
    this.projectService.deleteStatus(status.id).subscribe(res => {
      if (res) {
        this.snackBar.open('Statuse was successfully deleted', 'OK', { duration: 3000 });
        this.getStatuses();
      }
    })
  }
}
