<app-base-card [title]="'Test Steps'" [icon]="'hourglass_top'" [showAdd]="false" [showClose]="true"
  (closeEvent)="closeEvent.emit()">

  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
    appearance="line"></ngx-skeleton-loader>

  <mat-accordion [multi]="shortCases && shortSuit">
    <mat-expansion-panel #panel *ngFor="let testStep of testSuitStep; index as i" [expanded]="shortCases && shortSuit"
      [class]="!(shortCases && shortSuit) ? 'list-card__item item-list item-list-accordion' : 'list-card__item item-list item-list-accordion-short-item'"
      [routerLink]="'./'"
      [queryParams]="{ project: queryParams.project, type: queryParams.type, id: queryParams.id, sub: queryParams.sub, level: queryParams.level, tc: queryParams.tc, ts: panel.expanded ? testStep.id : null}">
      <mat-expansion-panel-header>
        <mat-panel-title class="item-list-accordion__title">
          <span>{{testStep.description}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="item-list-accordion__info info-item-list">
        <app-test-step-form (onUpdateStep)="updateStep($event)" (onDeleteStep)="deleteStep($event)"
          [form]="testStep.form" [shortForm]="shortCases && shortSuit" [queryParams]="queryParams"
          [testStep]="testStep"></app-test-step-form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="card__footer footer-card footer-card-add-steps">
    <ng-container *ngIf="showAddNewTestStepForm">
      <div class="footer-card__form form-footer-card">
        <div class="form-footer-card__wrap">
          <app-test-step-form [eventAdd]="true" [eventEdit]="false" [eventDelete]="false"
            (onAddStep)="createTestStep($event)" (onCloseForm)="closeTestStepForm($event)"
            [shortForm]="shortCases && shortSuit" [form]="testStepForm"></app-test-step-form>
          <!--          <app-test-step-form [eventAdd]="true" [eventEdit]="false" [eventDelete]="false" (onAddStep)="createTestStep($event)" (onCloseForm)="closeTestStepForm($event)" [form]="testStepForm"></app-test-step-form>-->
        </div>
      </div>
    </ng-container>
    <div class="footer-card__buttons">
      <div class="footer-card__button footer-card__button-100">
        <ng-container *ngIf="!showAddNewTestStepForm">
          <button mat-raised-button color="primary" (click)="onAddNewTestStep()" [disabled]="!addAccessAction">Add New
            Test Step</button>
        </ng-container>
      </div>
    </div>
  </div>

</app-base-card>