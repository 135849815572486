<div class="container">
  <div class="dialog-title">
    <h1 mat-dialog-title>Select Test Cases</h1>
  </div>
  <div class="dialog-content" mat-dialog-content>
    <app-shared-suit-plan-select #shared [testSuits]="data.testSuits" [testPlans]="data.testPlans"
      [selectedTestSuitCases]="data.selectedSuits" [selectedTestPlanCases]="data.selectedPlans || null"
      [type]="data.title"></app-shared-suit-plan-select>
  </div>
  <div class="dialog-actions" mat-dialog-actions>
    <button mat-stroked-button (click)="closeEventAction(false)" cdkFocusInitial>CANCEL</button>
    <!-- <button mat-raised-button color="primary" style="background: #2d64ba"
      [disabled]="sharedComponent?.checklistSelection.selected.length === 0 && sharedComponent?.checklistSelectionPlans.selected.length === 0"
      (click)="closeEventAction(true)" cdkFocusInitial>SELECT</button> -->

    <button mat-raised-button color="primary" style="background: #2d64ba"
      [disabled]="checkSelectFromSuit() && sharedComponent?.checklistSelectionPlans.selected.length === 0"
      (click)="closeEventAction(true)" cdkFocusInitial>SELECT</button>
  </div>
</div>