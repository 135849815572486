// Default controllers import
import { TestRunControllers } from './controllers/default/test-run-controllers';
import { TestCaseControllers } from './controllers/default/test-case-controllers';
import { TestStepControllers } from './controllers/default/test-step-controllers';
import { UsersControllers } from './controllers/default/users-controllers';
import { ApiController } from './controllers/default/api-controller';
import { AuthenticationController } from "./controllers/default/authentication-controller";
import { ProjectsControllers } from './controllers/default/project-controllers';
import { StatusesControllers } from './controllers/default/statuses-controller';
import { TestPlanControllers } from './controllers/default/test-plan-controllers';
import { TestSuitControllers } from './controllers/default/test-suit-controller';
import { DashboardControllers } from './controllers/default/dashboard-controllers';
import { JiraControllers } from './controllers/default/jira-controllers';
// Service import
import { LocalStorageService } from './services/local-storage-service';
class TestyyCore {
    constructor() { }
    init(type, apiUrl) {
        switch (type) {
            case 'web':
                this.initDefault(apiUrl, 'web');
                this.type = 'web';
                break;
            case 'mobile':
                this.initDefault(apiUrl, 'mobile');
                this.initMobile(apiUrl);
                this.type = 'mobile';
                break;
            default:
                this.initDefault(apiUrl, 'web');
                this.type = 'web';
                break;
        }
    }
    initDefault(apiUrl, type) {
        this.localStorageService = new LocalStorageService(type);
        this.api = new ApiController(apiUrl, this.localStorageService);
        this.authentication = new AuthenticationController(this.api);
        this.testRun = new TestRunControllers(this.api);
        this.testCase = new TestCaseControllers(this.api);
        this.testStep = new TestStepControllers(this.api);
        this.testPlan = new TestPlanControllers(this.api);
        this.testSuit = new TestSuitControllers(this.api);
        // this.testPoint = new TestPointControllers(this.api)
        this.users = new UsersControllers(this.api);
        this.projects = new ProjectsControllers(this.api);
        this.statuses = new StatusesControllers(this.api);
        this.dashboard = new DashboardControllers(this.api);
        this.jira = new JiraControllers(this.api);
    }
    initMobile(apiUrl) {
    }
}
const testyy = new TestyyCore();
export default testyy;
