import { Injectable } from '@angular/core';
import { User } from './user.type';
import { from, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Attachment } from './attachment.type';
import { Core } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private users: User[];
  private _cache$: Observable<User[]>;

  constructor() { }

  testStepAttachments(param): Observable<Attachment[]> {
    return from(Core.users.attachmentsStep(param).catch(error => console.log(error))).pipe(map(res => {
      if (res) return this.mapAttachments(res.data)
      else return []
    }))
  }

  addAttachments(param) {
    return from(Core.users.addAttachments(param).catch(error => console.log(error)))
  }

  setCurrentStep(param): Observable<boolean> {
    return from(Core.users.setCurrentState(param).catch(error => console.log(error))).pipe(map(res => {
      if (+res.valid === 1) return true
      else return false
    }))
  }

  getCurrentStep(param): Observable<{}> {
    return from(Core.users.getCurrentState(param).catch(error => console.log(error))).pipe(map(res => {
      if (res.valid === 1) return res.data
      else return null
    }))
  }

  getUsers(): Observable<User[]> {
    return this._cache$ = this.requestGetUsers().pipe(shareReplay(1))
  }

  private requestGetUsers(): Observable<User[]> {
    return from(Core.users.usersList({}).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        this.users = this.mapUsers(res.users);
        return this.users
      } else return []
    }))
  }

  // getUserByProject(id: string | string[]): Observable<User[]> {
  getUserByProject(id: string | string[]): Observable<User[]> {
    return from(Core.users.list({ project_id: id }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapUsers(res.users)
      else return []
    }))
  }

  getUserActivity(param): Observable<any[]> {
    return from(Core.users.getActivityUser(param).catch(error => console.log(error))).pipe(map(res => {
      if (res) {
        return res.activity_log
      } else return []
    }))
  }

  createUser(user: User): Observable<boolean> {
    return from(Core.users.create({ ...this.mapUserReverse(user) }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  updateUser(param): Observable<boolean> {
    return from(Core.users.update(param).catch(error => console.error(error))).pipe(map(res => {
      if (+res.valid === 1) return true
      else return false
    }))
  }

  deleteUser(id: string): Observable<boolean> {
    return from(Core.users.delete({ id: id }).catch(error => console.error(error))).pipe(map(res => {
      if (+res.valid === 1) return true
      else return false
    }))
  }

  mapUsers(raw: any[]): User[] {
    return raw.map((val: any) => this.mapUser(val));
  }

  getUsersRole():Observable<boolean> {
    return from(Core.users.getUsersRoles({}).catch(error => console.error(error))).pipe(map(res => {
      if (+res.valid === 1) {
        return res
      } else {
        return false
      }
    }))
  }

  mapUser(raw: any): User {
    const result: User = new User();

    result.id = raw.id;
    result.login = raw.login;
    result.password = raw.password;
    result.firstName = raw.first_name || raw.first_Name;
    result.lastName = raw.last_name;
    result.createdDate = raw.created_date;
    result.modifiedDate = raw.modified_date;
    result.roleId = raw.role_id;
    result.companyId = raw.company_id;
    result.accountType = raw.account_type;

    return result;
  }

  mapUserReverse(raw: User): User {
    const result: any = {}

    //  result.id = raw.id;
    result.login = raw.login;
    result.password = raw.password;
    result.first_name = raw.firstName;
    result.last_name = raw.lastName;
    result.role_id = raw.roleId;
    result.company_id = raw.companyId;
    result.account_type = raw.accountType;

    return result
  }

  mapAttachments(raw: any[]): Attachment[] {
    return raw.map((val: any) => this.mapAttachment(val));
  }

  mapAttachment(raw: any): Attachment {
    const result: Attachment = new Attachment();

    result.id = raw.id;
    result.userTrackingId = raw.user_tracking_id;
    result.relativePath = raw.relative_path;
    result.createdAbsolutePath = raw.created_absolute_path;
    result.fileType = raw.file_type;
    result.fileDataType = raw.file_data_type;

    return result;
  }
}
