import { OnInit, Component, ViewChild, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { BaseChartBySuitsComponent } from '../base-chart-by-suits/base-chart-by-suits.component';


@Component({
  selector: 'app-chart-tests-changed',
  templateUrl: './chart-tests-changed.component.html',
  styleUrls: ['./chart-tests-changed.component.scss']
})
export class ChartTestsChangedComponent implements OnInit {
  @ViewChild('baseChart') baseChart: BaseChartBySuitsComponent;
  @Input() public currentProject;
  public title: string = 'Number of tests changed'

  public width: number = innerWidth / 2 - 50;
  public height: number = innerWidth / 8;

  public data = [];
  public labels = []

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
  }

  paramEvent(param): void {
    this.data = [];
    this.labels = [];
    for (let i = Date.parse(param.start_date); i <= Date.parse(param.end_date); i = i + 24 * 60 * 60 * 1000) {
      this.labels.push(new Date(i).toISOString().substr(0, 10));
    }
    if (param.selected_suits.length > 0) this._formData(param);
    else {
      this.labels.forEach(label => { this.data.push('0') });
      this._createChart();
    }
  }

  private _formData(param) {
    this.dashboardService.getDateForChartChanged(param).subscribe(res => {
      this.labels.forEach(label => {
        let find = res.find(item => item.date === label);
        find ? this.data.push(find.count) : this.data.push('0');
      });
      this._createChart();
    });
  }

  private _createChart() {
    if (this.baseChart) {
      this.baseChart.pending = false;
      this.baseChart.createChart(this.labels, this.data);
    }
  }

  updateData() {
    this.baseChart?.initData();
  }
}