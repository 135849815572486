import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-edit',
  templateUrl: './dialog-edit.component.html',
  styleUrls: ['./dialog-edit.component.scss']
})
export class DialogEditComponent implements OnInit {

  public editForm: FormGroup

  constructor(
    public dialogRef: MatDialogRef<DialogEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dialogTitle: string, title: string, description:string},
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.editForm = new FormGroup({
      title: new FormControl(this.data.title, [Validators.required]),
      description: new FormControl(this.data.description, [Validators.required]),
    })
  }

  get f() {
    return this.editForm.controls
  }

  onCancel() {
    this.dialogRef.close(false)
  }

  save() {
    this.dialogRef.close(this.editForm.value);
  }

}
