import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { ProjectService } from '../../services/project.service';
import { TestSuitService } from '../../services/test-suit.service';
import { TestSuit } from '../../services/test-suit.type';
import { TestCaseService } from '../../services/test-case.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Core } from 'src/app/services/core.service';


export interface DialogData {
  project: Project
}

@Component({
  selector: 'app-dialog-create-project',
  templateUrl: 'dialog-create-project.component.html',
  styleUrls: ['./dialog-create-project.component.scss']
})
export class DialogCreateProjectComponent implements OnInit {

  project: Project = new Project();
  nextStep = false;
  testSuits: TestSuit[] = [];
  selectedTestCases: any[] = [];
  craeteProjectForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
    private testSuitService: TestSuitService,
    private testCaseService: TestCaseService
  ) { }


  ngOnInit(): void {
    this.initForm();
    this.testSuitService.getTestSuits({ options: { test_cases: true } }).subscribe(res => {
      res.map(s => {
        this.testCaseService.getTestCasesByTestSuit({ ts_id: s.id }).subscribe(c => {
          s.testCases = c;
          if (s === res[res.length - 1] && res[res.length - 1].testCases !== undefined) {
            this.testSuits = res;
          }
        });
      });
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  initForm() {
    this.craeteProjectForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    })
  }

  get f() {
    return this.craeteProjectForm.controls
  }

  createProject(): void {
    const userId = JSON.parse(Core.localStorageService.getItem('user')).user.id;
    this.projectService.createProject(
      { title: this.craeteProjectForm.value.title, description: this.craeteProjectForm.value.description, owner_id: userId }
    ).subscribe((res) => {
      this.dialogRef.close(res)
    })
  }

}
