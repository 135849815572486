import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { Core } from '../services/core.service';
import { ProjectService } from '../services/project.service';
import { TestCase } from '../services/test-case.type';
import { TestPlan } from '../services/test-plan.type';
import { TestRun } from '../services/test-run.type';
import { TestSuit } from '../services/test-suit.type';
import { CardTestPlanCaseComponent } from './card-test-plan-case/card-test-plan-case.component';
import { CardTestPlanComponent } from './card-test-plan/card-test-plan.component';
import { CardTestRunCaseComponent } from './card-test-run-case/card-test-run-case.component';
import { CardTestRunDetailsComponent } from './card-test-run-details/card-test-run-details.component';
import { CardTestRunStepComponent } from './card-test-run-step/card-test-run-step.component';
import { CardTestRunComponent } from './card-test-run/card-test-run.component';
import { CardTestSuitCaseComponent } from './card-test-suit-case/card-test-suit-case.component';
import { CardTestSuitComponent } from './card-test-suit/card-test-suit.component';
import { TestRunService } from "../services/test-run.service";

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit, AfterViewInit {

  @ViewChild('testRunCard') private testRunCard: CardTestRunComponent;
  @ViewChild('testRunCase') private testRunCase: CardTestRunCaseComponent;
  @ViewChild('testRunStep') private testRunStep: CardTestRunStepComponent;
  @ViewChild('testPlanCard') private testPlanCard: CardTestPlanComponent;
  @ViewChild('testPlanCase') private testPlanCase: CardTestPlanCaseComponent;
  @ViewChild('testSuitCard') private testSuitCard: CardTestSuitComponent;
  @ViewChild('testSuitCase') private testSuitCase: CardTestSuitCaseComponent;
  @ViewChild('testRunDetails') private testRunDetails: CardTestRunDetailsComponent;

  public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';

  public showCase: boolean = false;
  public showStep: boolean = false;

  public showRun: boolean = true;
  public showPlan: boolean = true;
  public showSuit: boolean = true;
  public showDetails: boolean = false;

  public shortFormRun: boolean = false;
  public shortFormPlan: boolean = false;
  public shortFormSuit: boolean = false;
  public shortFormRunCase: boolean = false;
  public shortFormPlanCase: boolean = false;
  public shortFormSuitCase: boolean = false;

  public switchCases: string = '';
  public switchSteps: string = '';

  public selectedTest: TestRun | TestPlan | TestSuit;

  public selectedCase: TestCase;
  public queryParams: Params;
  public isShowSkeleton = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private testRunService: TestRunService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(res => {
      if (window.location.pathname === '/test-page') {
        this.queryParams = res;
      }
    })
  }

  isShowSkeletonEvent(value) {
    value === false ? this.isShowSkeleton = false : this.isShowSkeleton = true;
  }

  hideCollapse(type: string) {
    if (!this.testSuitCard.selectedValue && !this.testPlanCard.selectedValue && type === 'TestRun') return
    switch (type) {
      case 'TestRun': this.testRunCard.selectedValue = null; break;
      case 'TestPlan': this.testPlanCard.selectedValue = null; break;
      case 'TestSuit': this.testSuitCard.selectedValue = null; break;
    }
  }

  shortFormEvent(type: string) {
    switch (type) {
      case 'TestRun': this.showPlan = !this.showPlan; this.showSuit = !this.showSuit; this.shortFormRun = !this.shortFormRun; break;
      case 'TestPlan': this.showRun = !this.showRun; this.showSuit = !this.showSuit; this.shortFormPlan = !this.shortFormPlan; break;
      case 'TestSuit': this.showPlan = !this.showPlan; this.showRun = !this.showRun; this.shortFormSuit = !this.shortFormSuit; break;
      case 'TestRunCase': this.shortFormRunCase = !this.shortFormRunCase; break;
      case 'TestPlanCase': this.shortFormPlanCase = !this.shortFormPlanCase; break;
      case 'TestSuitCase': this.shortFormSuitCase = !this.shortFormSuitCase; break;
    }
  }

  showCases() {
    this.shortFormRunCase = false;
    this.shortFormPlanCase = false;
    this.shortFormSuitCase = false;
  }

  closeCase(type: string) {
    this.showStep = false;
    this.showCase = false;

    switch (type) {
      case 'TestRun': this.showPlan = true; this.showSuit = true; this.shortFormRun = false; this.showDetails = false; break;
      case 'TestPlan': this.showRun = true; this.showSuit = true; this.shortFormPlan = false; break;
      case 'TestSuit': this.showPlan = true; this.showRun = true; this.shortFormSuit = false; break;
    }
  }

  closeStep(type: string) {
    this.showStep = false;

    switch (type) {
      case 'TestRunCase': this.shortFormRunCase = false; break;
      case 'TestPlanCase': this.shortFormPlanCase = false; break;
      case 'TestSuitCase': this.shortFormSuitCase = false; break;
    }
  }

  onShowTestCase(value: TestRun | TestPlan | TestSuit, title: string) {
    if (value) this.selectedTest = value;
    this.showCaseColumn();
    this.switchCases = title;

    if (title === 'testRunDetailsCase') {
      this.showDetails = true;
      if (this.testRunDetails && title === 'testRunDetailsCase') {
        this.testRunDetails.testRun = value as TestRun;
        this.testRunDetails.dataSource = new MatTableDataSource();
        this.testRunDetails.data = { data: [], labels: [], colors: [], info: [], passed: { count: 0, per: 0 }, unTest: { count: 0, per: 0 }, all: 0 };
        this.testRunDetails.initData();
      }
    } else this.showDetails = false;
  }

  updateSelectedValue(value: TestRun) {
    if (value) this.selectedTest = value;
    if (this.testRunDetails) {
      this.testRunDetails.testRun = value as TestRun;
      this.testRunDetails.initData();
    }
  }

  onShowTestSteps(value: TestCase, title: string) {
    this.showStepColumn();
    this.switchSteps = title;
    this.selectedCase = value;
  }

  showCaseColumn() {
    this.showCase = true;
    this.showStep = false;
  }

  showStepColumn() {
    this.showStep = true;
  }

  updateTestRun() {
    this.testRunCard.getTestRun(false);
  }

  updateTestPlan() {
    this.testPlanCard.getTestPlans();
  }

  updateTestSuit() {
    this.testSuitCard.getTestSuit();
  }

  updateTestPlanCase() {
    this.testPlanCase?.getTestPlanCase(this.selectedTest.id)
  }

  updateTestSuitCase() {
    this.testSuitCase?.getTestSuitCase(this.selectedTest.id)
  }

  updateTestRunCase() {
    this.testRunCase?.getTestRunCase(this.selectedTest.id, false);
  }

  updateTestRunSteps(testCases: TestCase) {
    this.testRunStep?.getTestCaseSteps(testCases?.relationId)
  }

  refreshTestRunList() {
    this.testRunCard.refreshSelectionList();
  }

  refreshTestPlanList() {
    this.testPlanCard.refreshSelectionList();
  }

  refreshTestSuitList() {
    this.testSuitCard.refreshSelectionList();
  }

  openCase(testCase: TestCase, flag = true) {
    if (flag) this.onShowTestCase(this.selectedTest, 'testRunCase');
    if (this.testRunCase) {
      this.testRunCase.selectedFromDetails(testCase);
      return
    } else setTimeout(() => { this.openCase(testCase, false) }, 500);
  }
}
