import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { ProjectService } from '../../services/project.service';
import { Router } from '@angular/router';
import { TestSuit } from '../../services/test-suit.type';
import { TestSuitService } from '../../services/test-suit.service';
import { TestCaseService } from '../../services/test-case.service';
import { DataService } from '../../services/data.service'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Core } from 'src/app/services/core.service';

export interface DialogData {
  testSuits: TestSuit[];
}

@Component({
  selector: 'app-dialog-create-project',
  templateUrl: 'dialog-create-test-suit.component.html',
  styleUrls: ['./dialog-create-test-suit.component.scss']
})
export class DialogCreateTestSuitComponent implements OnInit {

  testSuit: TestSuit = new TestSuit();
  projects: Project[];
  nextStep = false;
  testSuits: TestSuit[] = [];
  selectedTestCases: any[] = [];

  craeteTestSuitForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateTestSuitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService,
    private testSuitService: TestSuitService,
    private router: Router,
    private testCaseService: TestCaseService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.initForm();
    this.projectService.getProjects().subscribe(response => {
      this.projects = response;
      this.data.testSuits?.map(s => {
        this.testCaseService.getTestCasesByTestSuit({ ts_id: s.id }).subscribe(c => {
          s.testCases = c;
          this.testSuits = this.data.testSuits;
        });
      });
    });
  }

  createSuit() {
    this.testSuit.title = this.craeteTestSuitForm.value.title;
    this.testSuit.description = this.craeteTestSuitForm.value.description;
    this.testSuit.projectId = this.craeteTestSuitForm.value.project;
    this.testSuitService.createTestSuit({ testSuit: this.testSuit, testCases: [] }).subscribe(res => {
      this.dialogRef.close(res)
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  initForm() {
    this.craeteTestSuitForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      project: new FormControl(this.projectService.getSelectedProjectFromStorage(), [Validators.required]),
    })
  }

  get f() {
    return this.craeteTestSuitForm.controls
  }

  mapSelectedTestCases(treeData): any {
    const selectedTestCases = [];
    treeData.forEach((value) => {
      if (value.level === 1) {
        selectedTestCases.push(value);
      }
    });
    return selectedTestCases;
  }

  createAndAddTestCases() {
    this.testSuit.title = this.craeteTestSuitForm.value.title;
    this.testSuit.description = this.craeteTestSuitForm.value.description;
    this.testSuit.projectId = this.craeteTestSuitForm.value.project;

    this.testSuitService.createTestSuit({ testSuit: this.testSuit, testCases: [] }).subscribe((createdTestSuit: TestSuit) => {
      this.dialogRef.close();
      // Passing data into data exchange sevice
      // debugger
      Core.localStorageService.setItem('chosenTestSuit', createdTestSuit.id);
      this.dataService.changeCreationMode(true);

      // Redirecting to Test Cases page. Test Cases component will receive data from data exchange service
      // this.router.navigate(['test-cases']);
      this.router.navigate(['test-cases-page']);
    });
  }

}
