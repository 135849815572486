import { Component, Input, OnInit } from '@angular/core';
import { TestCase } from 'src/app/services/test-case.type';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestRun } from 'src/app/services/test-run.type';
import { TestStep } from 'src/app/services/test-step.type';
import { TestSuit } from 'src/app/services/test-suit.type';
import { appTypes } from '../../services/app-types';

@Component({
  selector: 'app-test-cases-creation-mode',
  templateUrl: './test-cases-creation-mode.component.html',
  styleUrls: ['./test-cases-creation-mode.component.scss']
})
export class TestCasesCreationModeComponent implements OnInit {

  @Input() data: TestRun[] | TestPlan[] | TestSuit[] | TestCase[] | TestStep[] = [];
  @Input() currentType: string;
  public appTypes = appTypes;
  constructor() { }

  ngOnInit(): void {
  }

}
