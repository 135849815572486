const projectList = 'project/list', projectRead = 'project/read', projectCreate = 'project/create', projectUpdate = 'project/update', projectDelete = 'project/delete', projectUpdateAccess = 'project/manageProjectUsers', statusType = 'project/listStatuses';
export class ProjectsControllers {
    constructor(api) {
        this.api = api;
    }
    //get project list
    list(payload) {
        return this.api.postRequest(projectList, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //read project list
    readList(payload) {
        return this.api.postRequest(projectRead, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //create project
    create(payload) {
        return this.api.postRequest(projectCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update project
    update(payload) {
        return this.api.postRequest(projectUpdate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete project
    delete(payload) {
        return this.api.postRequest(projectDelete, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //update project access
    updateAccess(payload) {
        return this.api.postRequest(projectUpdateAccess, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //get status list
    statusesList(payload) {
        return this.api.postRequest(statusType, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
}
