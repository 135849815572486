import {Component, Inject, OnInit} from '@angular/core';
import {TestSuit} from '../../services/test-suit.type';
import {TestSuitService} from '../../services/test-suit.service';
import {Router} from '@angular/router';
import {TestPlanService} from '../../services/test-plan.service';
import {TestPlan} from '../../services/test-plan.type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  type: string,
  testPlan: TestPlan,
  testSuit: TestSuit
}

@Component({
  selector: 'app-dialog-run',
  templateUrl: 'dialog-run.component.html',
  styleUrls: ['./dialog-run.component.scss']
})
export class DialogRunComponent implements OnInit{

  title: string;

  constructor(
    public dialogRef: MatDialogRef<DialogRunComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private testSuitService: TestSuitService,
    private testPlanService: TestPlanService,
    private router: Router
  ) {}

  ngOnInit(): void { 
  }

  createAction(): void {
    switch (this.data.type) {
      case 'suit':
        this.testSuitService.runSuit({test_suit_id: this.data.testSuit.id, title: this.title}).subscribe(run => {
          this.dialogRef.close(true);
        });
        break;
      case 'plan':
        this.testPlanService.runPlan({test_plan_id: this.data.testPlan.id, title: this.title}).subscribe(run => {
          this.dialogRef.close(true);
        });
        break;
    }
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

}
