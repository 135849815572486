<app-base-card [title]="'Jira integration settings'" [icon]="'pix'" [showAdd]="true" [showClose]="false"
  [showSearch]="false" [showPadding]="true" (addEvent)="onAddEvent()">

  <ng-container *ngIf="isLoading; then content; else loader"></ng-container>

  <ng-template #content>
    <ng-container *ngIf="jiraIntegrationSettings?.length; then table; else not"></ng-container>
    <ng-template #table>
      <div class="content">
        <mat-card class="table-card">
          <div class="action-list">
            <mat-paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="5"
              [pageSizeOptions]="[1, 3, 5, 10]">
            </mat-paginator>
          </div>
          <div class="table-container">
            <table mat-table [dataSource]="dataSource">
              <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row mat-row *matRowDef="let row; columns: displayedColumns;" [class.active]="row.active"></mat-row>
              <ng-container matColumnDef="id">
                <mat-header-cell mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let setting">{{setting.id}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="project_title">
                <mat-header-cell *matHeaderCellDef>Project title</mat-header-cell>
                <mat-cell *matCellDef="let setting">
                  <div>{{setting.project_name}}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="basic_url">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Basic Url</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let setting">
                  <div>{{setting.basic_url}}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="service_name">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Service Name</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let setting">
                  <div>{{setting.service_name}}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="auth_data">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Auth Data</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let setting">
                  <div class="content">
                    <div><b>Email:</b> {{setting.auth_data.email}}</div>
                    <div class="token"><b>Token:</b> {{setting.auth_data.token}}</div>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="project_key">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Project Key</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let setting">
                  <div>{{setting.external_project_key}}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="fields_mapping_json">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Fields Mapping Json</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let setting">
                  <div>{{setting.fields_mapping_json}}</div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell mat-cell *matCellDef="let setting">
                  <div class="content">
                    <button class="item" mat-icon-button (click)="onEditEvent(setting)" stopPropagation>
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button class="item small" (click)="onDeleteEvent(setting)" stopPropagation>
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </mat-cell>
              </ng-container>
            </table>
          </div>
        </mat-card>
      </div>
    </ng-template>
    <ng-template #not>
      <div class="not-found">
        Jira integration settings not found...
      </div>
    </ng-template>
  </ng-template>

  <ng-template #loader>
    <ngx-skeleton-loader [theme]="{ height: '40px', background: '#D6DBDF', margin: '5px 0px 0px 0px' }" count="3"
      appearance="line"></ngx-skeleton-loader>
  </ng-template>
</app-base-card>