import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  value: string
}

@Component({
  selector: 'app-dialog-qr-code',
  templateUrl: 'dialog-qr-code.component.html',
  styleUrls: ['./dialog-qr-code.component.scss']
})
export class DialogQrCodeComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onCancel(): void {
    this.dialogRef.close();
  }

}
