import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantity'
})
export class QuantityPipe implements PipeTransform {

  static abbrNum(number: number, decPlaces: number): string {
    const abbrev = ['k', 'm', 'b', 't']

    let numRes: any = number
    let decRes = Math.pow(10, decPlaces)

    for(let i = abbrev.length - 1; i >= 0; i--){
      let size = Math.pow(10, (i + 1) * 3)
      if(size <= numRes) {
        numRes = Math.round(numRes * decRes / size) / decRes
        if((numRes === 1000) && (i < abbrev.length - 1)){
          numRes = 1
          i++
        }
      numRes +=abbrev[i]
      break;
      }
    }

    return numRes;
  }

  transform(value: any, decimalPlaces: number = 0): string {
    return QuantityPipe.abbrNum(value, decimalPlaces)
  }
}
