import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { DialogSelectComponent } from 'src/app/dialogs/dialog-select/dialog-select.component';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { ProjectService } from 'src/app/services/project.service';
import { Status } from 'src/app/services/status.type';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestRun } from 'src/app/services/test-run.type';
import { TestStep } from 'src/app/services/test-step.type';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/services/user.type';
import { Core } from 'src/app/services/core.service';
import { Params } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-card-test-run-defects',
  templateUrl: './card-test-run-defects.component.html',
  styleUrls: ['./card-test-run-defects.component.scss']
})
export class CardTestRunDefectsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelectionList, { static: true }) public selectionList: MatSelectionList;

  private _testCase: TestCase[];
  public testCase: TestCase[];
  public users: User[];
  public assigneTo: User;

  private _testRun: TestRun;
  public failedOnStep: TestStep;

  private _statuses: Status[];
  public statuses: Status[];

  @Input() showStep: boolean = false;
  @Input() shortForm: boolean = false;
  @Input() shortFormRun: boolean = false;
  @Input() queryParams: Params;

  @Input() set testRun(data: TestRun) {
    if (data) {
      this._testRun = data;
      this.getFailedTestCases(data.id);
    }
  } get testRun() {
    return this._testRun
  };

  @Output() showTestStep = new EventEmitter<TestCase>()
  @Output() closeEvent = new EventEmitter();
  @Output() hideCollapseRun = new EventEmitter();
  @Output() collapseEvent = new EventEmitter<string>();

  constructor(
    private projectService: ProjectService,
    private testRunService: TestRunService,
    private testCasesService: TestCaseService,
    private userService: UserService,
    public api: ApiBaseService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.setSelectionList()
    this.testCasesService.updateRightSectionOpen(true);
  }

  ngOnDestroy(): void {
    this.hideCollapseRun.emit();
    this.testCasesService.updateRightSectionOpen(false);
  }

  getStatuses() {
    const currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
    let id = currentProject
    if (isNaN(+currentProject)) {
      id = this.projectService.getPrivatProject[0].id
    }
    return this.testCasesService.getTestCaseStatuses({ project_id: id }).pipe(
      map(val => {
        this._statuses = val;
        this.statuses = val;
        return val;
      })
    )
  }

  setSelectionList() {
    this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true })
    });

    this.userService.getUsers().subscribe(res => {
      this.users = res
    })
  }

  selectionChange(event: MatSelectionListChange) {
    event.options.forEach(item => {
      this.showTestStep.emit(item.value);
    })
  }

  getUsers(): Promise<User[]> {
    return new Promise<User[]>(resolve => {
      this.userService.getUsers().subscribe((users: User[]) => {
        // Pushing pseudo user Unassigned into the array
        const unassigned = new User();
        unassigned.id = '-1';
        unassigned.firstName = 'Unassigned'
        users = [unassigned, ...users];

        resolve(users);
      });
    })
  }

  getFailedTestCases(trId: string) {
    this.testRunService.getFailedCasesInTestRun(trId).subscribe((testCases: TestCase[]) => {
      testCases.map(testCase => {
        // For future filtering
        testCase.filteredByPerson = '1';
        testCase.filteredByStatus = '1';

        // Defect already created?
        testCase.defectCreated = testCase.outerBugtrackingLink ? 'Yes' : 'No'
      })
      this._testCase = testCases;
      this.testCase = testCases;
      if (this.queryParams.td) this.searchPath(this.queryParams);
    })
  }

  searchPath(param: Params) {
    let find = this.testCase.find(elem => +elem.relationId === +param.td);
    if (find) {
      let sub = this.selectionList.options.changes.subscribe(res => {
        res.forEach(elem => { if (+elem.value.relationId === +param.td) elem.selected = true });
        if (sub) sub.unsubscribe();
      });
      this.showTestStep.emit(find);
    }
  }

  async onAssignedToEvent(item) {
    const users = await this.userService.getUsers().toPromise()
    this.dialog.open(DialogSelectComponent, {
      width: '650px',
      data: {
        title: 'Change Status',
        item: item,
        lists: users,
        value: 'assignedTo',
        showName: 'firstName'
      }
    }).afterClosed().subscribe((res: TestCase) => {
      if (res) {
        this.testRunService.asssignNewUserToTestCase({ relation_id: res.relationId, new_user_id: res.assignedTo }).subscribe(result => {
          if (result) {
            this.snackBar.open(`Test Case '${res.title}' assingee was updated`, 'OK', { duration: 3000 });
          } else {
            this.snackBar.open('Error happened while updating the assingee', 'OK', { duration: 5000 });
          }
        })
      }
    })
  }

  getAssigneTo(number: string) {
    if (this.users) this.assigneTo = this.users.find(item => item.id === number);
    if (this.assigneTo) return this.assigneTo.firstName;
    return 'assignment_ind';
  }

  copyLink() {
    if (window.location.href) {
      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
      this.clipboard.copy(window.location.href);
    }
  }
}
