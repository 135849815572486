const testsCreate = 'testCase/getCreatedCases', testsComplited = 'testCase/getTestCaseCompleted', testsChanged = 'testCase/getModifiedCases', testsFailures = 'testCase/getFailedTestCasesByTestRuns', testsEscalated = 'testCase/getDefectCases';
export class DashboardControllers {
    constructor(api) {
        this.api = api;
    }
    //getTestsCreated
    testsCreated(payload) {
        return this.api.postRequest(testsCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data.data;
            else
                throw Error(res.data);
        });
    }
    //testsComplited
    testsComplited(payload) {
        return this.api.postRequest(testsComplited, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data.data;
            else
                throw Error(res.data);
        });
    }
    //testsChanged
    testsChanged(payload) {
        return this.api.postRequest(testsChanged, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data.data;
            else
                throw Error(res.data);
        });
    }
    // //testsEscalated
    testsEscalated(payload) {
        return this.api.postRequest(testsEscalated, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data.data;
            else
                throw Error(res.data);
        });
    }
    //testsFailures
    testsFailures(payload) {
        return this.api.postRequest(testsFailures, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data.data;
            else
                throw Error(res.data);
        });
    }
}
