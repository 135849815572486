import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { TestCaseService } from '../services/test-case.service';
import { TestCase } from '../services/test-case.type';
import { TestStep } from '../services/test-step.type';
import { TestStepService } from '../services/test-step.service';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-case-details',
  templateUrl: './test-case-details.component.html',
  styleUrls: ['./test-case-details.component.scss']
})
export class TestCaseDetailsComponent implements OnInit {
  testSteps: TestStep[] = [];
  testStep: TestStep = new TestStep();
  testCase: TestCase;
  editMode = false;
  saving = false;


  constructor(
    private route: ActivatedRoute,
    private service: TestCaseService,
    private testStepService: TestStepService,
    private router: Router) { }

  ngOnInit() {
    const tuple = this.route.paramMap.pipe(
      map(params => {
        return this.service.getTestCase({ id: params.get('id'), project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) });
      }), publishReplay(1), refCount()
    );

    const testSuit$ = tuple.pipe(switchMap(t => t));
    testSuit$.subscribe(c => {
      this.testCase = c;
      this.testStepService.getTestSteps(c.id).subscribe(res => {
        this.testSteps = res
      });
    });
  }

  beginEdit(): void {
    this.editMode = true;
  }

  saveEdit(): void {
    // this.saving = true;
    // this.editMode = false;
    //
    // this.service.saveTestCase({testCase: this.testCase, testSteps: this.testSteps}).subscribe(() => {
    //   this.saving = false;
    // });
  }

  createStep(): void {
    this.testStepService.createTestStep(this.testCase.id, this.testStep).subscribe(res => {
      this.testSteps.push(this.testStep);
      this.testStep = new TestStep();
    })
  }

  deleteStep(testStep: TestStep): void {
    this.testStepService.deleteTestStep(testStep.id).subscribe(res => {
      const index = this.testSteps.indexOf(testStep);
      this.testSteps.splice(index, 1);
    })
  }

  deleteCase(): void {
    this.service.deleteTestCase({ id: this.testCase.id }).subscribe(res => {
      this.router.navigate(['test-cases']);
    })
  }

}
