<app-sidenav>
  <mat-card>
    <button mat-raised-button color="primary" (click)="createUser()"><mat-icon>add_box</mat-icon>CREATE</button>
  </mat-card>
  <mat-card>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>FIRST NAME</mat-label>
      <input matInput [(ngModel)]="user.firstName">
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>LAST NAME</mat-label>
      <input matInput [(ngModel)]="user.lastName">
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>LOGIN</mat-label>
      <input matInput [(ngModel)]="user.login">
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>PASSWORD</mat-label>
    <input matInput [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="user.password">
    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
      <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    </mat-form-field>
  </mat-card>
</app-sidenav>
