import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestSuit } from '../services/test-suit.type';
import { Project } from '../services/project.type';
import { ProjectService } from '../services/project.service';
import { TestSuitService } from '../services/test-suit.service';
import { BehaviorSubject } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';

export class TodoItemNode {
  id: string;
  children: TodoItemNode[];
  item: string;
}

export class TodoItemFlatNode {
  id: string;
  item: string;
  level: number;
  expandable: boolean;
}

@Injectable()
export class ChecklistDatabase {
  TREE_DATA = {};
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor() { }

  setupTreeData(treeData) {
    this.TREE_DATA = treeData;
  }

  initialize() {
    const data = this.buildFileTree(this.TREE_DATA, 0);
    this.dataChange.next(data);
  }
  buildFileTree(obj: any, level: number): TodoItemNode[] {
    const tree = Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key].testCases;
      if (value !== undefined && level === 0) {
        if (value.length < 1) {
          return accumulator;
        }
      }

      const node = new TodoItemNode();
      node.item = obj[key].title;
      node.id = obj[key].id;

      if (value != null) {
        node.children = this.buildFileTree(value, level + 1);
      }
      return accumulator.concat(node);

    }, []);
    return tree;
  }
}

export interface DialogTestCasesSelectData {
  testSuits: TestSuit[];
  selectedTestCases: TestSuit[];
}

@Component({
  selector: 'app-add-new-test-suit',
  templateUrl: './add-new-test-suit.component.html',
  styleUrls: ['./add-new-test-suit.component.scss']
})
export class AddNewTestSuitComponent implements OnInit {
  testSuit: TestSuit = new TestSuit();
  testSuits: TestSuit[];
  selectedTestCases: any[];
  projects: Project[];

  constructor(
    private projectService: ProjectService,
    private testSuitService: TestSuitService, public dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
    this.projectService.getProjects().subscribe(res => {
      this.projects = res;
    });

    this.testSuitService.getTestSuits({ options: { test_cases: true } }).subscribe(res => {
      this.testSuits = res;
    });
  }

  createSuit() {
    this.testSuitService.createTestSuit({ testSuit: this.testSuit, testCases: this.selectedTestCases }).subscribe(res => {
      this.router.navigate(['test-suits']);
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogTestCasesSelect, {
      width: '650px',
      data: { testSuits: this.testSuits, selectedTestCases: this.selectedTestCases }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.mapSelectedTestCases(result);
    });
  }

  mapSelectedTestCases(treeData) {
    if (treeData) {
      const selectedTestCases = [];
      treeData.forEach(function (value) {
        if (value.level === 1) {
          selectedTestCases.push(value);
        }
      });
      this.selectedTestCases = selectedTestCases;
    } else {
      this.selectedTestCases = [];
    }
  }

}

@Component({
  selector: 'app-dialog-test-cases-select',
  templateUrl: 'dialog-test-cases-select.html',
  providers: [ChecklistDatabase]
})
export class DialogTestCasesSelect {
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true);

  constructor(
    public dialogRef: MatDialogRef<DialogTestCasesSelect>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTestCasesSelectData,
    private _database: ChecklistDatabase) {
    this._database.setupTreeData(this.data.testSuits);
    this._database.initialize();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }
  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
