import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TestRun } from '../services/test-run.type';
import { ActivatedRoute, Router } from '@angular/router';
import { TestRunService } from '../services/test-run.service';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { TestCaseService } from '../services/test-case.service';
import { User } from '../services/user.type';
import { TestCase } from '../services/test-case.type';
import { TestStep } from '../services/test-step.type';
import { TestStepService } from '../services/test-step.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogTestCasesSelectComponent } from '../dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { TestSuit } from '../services/test-suit.type';
import { TestSuitService } from '../services/test-suit.service';
import { DialogQrCodeComponent } from '../dialogs/dialog-qr-code/dialog-qr-code.component';
import { Status } from '../services/status.type';
import { AuthenticationService } from '../services/authentication.service';
import { ApiBaseService } from '../services/api-base.service';
import { DefaultStatuses } from '../services/default-statuses.type';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Core } from '../services/core.service';


@Component({
  selector: 'app-test-run-details',
  templateUrl: './test-run-details.component.html',
  styleUrls: ['./test-run-details.component.scss'],
  animations: [
    trigger('changeSideComponentSize', [
      state('hide', style({ width: '0%' })),
      state('start', style({ width: '0%' })),
      state('small', style({ width: '45%' })),
      state('medium', style({ width: '50%' })),
      state('large', style({ width: '55%' })),
      transition('hide => small', animate('300ms ease-out')),
      transition('start => small', animate('300ms  ease-out')),
      transition('small => medium', animate('300ms ease-out')),
      transition('small => large', animate('300ms ease-out')),
      transition('medium => large', animate('300ms ease-out')),
      transition('large => medium', animate('300ms ease-out')),
      transition('medium => small', animate('300ms ease-out')),
      transition('large => hide', animate('300ms ease-out')),
      transition('large => small', animate('300ms ease-out')),
      transition('medium => hide', animate('300ms ease-out')),
      transition('small => hide', animate('300ms  ease-out'))
    ]),
  ]
})

export class TestRunDetailsComponent implements OnInit, OnDestroy {
  testRun: TestRun = new TestRun();
  testCase: TestCase = new TestCase();
  testSteps: TestStep[] = [];
  testSuits: TestSuit[] = [];
  selectedTestCases: TestCase[] = [];
  editMode: boolean;
  saving = false;
  statuses: Status[] = [];
  users: User[] = [];
  selectedStatuses: Status[] = [];
  selectedPerson: User[] = [];
  selectedPersonName: string[] = [];
  @ViewChild('SetStatusSelect', { static: false }) public SetStatusSelect: any;
  @ViewChild('SetPersonSelect', { static: false }) public SetPersonSelect: any;
  filterOpened = {
    SetStatusSelect: false,
    SetPersonSelect: false
  }
  currentProjectId: string;
  relationId: string;
  testRunId: string;
  testCaseId: string;
  defaultStatuses = new DefaultStatuses();
  componentShowHide = 'start';
  public editTestRunForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testRunService: TestRunService,
    private userService: UserService,
    private testCasesService: TestCaseService,
    private testCaseService: TestCaseService,
    private testStepService: TestStepService,
    private testSuitService: TestSuitService,
    public dialog: MatDialog,
    public auth: AuthenticationService,
    private apiBaseService: ApiBaseService
  ) { }

  displayedColumns: string[] = ['id', 'title', 'status', 'assigned_to'];

  async ngOnInit() {
    // Setting init data if sidebar was opened
    const initData = Core.localStorageService.getItem('testCase');
    if (initData) {
      this.selectedTestCases = JSON.parse(initData);
      this.testRun = JSON.parse(Core.localStorageService.getItem('testRun'));
    }
    // Fetching Initial data
    const tuple = this.route.paramMap.pipe(
      map(params => {
        this.testRunId = params.get('id');
        this.testCaseId = params.get('testCaseId');
        return this.testRunService.getTestRun({
          id: params.get('id'),
          testCaseId: params.get('testCaseId')
        });
      }), publishReplay(1), refCount()
    );

    // Getting users
    this.users = await this.getUsers();

    const testRun$ = tuple.pipe(switchMap(t => t));
    testRun$.subscribe(async (testRun: TestRun) => {
      // Fetching current Test Run
      this.testRun = testRun;
      this.initForm()
      this.currentProjectId = this.testRun.projectId;

      // Getting Test Run Cases
      this.selectedTestCases = await this.getTestRunCases();

      // Getting Project Statuses
      this.statuses = await this.getProjectStatuses();
      // Counting Statuses quantity to be rendered in header
      this.updateStatusCounters();

      if (this.testCaseId) this.openSideComponent();
    });
  }

  initForm() {
    this.editTestRunForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.testRun.title, [Validators.required]),
      description: new UntypedFormControl(this.testRun.description, [Validators.required]),
    })
  }

  get f() {
    return this.editTestRunForm.controls
  }

  getUsers(): Promise<User[]> {
    return new Promise<User[]>(resolve => {
      this.userService.getUsers().subscribe((users: User[]) => {
        // Pushing pseudo user Unassigned into the array
        const unassigned = new User();
        unassigned.id = '-1';
        unassigned.firstName = 'Unassigned'
        users = [unassigned, ...users];

        resolve(users);
      });
    })
  }

  getTestSuits(): Promise<TestSuit[]> {
    return new Promise<TestSuit[]>(resolve => {
      this.testSuitService.getTestSuits({}).subscribe(async suits => {
        suits = await this.getSuitCases(suits);
        resolve(suits);
      });
    })
  }

  getTestRunCases(): Promise<TestCase[]> {
    return new Promise<TestCase[]>(resolve => {
      this.testCasesService.getTestCasesByTestRun({
        tr_id: this.testRun.id
      }).subscribe(testCases => {
        testCases.map(testCase => {
          testCase.filteredByPerson = '1';
          testCase.filteredByStatus = '1';
        })
        resolve(testCases);
      })
    })
  }

  getProjectStatuses() {
    return new Promise<Status[]>(resolve => {
      // Getting this project statuses
      this.testCasesService.getTestCaseStatuses({ project_id: this.currentProjectId }).subscribe(statuses => {
        statuses = this.mapStatuses(statuses);
        resolve(statuses);
      });
    })
  }

  async openSideComponent() {
    // if test case id was passed into URL we open detailed page with sidebar
    this.testCase = this.selectedTestCases.filter((testcase: TestCase) => {
      if (testcase.testCaseId === this.testCaseId) {
        return true;
      } else {
        return false;
      }
    })[0];

    this.setActiveRow(this.testCase.tsId, this.testCase.testCaseId);
    this.componentShowHide = 'small';

    // Getting test Steps for current testCase
    this.testSteps = await this.getTestCaseSteps(this.testCase.relationId);
  }

  doneAnimation() {
    // Once sideBar was closed, we redirect the user onto test-cases URL
    if (this.router.url.includes('test-run/') && this.componentShowHide === 'hide') {
      this.router.navigate([`test-run/${this.testRun.id}`]);
    }
  }

  getTestCaseSteps(relationId: string): Promise<TestStep[]> {
    return new Promise<TestStep[]>(resolve => {
      this.testStepService.getTestStepsByRelationId({ relation_id: relationId }).subscribe((steps: TestStep[]) => {
        steps = this.mapTestCaseSteps(steps);
        resolve(steps);
      })
    })
  }

  getSuitCases(testSuits: TestSuit[]): Promise<TestSuit[]> {
    return Promise.all(testSuits.map((s => {
      return new Promise<TestSuit>((resolve) => {
        this.testCaseService.getTestCasesByTestSuit({ ts_id: s.id }).subscribe(cases => {
          cases.map(c => c.tsId = s.id);
          s.testCases = cases;
          resolve(s);
        });
      });
    })))
  }

  mapStatuses(statuses: Status[]): Status[] {
    return statuses.map(status => {
      if (status.statusState === '0' && status.isDefault === '1') {
        this.defaultStatuses.defaultNeutralStatus = status;
      }
      if (status.statusState === '-1' && status.isDefault === '1') {
        this.defaultStatuses.defaultNegativeStatus = status;
      }
      status.quantity = 0;
      return status
    })
  }

  beginEdit(): void {
    this.editMode = true;
  }
  saveEdit(): void {
    this.saving = true;
    this.editMode = false;

    this.testRun.title = this.editTestRunForm.value.title;
    this.testRun.description = this.editTestRunForm.value.description;

    this.testRunService.saveTestRun(this.testRun).subscribe(() => {
      this.saving = false;
    });
  }

  onResize(size: string): void {
    this.componentShowHide = size;
  }

  openTestCaseDetail(testCase: TestCase): void {
    this.router.navigate([`test-run/${this.testRunId}/${testCase.testCaseId}`]);
    this.testCase = testCase;
    // Saving tesCases in local strorage to deisplay on init
    Core.localStorageService.setItem('testCase', JSON.stringify(this.selectedTestCases.slice(0, 15)));
    Core.localStorageService.setItem('testRun', JSON.stringify(this.testRun));
    // Setting Active Row
    this.setActiveRow(testCase.tsId, testCase.testCaseId);
    if (this.componentShowHide === 'start' || 'hide') {
      this.onResize('small');
    }
    this.testStepService.getTestStepsByRelationId({ relation_id: testCase.relationId }).subscribe(result => {
      this.testSteps = this.mapTestCaseSteps(result);
    })
  }

  async openDialog() {
    // Getting Test Suits
    this.testSuits = await this.getTestSuits();

    const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
      width: '650px',
      data: { testSuits: this.testSuits, selectedTestCases: this.selectedTestCases, title: 'SelectFromRun' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.testRunService.updateTestCasesList({
          test_run_id: this.testRun.id,
          test_cases: result.filter(c => {
            return c.level === 1;
          }).map(c => { return { tc_id: c.id, ts_id: c.tsId }; })
        }).subscribe(() => { location.reload() })
      }
    });
  }

  openQrCodeDialog(): void {
    const v = {
      test_run_id: this.testRun.id,
      user_id: this.auth.getUser().id,
      token: Core.localStorageService.getItem('token'),
      api_url: this.apiBaseService.getApiUrl(),
      api_title: new URL(this.apiBaseService.getApiUrl()).hostname
    };
    const dialogRef = this.dialog.open(DialogQrCodeComponent, {
      width: '450px',
      data: { value: JSON.stringify(v) }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  deleteRun(): void {
    this.testRunService.deleteTestRun({ id: this.testRun.id }).subscribe(() => {
      this.router.navigate(['test-runs']).then(() => { });
    })
  }

  goToTestRuns() {
    this.router.navigate(['test-runs']).then(() => { });
  }

  updateStatusCounters() {
    // Recetting all counters
    this.statuses.map(status => {
      status.quantity = 0;
      return status;
    })
    // Setting updated value
    this.selectedTestCases.map(testCase => {
      this.statuses.map(status => {
        if (status.id === testCase.status) {
          status.quantity += 1;
        }
      })
    })
  }

  mapTestCaseSteps(steps) {
    // if testStep doesn't have any status, we set it to neutral
    steps.map(step => {
      step.showUndo = false;
      if (step.status == null) {
        step.status = this.defaultStatuses.defaultNeutralStatus.id;
      }
    })
    // mapping status_states to TestSteps
    steps.map(step => {
      this.statuses.map(status => {
        if (status.id === step.status) {
          step.statusObject = status;
          switch (step.statusObject.statusState) {
            case '-1':
              step.statusText = 'Test step failed';
              break;
            case '1':
              step.statusText = 'Test step passed';
              break;
          }
        }
      })
    })
    return steps;
  }

  reRun(): void {
    this.testRunService.reRun({ tr_id: this.testRun.id }).subscribe(run => {
      this.router.navigate(['test-run', run.id]).then(() => { });
    });
  }

  onTestStepFail(testStepId: string) {
    this.testSteps.map((testStep: TestStep) => {
      if (testStep.id === testStepId) {
        testStep.status = this.defaultStatuses.defaultNegativeStatus.id;
        testStep.statusObject = this.defaultStatuses.defaultNegativeStatus;
        testStep.statusText = 'Test step failed';
      }
    })
  }

  setActiveRow(tsId: string, testCaseId: string) {
    this.selectedTestCases.map((testCase: TestCase) => {
      testCase.active = (testCase.tsId === tsId && testCase.testCaseId === testCaseId) ? true : false;
    })
  }

  ngOnDestroy() {
    if (!this.router.url.includes('test-run')) {
      Core.localStorageService.removeItem('testCase');
      Core.localStorageService.removeItem('testRun');
    }
  }
}
