import { Component, OnInit } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { AuthenticationService } from '../services/authentication.service';
import { Project } from '../services/project.type';
import { ProjectService } from '../services/project.service';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('openCloseSidenav', [
      state('open', style({ width: '240px', })),
      state('closed', style({ width: '70px', })),
      transition('open <=> closed', [animate('0.1s')]),
    ]),
    trigger('openCloseSidenavContent', [
      state('open', style({ 'margin-left': '240px', })),
      state('closed', style({ 'margin-left': '70px', })),
      transition('open <=> closed', [animate('0.1s')]),
    ]),
  ]
})
export class SidenavComponent implements OnInit {

  user$: string;
  projects: Project[];
  selectedProject: string;
  selectedProjectName: string;
  componentSizeToggle: string;
  isMenuOpen: boolean;
  selectOpen = false;
  isOpen = false;
  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 10
    }
  ];
  creationMode;

  constructor(
    public auth: AuthenticationService,
    private projectService: ProjectService,
    public router: Router,
    private dataService: DataService) { }

  ngOnInit(): void {
    // Receiving edtitMode from sidebar component
    this.dataService.creationModeType.subscribe(newstate => this.creationMode = newstate)
    this.projectService.selectedProjectName.subscribe(name => this.selectedProjectName = name);

    this.user$ = this.auth.getUser();
    this.getMenuState();

    this.projectService.getProjects().subscribe(res => {
      this.projects = res;
      const projects = JSON.stringify(this.projects)
      Core.localStorageService.setItem('projects', projects);
      this.projects.push({ id: 'all', title: 'All', description: '', createdDate: '', modifiedDate: '', users: [], creator_id: '', creator_name: '' });
      this.getSelectedProject();
    });
  }

  onProjectSelection() {
    Core.localStorageService.setItem('selected_project', this.selectedProject);
    window.location.reload();
  }

  selectProject(project: Project) {
    this.selectedProject = project.id;
    this.selectedProjectName = project.title;
    Core.localStorageService.setItem('selected_project', this.selectedProject);
    window.location.reload();
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
    Core.localStorageService.setItem('menu_state', this.isMenuOpen === true ? 'true' : 'false');
  }

  getSelectedProject() {
    if (Core.localStorageService.getItem('selected_project') != null) {
      this.selectedProject = Core.localStorageService.getItem('selected_project');
      this.projectService.changeSelectedProjectName(this.projects.find(element => element.id === this.selectedProject).title);
    } else {
      Core.localStorageService.setItem('selected_project', this.projects[0].id);
      this.selectedProject = this.projects[0].id;
      this.projectService.changeSelectedProjectName(this.projects[0].title);
    }
  }

  getMenuState() {
    if (Core.localStorageService.getItem('menu_state') != null) {
      this.isMenuOpen = (Core.localStorageService.getItem('menu_state') === 'true');
    } else {
      Core.localStorageService.setItem('menu_state', 'false');
      this.isMenuOpen = false;
    }
  }

  onCreationMode() {
    this.creationMode = !this.creationMode
    this.dataService.changeCreationMode(this.creationMode);
    if (this.creationMode === true && this.router.url.includes('test-suit/')) {
      // When Creation Mode chosen on test suit details page - we chose this suit in creation mode select on Test cases page
      Core.localStorageService.setItem('chosenTestSuit', this.router.url.replace('/test-suit/', ''))
      this.router.navigate(['test-cases']);
    }
    if (this.creationMode === true && !this.router.url.includes('test-case/')) {
      this.router.navigate(['test-cases'])
    }
  }
}
