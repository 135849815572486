import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Project} from '../services/project.type';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {DialogUsersSelectComponent} from '../dialogs/dialog-users-select/dialog-users-select.component';
import {User} from '../services/user.type';
import {UserService} from '../services/user.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

  public data: User[];
  public filterValue: string = '';
  public displayedColumns: string[] = ['id','firstName', 'lastName'];

  project: Project = new Project();
  users: User[] = [];
  editMode = false;
  selectedUsers: any[] = [];

  editProjectForm:UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    const tuple = this.route.paramMap.pipe(
      map(params => {
        return this.projectService.getProject({project_id:params.get('id')});
      }), publishReplay(1), refCount()
    );

    const project = tuple.pipe(switchMap(t => t));
    project.subscribe(currentProject => {
      this.project = currentProject;
      this.initForm();
      this.userService.getUserByProject(this.project.id).subscribe( (users: User[]) => {
        this.users = users
        this.data = this.users.map(val => {
          return Object.assign({}, ...Object.keys(val).map(key => ({[this.snakeToCamel(key)]: val[key]})))
        })

        users.map(x => {
          this.selectedUsers.push(x.id);
        })
      })
    });

    this.userService.getUsers().subscribe(res => {
      this.users = res;
    });
  }

  initForm() {
    this.editProjectForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.project.title, [Validators.required]),
      description: new UntypedFormControl(this.project.description, [Validators.required]),
    })
  }

  get f() {
    return this.editProjectForm.controls
  }

  snakeToCamel(s){
    return s.replace(/(\_\w)/g, (m) => m[1].toUpperCase() );
  }

  beginEdit(): void {
    this.editMode = true;
  }

  saveEdit(): void {
    this.editMode = false;
    const formValue = this.editProjectForm.value;

    this.project.title = formValue.title,
    this.project.description = formValue.description;

    this.projectService.saveProject(this.project).subscribe(() => {});
  }

  deleteProject(): void {
    this.projectService.deleteProject(this.project.id).subscribe(() => {
      this.router.navigate(['projects']).then(() => {});
    })
  }

  openDialogUsersSelect(): void {
    const dialogRef = this.dialog.open(DialogUsersSelectComponent, {
      width: '650px',
      data: {users: this.users, selectedOptions: this.selectedUsers}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.projectService.updateProjectAccess(+this.project.id, result).subscribe(() => {
          document.location.reload();
        })
      }
    });
  }

  goToProjects() {
    this.router.navigate(['projects']).then(() => {});
  }

}
