<div class="dialog-title">
  <h1 mat-dialog-title>Create New User</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <form [formGroup]="userForm">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Login</mat-label>
      <input matInput formControlName="login">
      <mat-error *ngIf="f.login.hasError('required')">
        Login is required
      </mat-error>
      <mat-error *ngIf="f.login.hasError('email')">
        This field must be an email
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Password</mat-label>
      <label>
        <input matInput [type]="hiddenPassword ? 'password' : 'text'" formControlName="password">
        <button id="hide-password-btn" type="button" mat-icon-button matSuffix (click)="hiddenPassword = !hiddenPassword" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hiddenPassword">
          <mat-icon>{{hiddenPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </label>
      <mat-error *ngIf="f.password.hasError('required')">
        Password is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName">
      <mat-error *ngIf="f.firstName.hasError('required')">
        First Name is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastName">
      <mat-error *ngIf="f.lastName.hasError('required')">
        Last Name is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Select Access Level User</mat-label>
      <mat-select formControlName="userRole" >
        <mat-option *ngFor="let role of usersRolesList" [value]="role.id" >{{role.role_title | titlecase}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button [disabled]="!userForm.valid" color="primary" style="background: #2d64ba" (click)="createUser()">Create</button>
</div>
