<app-sidenav>
  <mat-card id="panel" class="sidenav-opened">
    <div class="sidenav-opened__header">
      <button mat-icon-button class="base-button" (click)="goToProjects()">
        <mat-icon style="color: #000;">arrow_back_ios</mat-icon>
      </button>
      <mat-card-title class="details">
        <span class="details__title">{{project.title}}</span>
        <span class="details__id">
          PROJECT ID - {{project.id}}
        </span>
      </mat-card-title>
      <mat-card-content class="panel-right-side">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="base-button" *ngIf="!editMode">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button [disabled]="!editProjectForm.valid" id="clear-style" (click)="saveEdit()" class="save-icon" *ngIf="editMode">
          <mat-icon>save</mat-icon>
        </button>
        <mat-icon *ngIf="editMode" (click)="editMode = false">close</mat-icon>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openDialogUsersSelect()">Select Users</button>
          <button mat-menu-item (click)="beginEdit()">Edit</button>
          <button mat-menu-item (click)="deleteProject()">Delete</button>
        </mat-menu>
      </mat-card-content>
    </div>
    <form class="edit-mode-dropdown" *ngIf="editMode" [formGroup]="editProjectForm">
      <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="title" [readonly]="!editMode">
        <mat-error *ngIf="f.title.hasError('required')" class="error-outline-field">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" formControlName="description" [readonly]="!editMode">
        <mat-error *ngIf="f.description.hasError('required')" class="error-outline-field">
          Title is required
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card>
  <mat-card class="table-card">
    <app-abstract-table [filter]="filterValue" 
                        [data]="data"
                        [clickabledRow]="false"
                        [columns]="displayedColumns">
    </app-abstract-table>
  </mat-card>
</app-sidenav>
