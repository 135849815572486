import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Core } from './core.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  public creationModeType = new BehaviorSubject<boolean>(false);

  constructor() {
    this.getCreationMode !== null ? this.creationModeType.next(this.getCreationMode) : this.setCreationMode = false
  }

  changeCreationMode(newState: boolean): void {
    this.creationModeType.next(newState);
    this.setCreationMode = newState;
  }

  get getCreationMode(): boolean {
    return JSON.parse(Core.localStorageService.getItem('creationMode'));
  }

  set setCreationMode(state: boolean) {
    Core.localStorageService.setItem('creationMode', JSON.stringify(state))
  }
}
