<app-sidenav>
  <mat-card>
    <button mat-raised-button color="primary" (click)="createSuit()"><mat-icon>add_box</mat-icon>CREATE</button>
    <button mat-raised-button color="primary" (click)="openDialog()"><mat-icon>vertical_split</mat-icon>SELECT TEST SUITS</button>
  </mat-card>
  <mat-card>
    <mat-form-field appearance="standard">
      <mat-label>TITLE</mat-label>
      <input matInput [(ngModel)]="testPlan.title">
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>DESCRIPTION</mat-label>
      <input matInput [(ngModel)]="testPlan.description">
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>PROJECT</mat-label>
      <mat-select [(ngModel)]="testPlan.projectId">
        <mat-option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>
</app-sidenav>
