<div class="dialog-select">

  <div class="dialog-title">
    <h1 mat-dialog-title>Select Users</h1>
  </div>
  <div class="dialog-content" mat-dialog-content>
    <mat-selection-list #list [(ngModel)]="data.selectedOptions">
      <mat-list-option *ngFor="let user of data.users" [value]="user.id">
        {{user.firstName}} {{user.lastName}}
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div class="dialog-actions" mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="null" (click)="onCancel()">Cancel</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="data.selectedOptions" cdkFocusInitial>Select</button>
  </div>
  
</div>
