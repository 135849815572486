import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TestSuitService } from '../services/test-suit.service';
import { TestSuit } from '../services/test-suit.type';
import { DialogCreateTestSuitComponent } from '../dialogs/dialog-create-test-suit/dialog-create-test-suit.component';
import { TestCaseService } from '../services/test-case.service';
import { MatDialog } from '@angular/material/dialog';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-suits',
  templateUrl: './test-suits.component.html',
  styleUrls: ['./test-suits.component.scss']
})
export class TestSuitsComponent implements OnInit {

  public data: TestSuit[];
  public filterValue: string = '';
  public displayedColumns = [];

  testSuits: TestSuit[];
  projects = JSON.parse(Core.localStorageService.getItem('projects'));
  editMode: boolean;

  constructor(
    private testSuitService: TestSuitService,
    private router: Router,
    public dialog: MatDialog,
    private testCaseService: TestCaseService
  ) { }

  ngOnInit() {

    if (Core.localStorageService.getItem('selected_project') === 'all') {
      this.displayedColumns = ['id', 'title', 'projectName', 'description', 'createdDate', 'modifiedDate'];
      // If all projects chosen, we pass no parameters to receive all available test suits in all projects
      this.testSuitService.getTestSuits({}).subscribe(testSuits => {
        this.testSuits = testSuits;
        this.getProjectName();
        this.data = testSuits
      })
    }
    else {
      this.displayedColumns = ['id', 'title', 'description', 'createdDate', 'modifiedDate'];
      // If particular project chosen, we pass it's id as parameter of request to receive test suits in this project
      this.testSuitService.getTestSuits({ project_id: Core.localStorageService.getItem('selected_project') }).subscribe(testSuits => {
        this.testSuits = testSuits;
        this.getProjectName();
        this.data = testSuits
      })
    }
  }

  openCreateDialog(): void {
    this.dialog.open(DialogCreateTestSuitComponent, {
      width: '650px',
      data: { testSuits: this.testSuits }
    });
  }

  navigateToDetail(row: TestSuit) {
    this.router.navigate(['test-suit', row.id]).then(() => { });
  }

  getProjectName() {
    // this.testSuits.map(suit => {
    //   this.projects.map(project => {
    //     if (project.id === suit.projectId) {
    //       suit.projectName = project.title;
    //     }
    //   })
    // })
  }

  onCreationMode(): void {
    this.router.navigate(['test-cases'], { queryParams: { creationMode: true } }).then(() => { });
  }
}
