<div class="mat-dialog-container">
    <div class="dialog-title">
        <h1 mat-dialog-title>Select Test Suits for copy</h1>
    </div>

    <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '35px', background: '#D6DBDF', margin: '15px 0px 0px 0px' }" count="4" appearance="line"></ngx-skeleton-loader>
    <div class="dialog-content" mat-dialog-content>
        <div class="mat-tree-container">
            <mat-tree class="dialog-mat-tree" [dataSource]="treeData" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node;">
                    <button mat-icon-button disabled style="display: none">
                    </button>
                    <mat-checkbox class="checklist-leaf-node"
                                  [checked]="selectedItem === node"
                                  (click)="todoLeafItemSelectionToggle(node)"
                    ></mat-checkbox>
                    {{ node.title }}
                </mat-tree-node>
            </mat-tree>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-button (click)="onCancelClick()">CANCEL</button>
        <button mat-button [mat-dialog-close]="this.checklistSelection.selected" cdkFocusInitial>SELECT
        </button>
    </div>

</div>

