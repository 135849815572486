<form [formGroup]="form" [ngClass]="shortForm ? 'short-form' : ''">

  <mat-form-field appearance="outline" floatLabel="auto" class="width-100">
    <mat-label>Description</mat-label>
    <textarea #textDesc [readonly]="!editAccessAction" matInput formControlName="description" (focus)="descrSize = true"
      (blur)="descrSize = false" [ngStyle]="descrSize ? {height: textHeightFunc(textDesc)} : {height : 'auto'}"
      [ngClass]="!editAccessAction ? 'notResize' : ''"></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="auto" class="width-100">
    <mat-label>Expected result</mat-label>
    <textarea #textExp [readonly]="!editAccessAction" matInput formControlName="expectedResult" (focus)="expSize = true"
      (blur)="expSize = false" [ngStyle]="expSize ? {height: textHeightFunc(textExp)} : {height : 'auto'}"
      [ngClass]="!editAccessAction ? 'notResize' : ''"></textarea>
  </mat-form-field>

  <div class="info-item-list__actions center">
    <ng-container *ngIf="eventAdd">
      <button type="submit" mat-flat-button color="primary" class="info-item-list__add" [disabled]="!form.valid"
        (click)="addStep()">ADD</button>
      <button type="reset" mat-flat-button color="primary" class="info-item-list__add"
        (click)="closeForm()">Close</button>
    </ng-container>
    <ng-container *ngIf="eventEdit">
      <div class="flex-center">
        <button [style.margin.px]="7" type="submit" mat-flat-button color="primary" class="info-item-list__save"
          [disabled]="!form.valid || !editAccessAction" (click)="updateStep()">SAVE</button>
        <button [class]="!shortForm ? 'item-list__body-copy' : 'item-list__body-copy absolute'" mat-icon-button
          matTooltip="Copy link" (click)="copyLink()">
          <mat-icon>link</mat-icon>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="eventDelete">
      <button type="reset" mat-flat-button color="warn" class="info-item-list__delete" (click)="deleteStep()"
        [disabled]="!deleteAccessAction">DELETE</button>
    </ng-container>

  </div>

</form>