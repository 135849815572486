<mat-list class="main-list" #list>
  <mat-list-item class="main-list__title">
    <mat-icon *ngIf="currentType === this.appTypes.testRun">directions_run</mat-icon>
    <mat-icon *ngIf="currentType === this.appTypes.testSuit">work</mat-icon>
    <mat-icon *ngIf="currentType === this.appTypes.testPlan">date_range</mat-icon> 
    <mat-icon *ngIf="currentType === this.appTypes.testCase">dns</mat-icon> 
    <mat-icon *ngIf="currentType === this.appTypes.testStep">hourglass_top</mat-icon>
    <p class="main-list__title__text">{{currentType}}s</p>
    <mat-icon *ngIf="canClose && canClose === '1'" class="close" (click)="closeEvent.emit(currentType)">close</mat-icon>
  </mat-list-item>
  <mat-list-item class="main-list__actions actions-main-list">
    <ng-container *ngIf="actions === 'add'">
      <div class="actions-main-list__add" (click)="createNewItemEvent.emit(currentType)">
        <mat-icon>add</mat-icon>
        <p>Add New {{currentType}}</p>
      </div>
    </ng-container>
  </mat-list-item>
  <mat-list-item
    *ngFor="let item of data"
    class="main-list__item"
    (click)="onItemClick(item)"
    [class.active]="item.isOpen">
    <ng-container *ngIf="ifTest">
      <p class="main-list__item__title" [class.test-run]="currentType === this.appTypes.testRun">
        {{item.title || item.description}}
      </p>
      <mat-list class="statuses" *ngIf="currentType === this.appTypes.testRun">
        <mat-list-item class="statuses__item" *ngFor="let status of item.statuses"> 
          <div class="statuses__item__quantity" [ngStyle]="{'background-color': status.color}" [matTooltip]="status.name">{{status.count}}</div>
        </mat-list-item>
      </mat-list>
      <ng-container *ngIf="item.isOpen && ifTest">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="selectEvent.emit(item)">
            <span>Select test cases</span>
          </button>
          <button mat-menu-item *ngIf="!(currentType === this.appTypes.testPlan || currentType === this.appTypes.testSuit)" (click)="reRunEvent.emit(item)">
            <span>Re run</span>
          </button>
          <button mat-menu-item (click)="editEvent.emit(item)">
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="deleteEvent.emit(item)">
            <span>Delete</span>
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="currentType === this.appTypes.testCase">
      <p class="main-list__item__title">
        {{item.title || item.description}}
      </p>
      <ng-container *ngIf="item.isOpen && !ifCreateTestCase">
        <button mat-icon-button [matMenuTriggerFor]="testRunMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #testRunMenu="matMenu">
          <button mat-menu-item (click)="changeStatusEvent.emit(item)">
            <span>Change Status</span>
          </button>
          <button mat-menu-item (click)="assignedToEvent.emit(item)">
            <span>Assigned To</span>
          </button>
        </mat-menu>
      </ng-container>
      <button (click)="onCreateTestCase($event, item)" mat-icon-button matTooltip="Create new Test Case" *ngIf="ifCreateTestCase">
        <mat-icon>directions_run</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="currentType === this.appTypes.testStep">
      <p class="main-list__item__title">
        {{item.title || item.description}}
      </p>
    </ng-container>
  </mat-list-item>
</mat-list>