import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TestSuitsComponent } from './test-suits/test-suits.component';
import { TestSuitDetailsComponent } from './test-suit-details/test-suit-details.component';
import { TestCaseDetailsComponent } from './test-case-details/test-case-details.component';
import { TestStepsTableComponent } from './test-case-details/test-steps-table/test-steps-table.component';
import { TestPlansComponent } from './test-plans/test-plans.component';
import { TestPlanDetailsComponent } from './test-plan-details/test-plan-details.component';
import { TestRunsComponent } from './test-runs/test-runs.component';
import { TestRunDetailsComponent } from './test-run-details/test-run-details.component';
import { SettingsComponent } from './settings/settings.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManageProjectsComponent } from './manage-projects/manage-projects.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectsComponent } from './projects/projects.component';
import { LoginComponent } from './login/login.component';
import { TestCasesComponent } from './test-cases/test-cases.component';
import { AddNewTestCaseComponent } from './add-new-test-case/add-new-test-case.component';
import { AddNewTestSuitComponent, DialogTestCasesSelect } from './add-new-test-suit/add-new-test-suit.component';
import { AddNewTestPlanComponent, DialogTestSuitsSelect } from './add-new-test-plan/add-new-test-plan.component';
import { AddNewTestRunComponent, DialogTestPlansSelectComponent } from './add-new-test-run/add-new-test-run.component';
import { AddNewProjectComponent } from './add-new-project/add-new-project.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { TestCaseDetailsSidebarComponent } from './test-case-details-sidebar/test-case-details-sidebar.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { AnQrcodeModule } from 'an-qrcode';
import { MatMenuModule } from '@angular/material/menu';
import { TestRunDetailsSidebarComponent } from './test-run-details-sidebar/test-run-details-sidebar.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { ManageStatusesComponent } from './manage-statuses/manage-statuses.component';
import { DialogViewAttachmentsComponent } from './dialogs/dialog-view-attachments/dialog-view-attachments.component';
import { CustomTooltipViewAttachmantsComponent } from './custom-tooltip-view-attachmants/custom-tooltip-view-attachmants.component';
import { TestRunDefecsPageComponent } from './test-run-defecs-page/test-run-defecs-page.component';
import { TestRunDetailsTableComponent } from './tables/test-run-details-table/test-run-details-table.component';
import { AbstractTableComponent } from './abstract-table/abstract-table.component';
import { TestRunsMainComponent } from './test-runs-main/test-runs-main.component';
import { MainListComponent } from './test-run-page/main-list/main-list.component';
import { TestRunsTabComponent } from './test-runs-main/test-runs-tab/test-runs-tab.component';
import { TestCasesCreationModeComponent } from './test-runs-main/test-cases-creation-mode/test-cases-creation-mode.component';
import { SubListsComponent } from './test-runs-main/sub-lists/sub-lists.component';
import { BasePageComponent } from './base-page/base-page.component';
import { TestRunPageComponent } from './test-run-page/test-run-page.component';
import { TestPageComponent } from './test-page/test-page.component';
import { CardTestRunComponent } from './test-page/card-test-run/card-test-run.component';
import { CardTestPlanComponent } from './test-page/card-test-plan/card-test-plan.component';
import { CardTestSuitComponent } from './test-page/card-test-suit/card-test-suit.component';
import { CardTestRunCaseComponent } from './test-page/card-test-run-case/card-test-run-case.component';
import { CardTestPlanCaseComponent } from './test-page/card-test-plan-case/card-test-plan-case.component';
import { CardTestSuitCaseComponent } from './test-page/card-test-suit-case/card-test-suit-case.component';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { CardTestRunStepComponent } from './test-page/card-test-run-step/card-test-run-step.component';
import { CardTestPlanStepComponent } from './test-page/card-test-plan-step/card-test-plan-step.component';
import { CardTestSuitStepComponent } from './test-page/card-test-suit-step/card-test-suit-step.component';
import { CardTestRunDefectsComponent } from './test-page/card-test-run-defects/card-test-run-defects.component';
import { CardTestRunDefectsStepComponent } from './test-page/card-test-run-defects-step/card-test-run-defects-step.component';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { CardProjectsComponent } from './projects-page/card-projects/card-projects.component';
import { CardProjectsUsersComponent } from './projects-page/card-projects-users/card-projects-users.component';
import { BaseCardComponent } from './app-shared-component/base-card/base-card.component';
import { TestCasesPageComponent } from './test-cases-page/test-cases-page.component';
import { CardTestCasesComponent } from './test-cases-page/card-test-cases/card-test-cases.component';
import { CardTestCasesStepComponent } from './test-cases-page/card-test-cases-step/card-test-cases-step.component';
import { TestStepFormComponent } from './app-shared-component/test-step-form/test-step-form.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { CardUsersComponent } from './settings-page/card-users/card-users.component';
import { CardManageStatusesComponent } from './settings-page/card-manage-statuses/card-manage-statuses.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { CardDashboardComponent } from './dashboard-page/card-dashboard/card-dashboard/card-dashboard.component';
import { ChartTestsCompletedComponent } from './dashboard-page/card-dashboard/chart-tests-completed/chartTestsCompleted.component';
import { ChartNewTestsCreatedComponent } from './dashboard-page/card-dashboard/chart-new-tests-created/chart-new-tests-created.component';
import { ChartTestsChangedComponent } from './dashboard-page/card-dashboard/chart-tests-changed/chart-tests-changed.component';
import { ChartTestsEscalatedComponent } from './dashboard-page/card-dashboard/chart-tests-escalated/chart-tests-escalated.component';
import { ChartTestsFailuresComponent } from './dashboard-page/card-dashboard/chart-tests-failures/chart-tests-failures.component';
import { BaseChartByRunsComponent } from './dashboard-page/card-dashboard/base-chart-by-runs/base-chart-by-runs.component';
import { BaseChartBySuitsComponent } from './dashboard-page/card-dashboard/base-chart-by-suits/base-chart-by-suits.component';
import { CardTestRunDetailsComponent } from './test-page/card-test-run-details/card-test-run-details.component';
import { TableLastRunsComponent } from './dashboard-page/card-dashboard/teble-last-runs/table-last-runs.component';
import { TestStepRunFormComponent } from './app-shared-component/test-step-run-form/test-step-run-form.component';
import { ChartDiagramsComponent } from './dashboard-page/card-dashboard/chart-diagrams/chart-diagrams.component';
import { ActivityTableComponent } from './dashboard-page/card-dashboard/activity-table/activity-table.component';
import { TestCasesWrapperComponent } from './test-cases-wrapper/test-cases-wrapper.component';
import { UnesignedCasesPageComponent } from './unesigned-cases-page/unesigned-cases-page.component';
import { CardUnesignedCasesComponent } from './unesigned-cases-page/card-unesigned-cases/card-unesigned-cases.component';
import { CardUnesignedStepComponent } from './unesigned-cases-page/card-unesigned-step/card-unesigned-step.component';
import { CardJiraIntegrationComponent } from './jira-integration-page/card-jira-integration/card-jira-integration.component';
import { JiraIntegrationPageComponent } from './jira-integration-page/jira-integration-page.component';
import { DialogChangeProjectComponent } from './dialogs/dialog-change-project/dialog-change-project.component';
import { DialogCreateStatusComponent } from './dialogs/dialog-create-status/dialog-create-status.component';
import { DialogCreateTestCaseComponent } from './dialogs/dialog-create-test-case/dialog-create-test-case.component';
import { DialogCreateUserComponent } from './dialogs/dialog-create-user/dialog-create-user.component';
import { DialogEditComponent } from './dialogs/dialog-edit/dialog-edit.component';
import { DialogEditStatusComponent } from './dialogs/dialog-edit-status/dialog-edit-status.component';
import { DialogEditUserComponent } from './dialogs/dialog-edit-user/dialog-edit-user.component';
import { DialogQrCodeComponent } from './dialogs/dialog-qr-code/dialog-qr-code.component';
import { DialogRunComponent } from './dialogs/dialog-run/dialog-run.component';
import { DialogSelectComponent } from './dialogs/dialog-select/dialog-select.component';
import { DialogTestCaseFailComponent } from './dialogs/dialog-test-case-fail/dialog-test-case-fail.component';
import { DialogUsersSelectComponent } from './dialogs/dialog-users-select/dialog-users-select.component';
import { DialogTestCasesSelectComponent } from './dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { DialogCreateProjectComponent } from './dialogs/dialog-create-project/dialog-create-project.component';
import { DialogCreateTestPlanComponent } from './dialogs/dialog-create-test-plan/dialog-create-test-plan.component';
import { DialogCreateTestRunComponent } from './dialogs/dialog-create-test-run/dialog-create-test-run.component';
import { DialogCreateTestSuitComponent } from './dialogs/dialog-create-test-suit/dialog-create-test-suit.component';
import { DialogCreateSubTestSuitComponent } from './dialogs/dialog-create-sub-test-suit/dialog-create-sub-test-suit.component';
import { DialogConfirmComponent } from './dialogs/dialog-confirm/dialog-confirm.component';
import { DialogTestCasesSelectToMergeComponent } from './dialogs/dialog-test-cases-select-to-merge/dialog-test-cases-select-to-merge.component'
import { DialogConfirmForTestrunComponent } from './dialogs/dialog-confirm-for-testrun/dialog-confirm-for-testrun.component';
import { DialogCreateJiraIntegrationSettingsComponent } from './dialogs/dialog-create-jira-integration-settings/dialog-create-jira-integration-settings.component';
import { ChecklistDatabase, SharedSuitPlanSelectComponent } from './dialogs/shared/dialog-shared-suit-plan-select/shared-suit-plan-select.component';


import { AuthGuard } from './guards/auth.guard';
import { DataService } from './services/data.service';
import { SearchCustomPipe } from './pipes/search-custom.pipe';
import { SearchCases } from './pipes/search-cases.pipe';
import { QuantityPipe } from './pipes/quantity.pipe';
import { ShortNamePipe } from './pipes/short-name.pipe';

import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ColorPickerModule } from 'ngx-color-picker';
import { NgChartsModule } from 'ng2-charts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CardProjectsInfoComponent } from './projects-page/card-projects-info/card-projects-info.component';
import { DialogSelectSuitComponent } from './dialogs/dialog-select-suit/dialog-select-suit.component';
import { DialogCaseConnectionsComponent } from './dialogs/dialog-case-connections/dialog-case-connections.component';
import { CardManagePageComponent } from "./settings-page/card-manage-page/card-manage-page.component";


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidenavComponent,
    TestSuitsComponent,
    TestSuitDetailsComponent,
    TestCaseDetailsComponent,
    TestStepsTableComponent,
    TestPlansComponent,
    TestPlanDetailsComponent,
    TestRunsComponent,
    TestRunDetailsComponent,
    SettingsComponent,
    ManageUsersComponent,
    ManageProjectsComponent,
    ProjectDetailsComponent,
    ProjectsComponent,
    LoginComponent,
    TestCasesComponent,
    AddNewTestCaseComponent,
    AddNewTestSuitComponent,
    AddNewTestPlanComponent,
    AddNewTestRunComponent,
    AddNewProjectComponent,
    AddNewUserComponent,
    TestCaseDetailsSidebarComponent,
    TestRunDetailsSidebarComponent,
    CustomTooltipComponent,
    ManageStatusesComponent,
    CustomTooltipViewAttachmantsComponent,
    TestRunDefecsPageComponent,
    TestRunDetailsTableComponent,
    AbstractTableComponent,
    TestRunsMainComponent,
    MainListComponent,
    TestRunsTabComponent,
    TestCasesCreationModeComponent,
    SubListsComponent,
    BasePageComponent,
    TestRunPageComponent,
    TestPageComponent,
    CardTestRunComponent,
    CardTestPlanComponent,
    CardTestSuitComponent,
    CardTestRunCaseComponent,
    CardTestPlanCaseComponent,
    CardTestSuitCaseComponent,
    StopPropagationDirective,
    CardTestRunStepComponent,
    CardTestPlanStepComponent,
    CardTestSuitStepComponent,
    CardTestRunDefectsComponent,
    CardTestRunDefectsStepComponent,
    CardTestRunDetailsComponent,
    ProjectsPageComponent,
    CardProjectsComponent,
    CardProjectsUsersComponent,
    BaseCardComponent,
    TestCasesPageComponent,
    CardTestCasesComponent,
    CardTestCasesStepComponent,
    TestStepFormComponent,
    TestStepRunFormComponent,
    SettingsPageComponent,
    CardUsersComponent,
    CardManageStatusesComponent,
    DashboardPageComponent,
    CardDashboardComponent,
    ChartNewTestsCreatedComponent,
    ChartTestsChangedComponent,
    ChartTestsCompletedComponent,
    ChartTestsEscalatedComponent,
    ChartTestsFailuresComponent,
    BaseChartByRunsComponent,
    BaseChartBySuitsComponent,
    SearchCustomPipe,
    SearchCases,
    DialogChangeProjectComponent,
    DialogCreateStatusComponent,
    DialogCreateProjectComponent,
    DialogCreateTestPlanComponent,
    DialogCreateTestRunComponent,
    DialogCreateTestSuitComponent,
    DialogCreateTestCaseComponent,
    DialogCreateUserComponent,
    DialogEditComponent,
    DialogEditStatusComponent,
    DialogEditUserComponent,
    DialogQrCodeComponent,
    DialogRunComponent,
    DialogSelectComponent,
    DialogTestCaseFailComponent,
    DialogUsersSelectComponent,
    DialogUsersSelectComponent,
    DialogViewAttachmentsComponent,
    DialogTestCasesSelectComponent,
    DialogTestCasesSelect,
    DialogTestSuitsSelect,
    DialogTestCasesSelect,
    DialogTestPlansSelectComponent,
    DialogConfirmComponent,
    QuantityPipe,
    ShortNamePipe,
    ChartDiagramsComponent,
    TableLastRunsComponent,
    ActivityTableComponent,
    DialogTestCasesSelectToMergeComponent,
    DialogConfirmForTestrunComponent,
    TestCasesWrapperComponent,
    UnesignedCasesPageComponent,
    CardUnesignedCasesComponent,
    CardUnesignedStepComponent,
    DialogCreateSubTestSuitComponent,
    CardProjectsInfoComponent,
    JiraIntegrationPageComponent,
    CardJiraIntegrationComponent,
    DialogCreateJiraIntegrationSettingsComponent,
    DialogConfirmForTestrunComponent,
    DialogSelectSuitComponent,
    DialogCaseConnectionsComponent,
    SharedSuitPlanSelectComponent,
    CardManagePageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    HttpClientModule,
    MatGridListModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatChipsModule,
    AnQrcodeModule,
    MatMenuModule,
    MatSortModule,
    OverlayModule,
    ColorPickerModule,
    MatTabsModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatRippleModule,
    MatDatepickerModule,
    A11yModule,
    CdkTreeModule,
    CdkTableModule,
    NgChartsModule,
    DragDropModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [
    AuthGuard,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    ChecklistDatabase,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
