<app-sidenav>
  <mat-card>
    <button mat-raised-button color="primary" (click)="createRun()"><mat-icon>add_box</mat-icon>CREATE</button>
    <button mat-raised-button color="primary" (click)="openDialog()"><mat-icon>vertical_split</mat-icon>SELECT TEST PLANES</button>
  </mat-card>
  <mat-card>
    <div>
      <mat-form-field appearance="standard">
        <mat-label>TITLE</mat-label>
        <input matInput [(ngModel)]="testRun.title">
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>PROJECT</mat-label>
        <mat-select [(ngModel)]="testRun.projectId">
          <mat-option *ngFor="let option of projects" [value]="option.id">{{ option.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="standard" class="full-width">
        <mat-label>DESCRIPTION</mat-label>
        <textarea matInput placeholder="Description" [(ngModel)]="testRun.description"></textarea>
      </mat-form-field>
    </div>
  </mat-card>
</app-sidenav>
