import { Injectable } from '@angular/core';
import { TestSuit, TestSuitFolders, TestSuitFoldersTemplate } from './test-suit.type';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TestCaseService } from './test-case.service';
import { TestRunService } from './test-run.service';
import { TestRun } from './test-run.type';
import { Core } from './core.service';
import { TestCase } from './test-case.type';


@Injectable({
  providedIn: 'root'
})
export class TestSuitService {
  private test_suits: TestSuit[];
  private cacheSuits: Map<number, TestSuit> = new Map();
  public sortType: string;
  private styleColors = ['#0000ff1a', '#3cb3711a', '#ee82ee1a', '#ffa5001a', '#ff00001a'];

  constructor(
    private testCaseService: TestCaseService,
    private testRunService: TestRunService
  ) { }

  getTestSuits(param): Observable<TestSuit[]> {
    if (param.project_id === 'null' || param.project_id === 'all') param = {};
    return this.requestTestSuit(param);
  }

  private requestTestSuit(param): Observable<TestSuit[]> {
    return from(Core.testSuit.list(param).catch(error => console.log(error))).pipe(map(res => {
      if (res?.test_suits) {
        this.test_suits = this.mapTestSuits(res.test_suits);
        this.cacheSuits = new Map();
        this.test_suits.forEach(elem => { this.cacheSuits.set(+elem.id, elem) });
        return this.test_suits;
      } else return [];
    }))
  }

  deleteTestSuit(params): Observable<boolean> {
    return from(Core.testSuit.delete(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        this.cacheSuits.delete(+params.id)
        return true
      } else return false
    }))
  }

  deleteTestCasefromTestSuit(param: { test_suit_relation_id: string, delete_anyway?: number }): Observable<{ valid: boolean, msg?: string }> {
    return from(Core.testSuit.deleteTestCase(param).catch(error => console.error(error))).pipe(map(res => {
      if (res.valid === 'true') return { valid: true };
      if (+res.valid === 1) return { valid: true };
      if (+res.valid === 0 && res.error_msg === 'item delete_anyway not set') return { valid: true, msg: res.error_msg };
      else return { valid: false };
    }))
  }

  saveTestSuit(param): Observable<TestSuit> {
    return from(Core.testSuit.update(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        if (this.cacheSuits.has(+res.test_suit.id)) this.cacheSuits.delete(+res.test_suit.id);
        this.cacheSuits.set(+res.test_suit.id, res.test_suit);
        return this.mapTestSuit(res.test_suit);
      } else return null
    }))
  }

  addTestCases(param): Observable<boolean> {
    return from(Core.testSuit.addTestCase(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  updateTestCasesList(param): Observable<{ valid: boolean }> {
    return from(Core.testSuit.updateTestCase(param).catch(error => console.error(error))).pipe(map(res => {
      if (res.valid === 'true') return { valid: true };
      if (+res.valid === 1) return { valid: true };
      if (+res.valid === 0 && res.independent_cases) return { valid: true, msg: res.error_msg, independent_cases: res.independent_cases };
      else return { valid: false };
    }))
  }

  updateRelation(param): Observable<boolean> {
    return from(Core.testSuit.updateRelation(param).catch(error => console.error(error))).pipe(map(res => {
      console.log('suit updaterelation, 75', res)

      if (res) return true
      else return false
    }))
  }

  getTestSuit(param): Observable<TestSuit> {
    return from(Core.testSuit.readList(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestSuit(res.test_suit)
      else return null
    }))
  }

  createTestSuit(params: { testSuit: TestSuit, testCases: TestCase[], parent?: string | number, sort_order?: string | number }): Observable<TestSuit> {
    return from(Core.testSuit.create(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) {
        if (this.cacheSuits.has(+res.test_suit.id)) this.cacheSuits.delete(+res.test_suit.id);
        this.cacheSuits.set(+res.test_suit.id, res.test_suit);
        return this.mapTestSuit(res.test_suit);
      } else return null
    }))
  }

  runSuit(param): Observable<TestRun> {
    return from(Core.testSuit.createTestRunFromSuit(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.testRunService.mapTestRun(res.testRun)
      else return null
    }))
  }

  testPlansSuitList(param): Observable<TestSuit[]> {
    return from(Core.testSuit.testPlanSuitList(param).catch(error => console.error(error))).pipe(map(res => {
      console.log('testPlanSuitList', res)

      if (res) {
        this.test_suits = this.mapTestSuits(res.test_suits);
        return this.test_suits
      }
      else return []
    }))
  }

  // importTestRail(param): Observable<TestSuit[]> {
  //   return from(Core.testSuit.importTestRail(param).catch(error => console.error(error))).pipe(map(res => {
  //     if (res) {
  //       return this.mapTestSuits(res.data);
  //     } else {
  //       return []
  //     }
  //   }))
  // }

  importTestRail(param): Observable<TestSuit[]> {
    return from(Core.testSuit.importTestRail(param).catch(error => error)).pipe(map(res => {
      if (res) {
        return this.mapTestSuits(res.data);
      } else {
        return []
      }
    }))
  }

  foldersList(param): Observable<any> {
    return from(Core.testSuit.foldersList(param).catch(error => console.error(error))).pipe(map((res: any) => {
      if (typeof res === 'object') return res.reduce((acc, key, i) => [...acc, this.mapTestSuitsFolders(key, i)], []);
      else return []
    }))
  }

  addSuitToSuit(param): Observable<{}> {
    return from(Core.testSuit.addSuitToSuit(param).catch(error => console.error(error))).pipe(map(res => {
      if (res === true) return { valid: true }
      else return { valid: false, msg: res.error_msg }
    }))
  }

  deleteSuitFromSuit(param) {
    return from(Core.testSuit.deleteSuitFromSuit(param).catch(error => console.error(error))).pipe(map(res => {
      console.log(res)
    }))
  }

  public getCacheSuits(): TestSuit[] {
    let testSuits: TestSuit[] = [];
    this.cacheSuits.forEach(elem => testSuits.push(elem));
    return testSuits;
  }

  public getCacheSuitById(id): TestSuit[] | TestSuit {
    if (this.cacheSuits.has(id)) return this.cacheSuits.get(id);
    else return null;
  }

  mapTestSuits(raw: any[]): TestSuit[] {
    return raw.map((val: any) => this.mapTestSuit(val));
  }

  mapTestSuit(raw: any): TestSuit {
    const result: TestSuit = new TestSuit();

    result.id = raw.id;
    raw.project_id ? result.projectId = raw.project_id : result.projectId = raw.projectId;
    result.title = raw.title;
    result.description = raw.description;
    result.createdDate = raw.created_date;
    result.modifiedDate = raw.modified_date;
    result.creator_id = raw.creator_id;
    result.creator_name = raw.creator_name;
    raw.created_date ? result.createdDate = raw.created_date : result.createdDate = raw.createdDate;
    raw.modified_date ? result.modifiedDate = raw.modified_date : result.modifiedDate = raw.modifiedDate;
    if (raw.testCases !== undefined) {
      result.testCases = this.testCaseService.mapTestCases(raw.testCases);
    };
    return result;
  }

  sortSuits(allSuits: TestSuit[]) {
    let set = new Set();
    let suits: TestSuit[] = [];
    allSuits.sort((a, b) => +a.id - +b.id).reverse().forEach(elem => { set.add(elem.id) });
    set.forEach(id => {
      let find = allSuits.find(elem => +id === +elem.id);
      if (find) suits.push(find);
    });

    return suits.reverse()
  }

  mapTestSuitsFolders(raw: TestSuitFolders, i): TestSuitFolders {
    const result: TestSuitFolders = new TestSuitFolders();
    result.suit = this.mapTestSuit(raw.suit);
    result.sub_suits = raw.sub_suits;
    result.color = this.styleColors[i % 5];
    raw.cases?.length > 0 ? result.cases = this._mapTestCaseFromFolders(raw.cases) : result.cases = [];

    if (result.sub_suits?.length > 0) {
      result.sub_suits.forEach(elem => { elem[0] = this.mapTestSuitsFolders(elem[0], i); })
    }
    return result
  }

  _mapTestCaseFromFolders(cases): TestCase[] {
    return cases.map((caseItem: any) => {
      return {
        id: caseItem.id,
        title: caseItem.title,
        description: caseItem.description,
        creatorId: caseItem.creator_id,
        createdDate: caseItem.created_date,
        modifiedDate: caseItem.modified_date,
        tsId: caseItem.ts_id,
        testSuitId: caseItem.test_suit_id,
        tesSuitRelationId: caseItem.test_suit_relation_id,
        ref: caseItem.ref,
        independent: caseItem.independent,
        isShow: true,
      }
    });
  }

  public mapSuitFoldersTemplate(testFolder: TestSuitFoldersTemplate, arr = [], level = 0, par: any[] = []): TestSuitFoldersTemplate[] {
    let suitCases = arr;
    let parSuits = [...par, testFolder.suit]
    suitCases.push({ ...testFolder, show: level === 0 ? true : false, level, parents: par });
    if (testFolder.sub_suits?.length > 0) {
      testFolder.sub_suits.forEach(elem => this.mapSuitFoldersTemplate(elem[0], suitCases, level + 1, parSuits));
    }
    return suitCases
  }
}
