<app-base-card [title]="'Test Steps'" [icon]="'hourglass_top'" [showAdd]="false" [showClose]="true"
  (closeEvent)="closeEvent.emit()">

  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3" appearance="line"></ngx-skeleton-loader>

  <mat-accordion [multi]="shortCases && shortPlan">
    <mat-expansion-panel #panel *ngFor="let testStep of testPlanStep; index as i" [expanded]="shortCases && shortPlan"
      [class]="!(shortCases && shortPlan) ? 'list-card__item item-list item-list-accordion' : 'list-card__item item-list item-list-accordion-short-item'"
      [routerLink]="'./'"
      [queryParams]="{ project: queryParams.project, type: queryParams.type, id: queryParams.id, sub: queryParams.sub, level: queryParams.level, tcp: queryParams.tcp, ts: panel.expanded ? testStep.id : null}">
      <mat-expansion-panel-header>
        <mat-panel-title class="item-list-accordion__title">
          <span>{{testStep.description}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="item-list-accordion__info info-item-list">
        <app-test-step-form (onUpdateStep)="updateStep($event)" (onDeleteStep)="deleteStep($event)"
          [form]="testStep.form" [shortForm]="shortCases && shortPlan" [queryParams]="queryParams"
          [testStep]="testStep"></app-test-step-form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</app-base-card>
