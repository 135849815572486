<app-sidenav class="test-runs-details-page-test">
  <mat-card [class.no-underline]="!editMode" id="panel" class="sidenav-opened">
    <div class="sidenav-opened__header">
      <button mat-icon-button class="base-button" (click)="goToTestRuns()">
        <mat-icon style="color: #000;">arrow_back_ios</mat-icon>
      </button>
      <mat-card-title class="details">
        <span class="details__title" *ngIf="testRun && testRun.title">{{testRun.title}}</span>
        <span *ngIf="!testRun || !testRun.title"><mat-spinner [diameter]="20"></mat-spinner></span>
        <span class="details__id" *ngIf="testRun && testRun.id">
          TEST RUN ID - {{testRun.id}}
        </span>
        <span *ngIf="!testRun || !testRun.id"><mat-spinner [diameter]="15"></mat-spinner></span>
      </mat-card-title>
      <ul class="test-runs-statuses" *ngIf="statuses.length > 0">
        <li class="todo test-runs-statuses__item" *ngFor="let status of statuses">
          <div class="title">{{status.name}} - </div>
          <div class="quantity" [ngStyle]="{'color': status.color}">{{status["quantity"]}}</div>
        </li>
      </ul>
      <span *ngIf="statuses.length === 0"><mat-spinner [diameter]="20"></mat-spinner></span>
      <mat-icon class="qr-code-icon" (click) = 'openQrCodeDialog()'>qr_code </mat-icon>
      <div [ngStyle]="{'display': (componentShowHide === 'hide' || componentShowHide === 'start') ? 'none' : 'block'}" class="resize-toggle-group-wrap">
        <mat-button-toggle-group class="resize-toggle-group">
          <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'large'}" (click)="onResize('large')"><mat-icon svgIcon="cell-divide-in-left-area"></mat-icon></mat-button-toggle>
          <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'medium'}" (click)="onResize('medium')"><mat-icon svgIcon="cell-divide-in-center-area"></mat-icon></mat-button-toggle>
          <mat-button-toggle class="resize-toggle mat-button-toggle-checked" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'small'}" (click)="onResize('small')"><mat-icon svgIcon="cell-divide-in-right-area"></mat-icon></mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-icon class="chart-icon">pie_chart</mat-icon>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="base-button" *ngIf="!editMode">
        <mat-icon>more_vert</mat-icon>
      </button>
      <button [disabled]="!editTestRunForm.valid" id="clear-style" mat-icon-button *ngIf="editMode" (click)="saveEdit()" class="save-icon">
        <mat-icon>save</mat-icon>
      </button>
      <mat-icon *ngIf="editMode" (click)="editMode = false">close</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDialog()">Select test cases</button>
        <button mat-menu-item (click)="reRun()">Re run</button>
        <button mat-menu-item (click)="beginEdit()" >Edit</button>
        <button mat-menu-item (click)="deleteRun()">Delete</button>
      </mat-menu>
    </div>
    <form [formGroup]="editTestRunForm"  class="edit-mode-dropdown" *ngIf="editMode">
      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="title" [readonly]="!editMode">
        <mat-error *ngIf="f.title.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" formControlName="description" [readonly]="!editMode">
        <mat-error *ngIf="f.description.hasError('required')">
          Description is required
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card>
  <div class="main-container">
      <app-test-run-details-table
                                [data]="selectedTestCases"
                                [displayedColumns] = "displayedColumns"
                                [users] = "users"
                                [statuses]="statuses"
                                [currentProjectId] = "currentProjectId"
                                [defaultStatuses] = "defaultStatuses"
                                (shouldUpdateCounters) = updateStatusCounters();
                                (openSideComponent) = openTestCaseDetail($event);
                                (failedStep) = "onTestStepFail($event)"
                                style ="display: flex; flex-grow: 1" ></app-test-run-details-table>

    <div class="test-run-details-sidebar" [@changeSideComponentSize]="componentShowHide" (@changeSideComponentSize.done)="doneAnimation()">
      <app-test-run-details-sidebar [testCase]="testCase"
                                    [steps]="testSteps"
                                    [currentProjectId]= "currentProjectId"
                                    [relationId] = "testCase.relationId"
                                    [goBackURLId] = "testRunId"
                                    [defaultStatuses] = "defaultStatuses"
                                    [statuses] = "statuses"
                                    (Resize)="onResize($event)"
      ></app-test-run-details-sidebar>
    </div>
  </div><!-- End of main container-->
</app-sidenav>
