import { Component, OnInit } from '@angular/core';
import { TestCaseService } from '../services/test-case.service';

@Component({
  selector: 'app-test-cases-wrapper',
  templateUrl: './test-cases-wrapper.component.html',
  styleUrls: ['./test-cases-wrapper.component.scss']
})
export class TestCasesWrapperComponent implements OnInit {

  constructor(private testCaseService: TestCaseService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
