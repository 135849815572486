const projectUserList = 'project/projectUsers', staticList = 'user/getStaticData', listUsers = 'user/list', createUser = 'user/create', updateUser = 'user/update', deleteUser = 'user/delete', attachmentsStep = 'user/testStepAttachments', addAttachments = 'user/addAttachment', activityUser = 'user/getActivityLogUser', setCurrentState = 'user/setCurrentState', getCurrentState = 'user/getCurrentState', getUsersRoles = 'user/getAllRoles';
export class UsersControllers {
    constructor(api) {
        this.api = api;
    }
    //get list for projects
    list(payload) {
        return this.api.postRequest(projectUserList, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data.error_msg);
        });
    }
    //get list
    usersList(payload) {
        return this.api.postRequest(listUsers, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //get static users
    getUserStaticData(payload) {
        return this.api.postRequest(staticList, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //create user
    create(payload) {
        return this.api.postRequest(createUser, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update user
    update(payload) {
        return this.api.postRequest(updateUser, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete user
    delete(payload) {
        return this.api.postRequest(deleteUser, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //attachmentsStep user
    attachmentsStep(payload) {
        return this.api.postRequest(attachmentsStep, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //attachmentsStep user
    addAttachments(payload) {
        return this.api.postRequest(addAttachments, payload).then(res => {
            return res.data;
        });
    }
    getActivityUser(payload) {
        return this.api.postRequest(activityUser, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    setCurrentState(payload) {
        return this.api.postRequest(setCurrentState, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    getCurrentState(payload) {
        return this.api.postRequest(getCurrentState, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }

    getUsersRoles(payload) {
        return this.api.postRequest(getUsersRoles, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
}
