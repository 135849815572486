import { Component, OnInit } from '@angular/core';
import { Core } from '../services/core.service';
import { TestCase } from '../services/test-case.type';
import { TestRunService } from '../services/test-run.service';
import { TestRun } from '../services/test-run.type';
import { TestStep } from '../services/test-step.type';

@Component({
  selector: 'app-test-runs-main',
  templateUrl: './test-runs-main.component.html',
  styleUrls: ['./test-runs-main.component.scss']
})
export class TestRunsMainComponent implements OnInit {

  public creationMode = true;
  public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';

  public testRuns: TestRun[] = [];
  public testCases: TestCase[] = [];
  public testSteps: TestStep[] = [];


  constructor(
    private testRunService: TestRunService,
  ) { }

  ngOnInit(): void {
    this.getTestRuns();
  }

  getTestRuns() {
    this.testRunService.getTestRuns(this.currentProject).subscribe((testRuns: TestRun[]) => {
      this.testRuns = testRuns;
      this.testCases = [];
      this.testSteps = [];
    })
  }

  onCreationMode() {

  }

}
