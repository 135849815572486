<div class="container">
  <h3 class="title">{{titleText}}</h3>
  <ng-container *ngIf="this.pieChartDatasets[0].data.length; then canvas; else spin"></ng-container>
  <ng-template #canvas>
    <div class="chart-wrapper">
      <canvas class="canvas" baseChart #baseChart id="diagram-canvas" [type]="'pie'" [datasets]="pieChartDatasets"
        [labels]="pieChartLabels" [options]="pieChartOptions" [height]="400">
      </canvas>
    </div>
  </ng-template>
  <ng-template #spin>
    <div class="loading">
      <img src="../../../../assets/image/loading.gif" alt="pending">
    </div>
  </ng-template>
</div>