import {Component, Inject, OnInit} from '@angular/core';
import {Status} from '../../services/status.type';
import {ProjectService} from '../../services/project.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { newStateValidator } from '../dialog-create-status/validators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  status: Status
}

@Component({
  selector: 'app-dialog-edit-status',
  templateUrl: 'dialog-edit-status.component.html',
  styleUrls: ['./dialog-edit-status.component.scss']
})
export class DialogEditStatusComponent implements OnInit{

  public status: Status = new Status();
  public color: string;
  public statusForm:FormGroup
  constructor(
    public dialogRef: MatDialogRef<DialogEditStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.color = this.data.status.color
  }

  initForm() {
    this.statusForm = new FormGroup({
      name: new FormControl(this.data.status.name, [Validators.required]),
      statusState: new FormControl(this.data.status.statusState, [Validators.required]),
    })

    this.projectService.getAllProjectsStatuses().subscribe(res => {
      let statuses = res;
      this.statusForm.setValidators(newStateValidator(statuses, this.data.status.id))
    })
  }

  get f() {
    return this.statusForm.controls
  }

  updateStatus(): void {
    this.projectService.updateStatus({
      id: this.data.status.id,
      name: this.statusForm.value.name,
      color: this.data.status.color,
      project_id: this.data.status.ProjectId,
      status_state: this.statusForm.value.statusState
    }).subscribe(valid => {
      this.dialogRef.close(valid);
    })
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

}
