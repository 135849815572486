import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewContainerRef } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditComponent } from 'src/app/dialogs/dialog-edit/dialog-edit.component';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestSuit, TestSuitFolders, TestSuitFoldersTemplate } from 'src/app/services/test-suit.type';
import { Params } from '@angular/router';
import { BehaviorSubject, SubscriptionLike } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { DialogCreateTestCaseComponent } from '../../dialogs/dialog-create-test-case/dialog-create-test-case.component';
import { TestStepService } from '../../services/test-step.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DialogCreateSubTestSuitComponent } from 'src/app/dialogs/dialog-create-sub-test-suit/dialog-create-sub-test-suit.component';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-test-suit-case',
  templateUrl: './card-test-suit-case.component.html',
  styleUrls: ['./card-test-suit-case.component.scss']
})
export class CardTestSuitCaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public guestAccessAction: boolean = this._userAccessService.getLevelAccessUser === 4;
  public addAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitAddLevel');
  public addTestCaseAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitAddCaseLevel');
  public editAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitEditLevel');
  public deleteAccessAction: boolean = this._userAccessService.getAccess('testSuit', 'testSuitDeleteLevel');

  @ViewChildren('suit') suitsList: QueryList<any>;
  @ViewChild('main', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;
  public isCollapsed = true;
  public testSuitFolders: TestSuitFolders;
  public activeSuit: number;
  public _testSuitCase: TestCase[];
  public testSuitCases: TestSuitFoldersTemplate[];
  public testSuitCacheCases: TestSuitFoldersTemplate[];

  private _testSuit: TestSuit;
  public selectedValue: TestCase;
  private selectionListSubscriber: SubscriptionLike;
  public pending: boolean = false;
  public deletedItem: TestCase;
  public isLoading = true;

  @Input() set testSuit(data: TestSuit) {
    if (data) {
      this.testStepService.selectTestSuit = data
      this._testSuit = data;
      this.getTestSuitCase(data.id);
    }
  };

  @Input() showStep: boolean = false;
  @Input() shortForm: boolean = false;
  @Input() shortFormSuit: boolean = false;
  @Input() queryParams: Params;

  @Output() showTestStep = new EventEmitter<TestCase>();
  @Output() closeEvent = new EventEmitter();
  @Output() hideCollapseSuit = new EventEmitter();
  @Output() collapseEvent = new EventEmitter<string>();
  @Output() updateTestSuits = new EventEmitter<boolean>();

  constructor(
    private testCaseService: TestCaseService,
    private testSuitService: TestSuitService,
    private testStepService: TestStepService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void {
    this.setSelectionList();
    this.testCaseService.updateRightSectionOpen(true);
  }

  ngOnDestroy(): void {
    this.hideCollapseSuit.emit();
    this.testCaseService.updateRightSectionOpen(false);
    this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : '';
  }

  ngAfterViewInit(): void {
    let sub = this.suitsList?.changes.subscribe(res => {
      if (res) {
        this.scrollTo(null, false);
        sub?.unsubscribe();
      }
    })
  }

  searchPath(param: Params) {
    let find = this.testSuitCases.find(elem => +elem.level === +param.level && +elem.suit.id === +param.sub);
    if (find) {
      this.selectedValue = find.cases.find(item => +item.tesSuitRelationId === +param.tc);
      let sub = this.selectionList.options.changes.subscribe(res => {
        res.forEach(elem => { if (+elem.value.tesSuitRelationId === +param.tc) elem.selected = true });
        if (sub) sub.unsubscribe();
      });

      this.toggleSuit(find, true);
      this.showTestStep.emit(this.selectedValue);
    }
  }

  setSelectionList() {
    this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true });
    });
  }

  getTestSuitCase(id: string) {
    this.isLoading = true;
    this.dataSource = null;
    if (this.testSuitCases) this.testSuitCacheCases = this.testSuitCases;
    this.testSuitCases = null;
    this.testSuitService.foldersList({ ts_id: id }).subscribe(res => {
      this.isLoading = false;
      this.testSuitFolders = res;
      let casesObj = res.reduce((acc, key) => [...acc, this.testSuitService.mapSuitFoldersTemplate(key)], []);
      this.testSuitCases = casesObj.reduce((acc, item) => [...acc, ...item], []);
      this.testSuitCases = this.mapSelectedValue(this.testSuitCases);

      if (!this.dataSource) this.onInitTree();
      this.checkTreeList();

      if (!this.testSuitCacheCases && this.queryParams.tc) this.searchPath(this.queryParams);
    })
  }

  updateModifiedDateSuit() {
    this.testSuitService.getTestSuit({ id: this._testSuit.id }).subscribe(v => {
      this._testSuit.modifiedDate = v.modifiedDate;
    })
  }

  mapSelectedValue(testCaseFolders: TestSuitFoldersTemplate[]): TestSuitFoldersTemplate[] {
    if (this.selectedValue) {
      return testCaseFolders.map(elem => {
        elem.cases = elem.cases.map(item => {
          if (+item.tesSuitRelationId === +this.selectedValue.tesSuitRelationId) {
            item.isOpen = true
            this.showTestStep.emit(item);
          }
          else item.isOpen = false;
          return item
        })
        return elem
      })
    } else return testCaseFolders;
  }

  selectionChange(event: MatSelectionListChange) {
    event.options.forEach(item => {
      this.showTestStep.emit(item.value);
      this.selectedValue = item.value;
      this.activeSuit = item.value.tsId;
    })
  }

  onEditEvent(item: TestCase) {
    this.dialog.open(DialogEditComponent, {
      width: '650px',
      data: { title: item.title, description: item.description }
    }).afterClosed().subscribe(res => {
      let suitLevel = this.treeControl.dataNodes.find(node => +node.id === +item.testSuitId)?.level;
      if (res && item) {
        this.testCaseService.saveTestCase({ ...item, ...res }, suitLevel).toPromise().then(res => {
          if (res) {
            this.snackBar.open('Test Case was successfully edited', "OK", { duration: 3000 });
            this.getTestSuitCase(this._testSuit.id);
            this.scrollTo(null, false, item);
          }
        })
      }
    })
  }

  copyLink() {
    if (window.location.href) {
      let queryParamArr = window.location.href.split('?')[1].split('&');
      let params = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

      if (!params['ts']) this.clipboard.copy(window.location.href);
      else this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=${params['type']}&id=${params['id']}&tc=${params['tc']}`);

      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
    }
  }

  onDeleteCaseEvent(item: TestCase) {
    this.deletedItem = item;
    this.dialog.open(DialogConfirmComponent, {
      width: '650px',
      data: { dialogTitle: 'delete', name: item.title }
    }).afterClosed().subscribe((res: boolean) => {
      if (res && item) {
        this.pending = true;
        this.testCaseService.getTestCasesByTestSuit({ ts_id: item.tsId }).subscribe(result => {
          if (result) {
            this.testSuitService.updateTestCasesList({
              test_suit_id: item.tsId,
              project_id: item.projectId,
              test_cases: result.filter(c => c.tesSuitRelationId !== item.tesSuitRelationId).map(c => ({ tc_id: c.id, ts_id: c.tsId })),
              deleted_test_cases: [{ tc_id: item.id, ts_id: item.tsId }]
            }).subscribe((res: { valid: boolean, independent_cases?: TestCase[], msg?: string }) => {
              if (res.valid === true && res.independent_cases) {
                this.dialog.open(DialogConfirmComponent, {
                  width: '650px',
                  data: { dialogTitle: 'delete', name: res.independent_cases[0].title, errorMsg: 'Unesigned cases' }
                }).afterClosed().subscribe((res: boolean) => {
                  if (res) {
                    this.testSuitService.updateTestCasesList({
                      test_suit_id: this._testSuit.id,
                      project_id: item.projectId,
                      test_cases: result.filter(c => c.tesSuitRelationId !== item.tesSuitRelationId).map(c => ({ tc_id: c.id, ts_id: c.tsId })),
                      deleted_test_cases: [{ tc_id: item.id, ts_id: item.tsId, delete_anyway: 1 }]
                    }).subscribe((res: { valid: boolean, tc_id?: string, msg?: string }) => {
                      if (res.valid) this.updateAfterActionDelete();
                      else this.updateAfterActionDelete(true, true, false);
                    });
                  } else this.updateAfterActionDelete(false, false);
                });
              } else if (res.valid === true) this.updateAfterActionDelete();
              else this.updateAfterActionDelete(true, true, false);
            });
          };
        });
      } else this.deletedItem = null;
    })
  }

  updateAfterActionDelete(flag = true, msg = true, success = true) {
    this.pending = false;
    this.deletedItem = null;
    this.selectionList.deselectAll();
    if (flag) this.getTestSuitCase(this._testSuit.id);
    if (flag) this.updateTestSuits.emit(true);
    if (msg) {
      if (success) this.snackBar.open('Test Case was successfully deleted', "OK", { duration: 3000 });
      else this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 });
    }
  }


  //tree
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true);

  formDataSource(treedata): void {
    const TREE_DATA = treedata;
    const dataChange = new BehaviorSubject<TodoItemNode[]>([]);
    const data = this.buildFileTree(TREE_DATA, 0);
    dataChange.next(data);
    dataChange.subscribe(r => {
      this.dataSource.data = r;
    });
  }

  buildFileTree(obj: any, level: number, parents: TestSuit[] = []): TodoItemNode[] {
    const tree = Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      if (accumulator.map(elem => ({ id: elem.id, level: elem.level })).filter(elem => elem.id === obj.suit.id && elem.level === level).length > 0) return accumulator;
      let value = obj[key];

      const node = new TodoItemNode();
      node.title = value.suit.title;
      node.id = value.suit.id;
      node.elem = value.suit;
      node.tesSuitRelationId = value.tesSuitRelationId;
      node.level = level;
      node.open = false;
      node.parents = parents;
      node.color = value.color || null;

      if (value.sub_suits.length > 0) {
        node.children = value.sub_suits.map(res => {
          let parentSuits = [...parents, value.suit];
          return this.buildFileTree(res, level + 1, parentSuits)[0];
        });
      } else node.children = [];

      return accumulator.concat(node);
    }, []);
    return tree;
  }

  onInitTree(): void {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.formDataSource(this.testSuitFolders);
  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  transformer = (node: TodoItemNode, level: number): TodoItemFlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.title === node.title
      ? existingNode
      : new TodoItemFlatNode();

    flatNode.title = node.title ? node.title : node[0].title;
    flatNode.id = node.id ? node.id : node[0].id;
    flatNode.level = level;
    flatNode.color = node.color;
    flatNode.elem = node.elem ? node.elem : node[0].elem;
    if (level === 0) flatNode.open = true;
    else flatNode.open = node.open != undefined ? node.open : node[0].open;
    flatNode.parents = node.parents ? node.parents : node[0].parents;
    flatNode.expandable = node.children ? !!node.children.length : !!node[0].children.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  todoItemSelectionToggle(node: TodoItemFlatNode, folders): void {
    node.open = !node.open;
    node.open ? this.treeControl.expand(node) : this.treeControl.collapse(node);

    let find = folders.find(elem => elem.suit === node.elem);
    if (find) {
      find.show = node.open;

      if (!node.open) {
        folders.forEach(elem => { if (elem.parents.includes(node.elem)) elem.show = false; });
        this.treeControl.dataNodes.forEach(nodeElem => {
          if (nodeElem.parents.includes(node.elem)) {
            nodeElem.open = false;
            this.treeControl.collapse(nodeElem)
          }
        });
      }
    }
  }

  scrollTo(node, flag: boolean = true, elemTo: TestCase = null) {
    if (node && !node.open) this.todoItemSelectionToggle(node, this.testSuitCases);

    this.suitsList?.forEach(elem => {
      if (node && node.open && flag) {
        if (elem.nativeElement?.dataset.value === `${node.title + node.id + node.level}` && this.container) {
          const containerScrollTop = elem.nativeElement.offsetTop - this.container.element.nativeElement.offsetTop;
          this.container.element.nativeElement.scrollTop = containerScrollTop;
          this.activeSuit = node.id;
        };
      } else if (this.selectedValue || elemTo) {
        let selectedSuit = this.testSuitCases?.filter(elem => elem.suit.id === (elemTo ? elemTo.tsId : this.selectedValue.tsId));
        selectedSuit?.forEach(elemItem => {
          elemItem.cases?.forEach(caseItem => {
            if (+caseItem.id === +elemTo?.id || +this.selectedValue.id) {
              let nodeSelect = this.treeControl.dataNodes.find(elem => elem.elem === elemItem.suit);
              if (elem.nativeElement?.dataset.value === `${nodeSelect.title + nodeSelect.id + nodeSelect.level}` && this.container) {
                const containerScrollTop = elem.nativeElement.offsetTop - this.container.element.nativeElement.offsetTop;
                this.container.element.nativeElement.scrollTop = containerScrollTop;
                this.activeSuit = +nodeSelect.id;
              };
            }
          })
        })
      }
    })
  }

  toggleSuit(suitIsOpen, flag: boolean = false): void {
    if (suitIsOpen.level !== 0) {
      suitIsOpen.show = flag ? true : !suitIsOpen.show;

      this.treeControl.dataNodes.forEach(nodeElem => {
        if (suitIsOpen.suit === nodeElem.elem) {
          nodeElem.open = suitIsOpen.show;
          this.treeControl.toggle(nodeElem);

          if (suitIsOpen.show) {
            this.treeControl.dataNodes.forEach(nodeItem => {
              if (suitIsOpen.parents.includes(nodeItem.elem)) {
                nodeItem.open = true;
                this.treeControl.expand(nodeItem);
              };
            });
            this.testSuitCases.forEach((item: TestSuitFoldersTemplate) => {
              if (suitIsOpen.parents.includes(item.suit)) item.show = true;
            });
          } else {
            this.treeControl.dataNodes.forEach(nodeItem => {
              if (nodeItem.parents.includes(nodeElem.elem)) {
                nodeItem.open = false;
                this.treeControl.collapse(nodeItem);
              };
            });
            this.testSuitCases.forEach((item: TestSuitFoldersTemplate) => {
              if (item.parents.includes(suitIsOpen.suit)) item.show = false;
            });
          }

          (suitIsOpen.show && !flag) ? this.scrollTo(nodeElem) : null;
        }
      });
    }
  }

  checkIsOpen(): boolean {
    return this.treeControl?.dataNodes?.filter(elem => elem.level > 0).some(elem => elem.open);
  }

  openAll(flag: boolean) {
    this.treeControl.dataNodes.filter(elem => elem.level > 0).forEach(elem => {
      elem.open = flag;
      flag ? this.treeControl.expand(elem) : this.treeControl.collapse(elem);
    });
    this.testSuitCases.filter((elem: TestSuitFoldersTemplate) => elem.level > 0).forEach((elem: TestSuitFoldersTemplate) => {
      elem.show = flag;
    });
  }

  checkTreeList() {
    this.formDataSource(this.testSuitFolders);
    this.treeControl?.expand(this.treeControl.dataNodes.filter(elem => elem.level === 0)[0]);

    if (this.testSuitCacheCases) {
      this.testSuitCacheCases.forEach((elem: TestSuitFoldersTemplate) => {
        this.treeControl.dataNodes.forEach(nodeItem => {
          const equal = `${nodeItem.elem.id + nodeItem.level}` === `${elem.suit.id + elem.level}`;
          if (equal && nodeItem.open !== elem.show) this.todoItemSelectionToggle(nodeItem, this.testSuitCases);
        });
      });
    }
  }
  //tree

  addSubSuit(node) {
    let ids: number[] = [];
    node.parents.forEach(elem => { ids.push(elem.id) });
    this.dialog.open(DialogCreateSubTestSuitComponent, {
      width: '650px',
      data: { testParentSuit: node.elem, testParentObjIds: ids }
    }).afterClosed().subscribe((res: { action: string, result: { valid, msg?} }) => {
      if (res) {
        if ((res.action === 'create') || (res.action === 'add' && res.result.valid)) {
          this.getTestSuitCase(this._testSuit.id);
          this.updateTestSuits.emit();
          this.snackBar.open('SubSuit was successfully added', "OK", { duration: 3000 });
          this.scrollTo(node);
        } else {
          res.action === 'create' ?
            this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 }) :
            this.snackBar.open(res.result.msg, "OK", { duration: 3000 });
        }
      }
    })
  }

  deleteSubSuit(node) {
    this.dialog.open(DialogConfirmComponent, {
      width: '650px',
      data: { dialogTitle: 'Delete subSuit', name: node.title }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.testSuitService.deleteTestSuit({ id: node.id }).subscribe(result => {
          if (result) {
            this.getTestSuitCase(this._testSuit.id);
            this.snackBar.open('SubSuit was successfully deleted', "OK", { duration: 3000 });
            this.updateModifiedDateSuit()
          } else this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 });
        })
      }
    })
  }

  editSubSuit(node) {
    const dialogRef = this.dialog.open(DialogEditComponent, {
      width: '650px',
      data: { dialogTitle: 'Edit Test Suits', title: node.elem.title, description: node.elem.description }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.testSuitService.saveTestSuit({ testSuit: { ...node.elem, ...res } }).subscribe(res => {
          if (res) {
            this.getTestSuitCase(this._testSuit.id);
            this.snackBar.open('SubSuit was successfully edited', "OK", { duration: 3000 });
            this.scrollTo(node);
          }
        })
      }
    })
  }

  onAddEvent(node) {
    this.dialog.open(DialogCreateTestCaseComponent, {
      width: '650px',
      data: {
        testSuitId: node.elem.id
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.getTestSuitCase(this._testSuit.id);
        this.snackBar.open('Case was successfully added', "OK", { duration: 3000 });
        this.scrollTo(node);
      }
    })
  }

  onImportEvent(event) {
    let uploadFormData = new FormData();
    uploadFormData.set('file', event.target.files[0])
    uploadFormData.set('ts_id', this._testSuit.id)

    this.testSuitService.importTestRail(uploadFormData).subscribe(v => {
      this.updateTestSuits.emit()
      this.snackBar.open('Suit was successfully added', "OK", { duration: 3000 });
    }, () => {
      this.snackBar.open('Error', "OK", { duration: 3000 });
    })
  }

}

class TodoItemNode {
  id: string;
  elem: TestSuit;
  children: TodoItemNode[];
  parents: TestSuit[]
  title: string;
  tesSuitRelationId: string;
  level: number;
  open: boolean;
  color: string;
}

class TodoItemFlatNode {
  id: string;
  elem: TestSuit;
  parents: TestSuit[]
  title: string;
  level: number;
  expandable: boolean;
  tesSuitRelationId: string;
  open: boolean;
  color: string;
}
