import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { TestCaseService } from '../services/test-case.service';
import { TestCase } from '../services/test-case.type';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { TestSuitService } from '../services/test-suit.service';
import { TestSuit } from '../services/test-suit.type';
import { TestStep } from '../services/test-step.type';
import { TestStepService } from '../services/test-step.service';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogCreateTestCaseComponent } from '../dialogs/dialog-create-test-case/dialog-create-test-case.component';
import { TestPlan } from '../services/test-plan.type';
import { TestRun } from '../services/test-run.type';
import { TestPlanService } from '../services/test-plan.service';
import { TestRunService } from '../services/test-run.service';
import { DataService } from '../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AbstractTableComponent } from '../abstract-table/abstract-table.component';
import { MatSelect } from '@angular/material/select';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-cases',
  templateUrl: './test-cases.component.html',
  styleUrls: ['./test-cases.component.scss'],
  animations: [
    trigger('changeSideComponentSize', [
      state('hide', style({ width: '0%' })),
      state('start', style({ width: '0%' })),
      state('small', style({ width: '45%' })),
      state('medium', style({ width: '50%' })),
      state('large', style({ width: '55%' })),
      transition('hide => small', animate('300ms ease-out')),
      transition('start => small', animate('300ms  ease-out')),
      transition('small => medium', animate('300ms ease-out')),
      transition('small => large', animate('300ms ease-out')),
      transition('medium => large', animate('300ms ease-out')),
      transition('large => medium', animate('300ms ease-out')),
      transition('medium => small', animate('300ms ease-out')),
      transition('large => hide', animate('300ms ease-out')),
      transition('large => small', animate('300ms ease-out')),
      transition('medium => hide', animate('300ms ease-out')),
      transition('small => hide', animate('300ms  ease-out'))
    ]),
  ]
})
export class TestCasesComponent implements OnDestroy, OnInit {

  public dataValue: TestCase[] = [];
  public filterValue: string = '';
  public displayedColumns: string[] = [];

  data: Array<TestCase> = [];
  testCase: TestCase;
  testSteps: TestStep[];
  width = 0;
  selection = new SelectionModel<TestCase>(true, []);
  createTestCase = new TestCase();
  selectedTestSuit: TestSuit[];
  selectedTestSuitTitles: string;
  testSuits: TestSuit[] = [];
  public bankMultiCtrl: UntypedFormControl = new UntypedFormControl();
  public bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredBanksMulti: ReplaySubject<TestSuit[]> = new ReplaySubject<TestSuit[]>(1);
  protected _onDestroy = new Subject<void>();
  @ViewChild('selectList', { static: false }) public selectList: MatSelect;
  @ViewChild('IntoSuit', { static: false }) public IntoSuit: any;
  @ViewChild('IntoPlan', { static: false }) public IntoPlan: any;
  @ViewChild('IntoRun', { static: false }) public IntoRun: any;
  @ViewChild('abstractTable') private abstractTable: AbstractTableComponent;
  @ViewChild('inputFocus') inputFocus: ElementRef;
  filteredTestSuits: TestSuit[] = [];
  filter;
  visibleSelect = 'IntoSuit';
  componentShowHide = 'start';
  selectOpened = false;
  IntoSuitSelectOpened = false;
  IntoPlanSelectOpened = false;
  selectedTestPlans: TestPlan[];
  selectedTestPlanTitles: string;
  IntoRunSelectOpened = false;
  selectedTestRuns: TestRun[];
  selectedTestRunTitles: string;
  projects = JSON.parse(Core.localStorageService.getItem('projects'));
  currentProjectId = Core.localStorageService.getItem('selected_project');
  testPlans: TestPlan[];
  testRuns: TestRun[];
  creationMode: boolean;
  currentTestCaseId: string;
  showSteps = false;

  constructor(
    private route: ActivatedRoute,
    private testCaseService: TestCaseService,
    private router: Router,
    private testSuitService: TestSuitService,
    private testStepService: TestStepService,
    public dialog: MatDialog,
    private testPlanService: TestPlanService,
    private testRunService: TestRunService,
    private dataService: DataService,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    this.dataService.creationModeType.subscribe(async newstate => {
      this.creationMode = newstate;
      await this.init();

      if (!this.creationMode) {
        // Once Creation Mode switched off table data should be set to initial
        this.createTestCase.tsId = '';
        this.applyTestSuitsFilter();
      } else {
        // If there is a test suit id in local storage, we set in into select
        const testSuitId = Core.localStorageService.getItem('chosenTestSuit');
        if (testSuitId) {
          this.createTestCase.tsId = testSuitId;
          this.applyTestSuitsFilter();
        } else {
          this.createTestCase.tsId = this.testSuits[0].id;
          this.applyTestSuitsFilter();
        }
      }
    })
  }

  init() {
    return new Promise<any>(async resolve => {

      // To prevent delays in data load we get data from Local Stoarage if it's there
      this.data = JSON.parse(Core.localStorageService.getItem('testCases'));
      if (this.data) this.dataValue = this.data;

      if (this.router.url.includes('test-case/')) {
        this.currentTestCaseId = this.router.url.replace('/test-case/', '');
      }

      if (Core.localStorageService.getItem('selected_project') === 'all') {
        this.displayedColumns = ['select', 'id', 'title', 'description', 'projectName', 'testSuitName', 'actions'];
        // Fetching test Suits, Plans, Runs for all projects
        await this.setInitialData('', this.currentTestCaseId);
        resolve(this.displayedColumns);

      } else {
        this.displayedColumns = ['select', 'id', 'title', 'description', 'testSuitName', 'actions'];
        // Fetching test Suits, Plans, Runs for current project
        await this.setInitialData(this.currentProjectId, this.currentTestCaseId);
        if (this.creationMode) {
          await this.inputFocus.nativeElement.focus();
        }
        resolve(this.displayedColumns);
      }
    })
  }

  setInitialData(currentProjectId, currentTestCaseId) {
    return new Promise<any>(resolve => {
      let params = {};
      if (currentProjectId) {
        params = { project_id: currentProjectId };
      }
      this.testSuitService.getTestSuits(params).subscribe(async testSuits => {

        this.testPlanService.getTestPlans(params).subscribe(res => this.testPlans = res);
        this.testRunService.getTestRuns(params).subscribe(res => this.testRuns = res);

        this.testSuits = testSuits;
        this.filteredTestSuits = this.testSuits;

        this.data = await this.getTestCases(currentProjectId, currentTestCaseId);
        this.abstractTable.data = this.data;

        this.bankMultiCtrl.setValue([]);
        this.filteredBanksMulti.next(testSuits.slice()); this.bankMultiFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanksMulti();
          });
        resolve(this.testSuits);
      });
    })
  }

  getTestSuits() {
    return new Promise<TestSuit[]>(resolve => {
      if (Core.localStorageService.getItem('selected_project') === 'all') {
        this.testSuitService.getTestSuits({}).subscribe((testSuits: TestSuit[]) => {
          resolve(testSuits);
        })
      } else {
        this.testSuitService.getTestSuits({ project_id: this.currentProjectId }).subscribe((testSuits: TestSuit[]) => {
          resolve(testSuits);
        })
      }
    })
  }

  getTestCases(projectId, currentTestCaseId): Promise<TestCase[]> {
    return new Promise<TestCase[]>((resolve) => {
      if (projectId) {
        this.testCaseService.getTestCases(projectId).subscribe(testCases => {
          // Filtering test cases of chosen project
          testCases = testCases.filter(testCase => {
            if (testCase.projectId === this.currentProjectId) {
              return true;
            } else {
              return false;
            }
          })
          testCases = this.getProjectName(testCases);
          testCases = this.getTestSuitNamebyId(testCases);

          if (currentTestCaseId) {
            this.testCaseService.getTestCase({ id: currentTestCaseId, project_id: this.currentProjectId }).subscribe(res => {
              this.testCase = res;
              this.testStepService.getTestSteps(this.testCase.id).subscribe(result => {
                this.testSteps = result;
                this.setActiveRow(JSON.parse(Core.localStorageService.getItem('activeRow')).tesSuitRelationId);
                if (this.router.url.includes('test-case/')) {
                  this.componentShowHide = 'small';
                }
              });
            })
          }
          resolve(testCases);
        });
      } else {
        this.testCaseService.getTestCases('all').subscribe(testCases => {

          testCases = this.getProjectName(testCases);
          testCases = this.getTestSuitNamebyId(testCases);

          testCases.map(testCase => {
            if (testCase.id === currentTestCaseId) {
              projectId = testCase.projectId
            }
          })

          if (currentTestCaseId) {
            this.testCaseService.getTestCase({ id: currentTestCaseId, project_id: projectId }).subscribe(res => {
              this.testCase = res;
              this.testStepService.getTestSteps(this.testCase.id).subscribe(result => {
                this.testSteps = result;
                this.setActiveRow(JSON.parse(Core.localStorageService.getItem('activeRow')).tesSuitRelationId);
                if (this.router.url.includes('test-case/'))
                  this.componentShowHide = 'small'
              });
            })
          }
          resolve(testCases);
        });
      }
    })
  }

  async updateMainTable() {
    if (Core.localStorageService.getItem('selected_project') === 'all') {
      this.data = await this.getTestCases('', '');
    } else {
      this.data = await this.getTestCases(this.currentProjectId, '');
    }

    this.abstractTable.data = this.data
  }

  ngOnDestroy(): void {
    if (!this.router.url.includes('test-case')) {
      Core.localStorageService.removeItem('testCases');
      this.dataService.changeCreationMode(false);
    }
  }

  // TODO: Deprecated symbol used, consult docs for better alternative
  // @HostListener('window:keydown',['$event'])
  // onKeyPress($event: KeyboardEvent) {
  //   if(($event.ctrlKey || $event.metaKey) && $event.keyCode === 13) {
  //     this.onCreateCase();
  //   }
  // }

  onCreateCase() {
    // this.createTestCase.testSuitId = this.createTestCase.tsId;
    // this.testCaseService.createTestCase(this.createTestCase, []).subscribe(async res => {

    //   await this.updateMainTable();
    //   this.applyTestSuitsFilter();
    //   this.createTestCase.title = '';
    //   this.createTestCase.description = '';
    //   this.inputFocus.nativeElement.focus();
    // });
  }

  openCreateDialog(): void {
    this.dialog.open(DialogCreateTestCaseComponent, {
      width: '650px'
    });
  }

  onDeleteCases() {
    this.selection.selected.forEach(item => {
      this.onDeleteCase(item);
    });
    this.selection.clear();
  }

  onDeleteCase(testCase: TestCase) {
    this.testSuitService.deleteTestCasefromTestSuit({ test_suit_relation_id: testCase.tesSuitRelationId }).subscribe(() => {
      const index: number = this.data.findIndex(d => d === testCase);
      this.data.splice(index, 1);
      this.abstractTable.data = this.data
      this.applyTestSuitsFilter();
    });
  }

  onPush() {
    switch (this.visibleSelect) {
      case 'IntoSuit':
        this.selectedTestSuit.map(sts => {
          this.testSuitService.addTestCases({ test_suit_id: sts.id, test_cases_ids: this.selection.selected.map(c => c.id) })
            .subscribe(async () => {
              await this.updateMainTable();
              this.applyTestSuitsFilter();
              this.selection = new SelectionModel<TestCase>(true, []);
              this.snackBar.open('Test Suit was successfully updated', 'OK', { duration: 3000 });
            }
            );
        })
        break;
      case 'IntoPlan':
        const tsIdsTestPlan = [];
        const paramsTestPlan = []

        this.selectedTestPlans.map(str => {
          this.selection.selected.map(testCase => {
            const param = {
              test_plan_id: '',
              test_suit_id: '',
              test_cases_ids: []
            }
            param.test_plan_id = str.id;
            param.test_suit_id = testCase.tsId;
            param.test_cases_ids = [];
            this.selection.selected.map(tc => {
              if (tc.tsId === param.test_suit_id) {
                param.test_cases_ids.push(tc.id)
              }
            })
            if (tsIdsTestPlan.indexOf(param.test_suit_id) < 0) {
              paramsTestPlan.push(param);
              tsIdsTestPlan.push(param.test_suit_id);
            }
          })
        })
        paramsTestPlan.forEach(param => {
          this.testPlanService.addTestCases(param).subscribe(() => {
            this.selection = new SelectionModel<TestCase>(true, []);
            this.snackBar.open('Test Plan was successfully updated', 'OK', { duration: 3000 });
          });
        })
        break;
      case 'IntoRun':
        const tsIds = [];
        const params = [];
        this.selectedTestRuns.map(str => {
          this.selection.selected.map(testCase => {
            const param = {
              test_run_id: '',
              test_suit_id: '',
              test_cases_ids: []
            }

            param.test_run_id = str.id;
            param.test_suit_id = testCase.tsId;
            param.test_cases_ids = [];
            this.selection.selected.map(tc => {
              if (tc.tsId === param.test_suit_id) {
                param.test_cases_ids.push(tc.id)
              }
            })
            if (tsIds.indexOf(param.test_suit_id) < 0) {
              params.push(param);
              tsIds.push(param.test_suit_id);
            }
          })
        })
        params.forEach(param => {
          this.selection = new SelectionModel<TestCase>(true, []);
          this.testRunService.addTestCases(param).subscribe(() => {
            this.snackBar.open('Test Run was successfully updated', 'OK', { duration: 3000 });
          });
        })
        break;
    }
  }

  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.filterValue = filterValue;
  }

  onResize(size: string): void {
    this.componentShowHide = size;
  }

  doneAnimation() {
    if (this.componentShowHide !== 'hide' && this.componentShowHide !== 'start') {
      // To prevent delays we show steps in side component only once the component was opened
      this.showSteps = true;
    }
    // Once sideBar was closed, we redrect the user onto test-cases URL
    if (this.router.url.includes('test-case/') && this.componentShowHide === 'hide') {
      this.router.navigate(['test-cases']);
    }
  }

  openTestCaseDetail(testCase: TestCase): void {
    this.testCaseService.getTestCase({ id: testCase.id, project_id: testCase.projectId }).subscribe(res => {
      // Saving data in Local storage to show before actual data is loading to prevent delays
      Core.localStorageService.setItem('testCases', JSON.stringify(this.abstractTable.dataSource.filteredData));
      Core.localStorageService.setItem('activeRow', JSON.stringify(testCase))

      this.testCase = res;
      // Fetching Steps to display in side component
      this.testStepService.getTestSteps(this.testCase.id).subscribe(result => {
        this.testSteps = result;
        // Setting Active Row
        this.setActiveRow(testCase.tesSuitRelationId);
        // Setting Chosen Test Suit if this is creation mode
        Core.localStorageService.setItem('chosenTestSuit', this.createTestCase.tsId);
        // Navigating to testCase Url
        this.router.navigate(['test-case', testCase.id]);
      });
    })
  }

  setActiveRow(id: string) {
    this.data.map(testCase => {
      testCase.active = (testCase.tesSuitRelationId === id) ? true : false;
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataValue.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataValue.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: TestCase): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyTestSuitsFilter(): void {
    // Setting fiter value once test Suit was chosen in select
    this.testSuits.map((testSuit: TestSuit) => {
      if (testSuit.id === this.createTestCase.tsId) {
        this.filter = testSuit.title;
      }
    })

    if (this.createTestCase.tsId) {
      // Defining how data should be filtered
      this.abstractTable.dataSource.filterPredicate = (data, filter: string): boolean => {
        return data.tsId === filter
      }
      // Applying all filters
      this.filterValue = this.createTestCase.tsId;
    } else {
      // Reseting filtered data if filter was reset
      this.filter = '';
      this.filterValue = '';
    }
  }

  selectInput() {
    this.inputFocus.nativeElement.focus();
  }

  protected filterBanksMulti() {
    if (!this.testSuits) {
      return;
    }
    // get the search keyword
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.filteredBanksMulti.next(this.testSuits.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanksMulti.next(
      this.testSuits.filter(bank => bank.title.toLowerCase().indexOf(search) > -1)
    );
  }

  onFilterTestSuits(event) {
    // Filtering results in Test suit select dropdown when user enters something into the input
    if (typeof event === 'string') {
      this.filteredTestSuits = this.testSuits.filter(a => {
        return a.title.toLowerCase().includes(event.toLowerCase()) ? 1 : 0
      });
    }
    this.selectList.open();
  }

  onBlurInput() {
    if (typeof this.filter === 'string' && this.filter.length === 0) {
      this.createTestCase.tsId = undefined;
      this.applyTestSuitsFilter();
    }
  }

  updateSelectedTestSuits() {
    const titles = [];
    this.selectedTestSuit.map(testSuit => {
      titles.push(testSuit.title);
    })
    this.selectedTestSuitTitles = titles.join(', ')
  }

  updateSelectedTestPlans() {
    const titles = [];
    this.selectedTestPlans.map(p => {
      titles.push(p.title);
    })
    this.selectedTestPlanTitles = titles.join(', ')
  }

  updateSelectedTestRuns() {
    const titles = [];
    this.selectedTestRuns.map(r => {
      titles.push(r.title);
    })
    this.selectedTestRunTitles = titles.join(', ')
  }

  getProjectName(data) {
    data.map(testCase => {
      this.projects.map(project => {
        if (project.id === testCase.projectId) {
          testCase.projectName = project.title;
        }
      })
    })
    return data;
  }

  getTestSuitNamebyId(data) {
    data.map(testCase => {
      this.testSuits.map(suit => {
        if (suit.id === testCase.tsId) {
          testCase.testSuitName = suit.title;
        }
      })
    })
    return data;
  }
}
