<div class="table-container mat-elevation-z8">
  <div class="table-container">
    <div class="loading-shade" *ngIf="dataSource.loading$ | async">
    </div>

    <mat-table mat-table #table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="testCaseId">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >testCaseId</mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.testCaseId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="order">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >order</mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.order }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="expectedResult">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >expectedResult</mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.expectedResult }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >description</mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >createdDate</mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.createdDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="modifiedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >modifiedDate</mat-header-cell
        >
        <mat-cell *matCellDef="let row">{{ row.modifiedDate }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
  </div>
  <mat-paginator
    #paginator
    [length]="1000"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[25, 50, 100, 250]"
  >
  </mat-paginator>
</div>
