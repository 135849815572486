import { Component, OnInit } from '@angular/core';
import {UserService} from '../services/user.service';
import {Router} from '@angular/router';
import {User} from '../services/user.type';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent implements OnInit {

  user: User = new User();
  hidePassword = true;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void { }

  createUser(): void {
    this.userService.createUser(this.user).subscribe(res => {
      this.router.navigate(['users']);
    });
  }

}
