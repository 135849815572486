<app-base-card [title]="'Test Case'" [icon]="'dns'" [showAdd]="false" [showClose]="true" [shortForm]="shortForm"
  (closeEvent)="closeEvent.emit()" [showCollapse]="showStep" (collapseEvent)="collapseEvent.emit('TestPlanCase')">

  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
    appearance="line"></ngx-skeleton-loader>

  <div class="main-flex">
    <ng-container *ngIf="dataSource && testSuitCases.length > 0">
      <div class="tree sticky">
        <div class="toogle-visibility" *ngIf="testSuitCases.length > 1">
          <mat-icon *ngIf="checkIsOpen()" (click)="openAll(false)">visibility</mat-icon>
          <mat-icon *ngIf="!checkIsOpen()" (click)="openAll(true)">visibility_off</mat-icon>
        </div>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" [attr.aria-expanded]="node.open">
            <div class="content flex">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename"
                (click)="todoItemSelectionToggle(node, testSuitCases)" [style.marginLeft.px]="node.level * 5">
                <div *ngIf="node.level !== 0" class="badge"
                  [style.backgroundColor]="node.open ? node.color : '#0000001a'">{{node.level}}</div>
                <mat-icon *ngIf="!node.open && node.level === 0">folder_special</mat-icon>
                <mat-icon *ngIf="node.open && node.level === 0">folder_open</mat-icon>
              </button>
              <div class="flex gap">
                <span
                  [class]="(node.open && (+activeSuit === +node.elem.id)) ? 'open open--accent' : node.open ? 'open' :'close'"
                  [matTooltip]="node.title" (click)="scrollTo(node)">
                  {{node.title | shortName: (23 - (node.level * 2))}}</span>
                <ng-container *ngIf="node.expandable">
                  <mat-icon *ngIf="!node.open" matTreeNodeToggle
                    (click)="todoItemSelectionToggle(node, testSuitCases)">keyboard_arrow_right</mat-icon>
                  <mat-icon *ngIf="node.open" matTreeNodeToggle
                    (click)="todoItemSelectionToggle(node, testSuitCases)">keyboard_arrow_left</mat-icon>
                </ng-container>
              </div>
            </div>
          </mat-tree-node>
        </mat-tree>
      </div>
    </ng-container>

    <div #main [class]="dataSource ? 'main-container sticky' : 'main-container sticky width-100'">
      <mat-selection-list #selection class="list" (selectionChange)="selectionChange($event)">
        <div *ngFor="let suit of testSuitCases">
          <div [class]="suit.level ? 'container margin' : 'container'" [style.marginLeft.px]="suit.level * 15"
            [style]="!suit.show ? {'border-bottom' : '1px solid #b5bec8'} : {}">
            <div #suit [attr.data-value]="suit.suit.title + suit.suit.id + suit.level"
              [class]="+suit.suit.id === +activeSuit ? 'item-title item-title--accent' : 'item-title'"
              (click)="toggleSuit(suit)">
              <div class="flex">
                <mat-icon *ngIf="suit.level === 0">work</mat-icon>
                <div *ngIf="suit.level !== 0" [style.backgroundColor]="suit.show ? suit.color : '#0000001a'"
                  [class]="suit.show ? 'badge badge--large' : 'badge badge--large'">
                  {{suit.level}}</div>

                <span [matTooltip]="suit.suit.title">{{suit.suit.title | shortName: (60 -
                  suit.level * 2)}}</span>
              </div>
            </div>
            <ng-container *ngIf="suit.show && suit.cases.length">
              <mat-list-option #option *ngFor="let item of suit.cases" [value]="item" class="list-card__item item-list"
                [selected]="item.isOpen" [routerLink]="'./'"
                [queryParams]="{ project: queryParams.project, type: queryParams.type, id: queryParams.id, sub:suit.suit.id, level:suit.level, tcp: item.testCaseId}">
                <div
                  [class]="shortFormPlan ? 'item-list__body expanded' : guestAccessAction ? 'relative' : 'item-list__body'">
                  <div class="item-list__title flex">
                    <div [style.opacity]="(pending && deletedItem === item) ? .2 : 1" [matTooltip]="item.title">
                      {{item.title | shortName:
                      (55 -
                      suit.level * 2)}}</div>
                    <div *ngIf="pending && deletedItem === item" class="loading">
                      <img src="../../../assets/image/loading.gif" alt="pending">
                    </div>
                  </div>
                  <div class="item-list__action">
                    <ng-container *ngIf="!shortFormPlan">
                      <div class="item-list__right absolute" *ngIf="guestAccessAction">
                        <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
                          *ngIf="selectedValue === item && !queryParams.ts" stopPropagation>
                          <mat-icon>link</mat-icon>
                        </button>
                      </div>
                      <div class="item-list__menu" *ngIf="!guestAccessAction">
                        <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="copyLink()" *ngIf="selectedValue === item">
                            <span>Copy link</span>
                          </button>
                          <button [disabled]="!editAccessAction" mat-menu-item (click)="onEditEvent(item)">
                            <span>Edit</span>
                          </button>
                          <button [disabled]="!deleteAccessAction" mat-menu-item (click)="onDeleteCaseEvent(item)">
                            <span>Delete</span>
                          </button>
                        </mat-menu>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="shortFormPlan">
                      <div class="item-list__menu-custom">
                        <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
                          stopPropagation *ngIf="selectedValue === item">
                          <mat-icon>link</mat-icon>
                        </button>
                        <button *ngIf="editAccessAction" class="item" mat-icon-button (click)="onEditEvent(item)"
                          stopPropagation>
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="deleteAccessAction" mat-icon-button class="item small"
                          (click)="onDeleteCaseEvent(item)" stopPropagation>
                          <mat-icon>delete_outline</mat-icon>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </mat-list-option>
            </ng-container>
            <ng-container *ngIf="suit.show && !suit.cases.length">
              <div [class]="suit.parents.length ? 'not-found border-bottom' : 'not-found'">Test cases not found...</div>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="testSuitCases?.length === 0 && isLoading === false">
          <div class="not-found">Test suits not found...</div>
        </ng-container>
      </mat-selection-list>
    </div>
  </div>
</app-base-card>