import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { Project } from 'src/app/services/project.type';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/services/user.type';
import { DialogUsersSelectComponent } from '../../dialogs/dialog-users-select/dialog-users-select.component';
import { ProjectService } from '../../services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserAccessService } from 'src/app/services/user-access';
import { BaseCardComponent } from 'src/app/app-shared-component/base-card/base-card.component';

@Component({
  selector: 'app-card-projects-users',
  templateUrl: './card-projects-users.component.html',
  styleUrls: ['./card-projects-users.component.scss']
})
export class CardProjectsUsersComponent implements OnInit, OnDestroy {
  public addUserAccessAction: boolean = this._userAccessService.getAccess('project', 'projectAddUserLevel');

  @ViewChild(BaseCardComponent) private baseCard: BaseCardComponent;

  public projectUsers: User[]

  @Input() set project(data: Project) {
    this.getData(data)
  }

  @Output() closeEvent = new EventEmitter()

  private selectionListSubscriber: SubscriptionLike;
  private currentProject: Project;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _userAccessService: UserAccessService,
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : '';
  }

  getData(data: Project) {
    this.currentProject = data;
    this.getProjectUsers(data.id).subscribe(res => {
      if (res) this.projectUsers = res;
      else this.projectUsers = [];
    })
  }

  getProjectUsers(id: string) {
    return this.userService.getUserByProject(id)
  }

  async onSelectUsers() {
    const projectUsers = await this.getProjectUsers(this.currentProject.id).toPromise();
    const allUsers = await this.userService.getUsers().toPromise();

    if (this.baseCard) this.baseCard.pending = false;
    this.dialog.open(DialogUsersSelectComponent, {
      width: '650px',
      data: { users: allUsers, selectedOptions: projectUsers.map(val => val.id) },
      panelClass: 'custom-modalbox'
    }).afterClosed().subscribe((res: string[]) => {
      if (res) {
        this.projectService.updateProjectAccess(+this.currentProject.id, res).subscribe(result => {
          if (result) {
            this.snackBar.open('User was succefully updated', 'OK', { duration: 3000 });
            this.getData(this.currentProject)
          }
        })
      }
    });
  }

}
