<app-base-card [title]="'Dashboard'" [icon]="'dashboard'" [showAdd]="false" [showGap]="false">

  <div class="dashboard-main">
    <app-chart-new-tests-created #createdChart [currentProject]="currentProject"></app-chart-new-tests-created>
    <app-chart-tests-changed #changedChart [currentProject]="currentProject"></app-chart-tests-changed>
    <app-chart-tests-completed #complitedChart [currentProject]="currentProject"></app-chart-tests-completed>
    <app-chart-tests-escalated #escalatedChart [currentProject]="currentProject"></app-chart-tests-escalated>
    <app-chart-tests-failures #failuresChart [currentProject]="currentProject"></app-chart-tests-failures>
  </div>

  <div>
    <app-chart-diagrams #chartDiagram [currentProject]="currentProject"></app-chart-diagrams>
  </div>

  <div class="table-wrap">
    <app-table-last-runs #tableLastRuns></app-table-last-runs>
  </div>

  <div class="table-wrap">
    <app-activity-table #activityTable [currentProject]="currentProject"></app-activity-table>
  </div>

</app-base-card>