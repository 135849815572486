<div class="flex-container">
  <div [class]="testPlans ? 'flex-container__item' : 'flex-container__item width-100'">
    <ng-container *ngIf="dataSource">
      <div class="dialog-content">
        <div class="title" *ngIf="testPlans">From suits:</div>
        <div class="not-found" *ngIf="dataSource?.data.length < 1">Test suits not found</div>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <mat-icon *ngIf="!node.isCase">folder</mat-icon>
            <ng-container *ngIf="node.isCase; then  box; else text"></ng-container>

            <ng-template #box>
              <mat-icon [matTooltip]="node.info" class="flex__info">info</mat-icon>
              <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                (change)="todoLeafItemSelectionToggle(node)"
                [disabled]="type === 'SelectFromRun' ? includes(node) : false">
                <span *ngIf="!testPlans">{{node.title}}</span>
                <span *ngIf="testPlans">{{node.title |shortName: 30}}</span>
              </mat-checkbox>
            </ng-template>
            <ng-template #text>
              <div [style.paddingLeft.px]="15">
                <span *ngIf="!testPlans">{{node.title}}</span>
                <span *ngIf="testPlans">{{node.title |shortName: 30}}</span>
              </div>
            </ng-template>
          </mat-tree-node>

          <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <mat-icon *ngIf="!node.isCase">folder</mat-icon>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <ng-container *ngIf="node.isExistCasesOnLowLevel; then boxSuits; else textSuits"></ng-container>
            <ng-template #boxSuits>
              <mat-checkbox [disabled]="type === 'SelectFromRun' ? includesAll(node) : false"
                [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node)">
                <span *ngIf="!testPlans">{{node.title}}</span>
                <span *ngIf="testPlans">{{node.title | shortName: 30}}</span>
              </mat-checkbox>
            </ng-template>
            <ng-template #textSuits>
              <span *ngIf="!testPlans">{{node.title}}</span>
              <span *ngIf="testPlans">{{node.title | shortName: 30}}</span>
            </ng-template>
          </mat-tree-node>
        </mat-tree>
      </div>
    </ng-container>
  </div>
  <div class="flex-container__item" *ngIf="dataSourcePlans">
    <div class="dialog-content">
      <div class="title">From plans:</div>
      <div class="not-found" *ngIf="dataSourcePlans?.data.length < 1">Test plans not found</div>
      <mat-tree [dataSource]="dataSourcePlans" [treeControl]="treeControlPlans">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <mat-icon *ngIf="!node.isCase">folder</mat-icon>
          <div [style.paddingLeft.px]="node.isCase ? 15 : 8">
            <span>{{node.title |shortName: 30}}</span>
          </div>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <mat-icon *ngIf="!node.isCase">folder</mat-icon>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <ng-container *ngIf="node.level !== 0"><span>{{node.title | shortName: 30}}</span></ng-container>
          <ng-container *ngIf="node.level === 0">
            <mat-checkbox [checked]="descendantsAllSelectedPlans(node)"
              (change)="todoItemSelectionTogglePlans(node)">{{node.title
              | shortName:
              30}}</mat-checkbox>
          </ng-container>
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
</div>