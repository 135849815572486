const stepList = 'testCaseStep/list', stepListByRelationId = 'testCaseStep/listByRelationId', updateStatus = 'testCaseStep/updateTestStepStatus', stepRead = 'testCaseStep/read', stepCreate = 'testCaseStep/create', stepUpdate = 'testCaseStep/update', stepDelete = 'testCaseStep/delete';
export class TestStepControllers {
    constructor(api) {
        this.api = api;
    }
    //get steps list
    list(payload) {
        return this.api.postRequest(stepList, payload).then(res => {
            if (res.data.valid)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //get steps list by relationId
    listByRelationId(payload) {
        return this.api.postRequest(stepListByRelationId, payload).then(res => {
            if (res.data)
                return res.data;
            else
                throw Error(res);
        });
    }
    //update steps status
    updateStatus(payload) {
        return this.api.postRequest(updateStatus, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res);
        });
    }
    //get steps list
    readList(payload) {
        return this.api.postRequest(stepRead, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //create step
    create(payload) {
        return this.api.postRequest(stepCreate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //update step
    update(payload) {
        return this.api.postRequest(stepUpdate, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
    //delete step
    delete(payload) {
        return this.api.postRequest(stepDelete, payload).then(res => {
            if (+res.data.valid === 1)
                return res.data;
            else
                throw Error(res.data);
        });
    }
}
