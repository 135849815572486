<div class="chart dashboard">
  <div class="chart__title">{{title}}</div>
  <div class="chart__settings-date">

    <form class="chart-form" [formGroup]="dateForm" [style.width.px]="width - 20">
      <div class="chart-form__date">
        <mat-form-field appearance="fill">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
            <input #dateStart matStartDate placeholder="Start date" formControlName="start"
              (dateInput)="formatDate(dateStart)">
            <input #dateEnd matEndDate placeholder="End date" formControlName="end" (dateInput)="formatDate(dateEnd)">
          </mat-date-range-input>
          <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="chart-form__select">
        <mat-form-field appearance="outline">
          <mat-label style="text-transform: capitalize;">Runs</mat-label>
          <mat-select #select [formControl]="selectedControl" multiple (closed)="searchRuns(''); search.value = ''">
            <mat-select-trigger>
              {{(selectedControl.value?.includes('all') ? 'All' : ((selectedControl.value?.length &&
              selectedControl.value[0]?.title) ?
              selectedControl.value[0].title : '')) | shortName:12}}
              <span *ngIf="selectedControl.value?.length > 1 && selectedControl.value?.length <= testRuns?.length"
                class="example-additional-selection">
                (+{{selectedControl.value.length - 1}} {{selectedControl.value?.length === 2 ?
                'other' : 'others'}})</span>
              <span *ngIf="selectedControl.value?.length > testRuns?.length" class="example-additional-selection">
                ({{selectedControl.value.length - 1}} cases)</span>
            </mat-select-trigger>
            <div class="fixed">
              <mat-optgroup>
                <mat-form-field [ngStyle]="{'width': '100%', 'padding-top':'10px', 'margin': '0'}" class="search"
                  appearance="outline" floatLabel="auto">
                  <mat-label>Search</mat-label>
                  <label>
                    <input #search matInput class="changeCaret" (input)="searchRuns(search.value)">
                  </label>
                </mat-form-field>
              </mat-optgroup>
              <mat-option class="select-all" [value]="'all'" (click)="toggleAll()">{{selectedControl.value?.length >
                testRuns?.length ? 'Deselect all' : 'Select all'}}</mat-option>
            </div>
            <mat-optgroup class="chart-form__select-optgroup" *ngFor="let project of projectsWithRuns"
              [label]="project.title" [style.display]="isShow(project.runs) ? 'block' : 'none'">
              <ng-container *ngFor="let runs of project.runs">
                <mat-option [style.display]="runs.show ? 'flex' : 'none'" [value]="runs">{{runs.title |
                  shortName:15}}</mat-option>
              </ng-container>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="btn">
        <button [disabled]="disabledBtn || pending" mat-flat-button color="primary" (click)="updateChart()">
          <span>Update</span>
        </button>
        <div *ngIf="pending" class="loading">
          <img src="../../../../assets/image/loading.gif" alt="pending">
        </div>
      </div>
    </form>

  </div>
</div>

<div class="canvas" [style.width.px]="width">
  <canvas baseChart class="chart" [data]="lineChartData" [options]="lineChartOptions" [type]="lineChartType"
    [width]="width" [height]="height"></canvas>
</div>