import {Component, Inject, Injectable, OnInit} from '@angular/core';
import {TestRunService} from '../services/test-run.service';
import {TestRun} from '../services/test-run.type';
import {TestCase} from '../services/test-case.type';
import {Router} from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {TestSuitService} from '../services/test-suit.service';
import {Project} from '../services/project.type';
import {ProjectService} from '../services/project.service';
import {TestPlanService} from '../services/test-plan.service';
import {TestSuit} from '../services/test-suit.type';

export interface DialogTestSuitsSelectComponentData {
  testSuits: TestSuit[];
  selectedTestCases:  TestCase[];
  projects: Project[];
}

export class TodoItemNode {
  id: string;
  tsId: string;
  children: TodoItemNode[];
  item: string;
}

export class TodoItemFlatNode {
  id: string;
  tsId: string;
  item: string;
  level: number;
  expandable: boolean;
}

@Injectable()
export class ChecklistDatabase {
  TREE_DATA = { };
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor() { }

  setupTreeData(treeData) {
    this.TREE_DATA = treeData;
  }

  initialize() {
    const data = this.buildFileTree(this.TREE_DATA, 0, '0');
    this.dataChange.next(data);
  }
  buildFileTree(obj: any, level: number, tsId: string): TodoItemNode[] {
    const tree = Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key].testCases;
      if(value !== undefined && level === 0) {
        if(value.length < 1) {
          return accumulator;
        }
      }

      const node = new TodoItemNode();
      node.item = obj[key].title;
      node.id = obj[key].id;

      if(level === 1) {
        node.tsId = tsId
      }

      if (value != null) {
        node.children = this.buildFileTree(value, level + 1, obj[key].id);
      }
      return accumulator.concat(node);

    }, []);
    return tree;
  }
}

@Component({
  selector: 'app-add-new-test-run',
  templateUrl: './add-new-test-run.component.html',
  styleUrls: ['./add-new-test-run.component.scss']
})
export class AddNewTestRunComponent implements OnInit {

  testRun: TestRun = new TestRun();
  testSuits: TestSuit[];
  selectedTestCases: TestCase[] = [];
  projects: Project[];

  constructor(private testRunService: TestRunService,
              private testSuitService: TestSuitService,
              private testPlanService: TestPlanService,
              private projectService: ProjectService,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.testSuitService.getTestSuits({options: {test_cases: true}}).subscribe(res => {
      this.testSuits = res;
    });

    this.projectService.getProjects().subscribe(res => {
      this.projects = res;
    });
  }

  createRun() {
    this.testRunService.createTestRun(this.testRun, this.selectedTestCases).subscribe(res => {
      this.router.navigate(['test-runs']);
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogTestPlansSelectComponent, {
      width: '650px',
      data: {testSuits: this.testSuits, selectedTestCases: this.selectedTestCases}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.mapSelectedTestCases(result);
    });
  }

  mapSelectedTestCases(treeData) {
    if (treeData) {
      const selectedTestCases = [];
      treeData.forEach((value, index) => {
        if (value.level === 1) {
          selectedTestCases.push(value);
        }
      });
      this.selectedTestCases = selectedTestCases;
    } else {
      this.selectedTestCases = [];
    }
  }

}

@Component({
  selector: 'app-dialog-test-plans-select',
  templateUrl: 'dialog-test-plans-select.html',
  providers: [ChecklistDatabase]
})
export class DialogTestPlansSelectComponent {
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true);

  constructor(
    public dialogRef: MatDialogRef<DialogTestPlansSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTestSuitsSelectComponentData,
    private _database: ChecklistDatabase) {
    this._database.setupTreeData(this.data.testSuits);
    this._database.initialize();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(res => {
      this.dataSource.data = res;
    });
  }
  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.id = node.id;
    flatNode.tsId = node.tsId;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
