import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-custom-tooltip-view-attachmants',
  templateUrl: './custom-tooltip-view-attachmants.component.html',
  styleUrls: ['./custom-tooltip-view-attachmants.component.scss']
})

export class CustomTooltipViewAttachmantsComponent {

  @Input() StatusesCount: Array<object>;

}




