export class TestRun {
  id: string;
  title: string;
  description: string;
  projectId: string;
  status: string;
  createdDate: string;
  modifiedDate: string;
  testCases: Array<object>;
  projectName: string;
  userName: string;
  statuses: Array<object>;
  testCasesCount: number;
  isOpen: boolean;
  archive: string
  time: Date;
  creator_id: string;
}

export class TestRunDetails {
  data: number[];
  labels: string[];
  colors: string[];
  info?: { per: number, name: string }[];
  passed?: { count: number, per: number };
  unTest?: { count: number, per: number };
  all?: number;
}

export class TestRunForTree {
  runId: string;
  runName: string;
  open: boolean;
  folders: TestRunForTreeFolder[];
}

class TestRunForTreeFolder {
  folderId: string;
  folderName: string;
  open: boolean;
  suits: TestRunForTreeSuit[];
}

class TestRunForTreeSuit {
  suitId: string;
  suitName: string;
  open: boolean;
  cases: TestRunForTreeCase[];
}

class TestRunForTreeCase {
  caseId: string;
  caseName: string;
  open: boolean;
}

export class TodoItemNode {
  id: string;
  children: TodoItemNode[];
  item: string;
  open: boolean;
  collapse?: boolean;
  expandable: boolean;
  tsId?: string;
  relationId?: string;
}

export class TodoItemFlatNode {
  id: string;
  tsId: string;
  relationId: string;
  item: string;
  level: number;
  open: boolean;
  collapse?: boolean;
  expandable: boolean;
}
