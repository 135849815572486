<div class="main-page">
  <div class="main-page__row main-page__row--tab">
    <div class="main-page__column sticky" [class.main-page__column-50]="showCase">
      <app-card-test-cases #cardTestCases [queryParams]="queryParams" (showTestStep)="onShowTestCase($event)"
        (closeTestStep)="closeTestStep($event)" (changeUrl)="changeUrl()"></app-card-test-cases>
    </div>

    <ng-container *ngIf="showCase">
      <div class="main-page__column main-page__column-50 sticky">
        <app-card-test-cases-step [testCase]="selectedTestCase" [queryParams]="queryParams"
          (closeEvent)="closeTestStep(true)" (changeUrl)="changeUrl()"></app-card-test-cases-step>
      </div>
    </ng-container>
  </div>
</div>