<div class="test-case-wrap">
  <mat-card *ngIf="testCase" style="padding: 0" class="test-case">
    <mat-card-content>
            <button (click)="closeTestCaseDetail()" mat-icon-button class="close-test-case-details-sidenav">
              <mat-icon>close</mat-icon>
            </button>
            <mat-panel-title>
              {{testCase.title}}
            </mat-panel-title>
            <mat-panel-description>
              <span>
                TEST CASE ID - {{testCase.testCaseId || testCase.id}}
              </span>
            </mat-panel-description>
    </mat-card-content>
  </mat-card>

  <mat-card class="test-steps-wrap" *ngIf="!defectsPage">
    <div *ngFor="let testStep of testSteps; let i = index;" class="test-step"
    [ngClass]="testStep.statusObject.statusState === '1' ? 'test-step-passed' :
    (testStep.statusObject.statusState === '-1' ? 'test-step-failed' : '')">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
          [ngStyle] ="{'background-color':
          (testStep.statusObject.statusState !== '0') ? testStep.statusObject.color : '#f0f1f5'}">
            <mat-icon class="color-red status-failed" (click) ="setStatusFailed($event, testStep)"
            *ngIf ="testStep.statusObject.statusState === '0'">close</mat-icon>
            <mat-icon class="on-status-change" *ngIf ="testStep.statusObject.statusState === '-1'">close</mat-icon>
            <mat-icon class="on-status-change" *ngIf ="testStep.statusObject.statusState === '1'">done</mat-icon>
            <mat-panel-title>
              {{ (testStep.statusObject.statusState !== '0') ? testStep.statusText :
                ((testStep.description.length>35)? (testStep.description | slice:0:35)+'...':(testStep.description))}}
            </mat-panel-title>
            <mat-panel-description *ngIf ="testStep.statusObject.statusState === '0'">
              <span>
                {{ "TEST STEP ID - " + testStep.id }}
              </span>
            </mat-panel-description >
            <div class="icons-wrap" *ngIf ="(testStep.statusObject.statusState === '0')">
              <span class="material-icons expand">
                expand_more
             </span>
              <span class="material-icons save">
                save
             </span>
             <span class="material-icons close">
                close
           </span>
            </div>
            <mat-icon class="color-green status-passed" *ngIf ="(testStep.statusObject.statusState === '0')"
            (click) ="setStatusPassed($event, testStep)">done</mat-icon>
            <button (click)="openDialogViewAttachment(testStep, $event)" mat-icon-button aria-label="View attachments" style="width: auto;">
              <mat-icon>attachment</mat-icon>
              <span> - {{ testStep.attachments.length }}</span>
            </button>
            <div class="timer" *ngIf="(testStep.statusObject.statusState !== '0') && testStep.showUndo" [ngStyle] ="{'color':
            (testStep.statusObject.statusState !== '0') ? testStep.statusObject.color : '#fff'}">{{testStep.counter}}</div>
            <span class="undo-status" *ngIf ="!(testStep.statusObject.statusState === '0') && testStep.showUndo"
            (click) ="undoSetStatus($event, testStep)">UNDO</span>
          </mat-expansion-panel-header>
          <div *ngIf="testCase">
            <div class="test-step-description">
              <h5 class="title">
                Description
              </h5>
              <div class="text">
                {{testStep.description}}
              </div>
            </div>
            <div class="test-step-expected-result">
              <h5 class="title">
                Expected result
              </h5>
              <div class="text">
                {{testStep.expectedResult}}
              </div>
            </div>

            <br>
            <mat-form-field appearance="outline" floatLabel="auto" class="full-width">
              <mat-label>Status</mat-label>
              <mat-select class="custom-select"
                          [ngModel]="testStep.status"
                          (ngModelChange) ="onStatusSelectionChange($event, testStep)"
                          disableOptionCentering>
                <mat-option *ngFor="let status of statuses" [value]="status['id']">{{ status.name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="auto" class="full-width">
              <mat-label>Comment</mat-label>
              <input matInput>
            </mat-form-field>

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card>
  <mat-card *ngIf="defectsPage" class="defect-wrap">

    <mat-form-field appearance="outline" floatLabel="auto" class="full-width">
      <mat-label>Defect Title</mat-label>
      <input matInput [(ngModel)]="testCase.defectTitle">
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="auto" class="full-width">
      <mat-label>Defect Description</mat-label>
      <textarea  matInput [(ngModel)]="testCase.comment" rows="10"></textarea>
    </mat-form-field>


    <mat-accordion class="view-attachments-wrap">
      <div *ngFor="let attachment of failedOnStep.attachments">
        <mat-expansion-panel *ngIf="attachment.fileType && attachment.fileType.includes('image')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>subject</mat-icon>
              <span>Screenshot</span>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <img [src]="api.getApi() + attachment.relativePath" style="width: 100%">
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="attachment.fileType === 'text/plain'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>subject</mat-icon>
              <span>Text</span>
            </mat-panel-title>
            <mat-panel-description>
              File type: {{attachment.fileDataType}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Data</mat-label>
            <textarea matInput>{{attachment.fileData}}</textarea>
          </mat-form-field>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
    <div class="create-defect-button-wrap">
      <button mat-raised-button color="primary" (click)="onCreateDefect()"><mat-icon>add</mat-icon>Create Defect</button>
    </div>
  </mat-card>
  </div> <!-- Main Test Case Wrap-->

