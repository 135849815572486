import { Component, OnInit } from '@angular/core';
import {Project} from '../services/project.type';
import {ProjectService} from '../services/project.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-new-project',
  templateUrl: './add-new-project.component.html',
  styleUrls: ['./add-new-project.component.scss']
})
export class AddNewProjectComponent implements OnInit {

  project: Project = new Project();

  constructor(private projectService: ProjectService, private router: Router) { }

  ngOnInit(): void { }

  createProject():void {
    this.projectService.createProject({project: this.project}).subscribe(res => {
      this.router.navigate(['projects']);
    });
  }

}
