<app-base-card [title]="'Test Steps'" [icon]="'hourglass_top'" [showAdd]="false" [showClose]="true"
  (closeEvent)="closeEvent.emit()">
  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF', margin: '5px 0px 0px 0px' }"
    count="3" appearance="line"></ngx-skeleton-loader>
  <mat-accordion [multi]="shortCases && shortRun">
    <mat-expansion-panel #panel *ngFor="let testStep of testRunSteps; index as i" [expanded]="shortCases && shortRun"
      [class]="!(shortCases && shortRun) ? 'list-card__item item-list item-list-accordion' : 'list-card__item item-list item-list-accordion-short'"
      (click)="trackedStep(testStep)" [routerLink]="'./'"
      [queryParams]="{ project: queryParams.project, type: queryParams.type, id: queryParams.id ,tc: queryParams.tc, ts: panel.expanded ? testStep.id : null}">
      <mat-expansion-panel-header class="item-list-accordion__header"
        [class.item-list-accordion__header-green]="testStep.statusObject.statusState === '1'"
        [class.item-list-accordion__header-red]="testStep.statusObject.statusState === '-1'">
        <mat-panel-title class="item-list-accordion__title">
          <span>{{ (testStep.statusObject.statusState === '1' || testStep.statusObject.statusState === '-1') ?
            testStep.statusText : testStep.description}}</span>
          <mat-icon class="item-list-accordion__icon-res"
            *ngIf="testStep.statusObject.statusState === '-1'">close</mat-icon>
          <mat-icon class="item-list-accordion__icon-res"
            *ngIf="testStep.statusObject.statusState === '1'">done</mat-icon>
        </mat-panel-title>
        <button class="item-list-accordion__icon" mat-icon-button *ngIf="(testStep.statusObject.statusState === '0')"
          [disabled]="!editAccessAction" (click)="setStatusPassed($event, testStep)">
          <mat-icon>done</mat-icon>
        </button>
        <div class="attachment">
          <button (click)="openDialogViewAttachment(testStep, $event)" mat-icon-button aria-label="View attachments"
            class="item-list-accordion__icon item-list-accordion__attachment">
            <mat-icon>attachment</mat-icon>
          </button>
          <div *ngIf="!activeIdsPendingArr.includes(+testStep.id)" class=""> - {{ testStep.attachments.length }}</div>
          <div *ngIf="activeIdsPendingArr.includes(+testStep.id)" class="loading">
            <img src="../../../assets/image/loading.gif" alt="pending">
          </div>
        </div>
      </mat-expansion-panel-header>

      <div class="item-list-accordion__info info-item-list">
        <div class="info-item-list__row">
          <div class="info-item-list__wrap">
            <div class="info-item-list__title">Description</div>
            <div class="info-item-list__sub-title">{{testStep.description}}</div>
          </div>
          <div class="info-item-list__wrap">
            <div class="info-item-list__title">Expected result</div>
            <div class="info-item-list__sub-title">{{testStep.expectedResult}}</div>
          </div>
          <div class="info-item-list__wrap">
            <div class="info-item-list__title">Comment</div>
            <div class="info-item-list__sub-title">{{testStep.commentValue || ''}}</div>
          </div>
        </div>

        <div [ngClass]="!shortCases ? 'info-item-list__row' : 'info-item-list__row form'">
          <div class="info-item-list__form">
            <mat-form-field appearance="outline" floatLabel="auto" class="width-100">
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="testStep.status" disableOptionCentering [disabled]="!editAccessAction">
                <mat-option *ngFor="let status of statuses" [value]="status['id']">{{ status.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="info-item-list__form">
            <app-test-step-run-form [testStep]="testStep"></app-test-step-run-form>
          </div>
          <div class="flex-center">
            <button mat-flat-button color="primary" class="info-item-list__save" [style.margin.px]="7"
              (click)="updateStep(testStep)" [disabled]="!editAccessAction">SAVE</button>
            <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink(testStep)">
              <mat-icon>link</mat-icon>
            </button>
          </div>
        </div>
      </div>

    </mat-expansion-panel>
  </mat-accordion>

</app-base-card>