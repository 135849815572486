import { Injectable, OnInit } from '@angular/core';
import { Project } from './project.type';

@Injectable({
  providedIn: 'root'
})
export class TransferDataService implements OnInit {

  public projects: Project[];

  constructor() { }

  private deleteLevel = 2;
  private editLevel = 3;
  private addLevel = 2;
  private createLevel = 3;
  private addTestCaseForTest = 3;

  ngOnInit(): void {
  }

  accessLevelForAction = {
    testRun: {
      testRunDeleteLevel: this.deleteLevel,
      testRunEditLevel: this.editLevel,
      testRunCreateLevel: this.createLevel,
      testRunReRunLevel: this.createLevel,
      testRunArchiveLevel: 3,
      testRunOpenQrLevel: 3,

      testRunAddCaseLevel: this.addTestCaseForTest,
      testRunDeleteCaseLevel: this.addTestCaseForTest,
    },
    testSuit: {
      testSuitAddLevel: this.addLevel,
      testSuitDeleteLevel: this.deleteLevel,
      testSuitEditLevel: this.editLevel,
      testSuitCreateLevel: this.createLevel,
      testSuitAddCaseLevel: this.addTestCaseForTest,
      testSuitCreateRunLevel: this.createLevel,
    },
    testPlan: {
      testPlanDeleteLevel: this.deleteLevel,
      testPlanEditLevel: this.editLevel,
      testPlanCreateLevel: this.createLevel,
      testPlanAddCaseLevel: this.createLevel,
      testPlanCreateRunLevel: this.createLevel,
    },
    testCase: {
      testCaseDeleteLevel: this.deleteLevel,
      testCaseEditLevel: this.editLevel,
      testCaseCreateLevel: this.createLevel,
      testCaseChangeStatusLevel: 3,
      testCaseAssignToLevel: 3,
    },
    testStep: {
      testStepDeleteLevel: this.deleteLevel,
      testStepEditLevel: this.editLevel,
      testStepCreateLevel: this.createLevel,
      testStepUpdateLevel: 3,
    },
    users: {
      deleteUserLevel: 1,
      editUserLevel: 1,
      addUserLevel: 1,
    },
    project: {
      projectCreateLevel: 2,
      projectDeleteLevel: 2,
      projectEditLevel: 2,
      projectAddUserLevel: 2,
    },
    statuses: {
      statusesDeleteLevel: 2,
      statusesCreateLevel: 2,
      statusesEditLevel: 2,
    },
    setting: {
      jiraIntergation: 2,
    }
  }

}
