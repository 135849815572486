import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TestSuitsComponent } from './test-suits/test-suits.component';
import { TestSuitDetailsComponent } from './test-suit-details/test-suit-details.component';
import { TestPlansComponent } from './test-plans/test-plans.component';
import { TestPlanDetailsComponent } from './test-plan-details/test-plan-details.component';
import { TestRunsComponent } from './test-runs/test-runs.component';
import { TestRunDetailsComponent } from './test-run-details/test-run-details.component';
import { SettingsComponent } from './settings/settings.component';
import { ManageProjectsComponent } from './manage-projects/manage-projects.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectsComponent } from './projects/projects.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { TestCasesComponent } from './test-cases/test-cases.component';
import { AddNewTestCaseComponent } from './add-new-test-case/add-new-test-case.component';
import { AddNewTestSuitComponent } from './add-new-test-suit/add-new-test-suit.component';
import { AddNewTestPlanComponent } from './add-new-test-plan/add-new-test-plan.component';
import { AddNewTestRunComponent } from './add-new-test-run/add-new-test-run.component';
import { AddNewProjectComponent } from './add-new-project/add-new-project.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { ManageStatusesComponent } from './manage-statuses/manage-statuses.component';
import { TestRunDefecsPageComponent } from './test-run-defecs-page/test-run-defecs-page.component';
import { TestRunsMainComponent } from './test-runs-main/test-runs-main.component'
import { TestRunPageComponent } from './test-run-page/test-run-page.component';
import { TestPageComponent } from './test-page/test-page.component';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { TestCasesPageComponent } from './test-cases-page/test-cases-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { TestCasesWrapperComponent } from './test-cases-wrapper/test-cases-wrapper.component';
import { UnesignedCasesPageComponent } from './unesigned-cases-page/unesigned-cases-page.component';
import { JiraIntegrationPageComponent } from './jira-integration-page/jira-integration-page.component';
import { CardManagePageComponent } from "./settings-page/card-manage-page/card-manage-page.component";
import { JiraIntergrationGuard } from "./guards/jira-intergration.guard";


const routes: Routes = [
  { path: 'test-page', component: TestPageComponent, canActivate: [AuthGuard] },
  { path: 'projects-page', component: ProjectsPageComponent, canActivate: [AuthGuard] },
  {
    path: 'test-cases-page', component: TestCasesWrapperComponent, canActivate: [AuthGuard], children:
      [
        { path: 'main', component: TestCasesPageComponent },
        { path: 'unesigned-cases', component: UnesignedCasesPageComponent },
        { path: '**', redirectTo: 'main', pathMatch: 'full' }
      ]
  },
  {
    path: 'settings-page', component: SettingsComponent, canActivate: [AuthGuard], children:
      [
        { path: 'main', component: SettingsPageComponent },
        { path: 'jira-integration', component: JiraIntegrationPageComponent, canActivate: [JiraIntergrationGuard] },
        { path: 'statuses', component: CardManagePageComponent },
        { path: '**', redirectTo: 'main', pathMatch: 'full' }
      ]
  },
  { path: 'test-cases-page', component: TestCasesPageComponent, canActivate: [AuthGuard] },
  {
    path: 'settings-page', component: SettingsComponent, canActivate: [AuthGuard], children:
      [
        { path: 'main', component: SettingsPageComponent },
        { path: 'jira-integration', component: JiraIntegrationPageComponent },
        { path: 'statuses', component: CardManagePageComponent },
        { path: '**', redirectTo: 'main', pathMatch: 'full' }
      ]
  },
  { path: 'dashboard-page', component: DashboardPageComponent, canActivate: [AuthGuard] },

  { path: 'test-runs-page', component: TestRunPageComponent, canActivate: [AuthGuard] },
  { path: 'main-page', component: TestRunsMainComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'test-suits', component: TestSuitsComponent, canActivate: [AuthGuard] },
  { path: 'test-planes', component: TestPlansComponent, canActivate: [AuthGuard] },
  { path: 'test-runs', component: TestRunsComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'test-cases', component: TestCasesComponent, canActivate: [AuthGuard] },
  { path: 'add-new-test-case', component: AddNewTestCaseComponent, canActivate: [AuthGuard] },
  { path: 'add-new-test-suit', component: AddNewTestSuitComponent, canActivate: [AuthGuard] },
  { path: 'add-new-test-plan', component: AddNewTestPlanComponent, canActivate: [AuthGuard] },
  { path: 'add-new-test-run', component: AddNewTestRunComponent, canActivate: [AuthGuard] },
  { path: 'add-new-project', component: AddNewProjectComponent, canActivate: [AuthGuard] },
  { path: 'add-new-user', component: AddNewUserComponent, canActivate: [AuthGuard] },
  { path: 'manage-projects', component: ManageProjectsComponent, canActivate: [AuthGuard] },
  { path: 'manage-users', component: ManageUsersComponent, canActivate: [AuthGuard] },
  { path: 'test-suit/:id', component: TestSuitDetailsComponent, canActivate: [AuthGuard] },
  { path: 'test-case/:id', component: TestCasesComponent, canActivate: [AuthGuard] },
  { path: 'test-plan/:id', component: TestPlanDetailsComponent, canActivate: [AuthGuard] },
  { path: 'test-plan/:id/:testCaseId', component: TestPlanDetailsComponent, canActivate: [AuthGuard] },
  { path: 'test-run/:id', component: TestRunDetailsComponent, canActivate: [AuthGuard] },
  { path: 'test-run/:id/:testCaseId', component: TestRunDetailsComponent, canActivate: [AuthGuard] },
  { path: 'test-suit/:id/:testCaseId', component: TestSuitDetailsComponent, canActivate: [AuthGuard] },
  { path: 'project/:id', component: ProjectDetailsComponent, canActivate: [AuthGuard] },
  { path: 'manage-statuses', component: ManageStatusesComponent, canActivate: [AuthGuard] },
  { path: 'test-run-defects/:id', component: TestRunDefecsPageComponent, canActivate: [AuthGuard] },
  { path: 'test-run-defects/:id/:testCaseId', component: TestRunDefecsPageComponent, canActivate: [AuthGuard] },
  {
    path: '',
    redirectTo: '/dashboard-page',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '/dashboard-page', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
