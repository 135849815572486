import { Component, EventEmitter, OnInit, ViewChild, Output, OnDestroy, Input } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionLike, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogCreateTestRunComponent } from 'src/app/dialogs/dialog-create-test-run/dialog-create-test-run.component';
import { DialogEditComponent } from 'src/app/dialogs/dialog-edit/dialog-edit.component';
import { DialogTestCasesSelectComponent } from 'src/app/dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { ProjectService } from 'src/app/services/project.service';
import { Status } from 'src/app/services/status.type';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestRun } from 'src/app/services/test-run.type';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { TestSuit, TestSuitFolders } from 'src/app/services/test-suit.type';
import { Core } from 'src/app/services/core.service';
import { DialogQrCodeComponent } from 'src/app/dialogs/dialog-qr-code/dialog-qr-code.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Params } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestPlanService } from 'src/app/services/test-plan.service';
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component';
import { DialogConfirmForTestrunComponent } from 'src/app/dialogs/dialog-confirm-for-testrun/dialog-confirm-for-testrun.component';
import { BaseCardComponent } from 'src/app/app-shared-component/base-card/base-card.component';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-test-run',
  templateUrl: './card-test-run.component.html',
  styleUrls: ['./card-test-run.component.scss']
})
export class CardTestRunComponent implements OnInit, OnDestroy {
  public addAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunCreateLevel');
  public editAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunEditLevel');
  public deleteAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunDeleteLevel');
  public archiveAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunArchiveLevel');
  public qrAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunOpenQrLevel');
  public reRunAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunReRunLevel');
  public addCaseAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunAddCaseLevel');
  public guestAccessAction: boolean = this._userAccessService.getLevelAccessUser === 4;

  @ViewChild(BaseCardComponent) private baseCard: BaseCardComponent;
  @ViewChild(MatSelectionList, { static: true }) private selectionList: MatSelectionList;
  public search: string;

  private _testRun: TestRun[];
  public testRun: TestRun[];
  private _testSuits: TestSuit[];
  private _testPlans: TestPlan[];
  private _testRunsCase: TestCase[];
  public selectedValue: TestRun;
  private projectStatuses: Status[];
  public showSection: boolean = true;
  public activeTestRun: TestRun = null;
  public activeExistTestCaseSuits: TestCase[];
  public activeExistTestCasePlans: TestCase[];

  public archiveTestRun: TestRun[] = [];

  public pending: boolean = false;

  private sortType: string;
  private sortTypesData = {
    'Creation Date': 'createDate',
    'Modification Date': 'modifiedDate',
    'Description': 'description',
    'Created By': 'creator_id',
    'Name': 'title',
  };

  private testRunArchivedTypes = {
    'All': '',
    "Active": false,
    'Archive': true,
  }
  private selectionListSubscriber: SubscriptionLike;
  public isRightSectionOpen: boolean = false;

  @Input() showCase: boolean = false;
  @Input() showRun: boolean = true;
  @Input() shortForm: boolean = false;
  @Input() showDetailsPage: boolean = false;
  @Input() queryParams: Params;
  @Input() isShowSkeleton: boolean;
  @Input() showStep: boolean;

  @Input() showSelect = true

  @Output() showTestRunCase = new EventEmitter<TestRun>();
  @Output() updateSelectedValue = new EventEmitter<TestRun>();
  @Output() updateTestRunCases = new EventEmitter<TestRun>();
  @Output() showTestDefects = new EventEmitter<TestRun>();
  @Output() showTestDetails = new EventEmitter<TestRun>();
  @Output() collapseEvent = new EventEmitter<string>();
  @Output() showCaseEvent = new EventEmitter();
  @Output() closeCaseEvent = new EventEmitter();
  @Output() isShowSkeletonEvent = new EventEmitter<boolean>();
  @Output() selectedTypeValue = "All";
  @Output() baseCardSelectText = "Select archive type";
  @Output() sortSelectData = Object.keys(this.sortTypesData);
  @Output() baseSortValue = this.testRunService.sortType || this.sortSelectData[0];

  @Output() archiveList = Object.keys(this.testRunArchivedTypes);


  constructor(
    private testRunService: TestRunService,
    private testCaseService: TestCaseService,
    private testSuitService: TestSuitService,
    private testPlanService: TestPlanService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public auth: AuthenticationService,
    private _userAccessService: UserAccessService,
    private clipboard: Clipboard,
  ) { }

  ngOnDestroy(): void {
    this.selectionListSubscriber ? this.selectionListSubscriber.unsubscribe() : '';
  }

  ngOnInit(): void {
    this.setSelectionList()
    this.getData();
    this.testCaseService.isRightSectionOpen$.subscribe(v => {
      this.isRightSectionOpen = v;
    })
  }

  searchPath(param: Params) {
    let find = this.testRun.find(elem => +elem.id === +param.id && +elem.projectId === +param.project)
    if (find) {
      this.selectedValue = find;
      this.selectionList.options.changes.subscribe(res => {
        res.forEach(elem => { if (elem.value === find) elem.selected = true })
      });
      if (find.archive === 'true') return
      else if (param.details) this.showDetails(find);
      else if (param.defect) this.showDefects(find);
      else this.showTestRunCase.emit(find);
    }
  }

  setSelectionList() {
    this.selectionListSubscriber = this.selectionList.selectionChange.subscribe((s: MatSelectionListChange) => {
      this.selectionList.deselectAll();
      s.options.forEach(item => { item.selected = true; })
    });
  }

  refreshSelectionList() {
    this.selectionList.deselectAll();
  }

  getData() {
    this.getSelectedArchived()
    this.getTestRun();
    this.getTestSuits();
    this.getTestPlans();
  }

  getTestRun(flag = true) {
    let params = {
      project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null',
    }
    if (this.testRunArchivedTypes[this.selectedTypeValue] !== '') {
      params['archive'] = this.testRunArchivedTypes[this.selectedTypeValue]?.toString();
    }

    this.testRunService.getTestRuns(params).subscribe(res => {
      res.sort((a, b) => +JSON.parse(a.archive) - +JSON.parse(b.archive));
      this._testRun = res;
      this.testRun = this.mapSelectedValue(res);
      this.sortTestRunByEvent()
      if (flag && this.queryParams?.type === 'tr') this.searchPath(this.queryParams);
    })
    this.projectService.getProjectStatuses(JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null').subscribe(res => {
      this.projectStatuses = res;
      this.isShowSkeletonEvent.emit(false)
    });
  }

  getTestSuits() {
    this.testSuitService.getTestSuits({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe((testSuits: TestSuit[]) => {
      this._testSuits = testSuits;
    })
  }

  getTestPlans() {
    this.testPlanService.getTestPlans({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe((testPlans: TestPlan[]) => {
      this._testPlans = testPlans;
    })
  }

  getAllSuits(): Promise<TestSuit[]> {
    return new Promise<TestSuit[]>((resolve) => {
      this.testSuitService.getTestSuits({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe(cases => {
        resolve(cases);
      });
    });
  }

  getAllPlans(): Promise<TestPlan[]> {
    return new Promise<TestPlan[]>((resolve) => {
      this.testPlanService.getTestPlans({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe(cases => {
        resolve(cases);
      });
    });
  }

  getTestRunCases(testRunId: string) {
    return this.testCaseService.getTestCasesByTestRun({ tr_id: testRunId }).pipe(
      map((testCases: TestCase[]) => {
        testCases.map(val => {
          val.filteredByPerson = '1';
          val.filteredByStatus = '1';
        })
        this._testRunsCase = testCases;
        return testCases;
      })
    )
  }

  mapSelectedValue(testRun: TestRun[]): TestRun[] {
    if (this.selectedValue) {
      return testRun.map(val => {
        val.id === this.selectedValue.id ? val.isOpen = true : val.isOpen = false;
        if (val.isOpen) this.updateSelectedValue.emit(val)
        return val
      })
    } else {
      return testRun
    }
  }

  selectionChange(event: MatSelectionListChange) {
    event.options.forEach(item => {
      this.selectedValue = item.value;
      this.testRunService.selectTestRun = item.value;
      Core.localStorageService.setItem('selectedRun', JSON.stringify(this.selectedValue));

      if (item.value.archive === 'false') {
        this.showTestRunCase.emit(item.value);
        this.showCaseEvent.emit()
      } else this.closeCaseEvent.emit(true);
    })
  }

  onEditEvent(item: TestRun) {
    const dialogRef = this.dialog.open(DialogEditComponent, {
      width: '650px',
      data: { title: item.title, description: item.description }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const testRun = this._testRun.find(val => val.id === item.id);
        this.testRunService.saveTestRun(Object.assign(testRun, res)).toPromise()
          .then(res => {
            if (res) {
              this.snackBar.open('Test Run was successfully edited', "OK", { duration: 3000 })
              this.getTestRun()
            }
          })
      }
    })
  }

  onDeleteEvent(item: TestRun) {
    if (item.archive === 'false') this._deleteAsUnArchivated(item);
    else this._deleteTestRun(item);
  }

  private _deleteAsUnArchivated(item: TestRun) {
    let sub = this.dialog.open(DialogConfirmForTestrunComponent, {
      width: '650px',
      data: { name: item.title }
    }).afterClosed().subscribe(res => {
      if (res && res.action === 1 && item) this._deleteTestRun(item);
      else if (res && res.action === 0 && item) {
        this.testRunService.archive({ test_run_id: item.id, archive: 'true' }).subscribe(res => {
          if (res) {
            this.snackBar.open('Test Run was successfully archivated', "OK", { duration: 3000 });
            this.getTestRun(false);
            sub?.unsubscribe();
          }
        });
      }
    });
  }

  private _deleteTestRun(item: TestRun) {
    let sub = this.dialog.open(DialogConfirmComponent, {
      width: '650px',
      data: { dialogTitle: 'delete', name: item.title }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.testRunService.deleteTestRun({ id: item.id }).subscribe(res => {
          if (res) {
            this.snackBar.open('Test Run was successfully deleted', "OK", { duration: 3000 });
            this.getTestRun(false);
            sub?.unsubscribe();
          }
        });
      }
    })
  }

  onReRunEvent(item: TestRun) {
    this.testRunService.reRun({ tr_id: item.id }).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Run was successfully reruned', "OK", { duration: 3000 })
        this.getTestRun();
      }
    });
  }

  async onSelectEvent(item: TestRun) {
    this.activeTestRun = item;
    this.pending = true;
    let params;
    let testSuits = [];

    const projectId = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
    if (projectId !== 'all' && projectId !== 'null') params = { project_id: projectId };
    const testSuitsArr = await this.testSuitService.foldersList(params).toPromise();
    testSuitsArr?.forEach(elem => testSuits.push([elem]));

    const plans = await this.getAllPlans();
    const testPlans = await this.setCaseToPlans(plans);
    let testCaseRuns = await this.getTestRunCases(item.id).toPromise();

    this.activeExistTestCaseSuits = testCaseRuns.filter(elem => !elem.tpId);
    this.activeExistTestCasePlans = testCaseRuns.filter(elem => elem.tpId);

    this.pending = false;
    const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
      width: '850px',
      data: { testSuits: testSuits, testPlans: testPlans, selectedSuits: this.activeExistTestCaseSuits, selectedPlans: this.activeExistTestCasePlans, title: 'SelectFromRun' },
      panelClass: 'custom-modalbox'
    });
    let sub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._addToRunAction(result)
        sub?.unsubscribe();
      }
    })
  }

  private _addToRunAction(result) {
    let plans = [...result.selectedPlans].filter(elem => elem.level === 0).map(elem => elem.id);
    forkJoin([
      this.testRunService.updateTestCasesList({
        test_run_id: this.activeTestRun.id,
        project_id: this.activeTestRun.projectId,
        test_cases: result.selectedSuits.filter(c => c.isCase).map(c => ({ tc_id: c.elem.id, ts_id: c.elem.tsId, tp_id: c.elem.tpId })),
        deleted_test_cases: []
      }),
      plans.length ? this.testPlanService.plansToRun({ tr_id: this.activeTestRun.id, tp_ids: plans }) : of({ valid: 1, msg: 'true' })
    ]).subscribe(res => {
      if (res[0] === true && res[1].valid === 1) {
        if (res[1].msg === 'true') this._successHundler();
        else this._errorHundler();
      }
    })
  }

  private _successHundler() {
    this.getTestRun();
    this.updateTestRunCases.emit(this.selectedValue);
    this.activeTestRun = null;
    this.activeExistTestCaseSuits = null;
    this.activeExistTestCasePlans = null;
    this.snackBar.open('Test Cases was successfully selected', "OK", { duration: 3000 });
  }

  private _errorHundler() {
    this.getTestRun();
    this.updateTestRunCases.emit(this.selectedValue);
    this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 });
  }

  async onAddEvent() {
    let params;
    let testSuits = [];

    const projectId = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
    if (projectId !== 'all' && projectId !== 'null') params = { project_id: projectId };
    const testSuitsArr = await this.testSuitService.foldersList(params).toPromise();
    testSuitsArr?.forEach(elem => testSuits.push([elem]));

    const plans = await this.getAllPlans();
    const testPlans = await this.setCaseToPlans(plans);

    if (this.baseCard) this.baseCard.pending = false;
    this.dialog.open(DialogCreateTestRunComponent, {
      width: '850px',
      data: { testSuits: testSuits, testPlans: testPlans }
    }).afterClosed().subscribe(res => {
      if (+res?.addSuits?.valid === +1 && res?.toAddPlans?.length === 0) {
        this.snackBar.open('Test Run was successfully added', "OK", { duration: 3000 });
        this.getTestRun()
      } else if (+res?.addSuits?.valid === +1 && res?.toAddPlans?.length) {
        this.testPlanService.plansToRun({ tr_id: res.addSuits.test_run.id, tp_ids: res.toAddPlans }).subscribe(result => {
          if (result.valid === 1) {
            if (result.msg === 'true') this._successHundler();
            else this._errorHundler();
          } else this._errorHundler();
        })
      }
    })
  }

  getColor(status: { name: string }) {
    let find: Status;
    this.projectStatuses ? find = this.projectStatuses.find(item => item.name === status.name) : ''
    if (find) return find.color
  }

  setCaseToPlans(testPlans): Promise<TestSuitFolders[]> {
    return Promise.all(testPlans.map((s => {
      return new Promise<any>((resolve) => {
        this.testPlanService.foldersPlansList({ tp_id: s.id }).subscribe((folder: TestSuitFolders) => {
          resolve({ ...s, sub_suits: folder, });
        });
      });
    })))
  }

  setCaseToPlan(testPlans: TestPlan[]): Promise<TestPlan[]> {
    return Promise.all(testPlans.map((p => {
      return new Promise<TestPlan>((resolve) => {
        this.testPlanService.getTestCasesList(p.id).subscribe(cases => {
          p.testCases = cases;
          resolve(p);
        });
      });
    })))
  }

  openQrDialog(item: TestRun) {
    const v = {
      test_run_id: item.id,
      user_id: this.auth.getUser().id
    }
    const dialogRef = this.dialog.open(DialogQrCodeComponent, {
      width: '450px',
      data: { value: JSON.stringify(v) }
    })
    dialogRef.afterClosed().subscribe(result => {
    });

  }

  showDefects(testRun: TestRun) {
    this.selectionList.deselectAll();
    let find = this.selectionList.options.find(item => +item.value.id === +testRun.id);
    if (find) find.selected = true
    this.selectedValue = testRun;
    this.showTestRunCase.emit(testRun);
    this.showTestDefects.emit(testRun);
  }

  showDetails(testRun: TestRun) {
    this.selectionList.deselectAll();
    let find = this.selectionList.options.find(item => +item.value.id === +testRun.id);
    if (find) find.selected = true
    this.selectedValue = testRun;
    this.showTestRunCase.emit(testRun);
    this.showTestDetails.emit(testRun);
  }

  copyLink() {
    if (window.location.href) {
      let queryParamArr = window.location.href.split('?')[1].split('&');
      let params = queryParamArr?.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});

      if (!params['tc']) this.clipboard.copy(window.location.href);
      else this.clipboard.copy(`${window.location.origin}${window.location.pathname}?project=${params['project']}&type=${params['type']}&id=${params['id']}`);

      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
    }
  }

  selectArchivedType(item) {
    this.selectedTypeValue = item;

    this.testRunService.selectedArchivedType(item)

    this.getTestRun()
  }

  getSelectedArchived() {
    if (this.testRunService.selectedValue) {
      this.selectedTypeValue = this.testRunService.selectedValue;
    }
  }

  changeArchiveType(item) {
    this.testRunService.archive({ test_run_id: item.id, archive: JSON.stringify(!JSON.parse(item.archive)) }).subscribe();
    //If selected archived/active
    if (this.testRunArchivedTypes[this.selectedTypeValue] !== '') {
      this.testRun.splice(this.testRun.indexOf(item), 1)
    } else {
      //If selected all
      this.testRun.splice(this.testRun.indexOf(item), 1)
      JSON.parse(item.archive) ? this.testRun.unshift(item) : this.testRun.push(item)
      item.archive = JSON.stringify(!JSON.parse(item.archive));
    }
  }

  sortTestRunByEvent(event?) {
    this.sortType = this.testRunService.sortType;
    if (event) {
      this.testRunService.sortType = event.value
      this.sortType = event.value;
    }

    let sortedTestRun = this.testRun

    if (this.sortType) {
      switch (this.sortType) {
        case 'Creation Date':
          sortByCreationDate();
          break;
        case 'Modification Date':
          sortByModificationDate();
          break;
        case 'Description':
          sortByDescription();
          break;
        case 'Created By':
          sortByCreator();
          break;
        case 'Name':
          sortByName();
          break;
      }
    } else {
      sortByCreationDate()
    }

    function sortByCreationDate() {
      sortedTestRun.sort(function (a, b) {
        return +new Date(b.createdDate) - +new Date(a.createdDate);
      });
    }

    function sortByModificationDate() {
      sortedTestRun.sort(function (a, b) {
        return +new Date(b.modifiedDate) - +new Date(a.modifiedDate);
      });
    }

    function sortByDescription() {
      sortedTestRun.sort(function (a, b) {
        if (a.description?.toUpperCase() < b.description?.toUpperCase()) {
          return -1;
        }
        if (a.description?.toUpperCase() > b.description?.toUpperCase()) {
          return 1;
        }
        return 0;
      });
    }

    function sortByName() {
      sortedTestRun.sort(function (a, b) {

        if (a.title.toUpperCase() < b.title.toUpperCase()) {
          return -1;
        }
        if (a.title.toUpperCase() > b.title.toUpperCase()) {
          return 1;
        }
        return 0;
      });
    }

    function sortByCreator() {
      sortedTestRun.sort(function (a, b) {
        if (!a.userName) {
          return 1;
        }
        if (!b.userName) {
          return -1;
        }
        return a.userName.localeCompare(b.userName);
      });
    }

  }

}
