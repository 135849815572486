import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { map, tap } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import { TestStep } from '../../services/test-step.type';
import { TestStepService } from '../../services/test-step.service';


/**
 * Data source for the Job Orders view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TestStepsTableDataSource extends DataSource<TestStep> {
  private loadingSubject = new BehaviorSubject(true);
  private test_case_id:string;

  public loading$ = this.loadingSubject.asObservable();

  constructor(private paginator: MatPaginator, private sort: MatSort, private testStepService: TestStepService, test_case_id: string) {
    super();
    this.test_case_id = test_case_id;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<TestStep[]> {
    this.loadingSubject.next(true);
    return this.testStepService.getTestSteps(this.test_case_id).pipe(tap(c => this.loadingSubject.next(false)));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}
}
