import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { ProjectService } from '../../services/project.service';
import { TestSuit } from '../../services/test-suit.type';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface DialogData {
  testSuits: TestSuit[];
}

@Component({
  selector: 'app-dialog-change-project',
  templateUrl: './dialog-change-project.component.html',
  styleUrls: ['./dialog-change-project.component.scss']
})
export class DialogChangeProjectComponent implements OnInit {

  projects: Project[];
  chooseProjectForm: FormGroup;

  public isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<DialogChangeProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.initForm();
    this.projectService.getProjects().subscribe(response => {
      this.projects = response;
      this.isLoading = false;
    });
  }

  chooseProject() {
    let find = this.projects.find(item => +item.id === +this.chooseProjectForm.value.project)
    if (find) {
      this.dialogRef.close(find)
    }
  }

  initForm() {
    this.chooseProjectForm = new FormGroup({
      project: new FormControl('', [Validators.required]),
    })
  }

  get f() {
    return this.chooseProjectForm.controls
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
