import {Component, Inject, OnInit} from '@angular/core';
import {TestSuitService} from '../../services/test-suit.service';
import {Core} from '../../services/core.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {TodoItemFlatNode} from '../../add-new-test-suit/add-new-test-suit.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-select-suit',
  templateUrl: './dialog-select-suit.component.html',
  styleUrls: ['./dialog-select-suit.component.scss']
})
export class DialogSelectSuitComponent implements OnInit {

  constructor(
      private testSuitService: TestSuitService,
      private dialogRef: MatDialogRef<DialogSelectSuitComponent>,
      @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
  }

  public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
  public treeData: any[] = [];
  public isLoading: boolean = true;
  treeControl = new FlatTreeControl<any>(node => node.level, node => node.expandable);
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true);
  selectedItem: TodoItemFlatNode | undefined;



  ngOnInit(): void {
    this.getData();
    // this.treeData = this.dialogData.testSuits;
  }

  hasChild = (_: number, node: any) => node.children && node.children.length > 0;

  todoLeafItemSelectionToggle(node) {
    this.checklistSelection.clear();
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.deselect(node); // Если элемент уже выбран, снимаем его выбор
    } else {
      this.checklistSelection.select(node); // Выбираем новый элемент
    }

    if (this.selectedItem === node) {
      this.selectedItem = undefined; // Снять выбор с элемента, если он уже был выбран
    } else {
      this.selectedItem = node; // Выбрать новый элемент
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  getData() {
    this.testSuitService.getTestSuits({project_id: this.currentProject}).subscribe(v => {
      this.treeData = v;
    }, ()=> {}, () => {
      this.isLoading = false;
    })
  }

}
