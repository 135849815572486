<div class="custom-tooltip">
  <mat-list>
    <mat-list-item *ngFor="let item of StatusesCount" class="custom-tooltip-item">
      <span class="custom-tooltip-item-name">{{item.name}}</span><span class="custom-tooltip-item-count">{{item.count}}</span>
    </mat-list-item>
    <mat-list-item *ngIf = "StatusesCount.length === 0"class="custom-tooltip-item">
      <span class="no-item">This run is empty</span>
    </mat-list-item>
   </mat-list>
</div>
