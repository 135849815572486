<app-base-card [title]="'Test Case Defects'" [icon]="'dns'" [showAdd]="false" [showClose]="true" [shortForm]="shortForm"
  [showCollapse]="showStep" (closeEvent)="closeEvent.emit()" (collapseEvent)="collapseEvent.emit('TestRunCase')">

  <mat-selection-list (selectionChange)="selectionChange($event)">
    <mat-list-option #option *ngFor="let item of testCase" [value]="item" class="list-card__item item-list"
      [routerLink]="'./'"
      [queryParams]="{ project: queryParams.project, type: queryParams.type, id: queryParams.id, defect: true ,td: item.relationId}">
      <div [class]="shortFormRun ? 'item-list__body expanded' : 'item-list__body'">
        <div class="item-list__title">{{item.title}}</div>
        <div class="item-list__action">
          <ng-container *ngIf="option.selected && !shortFormRun">
            <div class="item-list__menu">
              <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onAssignedToEvent(item)">
                  <span>Assigned To</span>
                </button>
                <button mat-menu-item (click)="copyLink()" *ngIf="selectionList._value.includes(item)">
                  <span>Copy link</span>
                </button>
              </mat-menu>
            </div>
          </ng-container>
          <ng-container *ngIf="shortFormRun">
            <div class="item-list__menu-custom">
              <button class="item-list__body-copy" mat-icon-button matTooltip="Copy link" (click)="copyLink()"
                *ngIf="selectionList._value.includes(item)" stopPropagation>
                <mat-icon>link</mat-icon>
              </button>
              <button mat-icon-button class="item" (click)="onAssignedToEvent(item)">
                <mat-icon *ngIf="item.assignedTo === '-1'">assignment_ind</mat-icon>
                <span *ngIf="item.assignedTo !== '-1'">{{getAssigneTo(item.assignedTo) | shortName:11}}</span>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>

</app-base-card>