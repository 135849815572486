import { Component, EventEmitter, Input, OnInit, Output, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { TestCase } from '../services/test-case.type';
import { TestStep } from '../services/test-step.type';
import { TestCaseService } from '../services/test-case.service';
import { TestStepService } from '../services/test-step.service';
import { Router } from '@angular/router';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-test-case-details-sidebar',
  templateUrl: './test-case-details-sidebar.component.html',
  styleUrls: ['./test-case-details-sidebar.component.scss']
})
export class TestCaseDetailsSidebarComponent implements OnInit {

  _testCase: TestCase;
  _testSteps: TestStep[];

  @Input() set testCase(test: TestCase) {
    if (test) {
      this._testCase = test;
      this.initForm();
    }
  }
  get testCase() {
    return this._testCase;
  };
  @Input() set testSteps (testSteps: TestStep[]) {
    if(testSteps) {
      this._testSteps = testSteps;
      this.initFormSteps();
    }
  } get testSteps() {
    return this._testSteps
  };
  @Input() showSteps: boolean;
  @Input() goBackURLId: string;
  @Output() Resize = new EventEmitter<string>();
  @ViewChildren('textAreaFocus') textAreaFocus: QueryList<ElementRef>;
  extendedLast: boolean;

  editTestCaseForm:UntypedFormGroup;
  testStepsForm:UntypedFormGroup;

  constructor(
    public router: Router,
    private testCaseService: TestCaseService,
    private testStepService: TestStepService
  ) { }

  ngOnInit(): void {
    if (this.router.url.includes('suit')) {
      this.showSteps = true;
    }
  }

  initForm() {
    this.editTestCaseForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.testCase.title, [Validators.required]),
      description: new UntypedFormControl(this.testCase.description, [Validators.required]),
    })
  }

  initFormSteps() {
    this.testStepsForm = new UntypedFormGroup({
      steps: new UntypedFormArray([  ])
    })

    this.testSteps.forEach( val => {
      this.steps.push(this.createItem(val));
    })

  }

  get f() {
    return this.editTestCaseForm.controls
  }

  get steps() {
    return this.testStepsForm.get('steps') as UntypedFormArray;
  }

  createItem(step: TestStep) {
    return new UntypedFormGroup({
      id: new UntypedFormControl( step ? step.id: ''),
      description: new UntypedFormControl(step ? step.description: '', [Validators.required]),
      expectedResult: new UntypedFormControl(step ? step.expectedResult: '', [Validators.required]),
    })
  }

  closeTestCaseDetail(event): void {
    event.stopPropagation();
    this.Resize.emit('hide');
  }

  saveEdit(): void {

    this.testCase.title = this.editTestCaseForm.value.title;
    this.testCase.description = this.editTestCaseForm.value.description;

    this.testCaseService.saveTestCase(this.testCase).subscribe(() => { });
  }

  saveEditStep(testStep, index: number): void {
    if (testStep.id) {
      const item:TestStep = testStep.value
      this.testStepService.saveStep(item).subscribe(() => { });
    } else {
      this.testStepService.createTestStep(this.testCase.id, testStep.value).subscribe((createdStep: TestStep) => {
        this.steps.removeAt(index);
        this.steps.push(this.createItem(createdStep));
        this.testSteps[index] = createdStep;
      })
    }
  }

  ctrlEnter(testStep: TestStep, index: number): void { // edit here if function useless
    this.saveEditStep(testStep, index);
    this.createStep();
  }
  createStep(): void {
    const testStep = new TestStep();
    testStep.description = '';
    testStep.expectedResult = '';
    testStep.id = '';

    this.steps.push(this.createItem(testStep));

    this.textAreaFocus.changes.subscribe(() => {
      this.textAreaFocus.last.nativeElement.focus()
    })
    this.extendedLast = true
    this.testSteps.push(testStep);
  }

  deleteStep(last, testStep, index: number): void {
    if (last) {
      this.extendedLast = false
    }
    testStep = testStep.value
    if (testStep.id)
      // If Test Step was already saved
      this.testStepService.deleteTestStep(testStep.id).subscribe(() => {
        this.steps.removeAt(index);
        this.testSteps.splice(index, 1);
      })
    else
      // If Test Step hasn't been saved yet and has no id
      this.testSteps.splice(index, 1);
      this.steps.removeAt(index);
  }
}
