<app-base-page>
  <!--Main column with runs, plans and suits-->
  <div class="row">
    <div class="main-column">
      <app-main-list actions="add" getStatus="true"
                    [data]="data[appTypes.testRun].data" [currentType]="appTypes.testRun" 
                    (itemClicked)="onItemClicked($event, appTypes.testRun)" (createNewItemEvent)="createNewItem($event)"
                    (editEvent)="onEditEvent($event, appTypes.testRun)"
                    (deleteEvent)="ondDeleteEvent($event, appTypes.testRun)"
                    (selectEvent)="onSelectEvent($event, appTypes.testRun)"
                    (reRunEvent)="onReRunEvent($event, appTypes.testRun)"
                    >
      </app-main-list>
      <app-main-list actions="add" [data]="data[this.appTypes.testPlan].data" 
                    [currentType]="appTypes.testPlan" 
                    (itemClicked)="onItemClicked($event, appTypes.testPlan)" (createNewItemEvent)="createNewItem($event)"
                    (editEvent)="onEditEvent($event, appTypes.testPlan)"
                    (deleteEvent)="ondDeleteEvent($event, appTypes.testPlan)"
                    (selectEvent)="onSelectEvent($event, appTypes.testPlan)"
                    >
      </app-main-list>
      <app-main-list actions="add" [data]="data[this.appTypes.testSuit].data" 
                    [currentType]="appTypes.testSuit" 
                    (itemClicked)="onItemClicked($event, appTypes.testSuit)" (createNewItemEvent)="createNewItem($event)"
                    (editEvent)="onEditEvent($event, appTypes.testSuit)"
                    (deleteEvent)="ondDeleteEvent($event, appTypes.testSuit)"
                    (selectEvent)="onSelectEvent($event, appTypes.testSuit)"
                    >
      </app-main-list>
    </div> 
    <!--Secondary columns renderend based on what element was clicked in main column-->
    <ng-container *ngFor="let item of nesting">
      <div class="main-column sticky">
        <app-main-list
          *ngIf="true"
          [data]="data[item].data"
          [currentType]="item"
          actions="more"
          canClose="1"
          [createTestCase]="createTestCase"
          (createTestRunEvent)="onCreateTestRunEvent($event)"
          (changeStatusEvent)="onChangeStatusEvent($event)"
          (assignedToEvent)="onAssignedToEvent($event)"
          (itemClicked)="onItemClicked($event, item)"
          (closeEvent)="onClose($event)"
          (createNewItemEvent)="createNewItem($event)">
        </app-main-list>
        <app-test-cases-creation-mode *ngIf="item === appTypes.testCase && data[item].creationMode">
        </app-test-cases-creation-mode>
      </div>
    </ng-container>
  </div>

</app-base-page>