import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { TestCase } from '../services/test-case.type';
import { CardTestCasesComponent } from './card-test-cases/card-test-cases.component';
import { TestCaseService } from '../services/test-case.service';


@Component({
  selector: 'app-test-cases-page',
  templateUrl: './test-cases-page.component.html',
  styleUrls: ['./test-cases-page.component.scss']
})
export class TestCasesPageComponent implements OnInit, AfterViewInit {
  @ViewChild('cardTestCases') private cardTestCases: CardTestCasesComponent;

  public showCase: boolean;
  public selectedTestCase: TestCase;
  public queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private testCaseService: TestCaseService
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.testCaseService.setActiveComponent(null);
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(res => {
      if (window.location.pathname === '/test-cases-page/main') {
        if (!res['project'] && this.queryParams && this.queryParams['project']) null;
        else this.queryParams = res;
      }
    })
    if (this.cardTestCases) this.testCaseService.setActiveComponent(this.cardTestCases, 'CardTestCasesComponent');
  }

  changeUrl(url = null) {
    let param: Params;
    let queryParamArr;
    if (!url) queryParamArr = window.location.href?.split('?')[1]?.split('&');
    else if (url) queryParamArr = url.split('?')[1]?.split('&');

    if (queryParamArr) param = queryParamArr.map(elem => elem.split('=')).reduce((acc, n) => (acc[n[0]] = n[1], acc), {});
    if (param) this.queryParams = param;
  }

  onShowTestCase(testCase: TestCase) {
    this.selectedTestCase = testCase;
    this.showCase = true;
  }

  closeTestStep(flag) {
    if (flag) {
      if (this.cardTestCases) this.cardTestCases.selectedValue = null;
      this.selectedTestCase = null;
      this.showCase = false;
    }
  }
}
