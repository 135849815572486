import { Component, Input, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TestCase } from 'src/app/services/test-case.type';
import { Status } from '../../services/status.type';
import { User } from '../../services/user.type';
import { TestRunService } from '../../services/test-run.service';
import { TestStep } from '../../services/test-step.type';
import { TestStepService } from '../../services/test-step.service';
import { DialogTestCaseFailComponent } from '../../dialogs/dialog-test-case-fail/dialog-test-case-fail.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { DefaultStatuses } from '../../services/default-statuses.type';

@Component({
  selector: 'app-test-run-details-table',
  templateUrl: './test-run-details-table.component.html',
  styleUrls: ['./test-run-details-table.component.scss']
})
export class TestRunDetailsTableComponent implements OnInit {

  public dataSource: MatTableDataSource<TestCase>;
  private paginator: MatPaginator;
  private sort: MatSort;
  selectedStatuses: Status[] = [];
  selectedPerson: User[] = [];
  selectedPersonName: string[] = [];
  @ViewChild('SetStatusSelect', { static: false }) public SetStatusSelect: any;
  @ViewChild('SetPersonSelect', { static: false }) public SetPersonSelect: any;
  filterOpened = {
    SetStatusSelect: false,
    SetPersonSelect: false
  };
  relationId: string;
  selection = new SelectionModel<TestCase>(true, []);

  constructor(
    public testRunService: TestRunService,
    public testStepService: TestStepService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  @Input() public set data(data: TestCase[]) {
    this.applyDataSource(data);
  }
  @Input() public displayedColumns: string[];
  @Input() public defaultStatuses: DefaultStatuses;
  @Input() public currentProjectId: string;
  @Input() public statuses: Status[];
  @Input() public users: User[];
  @Output() shouldUpdateCounters = new EventEmitter<boolean>();
  @Output() openSideComponent = new EventEmitter<TestCase>();
  @Output() selectionChange = new EventEmitter<TestCase[]>();
  @Output() failedStep = new EventEmitter<string>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  ngOnInit() {
    this.selection.changed.subscribe(change => {
      this.selectionChange.emit(this.selection.selected);
    })
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  applyDataSource(data: TestCase[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openTestCaseDetail(testCase: TestCase) {
    this.openSideComponent.emit(testCase);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => { if (row.defectCreated === 'No') this.selection.select(row) });
  }

  checkboxLabel(row?: TestCase): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyFilter(filterBy) {

    switch (filterBy) {
      case 'byStatus':
        // Retrieving selected statuses ids to simplify filtering
        const selectedStatusId = [];
        this.selectedStatuses.map(status => selectedStatusId.push(status.id))

        // Preparing data for filtering
        if (this.selectedStatuses.length === 0) {
          this.dataSource.data.map(testCase => testCase.filteredByStatus = '1')
        } else {
          this.dataSource.data.map(testCase => {
            if (selectedStatusId.indexOf(testCase.status) >= 0) {
              testCase.filteredByStatus = '1';
            } else {
              testCase.filteredByStatus = '0';
            }
          })
        }
        break;
      case 'byPerson':
        // Retrieving selected statuses ids to simplify filtering
        const selectedPersonId = [];
        this.selectedPersonName = [];
        this.selectedPerson.map(user => {
          this.selectedPersonName.push(user.firstName)
          selectedPersonId.push(user.id)
        })

        // Preparing data for filtering
        if (this.selectedPerson.length === 0) {
          this.dataSource.data.map(testCase => testCase.filteredByPerson = '1')
        } else {
          this.dataSource.data.map(testCase => {
            if (selectedPersonId.indexOf(testCase.assignedTo) >= 0) {
              testCase.filteredByPerson = '1';
            } else {
              testCase.filteredByPerson = '0';
            }
          })
        }
        break;
    }

    this.applyAllFilters();
  }

  applyAllFilters() {
    // Defining how data should be filtered
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return data.filteredByStatus.includes(filter) && data.filteredByPerson.includes(filter);
    }

    // Applying all filters
    this.dataSource.filter = '1';
  }
  selectAllUsers() {
    this.selectedPerson = this.users.map(user => { return user });
    this.applyFilter('byPerson');
    this.selectedPersonName = ['All'];
  }

  clearAllUsers() {
    this.selectedPerson = [];
    this.selectedPersonName = [];
    this.applyFilter('byPerson')
  }

  selectAllSatuses() {
    this.selectedStatuses = this.statuses.map(status => { return status });
    this.applyFilter('byStatus')
  }

  clearAllSatuses() {
    this.selectedStatuses = [];
    this.applyFilter('byStatus')
  }

  mapTestCaseSteps(steps) {
    // if testStep doesn't have any status, we set it to neutral
    steps.map(step => {
      step.showUndo = false;
      if (step.status == null) {
        step.status = this.defaultStatuses.defaultNeutralStatus.id;
      }
    })
    // mapping status_states to TestSteps
    steps.map(step => {
      this.statuses.map(status => {
        if (status.id === step.status) {
          step.statusObject = status;
          switch (step.statusObject.statusState) {
            case '-1':
              step.statusText = 'Test step failed';
              break;
            case '1':
              step.statusText = 'Test step passed';
              break;
          }
        }
      })
    })
    return steps;
  }

  onStatusChange($event, testCase) {
    const prevStatus = testCase.status;
    this.relationId = testCase.relationId;

    let testCasefailed = 'false';

    this.statuses.map(status => {
      if (status.id === $event && status.statusState === '-1') {
        testCasefailed = 'true';
      }
    })

    // Updating the status in view model
    testCase.status = $event;

    if (testCasefailed === 'true') {
      // In case test case failed the report a bug dialog is being opened
      this.openOnTestCaseFailDialog(testCase, $event, prevStatus)
    } else {
      // If any other status was set we update it on backend
      this.testRunService.updateTestRunTestCaseStatus({
        relation_id: this.relationId,
        status: testCase.status,
        project_id: this.currentProjectId,
      }).subscribe(res => res)
    }

    // Updating status counter on status change
    this.shouldUpdateCounters.emit(true);

  }

  onUserChange(testCase: TestCase, newUserId: string) {
    this.testRunService.asssignNewUserToTestCase({ relation_id: testCase.relationId, new_user_id: newUserId })
      .subscribe((result) => {
        if (result)
          this.snackBar.open(`Test Case '${testCase.title}' assingee was updated`, 'OK', { duration: 3000 });
        else
          this.snackBar.open('Error happened while updating the assingee', 'OK', { duration: 3000 });
      })
  }

  openOnTestCaseFailDialog(testCase: TestCase, newStatus: string, prevStatus: string) {
    let steps: TestStep[] = [];
    this.relationId = testCase.relationId;

    // fetching test steps of current test case
    this.testStepService.getTestStepsByRelationId({ relation_id: this.relationId }).subscribe(testSteps => {
      if (testSteps.length === 0) {
        this.snackBar.open('You cannot fail Test Case with no Test Steps', 'OK', { duration: 3000 });
        testCase.status = prevStatus;
        return;
      }
      // mapping test steps
      steps = this.mapTestCaseSteps(testSteps);
      // openning on fail dialog
      const dialog = this.dialog.open(DialogTestCaseFailComponent, {
        width: '650px',
        data: {
          testSteps: steps
        }
      }).afterClosed().subscribe(dataFromDialog => {
        if (dataFromDialog !== null) {
          testCase.status = newStatus;
          // If SUBMIT was pressed we update testCase status on backend
          this.failTestCase(dataFromDialog);
        } else {
          // If CANCEL was pressed we change status to previous
          testCase.status = prevStatus;
          // Statuses quantity to be rendered in header
          this.shouldUpdateCounters.emit(true);
        }
      })
    })
  }

  failTestCase(dataFromDialog): void {
    this.testRunService.updateTestRunTestCaseStatus({
      relation_id: this.relationId,
      status: this.defaultStatuses.defaultNegativeStatus.id,
      failed_on_step: dataFromDialog.failedOnTestStep,
      project_id: this.currentProjectId,
      create_defect: dataFromDialog.createDefect,
      comment: dataFromDialog.comment
    }).subscribe((result) => {
      if (result.valid) {
        const snakBar = this.snackBar.open('Test Case status was successfully updated', 'OK', { duration: 3000 });
        snakBar.afterDismissed().subscribe((closed) => {
          this.failTestStep(dataFromDialog);
        })
      }
      else
        this.snackBar.open('Error happened while updating the status', 'OK', { duration: 3000 });
    });
  }

  failTestStep(dataFromDialog): void {
    this.testStepService.updateTestStepStatus({
      relation_id: this.relationId,
      step_id: dataFromDialog.failedOnTestStep,
      status: this.defaultStatuses.defaultNegativeStatus.id
    }).subscribe((result) => {
      if (result) {
        this.failedStep.emit(dataFromDialog.failedOnTestStep);
        this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
      }
      else
        this.snackBar.open('Error happened while updating the status of Test Step', 'OK', { duration: 3000 });
    })
  }
}
