import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TestCase } from 'src/app/services/test-case.type';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestRun } from 'src/app/services/test-run.type';
import { TestStep } from 'src/app/services/test-step.type';
import { TestSuit } from 'src/app/services/test-suit.type';
import { appTypes } from '../../services/app-types';
import { Status } from '../../services/status.type';
import { TestCaseService } from 'src/app/services/test-case.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main-list',
  templateUrl: './main-list.component.html',
  styleUrls: ['./main-list.component.scss']
})
export class MainListComponent implements OnInit {

  public appTypes = appTypes;
  public _data:any;
  private _type:string;

  @Input() getStatus: boolean;
  @Input() set data(data:any) {
    if(data.length) {
      this._data = data;
      this.currentProject = data[0].projectId;
      this.getData();
    }
  } get data() {
    return this._data
  }
  @Input() set currentType(type: string) {
    this._type = type
  } get currentType() {
    return this._type;
  };
  @Input() canClose: string;
  @Input() actions: string
  @Input() createTestCase: boolean

  @Output() itemClicked = new EventEmitter();
  @Output() createNewItemEvent = new EventEmitter();
  @Output() createTestRunEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() selectEvent = new EventEmitter();
  @Output() reRunEvent = new EventEmitter();
  @Output() changeStatusEvent = new EventEmitter();
  @Output() assignedToEvent = new EventEmitter();

  statuses: Status[] = [];
  public newItem: TestRun | TestPlan | TestSuit | TestCase | TestStep;
  public newItemTitle = '';
  private currentProject;

  constructor(
    private testCaseService: TestCaseService,
  ) { }

  async ngOnInit() {
  }

  public get ifTest() {
    return this.currentType === this.appTypes.testRun || this.currentType === this.appTypes.testSuit || this.currentType === this.appTypes.testPlan
  }

  public get ifCreateTestCase() {
    return this.currentType === this.appTypes.testCase && this.createTestCase
  }

  getData() {
    if(this.getStatus) {
      this.getProjectStatuses().subscribe( res => {
        this.statuses = res
        for (const testRun of this.data) {
          if (testRun.statuses) {
            testRun.statuses.map(async (status: Status) => {
              this.statuses.map((statusItem: Status) => {
                if (statusItem.name === status.name) {
                  status.color = statusItem.color;
                }
              })
            })
          }
        }
      })
    }
  }

  onItemClick(item: TestRun | TestPlan | TestSuit | TestCase | TestStep ) {
    this.itemClicked.emit(item);
    this.setActiveItem(item);
  }

  onCreateTestCase(event:MouseEvent, item) {
    event.stopPropagation();
    this.createTestRunEvent.emit(item);
  }

  setActiveItem(item) {
    for (const dataItem of this.data) {
      dataItem.isOpen = false;
    }
    item.isOpen = true;
  }

  getProjectStatuses():Observable<Status[]> {
    return this.testCaseService.getTestCaseStatuses({project_id : this.currentProject})
  }

}
