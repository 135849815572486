import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export interface DialogTestCasesSelectDataToMerge {
  msg: string
}

@Component({
  selector: 'app-dialog-test-cases-select-to-merge',
  templateUrl: 'dialog-test-cases-select-to-merge.component.html',
  styleUrls: ['./dialog-test-cases-select-to-merge.component.scss'],
})
export class DialogTestCasesSelectToMergeComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogTestCasesSelectToMergeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogTestCasesSelectDataToMerge,
  ) { }

  ngOnInit() {
  }

}
