<div class="dialog-wrap">

    <div class="dialog-title">
        <h1 mat-dialog-title>
            Connections this Test Cases
        </h1>
    </div>

    <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '30px', background: '#D6DBDF', margin: '10px 0px 0px 0px' }" count="3" appearance="line"></ngx-skeleton-loader>
    <div class="dialog-content" mat-dialog-content>

        <mat-list class="mat-list-content">
            <mat-list-item class="row" *ngFor="let item of links">
                <mat-icon matListItemIcon>folder</mat-icon>
                <div matListItemTitle (click)="navigateTo(item.link)">{{item.title}}</div>
            </mat-list-item>
        </mat-list>

    </div>

    <div class="dialog-actions" mat-dialog-actions>
        <button mat-stroked-button [mat-dialog-close]="null" cdkFocusInitial>CANCEL</button>
    </div>

</div>
