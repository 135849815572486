import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TestRun, TestRunDetails, TestRunForTree } from 'src/app/services/test-run.type';
import { Chart, ChartConfiguration, ChartType } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import { ProjectService } from 'src/app/services/project.service';
import { DefaultStatuses } from 'src/app/services/default-statuses.type';
import { Status } from 'src/app/services/status.type';
import { User } from 'src/app/services/user.type';
import { TestCase } from 'src/app/services/test-case.type';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { TestRunService } from 'src/app/services/test-run.service';
import { TestCaseService } from 'src/app/services/test-case.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TestStepService } from 'src/app/services/test-step.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogTestCaseFailComponent } from 'src/app/dialogs/dialog-test-case-fail/dialog-test-case-fail.component';
import { DialogEditComponent } from 'src/app/dialogs/dialog-edit/dialog-edit.component';
import { DialogConfirmComponent } from 'src/app/dialogs/dialog-confirm/dialog-confirm.component';
import { TestSuit, TestSuitFolders } from 'src/app/services/test-suit.type';
import { map } from 'rxjs/operators';
import { DialogTestCasesSelectComponent } from 'src/app/dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { Observable, forkJoin, of } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { Params } from '@angular/router';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestSuitService } from 'src/app/services/test-suit.service';
import { TestPlanService } from 'src/app/services/test-plan.service';
import { Core } from 'src/app/services/core.service';
import { DialogConfirmForTestrunComponent } from 'src/app/dialogs/dialog-confirm-for-testrun/dialog-confirm-for-testrun.component';
import { UserAccessService } from 'src/app/services/user-access';


@Component({
  selector: 'app-card-test-run-details',
  templateUrl: './card-test-run-details.component.html',
  styleUrls: ['./card-test-run-details.component.scss']
})
export class CardTestRunDetailsComponent implements OnInit, OnDestroy {
  public guestAccessAction: boolean = this._userAccessService.getLevelAccessUser === 4;
  public assignedAccessAction: boolean = this._userAccessService.getAccess('testCase', 'testCaseAssignToLevel');
  public changeStatusAccessAction: boolean = this._userAccessService.getAccess('testCase', 'testCaseChangeStatusLevel');
  public addLevelAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunCreateLevel');
  public editLevelAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunEditLevel');
  public deleteLevelAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunDeleteLevel');
  public addCaseAccessAction: boolean = this._userAccessService.getAccess('testRun', 'testRunAddCaseLevel');

  public pieChartType: ChartType = 'pie';
  public pieChartData: ChartConfiguration['data'];
  public pieChartOptions: ChartConfiguration['options'];
  public data: TestRunDetails = { data: [], labels: [], colors: [], info: [], passed: { count: 0, per: 0 }, unTest: { count: 0, per: 0 }, all: 0 };
  public dataForTree: TestRunForTree[] = [];
  public statuses: Status[];
  public allUsers: User[];
  public users: User[];
  public selectOpen: boolean = false;

  public dataSource: MatTableDataSource<TestCase>;
  private paginator: MatPaginator;
  private sort: MatSort;
  public displayedColumns: string[] = ['select', 'id', 'title', 'status', 'assigned_to'];
  public defaultStatuses = new DefaultStatuses();
  public selection = new SelectionModel<TestCase>(true, []);
  public activeExistTestCaseSuits: TestCase[];
  public activeExistTestCasePlans: TestCase[];

  public pending: boolean = false;
  public currentUser: User;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if (ms) this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (mp) this.paginator = mp;
  }

  @Input() testRun: TestRun = null;
  @Input() showStep: boolean = true;
  @Input() shortForm: boolean = false;
  @Input() shortFormRun: boolean = false;
  @Output() closeEvent = new EventEmitter();
  @Output() updateTestRuns = new EventEmitter<boolean>();
  @Output() changeParams = new EventEmitter<Params>();
  @Output() openCaseComponent = new EventEmitter<TestCase>();

  constructor(
    private projectService: ProjectService,
    private testCaseService: TestCaseService,
    private usersService: UserService,
    private testRunService: TestRunService,
    private testStepService: TestStepService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private testSuitService: TestSuitService,
    private testPlanService: TestPlanService,
    private _userAccessService: UserAccessService
  ) { }

  ngOnInit(): void {
    Chart.register(Annotation);
    this.initData();
    this.getUsers();
    this.testCaseService.updateRightSectionOpen(true)
    this.currentUser = this.usersService.mapUser(JSON.parse(Core.localStorageService.getItem('user'))['user']);
    if (+this.currentUser.roleId >= 3) this.displayedColumns = ['id', 'title', 'status', 'assigned_to'];
  }

  ngOnDestroy(): void {
    this.testCaseService.updateRightSectionOpen(false)
  }

  initData(): void {
    let data: { data: number[], labels: string[], colors: string[], info: { per: number, name: string }[], passed: { count: number, per: number }, unTest: { count: number, per: number }, all: number } = { data: [], labels: [], colors: [], info: [], passed: { count: 0, per: 0 }, unTest: { count: 0, per: 0 }, all: 0 };
    let passedArr = [];
    let unTest = [];

    this.testCaseService.getTestCasesByTestRun({ tr_id: this.testRun.id }).subscribe(res => {
      if (res) {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })

    this.projectService.getProjectStatuses(this.testRun.projectId).subscribe(res => {
      if (res) {
        this.statuses = res;
        data.all = this.testRun.statuses.reduce((a, e: { name: string, count: string }) => a += +e.count, 0);
        this.testRun.statuses.forEach((elem: { name: string, count: string }) => {
          let find = res.find(status => status.name === elem.name);
          if (find) data.colors.push(find.color);
          else data.colors.push('#ffffff')
          data.labels.push(elem.name);
          data.data.push(+elem.count);
          data.info.push({ name: elem.name, per: +(100 / data.all * +elem.count).toFixed(2) });

          res.forEach(status => {
            if (status.name === elem.name && status.statusState === '1') passedArr.push(elem.count);
            if (status.name === elem.name && status.statusState === '0') unTest.push(elem.count);
          });

          data.passed.count = passedArr.reduce((a, e) => a += +e, 0);
          data.passed.per = +(100 / data.all * data.passed.count).toFixed(2);
          data.unTest.count = unTest.reduce((a, e) => a += +e, 0);
          data.unTest.per = +(100 / data.all * data.unTest.count).toFixed(2);
        });

        this.data = data;
        if (data.data.length < 1) {
          this.data = { data: [0], labels: ['no data'], colors: ['rgba(255, 128, 0, 0.5)'] }
        };

        this.createChart();
      }
    })
  }

  async getUsers() {
    this.allUsers = await this.usersService.getUsers().toPromise();
    this.usersService.getUserByProject(this.testRun.projectId).subscribe(res => {
      if (res && this.allUsers) {
        let users = [];
        res.forEach(user => {
          let find = this.allUsers.find(elem => +elem.id === +user.id);
          if (find) users.push(find);
        })
        this.users = users;
      }
    })
  }

  createChart(): void {
    this.pieChartData = {
      datasets: [
        {
          data: this.data.data,
          backgroundColor: this.data.colors,
          borderColor: this.data.colors,
          fill: 'origin',
        }
      ],
      labels: this.data.labels
    };

    this.pieChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
      }
    };
  }

  copyLink() {
    if (window.location.href) {
      this.snackBar.open('Link was successfully copied', "OK", { duration: 3000 });
      this.clipboard.copy(window.location.href);
    }
  }


  //table
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => { this.selection.select(row) });
  }

  onUserChange(testCase: TestCase, newUserId: string) {
    this.testRunService.asssignNewUserToTestCase({ relation_id: testCase.relationId, new_user_id: newUserId })
      .subscribe((result) => {
        if (result)
          this.snackBar.open(`Test Case '${testCase.title}' assingee was updated`, 'OK', { duration: 3000 });
        else
          this.snackBar.open('Error happened while updating the assingee', 'OK', { duration: 3000 });
      })
  }

  onStatusChange($event, testCase) {
    const status = this.statuses.find(val => +val.id === +$event && +val.statusState === -1)
    if (status) {
      this.openOnTestCaseFailDialog(testCase);
      return
    }
    this.testRunService.updateTestRunTestCaseStatus({
      relation_id: testCase.relationId,
      status: $event,
      project_id: testCase.projectId,
      tr_id: this.testRun.id,
    }).subscribe(res => {
      if (res) {
        this.updateTestRuns.emit(true);
        this.snackBar.open('Test Case status was successfully updated', 'OK', { duration: 3000 });
      }
    })
  }

  openOnTestCaseFailDialog(testCase: TestCase) {
    this.testStepService.getTestStepsByRelationId({ relation_id: testCase.relationId }).subscribe(res => {
      if (res.length === 0) {
        this.snackBar.open('You cannot fail Test Case with no Test Steps', 'OK', { duration: 3000 });
        return
      }
      const steps = this.mapTestCaseSteps(res);
      this.dialog.open(DialogTestCaseFailComponent, {
        width: '650px',
        data: {
          testSteps: steps
        }
      }).afterClosed().subscribe(dataFromDialog => {
        if (dataFromDialog !== null) this.failTestCase(dataFromDialog, testCase.relationId, testCase.projectId);
      })
    })
  }

  failTestCase(dataFromDialog, relationId, currentProjectId): void {
    this.testRunService.updateTestRunTestCaseStatus({
      relation_id: relationId,
      status: this.statuses.find(val => +val.statusState === -1 && +val.isDefault === 1).id,
      failed_on_step: dataFromDialog.failedOnTestStep,
      project_id: currentProjectId,
      create_defect: dataFromDialog.createDefect,
      comment: dataFromDialog.comment
    }).subscribe((result) => {
      if (result.valid === true) {
        const snakBar = this.snackBar.open('Test Case status was successfully updated', 'OK', { duration: 3000 });
        this.updateTestRuns.emit(true);
        snakBar.afterDismissed().subscribe((closed) => {
          this.failTestStep(dataFromDialog, relationId);
        })
      } else this.snackBar.open(result.error_msg, 'OK', { duration: 3000 });
    });
  }

  failTestStep(dataFromDialog, relationId): void {
    this.testStepService.updateTestStepStatus({
      relation_id: relationId,
      step_id: dataFromDialog.failedOnTestStep,
      status: this.statuses.find(status => status.statusState === '-1' && status.isDefault === '1').id,
      comment: dataFromDialog.comment
    }).subscribe((result) => {
      if (result) this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
      else this.snackBar.open('Error happened while updating the status of Test Step', 'OK', { duration: 3000 });
    })
  }

  mapTestCaseSteps(steps) {
    steps.map(step => {
      step.showUndo = false;
      if (step.status == null) {
        step.status = this.statuses.find(val => +val.statusState === 0 && +val.isDefault === 1).id;
      }
    })

    steps.map(step => {
      this.statuses.map(status => {
        if (status.id === step.status) {
          step.statusObject = status;
          switch (step.statusObject.statusState) {
            case '-1': step.statusText = 'Test step failed'; break;
            case '1': step.statusText = 'Test step passed'; break;
          }
        }
      })
    })
    return steps;
  }

  deleteTestCasesEvent() {
    let testCasesTitle = [];
    this.selection.selected.forEach(elem => { testCasesTitle.push(elem.title) });

    this.dialog.open(DialogConfirmComponent, {
      width: '650px',
      data: { dialogTitle: 'delete', name: testCasesTitle.join(', ') }
    }).afterClosed().subscribe((res: boolean) => {
      if (res && this.testRun) {
        this.testCaseService.getTestCasesByTestRun({ tr_id: this.testRun.id }).subscribe(result => {
          if (result) {
            this.selection.selected.forEach(elem => {
              result = result.filter(item => +item.relationId !== +elem.relationId)
            })
            this.testRunService.updateTestCasesList({
              test_run_id: this.testRun.id,
              project_id: this.testRun.projectId,
              test_cases: [],
              deleted_test_cases: this.selection.selected.map(c => ({ tc_id: c.testCaseId, ts_id: c.tsId }))
            }).subscribe(res => {
              if (res) {
                this.snackBar.open('Test Case was successfully deleted', "OK", { duration: 3000 });
                this.updateTestRuns.emit(true);
                this.dataSource._updateChangeSubscription();
                this.dataSource.data.forEach(elem => { this.selection.deselect(elem) });
              }
            })
          }
        })
      }
    })
  }

  getAllSuits(): Promise<TestSuit[]> {
    return new Promise<TestSuit[]>((resolve) => {
      this.testSuitService.getTestSuits({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe(cases => {
        resolve(cases);
      });
    });
  }

  getAllPlans(): Promise<TestPlan[]> {
    return new Promise<TestPlan[]>((resolve) => {
      this.testPlanService.getTestPlans({ project_id: JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null' }).subscribe(cases => {
        resolve(cases);
      });
    });
  }

  async addTestCaseEvent() {
    this.pending = true;
    let params;
    let testSuits = [];

    const projectId = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
    if (projectId !== 'all' && projectId !== 'null') params = { project_id: projectId };
    const testSuitsArr = await this.testSuitService.foldersList(params).toPromise();
    testSuitsArr?.forEach(elem => testSuits.push([elem]));

    const plans = await this.getAllPlans();
    const testPlans = await this.setCaseToPlans(plans);
    let testCaseRuns = await this.getTestRunCases(this.testRun.id).toPromise();

    this.activeExistTestCaseSuits = testCaseRuns.filter(elem => !elem.tpId);
    this.activeExistTestCasePlans = testCaseRuns.filter(elem => elem.tpId);

    this.pending = false;
    const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
      width: '850px',
      data: { testSuits: testSuits, testPlans: testPlans, selectedSuits: this.activeExistTestCaseSuits, selectedPlans: this.activeExistTestCasePlans, title: 'SelectFromRun' },
      panelClass: 'custom-modalbox'
    });
    let sub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._addToRunAction(result)
        sub?.unsubscribe();
      }
    })
  }

  private _addToRunAction(result) {
    let plans = [...result.selectedPlans].filter(elem => elem.level === 0).map(elem => elem.id);

    forkJoin([
      this.testRunService.updateTestCasesList({
        test_run_id: this.testRun.id,
        project_id: this.testRun.projectId,
        test_cases: result.selectedSuits.filter(c => c.isCase).map(c => ({ tc_id: c.elem.id, ts_id: c.elem.tsId, tp_id: c.elem.tpId })),
        deleted_test_cases: []
      }),
      plans.length ? this.testPlanService.plansToRun({ tr_id: this.testRun.id, tp_ids: plans }) : of({ valid: 1, msg: 'true' })
    ]).subscribe(res => {
      if (res[0] === true && res[1].valid === 1) {
        if (res[1].msg === 'true') this._successHundler();
        else this._errorHundler();
      }
    })
  }

  private _successHundler() {
    this.updateTestRuns.emit(true)
    this.activeExistTestCaseSuits = null;
    this.activeExistTestCasePlans = null;
    this.snackBar.open('Test Cases was successfully selected', "OK", { duration: 3000 });
  }

  private _errorHundler() {
    this.updateTestRuns.emit(true)
    this.snackBar.open('Something went wrong. Try later', "OK", { duration: 3000 });
  }

  setCaseToPlans(testPlans): Promise<TestSuitFolders[]> {
    return Promise.all(testPlans.map((s => {
      return new Promise<any>((resolve) => {
        this.testPlanService.foldersPlansList({ tp_id: s.id }).subscribe((folder: TestSuitFolders) => {
          resolve({ ...s, sub_suits: folder, });
        });
      });
    })))
  }

  getTestRunCases(testRunId: string): Observable<TestCase[]> {
    return this.testCaseService.getTestCasesByTestRun({ tr_id: testRunId }).pipe(
      map((testCases: TestCase[]) => {
        testCases.map(val => { val.filteredByPerson = '1'; val.filteredByStatus = '1' })
        return testCases;
      })
    )
  }

  reRunEvent() {
    this.testRunService.reRun({ tr_id: this.testRun.id }).subscribe(res => {
      if (res) {
        this.snackBar.open('Test Run was successfully reruned', "OK", { duration: 3000 })
        this.updateTestRuns.emit(true)
      }
    });
  }

  editEvent() {
    const dialogRef = this.dialog.open(DialogEditComponent, {
      width: '650px',
      data: { title: this.testRun.title, description: this.testRun.description }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.testRunService.saveTestRun(Object.assign(this.testRun, res)).subscribe(res => {
          if (res) {
            this.snackBar.open('Test Run was successfully edited', "OK", { duration: 3000 })
            this.updateTestRuns.emit(true)
          }
        })
      }
    })
  }

  deleteEvent() {
    this.dialog.open(DialogConfirmForTestrunComponent, {
      width: '650px',
      data: { name: this.testRun.title }
    }).afterClosed().subscribe(res => {
      if (res && res.action === 1 && this.testRun) this._deleteTestRun(this.testRun);
      else if (res && res.action === 0 && this.testRun) {
        this.testRunService.archive({ test_run_id: this.testRun.id, archive: 'true' }).subscribe(res => {
          if (res) {
            this.snackBar.open('Test Run was successfully archivated', "OK", { duration: 3000 });
            this.updateTestRuns.emit(true);
            this.closeEvent.emit();
          }
        });
      }
    });
  }

  private _deleteTestRun(item: TestRun) {
    this.dialog.open(DialogConfirmComponent, {
      width: '650px',
      data: { dialogTitle: 'delete', name: item.title }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.testRunService.deleteTestRun({ id: item.id }).subscribe(res => {
          if (res) {
            this.snackBar.open('Test Run was successfully deleted', "OK", { duration: 3000 });
            this.updateTestRuns.emit(true);
            this.closeEvent.emit();
          }
        });
      }
    })
  }
}
