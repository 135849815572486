<app-sidenav>

  <mat-card id="panel" class="sidenav-opened">
    <div class="sidenav-opened__header">
      <button mat-icon-button class="base-button" (click)="goToTestSuits()">
        <mat-icon style="color: #000;">arrow_back_ios</mat-icon>
      </button>
      <mat-card-title class="details">
        <span class="details__title" *ngIf="testSuit && testSuit.title">{{testSuit.title}}</span>
        <span><mat-spinner *ngIf="!testSuit || !testSuit.title" [diameter]="20"></mat-spinner></span>
        <span class="details__id" *ngIf="testSuit && testSuit.id">
          TEST SUIT ID - {{testSuit.id}} &nbsp;|&nbsp;PROJECT ID - {{testSuit.projectId}}
        </span>
        <span><mat-spinner *ngIf="!testSuit || !testSuit.id" [diameter]="15"></mat-spinner></span>
      </mat-card-title>
      <mat-card-content class="panel-right-side">
        <div [ngStyle]="{'display': (componentShowHide === 'hide' || componentShowHide === 'start') ? 'none' : 'block'}" class="resize-toggle-group-wrap">
          <mat-button-toggle-group class="resize-toggle-group">
            <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'large'}" (click)="onResize('large')"><mat-icon svgIcon="cell-divide-in-left-area"></mat-icon></mat-button-toggle>
            <mat-button-toggle class="resize-toggle" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'medium'}" (click)="onResize('medium')"><mat-icon svgIcon="cell-divide-in-center-area"></mat-icon></mat-button-toggle>
            <mat-button-toggle class="resize-toggle mat-button-toggle-checked" [ngClass] = "{'mat-button-toggle-checked': componentShowHide === 'small'}" (click)="onResize('small')"><mat-icon svgIcon="cell-divide-in-right-area"></mat-icon></mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <button mat-icon-button class="base-button" (click)="openRunDialog()" *ngIf="!editMode">
          <mat-icon>directions_run</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="base-button" *ngIf="!editMode">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button [disabled]="!editTestSuitForm.valid" id="clear-style" mat-icon-button *ngIf="editMode" (click)="saveTestSuit()" class="save-icon">
          <mat-icon>save</mat-icon>
        </button>
        <mat-icon *ngIf="editMode" (click)="editMode = false">close</mat-icon>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openSelectCasesDialog()">Select Test Cases</button>
          <button mat-menu-item (click)="editMode = true">Edit</button>
          <button mat-menu-item (click)="deleteSuit()">Delete</button>
        </mat-menu>
      </mat-card-content>
    </div>
    <form [formGroup]="editTestSuitForm" class="edit-mode-dropdown" *ngIf="editMode">
      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="title" [readonly]="!editMode">
        <mat-error *ngIf="f.title.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" formControlName="description" [readonly]="!editMode">
        <mat-error *ngIf="f.description.hasError('required')">
          Description is required
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card>

  <div class="main-container">
  <div class="table-main-container">

    <mat-card class="table-card" style="margin-top: 0 !important;">
      <app-abstract-table #abstractTable
                          [data]="data"
                          [columns]="displayedColumns"
                          (abstractRowEvent)="openTestCaseDetail($event)">
      </app-abstract-table>
    </mat-card>
  </div>

  <div class="test-case-details-sidebar"  [@changeSideComponentSize]="componentShowHide" (@changeSideComponentSize.done)="doneAnimation()">
    <app-test-case-details-sidebar [testCase]="testCase"
                                   [testSteps]="testSteps"
                                   (Resize)="onResize($event)"
    ></app-test-case-details-sidebar>
  </div>
  </div>

</app-sidenav>
