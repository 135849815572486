import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestCase } from '../services/test-case.type';
import { TestStep } from '../services/test-step.type';
import { TestStepService } from '../services/test-step.service';
import { TestRunService } from '../services/test-run.service';
import { Status } from '../services/status.type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogTestCaseFailComponent } from '../dialogs/dialog-test-case-fail/dialog-test-case-fail.component';
import { Router } from '@angular/router';
import { DialogViewAttachmentsComponent } from '../dialogs/dialog-view-attachments/dialog-view-attachments.component';
import { UserService } from '../services/user.service';
import { ApiBaseService } from '../services/api-base.service';
import { Attachment } from '../services/attachment.type';
import { DefaultStatuses } from '../services/default-statuses.type';

@Component({
  selector: 'app-test-run-details-sidebar',
  templateUrl: './test-run-details-sidebar.component.html',
  styleUrls: ['./test-run-details-sidebar.component.scss']
})
export class TestRunDetailsSidebarComponent implements OnInit {
  fromStep = true;
  testSteps: TestStep[] = [];
  @Input() defaultStatuses: DefaultStatuses;
  @Input() testCase: TestCase;
  @Input() public set steps(steps: TestStep[]) {
    this.testSteps = steps;
    if (this.testSteps.length >= 1 && (this.relationId && this.relationId.length > 0)) {
      this.getAttachments();
    }
  }
  @Input() currentProjectId: string;
  @Input() relationId: string;
  @Input() goBackURLId: string;
  @Input() defectsPage: boolean;
  @Input() statuses: Status[];
  @Input() public set failedTestSteps(failedTestSteps: TestStep[]) {
    this.testSteps = failedTestSteps;
    if (this.testSteps.length >= 1 && (this.testCase && this.testCase.relationId.length > 0)) {
      this.defectModeInit();
      this.getAttachments();
    }
  }
  @Output() Resize = new EventEmitter<string>();
  testCaseStatus;
  comment;
  createDefect;
  failedOnStep: TestStep = new TestStep();

  constructor(
    private router: Router,
    private testStepService: TestStepService,
    private testRunService: TestRunService,
    public dialog: MatDialog,
    private userService: UserService,
    public api: ApiBaseService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.testCaseStatus = this.testCase.status;
  }

  getAttachments(): void {
    this.testSteps.map(ts => {
      this.userService.testStepAttachments(
        { test_run_history_id: this.relationId || this.testCase.relationId, step_id: ts.id }
      ).subscribe(r => {
        ts.attachments = r;
        ts.attachments.map((attachment: Attachment) => {
          if (attachment.fileType.includes('text')) {
            this.previewTextFile(attachment);
          }
        })
      });
    });
  }

  previewTextFile(attachment: Attachment): void {
    fetch(this.api.getApi() + attachment.relativePath)
      .then(response => response.text())
      .then(data => {
        attachment.fileData = data;
      });
  }

  openDialogViewAttachment(testStep, $event): void {
    $event.stopPropagation();
    this.dialog.open(DialogViewAttachmentsComponent, {
      width: '650px', data: { testStep }
    });
  }

  closeTestCaseDetail(): void {
    this.Resize.emit('hide');
  }

  setStatusFailed($event, testStep) {
    // Prevent extension panel from expanding
    $event.stopPropagation();

    // Saving previous status
    testStep.previousStatus = testStep.statusObject;

    // Open set status failed dialog
    this.openFailedStepDialog(testStep, this.defaultStatuses.defaultNegativeStatus);
  }

  setStatusPassed($event, testStep) {
    // Prevent extension panel from expanding
    $event.stopPropagation();

    // Saving previous status
    testStep.previousStatus = testStep.statusObject

    // Setting new status
    testStep.status = 350;
    testStep.statusObject = this.defaultStatuses.defaultPositiveStatus;
    testStep.statusText = 'Test step passed';

    // Setting 5 seconds countdown to destroy UNDO after 5 seconds passed
    this.setUndoCounter(testStep);

    // updating the status on server
    this.updateTestStepstatus(testStep, testStep.status);
  }

  undoSetStatus($event, testStep) {
    // Prevent extension panel from expanding
    $event.stopPropagation();

    // Setting Undopressed to true
    testStep.undoPressed = true;

    // Saving previous status
    const previousState = testStep.statusObject

    // Resetting status to previous state
    testStep.statusObject = testStep.previousStatus;
    testStep.status = testStep.statusObject.id;

    // Updating previous status for future use
    testStep.previousStatus = previousState;

    if (testStep.statusObject.statusState !== '-1') {
      // Updating the status on server
      this.updateTestStepstatus(testStep, testStep.status);

    }
  }

  onStatusSelectionChange($event, testStep) {
    // Saving previous status in testStep
    const prevStatus = testStep.status;
    this.statuses.map(status => {
      if (status.id === prevStatus) {
        testStep.previousStatus = status;
      }
    })

    // Setting new status into ngModel
    testStep.status = $event;

    // Filtering statuses to find current one by it's id
    const currentStatus = this.statuses.filter(status => {
      if (status.id === testStep.status) {
        return true
      } else {
        return false;
      }
    })

    if (currentStatus[0].statusState === '-1') {
      // If new status is Fail we open on Test Case Fail Dialog to report defect
      this.openFailedStepDialog(testStep, currentStatus[0])
    } else {
      // Setting new status
      testStep.statusObject = currentStatus[0];
      testStep.status = currentStatus[0].id;

      if (testStep.statusObject.statusState === '1') {
        testStep.statusText = 'Test step passed';
        // Starting Undo counter for passed status
        this.setUndoCounter(testStep);
        // updating the status on server
        this.updateTestStepstatus(testStep, testStep.status);
      } else if (testStep.statusObject.statusState === '0') {
        this.updateTestStepstatus(testStep, testStep.status);
      }
    }
  }

  openFailedStepDialog(testStep, currentSatus) {
    const dialogRef = this.dialog.open(DialogTestCaseFailComponent, {
      width: '650px',
      data: {
        testSteps: this.testSteps,
        failedOnTestStep: testStep,
      }
    })

    dialogRef.afterClosed().subscribe(dataFromDialog => {
      if (dataFromDialog === null) {
        // if CANCEL was pressed we revert testStep status to previous status
        testStep.statusObject = testStep.previousStatus;
        testStep.status = testStep.statusObject.id;
      } else {
        // if SUBMIT was pressed we set status to Failed
        testStep.statusObject = currentSatus;
        testStep.status = currentSatus.id;
        testStep.statusText = 'Test step failed';
        this.setUndoCounter(testStep);

        this.comment = dataFromDialog.comment;
        this.createDefect = dataFromDialog.createDefect;
      }
    })
  }

  updateTestStepstatus(testStep: TestStep, updatedStatusId: string) {
    this.testStepService.updateTestStepStatus({
      relation_id: this.relationId,
      step_id: testStep.id,
      status: updatedStatusId
    }).subscribe(result => {
      if (result)
        this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
      else
        this.snackBar.open('Error happened while updating the status of Test Step', 'OK', { duration: 3000 });
    })
  }

  updateTestCaseStatus(testStep: TestStep, comment: string, createDefect: string, relationId: string) {
    this.testRunService.updateTestRunTestCaseStatus({
      relation_id: relationId,
      status: this.defaultStatuses.defaultNegativeStatus.id,
      failed_on_step: testStep.id,
      project_id: this.currentProjectId,
      comment,
      create_defect: createDefect
    }).subscribe(result => {
      if (result.valid)
        this.snackBar.open('Test Step status was successfully updated', 'OK', { duration: 3000 });
      else
        this.snackBar.open(result.error_msg, 'OK', { duration: 3000 });
    });
  }

  setUndoCounter(testStep) {
    testStep.counter = 5;
    testStep.showUndo = true;

    const timer = setInterval(() => {
      testStep.counter = testStep.counter - 1;
      if (testStep.counter <= 0 || testStep.statusObject.statusState === '0' || testStep.undoPressed === true) {

        clearInterval(timer);

        setTimeout(() => {
          testStep.showUndo = false;
          if (testStep.undoPressed !== true && testStep.status === this.defaultStatuses.defaultNegativeStatus.id) {
            // Updating negative test Step on Backend If undo button wasn't pressed
            this.updateTestStepstatus(testStep, testStep.status);

            // Updating negative Test Case on backend If undo button wasn't pressed
            this.testCase.status = this.defaultStatuses.defaultNegativeStatus.id;
            this.updateTestCaseStatus(testStep, this.comment, this.createDefect, this.relationId);
          }
          testStep.undoPressed = false;
        }, 1000);
        return;
      }
    }, 1000);
  }

  // Defects Details Mode Logic

  defectModeInit(): void {
    this.mapTestSteps();
    this.findLastFailedStep();
    this.setDefectDescription()
  }

  findLastFailedStep() {
    this.testSteps.map((testStep: TestStep) => {
      if (testStep.statusObject.statusState === '-1') {
        this.failedOnStep = testStep;
      }
    })
  }

  mapTestSteps() {
    // mapping status_states to TestSteps
    this.testSteps.map((step: TestStep) => {
      this.statuses.map((status: Status) => {
        if (status.id === step.status) {
          step.statusObject = status;
        }
      })
    })
  }

  setDefectDescription() {
    this.testCase.defectTitle = `${this.failedOnStep.description} - Failed`;
    let comment = '';
    const initialComment = this.testCase.comment;
    this.testSteps.forEach((testStep: TestStep, index: number) => {
      if (testStep.statusObject.statusState === '-1') {
        if (index === 0) {
          comment = `Test Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\nExpected Result: ${testStep.expectedResult}\nDefect details: ${initialComment}\n`;
        } else {
          comment = `${comment}\nTest Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\nExpected Result: ${testStep.expectedResult}\nDefect details: ${initialComment}\n`;
        }
      } else {
        if (index === 0) {
          comment = `Test Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\n`;
        } else {
          comment = `${comment}\nTest Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\n`;
        }
      }
    })

    this.testCase.comment = comment;
  }

  onCreateDefect() {
    this.updateTestCaseStatus(this.failedOnStep, this.testCase.comment, '1', this.testCase.relationId);
  }
}
