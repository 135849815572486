<app-sidenav>

  <mat-card id="panel">
    <span>Test Cases</span>
    <div [ngStyle]="{'display': (componentShowHide === 'hide' || componentShowHide === 'start') ? 'none' : 'block'}"
      class="resize-toggle-group-wrap">
      <mat-button-toggle-group class="resize-toggle-group">
        <mat-button-toggle class="resize-toggle"
          [ngClass]="{'mat-button-toggle-checked': componentShowHide === 'large'}" (click)="onResize('large')">
          <mat-icon svgIcon="cell-divide-in-left-area"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="resize-toggle"
          [ngClass]="{'mat-button-toggle-checked': componentShowHide === 'medium'}" (click)="onResize('medium')">
          <mat-icon svgIcon="cell-divide-in-center-area"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle class="resize-toggle mat-button-toggle-checked"
          [ngClass]="{'mat-button-toggle-checked': componentShowHide === 'small'}" (click)="onResize('small')">
          <mat-icon svgIcon="cell-divide-in-right-area"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-form-field appearance="outline" class="main-header-search">
      <label>
        <input matInput [(ngModel)]="filterValue" placeholder="Search">
      </label>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="openCreateDialog()">
      <mat-icon>add</mat-icon> New Test Case
    </button>
  </mat-card>

  <div class="main-container">
    <!-- [@changeTableSize]="showHide" -->
    <div class="table-main-container" (keydown.control.enter)="onCreateCase()">
      <div class="table-withcreation-mode-wrapper">
      <div *ngIf="creationMode" class="edit-mode-table-nav">
        <mat-form-field appearance="outline" floatLabel="auto" class="custom-input">
          <mat-label>Test suit</mat-label>
          <label>
            <input matInput (ngModelChange)="onFilterTestSuits($event)" [(ngModel)]="filter" (blur)="onBlurInput()" (focus)="this.selectList.open()">
          </label>
          <mat-icon [ngStyle]="{'transform': selectOpened == true ? 'rotate(180deg)' : 'rotate(0deg)'}">keyboard_arrow_down</mat-icon>
          <mat-select #selectList (openedChange) ="selectOpened = !selectOpened" [(ngModel)]="createTestCase.tsId" (selectionChange)="applyTestSuitsFilter()" disableOptionCentering>
            <mat-option *ngFor="let option of filteredTestSuits" [value]="option.id">{{ option.title }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="auto" >
          <mat-label>title</mat-label>
          <label>
            <input matInput [(ngModel)]="createTestCase.title" #inputFocus class="changeCaret">
          </label>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="auto">
          <mat-label>description</mat-label>
          <label>
            <input matInput [(ngModel)]="createTestCase.description">
          </label>
        </mat-form-field>
        <button mat-button (click)="onCreateCase()" class="create-case-button text-blue">create</button>
      </div>
      <mat-card class="table-card">
        
        <app-abstract-table #abstractTable
                            [filter]="filterValue" 
                            [data]="data"
                            [columns]="displayedColumns"
                            [actions]="['delete']"
                            [(selection)]="selection"
                            (abstractRowEvent)="openTestCaseDetail($event)"
                            (abstractActionDeleteEvent)="onDeleteCase($event)">
        </app-abstract-table>

        </mat-card>
      </div> <!-- End of table with creation mode wrapper-->


      <div *ngIf="creationMode && !selection.isEmpty()" class="selected-test-cases-popup">

        <div class="button-container">
          <button mat-flat-button (click)="visibleSelect = 'IntoSuit'" class="button-switch"
            [ngClass]="{'active': visibleSelect === 'IntoSuit'}"
            [ngStyle]="{'padding-left': componentShowHide !== 'hide' ? '0px': '1rem', 'padding-right': componentShowHide !== 'hide' ? '0px': '1rem'} ">Into
            Suit</button>
          <button mat-flat-button (click)="visibleSelect = 'IntoPlan'" class="button-switch"
            [ngClass]="{'active': visibleSelect === 'IntoPlan'}"
            [ngStyle]="{'padding-left': componentShowHide !== 'hide' ? '0px': '1rem', 'padding-right': componentShowHide !== 'hide' ? '0px': '1rem'} ">Into
            Plan</button>
          <button mat-flat-button (click)="visibleSelect = 'IntoRun'" class="button-switch"
            [ngClass]="{'active': visibleSelect === 'IntoRun'}"
            [ngStyle]="{'padding-left': componentShowHide !== 'hide' ? '0px': '1rem', 'padding-right': componentShowHide !== 'hide' ? '0px': '1rem'} ">Into
            Run</button>
        </div>

        <mat-form-field *ngIf="visibleSelect === 'IntoSuit'" appearance="outline" floatLabel="auto"
          class="form-container">
          <mat-label>Test Suit</mat-label>
          <label>
            <input matInput [(ngModel)]="selectedTestSuitTitles" (click)="IntoSuit.open()">
          </label>
          <mat-icon [ngStyle]="{'transform': IntoSuitSelectOpened == true ? 'rotate(180deg)' : 'rotate(0deg)'}"
            (click)="IntoSuit.open()">keyboard_arrow_down</mat-icon>
          <mat-select #IntoSuit (openedChange)="IntoSuitSelectOpened = !IntoSuitSelectOpened"
            [(ngModel)]="selectedTestSuit" class="test-suit-select" panelClass="dropdown-panel-class" multiple
            (selectionChange)="updateSelectedTestSuits()">
            <!--          <mat-select #IntoSuit [(ngModel)]="selectedTestSuit" class="test-suit-select" multiple disableOptionCentering (selectionChange)="updateSelectedTestSuits()">-->
            <mat-option *ngFor="let option of testSuits" [value]="option" class="test-suit-select-option">
              {{ option.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="visibleSelect === 'IntoPlan'" appearance="outline" floatLabel="auto"
          class="form-container">
          <mat-label>Test Plan</mat-label>
          <label>
            <input matInput [(ngModel)]="selectedTestPlanTitles" (click)="IntoPlan.open()">
          </label>
          <!--          <mat-select #IntoPlan multiple class="test-plan-select">-->
          <!--            <mat-option value="option">Option</mat-option>-->
          <!--          </mat-select>-->

          <mat-icon [ngStyle]="{'transform': IntoPlanSelectOpened == true ? 'rotate(180deg)' : 'rotate(0deg)'}"
            (click)="IntoPlan.open()">keyboard_arrow_down</mat-icon>
          <mat-select #IntoPlan (openedChange)="IntoPlanSelectOpened = !IntoPlanSelectOpened"
            [(ngModel)]="selectedTestPlans" class="test-suit-select" panelClass="dropdown-panel-class" multiple
            (selectionChange)="updateSelectedTestPlans()">
            <!--          <mat-select #IntoSuit [(ngModel)]="selectedTestSuit" class="test-suit-select" multiple disableOptionCentering (selectionChange)="updateSelectedTestSuits()">-->
            <mat-option *ngFor="let option of testPlans" [value]="option" class="test-suit-select-option">
              {{ option.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="visibleSelect === 'IntoRun'" appearance="outline" floatLabel="auto"
          class="form-container">
          <mat-label>Test Run</mat-label>
          <label>
            <input matInput [(ngModel)]="selectedTestRunTitles" (click)="IntoRun.open()">
          </label>
          <!--          <mat-select #IntoRun multiple class="test-run-select">-->
          <!--            <mat-option value="option">Option</mat-option>-->
          <!--          </mat-select>-->
          <mat-icon [ngStyle]="{'transform': IntoRunSelectOpened == true ? 'rotate(180deg)' : 'rotate(0deg)'}"
            (click)="IntoRun.open()">keyboard_arrow_down</mat-icon>
          <mat-select #IntoRun (openedChange)="IntoRunSelectOpened = !IntoRunSelectOpened"
            [(ngModel)]="selectedTestRuns" class="test-suit-select" panelClass="dropdown-panel-class" multiple
            (selectionChange)="updateSelectedTestRuns()">
            <!--          <mat-select #IntoSuit [(ngModel)]="selectedTestSuit" class="test-suit-select" multiple disableOptionCentering (selectionChange)="updateSelectedTestSuits()">-->
            <mat-option *ngFor="let option of testRuns" [value]="option" class="test-suit-select-option">
              {{ option.title }}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-flat-button (click)="onPush()" class="push-button"
          [ngStyle]="{'padding-left':componentShowHide !== 'hide' ? '0px': '3rem', 'padding-right': componentShowHide !== 'hide' ? '0px': '3rem'} ">push</button>
        <button mat-button *ngIf="!selection.isEmpty()" (click)="onDeleteCases()" class="button-red delete-button">
          <span class="material-icons">delete</span>
          {{componentShowHide !== 'hide'? 'delete cases' : 'delete selected cases'}}</button>
      </div> <!-- End of selected-test-cases-popup-->
    </div>

    <div class="test-case-details-sidebar" [@changeSideComponentSize]="componentShowHide"
      (@changeSideComponentSize.done)="doneAnimation()">
      <app-test-case-details-sidebar [testCase]="testCase" [testSteps]="testSteps" [showSteps]="showSteps"
        (Resize)="onResize($event)"></app-test-case-details-sidebar>
    </div>

  </div>
  <!--End of main container-->

</app-sidenav>
