<app-base-card [title]="'Unesigned Test Cases'" [icon]="'date_range'" [showAdd]="addAccessAction" [showClose]="false"
  [showSearch]="false" [showPadding]="true" (addEvent)="onAddEvent()">

  <div [style.display]="isLoading ? 'block' : 'none'">
    <div class="content" [style.display]="testCases?.length ? 'block' : 'none'">
      <mat-selection-list (selectionChange)="selectionChange($event)">
        <mat-list-option #option *ngFor="let item of testCases" [value]="item" class="list-card__item item-list"
          [routerLink]="'./'" [queryParams]="{ project: item.projectId, type: 'tcs', id: item.id }">
          <div class="item-list__body">
            <div class="item-list__title flex">
              <div [style.opacity]="(pending && deletedItem === item) ? .2 : 1">{{item.title}}</div>
              <div *ngIf="pending && deletedItem === item" class="loading">
                <img src="../../../assets/image/loading.gif" alt="pending">
              </div>
            </div>
            <div class="item-list__action">
              <div class="item-list__menu">
                <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <!-- <button mat-menu-item (click)="onEditEvent(item)">
                    <span>Edit</span>
                  </button> -->
                  <button mat-menu-item (click)="copyLink()" [disabled]="!selectionList._value?.includes(item)">
                    <span>Copy link</span>
                  </button>
                  <!-- <button mat-menu-item (click)="onDeleteCaseEvent(item)">
                    <span>Delete</span>
                  </button> -->
                </mat-menu>
              </div>
            </div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>

    <div class="not-found" *ngIf="!testCases?.length">
      Unesigned test cases not found...
    </div>
  </div>

  <div *ngIf="!isLoading">
    <ngx-skeleton-loader [theme]="{ height: '40px', background: '#D6DBDF', margin: '5px 0px 0px 0px' }" count="3"
      appearance="line"></ngx-skeleton-loader>
  </div>
</app-base-card>