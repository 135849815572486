import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { ApiBaseService } from 'src/app/services/api-base.service';
import { Attachment } from 'src/app/services/attachment.type';
import { Core } from 'src/app/services/core.service';
import { ProjectService } from 'src/app/services/project.service';
import { Status } from 'src/app/services/status.type';
import { TestCaseService } from 'src/app/services/test-case.service';
import { TestCase } from 'src/app/services/test-case.type';
import { TestStepService } from 'src/app/services/test-step.service';
import { TestStep } from 'src/app/services/test-step.type';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-card-test-run-defects-step',
  templateUrl: './card-test-run-defects-step.component.html',
  styleUrls: ['./card-test-run-defects-step.component.scss']
})
export class CardTestRunDefectsStepComponent implements OnInit {

  private _testCase: TestCase;

  private _testRunSteps: TestStep[];
  public failedOnStep: TestStep;

  @Input() public testRunSteps: TestStep[];
  @Input() public shortRun: boolean = false;
  @Input() public shortCases: boolean = false;
  @Input() queryParams: Params;

  @ViewChildren('panel') private panelSteps: QueryList<MatExpansionPanel>;

  private _statuses: Status[];
  public statuses: Status[];

  public showFailedTest: boolean = false;

  @Input() set testCase(data: TestCase) {
    if (data) {
      this._testCase = data;
      this.getTestCaseSteps(data.relationId);
    }
  } get testCase() {
    return this._testCase
  };

  @Output() closeEvent = new EventEmitter()

  constructor(
    private testStepService: TestStepService,
    private projectService: ProjectService,
    private testCasesService: TestCaseService,
    private userService: UserService,
    public api: ApiBaseService,
  ) { }

  ngOnInit(): void {
  }

  getStatuses() {
    const currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';
    let id = currentProject
    if (isNaN(+currentProject)) {
      id = this.projectService.getPrivatProject[0].id
    }
    return this.testCasesService.getTestCaseStatuses({ project_id: id }).pipe(
      map(val => {
        this._statuses = val;
        this.statuses = val;
        return val;
      })
    )
  }

  getTestCaseSteps(relationId: string) {
    this.testStepService.getTestStepsByRelationId({ relation_id: relationId }).subscribe(async (steps: TestStep[]) => {
      this._testRunSteps = steps;
      await this.getStatuses().toPromise()
      this.mapTestSteps()
      this.failedOnStep = steps.find(val => +val.statusObject.statusState === -1);
      this.setDefectDescription();
      this.getAttachments().then(res => {
        this.showFailedTest = true;
      }).catch(err => this.showFailedTest = true);
    })
  }

  getAttachments() {
    return Promise.all(
      this._testRunSteps.map(ts => {
        this.userService.testStepAttachments({ test_run_history_id: this._testCase.relationId || this.testCase.relationId, step_id: ts.id }).subscribe(r => {
          ts.attachments = r;
          ts.attachments.map((attachment: Attachment) => {
            if (attachment.fileType.includes('text')) {
              this.previewTextFile(attachment);
            }
          })
        });
      })
    )
  }

  previewTextFile(attachment: Attachment): void {
    fetch(this.api.getApi() + attachment.relativePath)
      .then(response => response.text())
      .then(data => {
        attachment.fileData = data;
      });
  }

  mapTestSteps() {
    // mapping status_states to TestSteps
    this._testRunSteps.map((step: TestStep) => {
      this._statuses.map((status: Status) => {
        if (status.id === step.status) {
          step.statusObject = status;
        }
      })
    })
  }

  setDefectDescription() {
    this.testCase.defectTitle = `${this.failedOnStep.description || ''} - Failed`;
    let comment = '';
    const initialComment = this.testCase.comment;
    this._testRunSteps.forEach((testStep: TestStep, index: number) => {
      if (testStep.statusObject.statusState === '-1') {
        if (index === 0) {
          comment = `Test Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\nExpected Result: ${testStep.expectedResult}\nDefect details: ${initialComment}\n`;
        } else {
          comment = `${comment}\nTest Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\nExpected Result: ${testStep.expectedResult}\nDefect details: ${initialComment}\n`;
        }
      } else {
        if (index === 0) {
          comment = `Test Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\n`;
        } else {
          comment = `${comment}\nTest Step #${index + 1}: "${testStep.description}"\nstatus: ${testStep.statusObject.name}\n`;
        }
      }
    })

    this.testCase.comment = comment;
  }

  onCreateDefect() {
    return
  }
}
