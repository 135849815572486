import {Component, OnInit } from '@angular/core';
import {ProjectService} from '../services/project.service';
import {Router} from '@angular/router';
import {Project} from '../services/project.type';
import { MatDialog } from '@angular/material/dialog';
import {DialogCreateProjectComponent} from '../dialogs/dialog-create-project/dialog-create-project.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  public data: Project[];
  public filterValue: string = '';
  public displayedColumns: string[] = ['id', 'title', 'description', 'createdDate', 'modifiedDate'];

  constructor(
    private projectService: ProjectService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.projectService.getProjects().subscribe(res => {
      this.data = res;
    })
  }

  navigateToDetail(row: Project) {
    this.router.navigate(['project', row.id]).then(() => {});
  }

  openCreateProjectDialog(): void {
    this.dialog.open(DialogCreateProjectComponent, {
      width: '650px'
    });
  }

}
