import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchCases'
})
export class SearchCases implements PipeTransform {
  transform(value: any[], searchValue: string): any[] {
    if (value && value.length && searchValue?.trim().length > 0) {
      return value.filter((v) => {
        if (v.suit.title?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) > -1) {
          v.cases.forEach(element => { element.isShow = true });
          return true;
        }

        let arr = [];
        arr.push(...v.cases);
        arr.forEach(caseItem => caseItem.isShow = false);
        arr = arr.filter((v) => v.title?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) > -1);
        arr.forEach(elem => elem.isShow = true);

        if (arr.length) return true
      });
    }

    value?.forEach(v => { v.cases?.forEach(iCase => iCase.isShow = true) })
    return value
  }
}
