<div class="dialog-title">
  <h1 mat-dialog-title>Delete</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <span *ngIf="!data.errorMsg">Do you really want to {{data.dialogTitle}} <span
      class="accent">{{data.name}}</span>?</span>
  <span *ngIf="data.errorMsg === 'Unesigned cases'">This is the last relation of this case after deletion it goes to the
    section unesigned cases! Do you really want to {{data.dialogTitle}} <span
      class="accent">{{data.name}}</span>?</span>
</div>

<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" style="background: #2d64ba" (click)="save()">Confirm</button>
</div>