import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { TestStepService } from '../../services/test-step.service';
import { TestStep } from '../../services/test-step.type';
import { TestStepsTableDataSource } from './test-steps-table.datasource';

@Component({
  selector: 'app-test-steps-table',
  templateUrl: './test-steps-table.component.html',
  styleUrls: ['./test-steps-table.component.scss']
})
export class TestStepsTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: TestStepsTableDataSource;

  constructor(private route: ActivatedRoute, private testStepService: TestStepService, private router: Router) { }

  displayedColumns = ['id', 'testCaseId', 'order', 'expectedResult', 'description', 'createdDate', 'modifiedDate'];

  ngOnInit() {
    var id:string = this.route.snapshot.paramMap.get('id');

    this.dataSource = new TestStepsTableDataSource(this.paginator, this.sort, this.testStepService, id);
  }

}
