<app-base-page>
  <div class="main-page">
    <div class="main-page__row">
      <div class="main-page__column sticky" [class.main-page__column-50]="showCase && !showDetails"
        [class.main-page__column-33]="showStep || (showDetails && showCase)"
        [class.main-page__column-3]="shortFormRun || shortFormPlan || shortFormSuit"
        [class.height]="shortFormRun || shortFormPlan || shortFormSuit">
        <app-card-test-run #testRunCard [showCase]="showCase" [showRun]="showRun" [shortForm]="shortFormRun"
          [showDetailsPage]="showDetails" [queryParams]="queryParams" [isShowSkeleton]="isShowSkeleton"
          [showStep]="showStep"
          (showTestRunCase)="onShowTestCase($event, 'testRunCase'); refreshTestPlanList(); refreshTestSuitList()"
          (updateTestRunCases)="updateTestRunCase()" (updateSelectedValue)="updateSelectedValue($event)"
          (showTestDefects)="onShowTestCase(selectedTest, 'testRunDefectsCase')"
          (showTestDetails)="onShowTestCase(selectedTest, 'testRunDetailsCase')"
          (collapseEvent)="shortFormEvent($event)" (showCaseEvent)="showCases()"
          (isShowSkeletonEvent)="isShowSkeletonEvent($event)"
          (closeCaseEvent)="closeCase('TestRun')"></app-card-test-run>
        <ngx-skeleton-loader *ngIf="isShowSkeleton" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
          appearance="line"></ngx-skeleton-loader>
        <app-card-test-plan #testPlanCard [showCase]="showCase" [showPlan]="showPlan" [shortForm]="shortFormPlan"
          [queryParams]="queryParams"
          (showTestPlanCase)="onShowTestCase($event, 'testPlanCase');  refreshTestRunList(); refreshTestSuitList()"
          (updateTestPlanCases)="updateTestPlanCase()" (updateTestRun)="updateTestRun()"
          (collapseEvent)="shortFormEvent($event)" (showCaseEvent)="showCases()"></app-card-test-plan>
        <ngx-skeleton-loader *ngIf="isShowSkeleton" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
          appearance="line"></ngx-skeleton-loader>
        <app-card-test-suit #testSuitCard [showCase]="showCase" [showSuit]="showSuit" [shortForm]="shortFormSuit"
          [queryParams]="queryParams"
          (showTestSuitCase)="onShowTestCase($event, 'testSuitCase');  refreshTestRunList(); refreshTestPlanList();"
          (updateTestSuitCases)="updateTestSuitCase()" (updateTestRun)="updateTestRun()"
          (collapseEvent)="shortFormEvent($event)" (showCaseEvent)="showCases()"></app-card-test-suit>
        <ngx-skeleton-loader *ngIf="isShowSkeleton" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
          appearance="line"></ngx-skeleton-loader>
      </div>

      <ng-container *ngIf="showCase">
        <div [ngSwitch]="switchCases"
          [class]="switchCases === 'testSuitCase' ? 'main-page__column main-page__column' : 'main-page__column sticky'"
          [class.main-page__column-50]="!showDetails" [class.main-page__column-33]="showStep"
          [class.main-page__column-97]="(shortFormRun || shortFormPlan || shortFormSuit) && !showStep"
          [class.main-page__column-48]="(shortFormRun || shortFormPlan || shortFormSuit) && !(shortFormRunCase || shortFormPlanCase || shortFormSuitCase) && showStep"
          [class.main-page__column-3]="(shortFormRunCase || shortFormPlanCase || shortFormSuitCase) && showStep"
          [class.height]="(shortFormRunCase || shortFormPlanCase || shortFormSuitCase) && showStep">
          <div *ngSwitchCase="'testRunCase'">
            <app-card-test-run-case #testRunCase [showStep]="showStep" [testRun]="selectedTest"
              [shortFormRun]="shortFormRun" [shortForm]="shortFormRunCase" [queryParams]="queryParams"
              (closeEvent)="closeCase('TestRun')" (closeStepEvent)="closeStep('TestRunCase')"
              (updateTestRuns)="updateTestRun()" (updateTestRunSteps)="updateTestRunSteps($event)"
              (showTestStep)="onShowTestSteps($event, 'testRunStep')" (hideCollapseRun)="hideCollapse('TestRun')"
              (collapseEvent)="shortFormEvent($event)"></app-card-test-run-case>
          </div>
          <div *ngSwitchCase="'testRunDetailsCase'">
            <app-card-test-run-details #testRunDetails [showStep]="showStep" [shortFormRun]="shortFormRun"
              [shortForm]="shortFormRunCase" [testRun]="selectedTest" (closeEvent)="closeCase('TestRun')"
              (updateTestRuns)="updateTestRun()" (openCaseComponent)="openCase($event)"></app-card-test-run-details>
          </div>
          <div *ngSwitchCase="'testRunDefectsCase'">
            <app-card-test-run-defects [testRun]="selectedTest" [showStep]="showStep"
              (closeEvent)="closeCase('TestRun')" [shortFormRun]="shortFormRun" [shortForm]="shortFormRunCase"
              [queryParams]="queryParams" (showTestStep)="onShowTestSteps($event, 'testRunStepDefects')"
              (hideCollapseRun)="hideCollapse('TestRun')"
              (collapseEvent)="shortFormEvent($event)"></app-card-test-run-defects>
          </div>
          <div *ngSwitchCase="'testPlanCase'" class="height">
            <app-card-test-plan-case #testPlanCase [showStep]="showStep" [testPlan]="selectedTest"
              [shortFormPlan]="shortFormPlan" [shortForm]="shortFormPlanCase" [queryParams]="queryParams"
              (closeEvent)="closeCase('TestPlan')" (showTestStep)="onShowTestSteps($event, 'testPlanStep')"
              (hideCollapsePlan)="hideCollapse('TestPlan')" (updateTestPlans)="updateTestPlan()"
              (collapseEvent)="shortFormEvent($event)"></app-card-test-plan-case>
          </div>
          <div *ngSwitchCase="'testSuitCase'" class="height">
            <app-card-test-suit-case #testSuitCase [showStep]="showStep" [testSuit]="selectedTest"
              [shortFormSuit]="shortFormSuit" [shortForm]="shortFormSuitCase" [queryParams]="queryParams"
              (closeEvent)="closeCase('TestSuit')" (showTestStep)="onShowTestSteps($event, 'testSuitStep')"
              (hideCollapseSuit)="hideCollapse('TestSuit')" (updateTestSuits)="updateTestSuit()"
              (collapseEvent)="shortFormEvent($event)"></app-card-test-suit-case>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showStep">
        <div [ngSwitch]="switchSteps" class="main-page__column main-page__column-33 sticky"
          [class.main-page__column-48]="(shortFormRun || shortFormPlan || shortFormSuit)"
          [class.main-page__column-64]="(shortFormRunCase || shortFormPlanCase || shortFormSuitCase)"
          [class.main-page__column-94]="(shortFormRun || shortFormPlan || shortFormSuit) && (shortFormRunCase || shortFormPlanCase || shortFormSuitCase)">
          <div *ngSwitchCase="'testRunStep'">
            <app-card-test-run-step #testRunStep [testCase]="selectedCase" [shortRun]="shortFormRun"
              [selectedRun]="selectedTest" [shortCases]="shortFormRunCase" [queryParams]="queryParams"
              (closeEvent)="closeStep('TestRunCase')" (updateTestRuns)="updateTestRun()"></app-card-test-run-step>
          </div>
          <div *ngSwitchCase="'testRunStepDefects'">
            <app-card-test-run-defects-step [testCase]="selectedCase" [shortRun]="shortFormRun"
              [queryParams]="queryParams" [shortCases]="shortFormRunCase"
              (closeEvent)="closeStep('TestRunCase')"></app-card-test-run-defects-step>
          </div>
          <div *ngSwitchCase="'testPlanStep'">
            <app-card-test-plan-step [testCase]="selectedCase" [shortPlan]="shortFormPlan"
              [shortCases]="shortFormPlanCase" [queryParams]="queryParams"
              (closeEvent)="closeStep('TestPlanCase')"></app-card-test-plan-step>
          </div>
          <div *ngSwitchCase="'testSuitStep'">
            <app-card-test-suit-step [testCase]="selectedCase" [shortSuit]="shortFormSuit"
              [shortCases]="shortFormSuitCase" [queryParams]="queryParams"
              (closeEvent)="closeStep('TestSuitCase')"></app-card-test-suit-step>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</app-base-page>