import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Status } from 'src/app/services/status.type';


export function newStateValidator(statuses: Status[], statusId: string): ValidatorFn {
  return (control: AbstractControl) => {

    let findTheSameName = statuses.find(item => item.name === control.get('name').value && item.statusState !== control.get('statusState').value && statusId !== item.id);
    let statusState = control.get('statusState').value;

    if (findTheSameName) {
      control.get('statusState').setErrors({ anotherStatusForThisName: 'State' })
      return
    }
    if (+statusState !== 0 && +statusState !== 1 && +statusState !== -1) {
      control.get('statusState').setErrors({ invalidDataForStatusState: 'State' })
      return
    }
    if (control.get('name').value.length < 1) {
      control.get('name').setErrors({ required: 'Name' })
      return
    }
    if (control.get('statusState').value.length < 1) {
      control.get('statusState').setErrors({ required: 'State' })
      return
    }

    control.get('statusState').setErrors(null)
    return null
  }
}
