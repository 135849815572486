import { Injectable } from '@angular/core';
import { TestPlan } from './test-plan.type';
import { from, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiService } from './api.service';
import { TestSuitService } from './test-suit.service';
import { TestCaseService } from './test-case.service';
import { TestRun } from './test-run.type';
import { TestRunService } from './test-run.service';
import { Core } from './core.service';
import { TestCase } from './test-case.type';
import { TestSuitFolders, TestSuitFoldersTemplate } from './test-suit.type';

@Injectable({
  providedIn: 'root'
})
export class TestPlanService {
  private testPlanes: TestPlan[];
  private styleColors = ['#0000ff1a', '#3cb3711a', '#ee82ee1a', '#ffa5001a', '#ff00001a'];

  private _cache$: Observable<TestPlan[]>;
  private count: number = 0;
  public sortType: string;

  constructor(
    private api: ApiService,
    private testSuitService: TestSuitService,
    private testCaseService: TestCaseService,
    private testRunService: TestRunService
  ) { }

  getTestPlans(param): Observable<TestPlan[]> {
    if (param.project_id === 'null' || param.project_id === 'all') param = {}
    return this._cache$ = this.requestTestPlans(param).pipe(
      shareReplay(1),
    )
  }

  private requestTestPlans(param): Observable<TestPlan[]> {
    return from(Core.testPlan.list(param).catch(error => console.log(error))).pipe(map(res => {
      if (res) {
        this.testPlanes = this.mapTestPlans(res.test_planes);
        return this.testPlanes;
      } else return []
    }))
  }

  getTestPlan(param): Observable<TestPlan> {
    return from(Core.testPlan.readList(param).catch(error => console.error(error))).pipe(map(res => {
      console.log('read plan 45', res)

      if (res) return this.mapTestPlan(res.test_plan)
      else return null
    }))
  }

  updateRelation(param): Observable<boolean> {
    return from(Core.testPlan.updateRelation(param).catch(error => console.error(error))).pipe(map(res => {
      console.log('update relation plan 54', res)

      if (res) return true
      else return false
    }))
  }

  createTestPlan(testPlan: TestPlan, testCases: any[]): Observable<boolean> {
    return from(Core.testPlan.create({
      title: testPlan.title,
      description: testPlan.description,
      project_id: testPlan.projectId,
      test_suit_relation_ids: testCases
    }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  runPlan(param): Observable<TestRun> {
    return from(Core.testPlan.createTestRunFromPlan(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.testRunService.mapTestRun(res.testRun);
      else return null
    }))
  }

  plansToRun(param) {
    return from(Core.testPlan.plansToRun(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return res;
      else return false
    }))
  }

  saveTestPlan(testPlan: TestPlan): Observable<TestPlan> {
    return from(Core.testPlan.update({ testPlan }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.mapTestPlan(res.test_plan);
      else return null
    }))
  }

  addTestCases(param): Observable<boolean> {
    return from(Core.testPlan.addTestCases(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  updateTestCasesList(param): Observable<boolean> {
    return from(Core.testPlan.updateTestCasesList(param).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  deleteTestPlan(params): Observable<boolean> {
    return from(Core.testPlan.delete(params).catch(error => console.error(error))).pipe(map(res => {
      if (res) return true
      else return false
    }))
  }

  getTestCasesList(tpId: string): Observable<TestCase[]> {
    return from(Core.testCase.listByTestPlan({ tp_id: tpId }).catch(error => console.error(error))).pipe(map(res => {
      if (res) return this.testCaseService.mapTestCases(res.test_cases);
      else return []
    }))
  }

  mapTestPlans(raw: any[]): TestPlan[] {
    return raw.map((val: any) => this.mapTestPlan(val));
  }

  mapTestPlan(raw: any): TestPlan {
    const result: TestPlan = new TestPlan();

    result.id = raw.id;
    result.title = raw.title;
    result.projectId = raw.project_id;
    result.description = raw.description;
    result.createdDate = raw.created_date;
    result.modifiedDate = raw.modified_date;
    result.creator_id = raw.creator_id;
    result.creator_name = raw.creator_name;
    if (raw.test_suits !== undefined) {
      result.testSuits = this.testSuitService.mapTestSuits(raw.test_suits);
    };
    if (raw.test_cases !== undefined) {
      result.testCases = this.testCaseService.mapTestCases(raw.test_cases);
    };
    return result;
  }

  foldersPlansList(param): Observable<any> {
    return from(Core.testSuit.foldersPlansList(param).catch(error => console.error(error))).pipe(map((res: any) => {
      if (typeof res === 'object') return res.reduce((acc, key, i) => [...acc, this.mapTestSuitsFolders(key, i)], []);
      else return []
    }))
  }

  mapTestSuitsFolders(raw: TestSuitFolders, i): TestSuitFolders {
    const result: TestSuitFolders = new TestSuitFolders();
    result.suit = raw.suit;
    result.sub_suits = raw.sub_suits;
    result.color = this.styleColors[i % 5];
    raw.cases?.length > 0 ? result.cases = this._mapTestCaseFromFolders(raw.cases) : result.cases = [];

    if (result.sub_suits?.length > 0) {
      result.sub_suits.forEach(elem => { elem[0] = this.mapTestSuitsFolders(elem[0], i); })
    }
    return result
  }

  _mapTestCaseFromFolders(cases): TestCase[] {
    return cases.map((caseItem: any) => {
      return {
        id: caseItem.id,
        testCaseId: caseItem.test_case_id,
        title: caseItem.title,
        description: caseItem.description,
        projectId: caseItem.project_id,
        creatorId: caseItem.creator_id,
        createdDate: caseItem.created_date,
        modifiedDate: caseItem.modified_date,
        tsId: caseItem.ts_id,
        tesSuitRelationId: caseItem.test_suit_relation_id,
        ref: caseItem.ref,
        independent: caseItem.independent,
        oldTcId: caseItem.oldTcId
      }
    });
  }

  public mapSuitFoldersTemplate(testFolder: TestSuitFoldersTemplate, arr = [], level = 0, par: any[] = []): TestSuitFoldersTemplate[] {
    let suitCases = arr;
    let parSuits = [...par, testFolder.suit];
    suitCases.push({ ...testFolder, show: false, level, parents: par });
    if (testFolder.sub_suits?.length > 0) {
      testFolder.sub_suits.forEach(elem => this.mapSuitFoldersTemplate(elem[0], suitCases, level + 1, parSuits));
    }
    return suitCases
  }
}
