import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from '../../services/project.type';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { User } from '../../services/user.type';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface DialogData {
  project: Project
}

@Component({
  selector: 'app-dialog-create-user',
  templateUrl: 'dialog-create-user.component.html',
  styleUrls: ['./dialog-create-user.component.scss']
})
export class DialogCreateUserComponent implements OnInit {

  user: User = new User();
  currentUser: any;
  hiddenPassword = true;

  userForm: FormGroup;

  public usersRolesList = [];

  constructor(
    public dialogRef: MatDialogRef<DialogCreateUserComponent>,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.getUser();
    this.initForm();
    this.userService.getUsersRole().subscribe(v => {
      this.usersRolesList = v['roles'];
    })
  }

  initForm() {
    this.userForm = new FormGroup({
      login: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      userRole: new FormControl('', [Validators.required]),
    })
  }

  get f() {
    return this.userForm.controls
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  createUser(): void {
    const user = {
      login: this.userForm.value.login,
      password: this.userForm.value.password,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      roleId: this.userForm.value.userRole,
      companyId: this.currentUser.company_id,
      accountType: 'default'
    }
    this.userService.createUser(Object.assign(new User(), user)).subscribe(res => {
      this.dialogRef.close(res);
    })
  }

}
