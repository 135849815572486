<app-base-card [title]="'Info'" [icon]="'info'" [showClose]="true" [showEdit]="editAccessAction" [showAdd]="false"
  [editButtonText]="textForEditButton" (closeEvent)="closeEvent.emit()" (editEvent)="editProject()">

  <form class="example-form project-info-form" [formGroup]="editProjectForm">
    <div class="project-info-item">
      <mat-form-field class="example-full-width" [matTooltip]="textForEditButton === 'Edit' ? project.title : ''">
        <mat-label>Title:</mat-label>
        <input class="project-mat-input mat-form-field-underline" matInput value="{{project.title}}"
          [formControlName]="'title'">
      </mat-form-field>
    </div>
    <div class="project-info-item">
      <mat-form-field class="example-full-width">
        <mat-label>Description:</mat-label>
        <textarea class="mat-textarea" cdkTextareaAutosize matInput value="{{project.description}}"
          [formControlName]="'description'"></textarea>
      </mat-form-field>
    </div>
  </form>

  <mat-list>
    <mat-list-item class="list-card__item item-list">
      <div class="item-list__body">
        <div class="item-list__title">Created By: {{project.creator_name}}</div>
      </div>
    </mat-list-item>
    <mat-list-item class="list-card__item item-list">
      <div class="item-list__body">
        <div class="item-list__title">Created Date: {{project.createdDate}}</div>
      </div>
    </mat-list-item>
    <mat-list-item class="list-card__item item-list">
      <div class="item-list__body">
        <div class="item-list__title">Modification Date: {{project.modifiedDate}}</div>
      </div>
    </mat-list-item>
  </mat-list>
</app-base-card>