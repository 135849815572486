<div class="dialog-title">
  <h1 mat-dialog-title>{{title}}</h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <form [formGroup]="selectForm">
    <mat-form-field appearance="outline" floatLabel="auto" class="dialog-field">
      <mat-select [(ngModel)]="item[value]" formControlName="option">
        <mat-option *ngFor="let item of lists" [value]="item.id">
          {{item[showName]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="dialog-actions" mat-dialog-actions>
      <button mat-stroked-button (click)="onCancel()">Cancel</button>
      <button (click)="onSubmit()" mat-raised-button [disabled]="!selectForm.valid && selectForm.touched"
        color="primary" style="background: #2d64ba">Edit</button>
    </div>
  </form>
</div>