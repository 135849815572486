import { Component, OnInit, Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TestSuit, TestSuitFoldersTemplate } from '../../../services/test-suit.type';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { TestPlan } from 'src/app/services/test-plan.type';
import { TestCase } from 'src/app/services/test-case.type';


export class TodoItemNode {
  id: string;
  elem: TestSuit | TestCase;
  children: TodoItemNode[];
  parents: TestSuit[];
  isCase: boolean;
  title: string;
  info?: string;
  tesSuitRelationId: string;
  level: number;
  open: boolean;
}

export class TodoItemFlatNode {
  id: string;
  elem: TestSuit | TestCase;
  parents: TestSuit[]
  title: string;
  info?: string;
  level: number;
  expandable: boolean;
  tesSuitRelationId: string;
  open: boolean;
  isCase: boolean;
  children: TodoItemNode[];
  isExistCasesOnLowLevel?: boolean;
}

export class TodoItemNodePlans {
  id: string;
  elem: TestSuit;
  children: TodoItemNodePlans[];
  parents: TestSuit[]
  title: string;
  tesSuitRelationId: string;
  level: number;
  open: boolean;
  isCase: boolean;
}

export class TodoItemFlatNodePlans {
  id: string;
  elem: TestSuit;
  parents: TestSuit[]
  title: string;
  level: number;
  expandable: boolean;
  tesSuitRelationId: string;
  open: boolean;
  isCase: boolean;
  isExistCasesOnLowLevel?: boolean;
}

@Injectable()
export class ChecklistDatabase {
  TREE_DATA = {};
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor() { }

  setupTreeData(treeData) {
    this.TREE_DATA = treeData;
  }

  initialize() {
    const data = this.buildFileTree((this.TREE_DATA as []).reduce((acc, n, i) => (acc['test' + i] = n, acc), {}), 0);
    this.dataChange.next(data);
  }

  buildFileTree(obj: any, level: number, parents: TestSuit[] = [], isCases: boolean = false): TodoItemNode[] {
    const tree = Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      let value = obj;
      if (accumulator.map(elem => ({ id: elem.id, level: elem.level })).filter(elem => (elem.id === (obj['suit']?.id || obj.id)) && elem.level === level).length > 0) return accumulator;
      if (!isCases) value = obj[key];
      if (level === 0) value = obj[key][0];

      const node = new TodoItemNode();
      node.title = value.suit?.title || value.title;
      node.id = value.suit?.id || value.id;
      node.elem = value['suit'] || value;
      node.tesSuitRelationId = value.tesSuitRelationId;
      node.level = level;
      node.open = false;
      node.parents = parents;
      node.isCase = isCases;
      if (isCases) node.info = `id: ${node.id}`
      node.children = [];

      if (value['cases']?.length > 0) {
        node.children = value.cases.map(caseItem => {
          let parentSuits = [...parents, value.suit];
          return this.buildFileTree(caseItem, level + 1, parentSuits, true)[0];
        });
      };

      if (value['sub_suits']?.length > 0) {
        node.children = [...node.children, ...value.sub_suits.map(res => {
          let parentSuits = [...parents, value.suit]
          return this.buildFileTree(res, level + 1, parentSuits)[0];
        })];
      };

      return accumulator.concat(node);
    }, []);

    return tree;
  }

  TREE_DATA_PLANS = {};
  dataChangePlans = new BehaviorSubject<TodoItemNodePlans[]>([]);
  get dataPlans(): TodoItemNodePlans[] { return this.dataChangePlans.value; }

  setupTreeDataPlans(treeData) {
    this.TREE_DATA_PLANS = treeData;
  }

  initializePlans() {
    const data = this.buildFileTreePlans(this.TREE_DATA_PLANS, 0);
    this.dataChangePlans.next(data);
  }

  buildFileTreePlans(obj: any, level: number, parents: TestSuit[] = [], isCases: boolean = false): TodoItemNodePlans[] {
    const tree = Object.keys(obj).reduce<TodoItemNodePlans[]>((accumulator, key) => {
      if (accumulator.map(elem => ({ id: elem.id, level: elem.level })).filter(elem => (elem.id === (obj['suit']?.id || obj.id)) && elem.level === level).length > 0) return accumulator;
      let value = obj;
      if (level === 0) value = obj[key];
      if (!isCases && level > 1) value = obj[key];

      const node = new TodoItemNodePlans();
      node.title = value.suit?.title || value.title;
      node.id = value.suit?.id || value.id || value.testCaseId;
      node.elem = value['suit'] || value;
      node.tesSuitRelationId = value.tesSuitRelationId;
      node.level = level;
      node.open = false;
      node.parents = parents;
      node.isCase = isCases;
      node.children = [];

      if (value['cases']?.length > 0) {
        node.children = value.cases.map(caseItem => {
          let parentSuits = [...parents, value.suit];
          return this.buildFileTreePlans(caseItem, level + 1, parentSuits, true)[0];
        });
      };

      if (value['sub_suits']?.length > 0) {
        node.children = [...node.children, ...value.sub_suits.map(res => {
          let parentSuits = [...parents, value.suit]
          return this.buildFileTreePlans(res, level + 1, parentSuits)[0];
        })];
      };

      return accumulator.concat(node);
    }, []);

    return tree;
  }
}

@Component({
  selector: 'app-shared-suit-plan-select',
  templateUrl: 'shared-suit-plan-select.component.html',
  styleUrls: ['./shared-suit-plan-select.component.scss'],
  providers: [ChecklistDatabase]
})
export class SharedSuitPlanSelectComponent implements OnInit {
  @Input() testSuits: TestSuitFoldersTemplate[] = null;
  @Input() testPlans: TestPlan[] = null;
  @Input() selectedTestSuitCases: TestSuitFoldersTemplate[] | TestCase[] = null;
  @Input() selectedTestPlanCases: TestCase[] = null;
  @Input() type: string = null;

  public selectedExistTestSuitCasesId = [];

  // FOR SUITS
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true);
  // FOR SUITS

  // FOR PLANS
  flatNodeMapPlans = new Map<TodoItemFlatNodePlans, TodoItemNodePlans>();
  nestedNodeMapPlans = new Map<TodoItemNodePlans, TodoItemFlatNodePlans>();
  treeControlPlans: FlatTreeControl<TodoItemFlatNodePlans>;
  treeFlattenerPlans: MatTreeFlattener<TodoItemNodePlans, TodoItemFlatNodePlans>;
  dataSourcePlans: MatTreeFlatDataSource<TodoItemNodePlans, TodoItemFlatNodePlans>;
  checklistSelectionPlans = new SelectionModel<TodoItemFlatNodePlans>(true);
  // FOR PLANS

  constructor(
    private _database: ChecklistDatabase,
  ) { }

  ngOnInit() {
    this._database.setupTreeData(this.testSuits);
    this._database.initialize();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this._database.dataChange.subscribe(r => {
      this.dataSource.data = r;
    });
    this.treeControl.dataNodes.forEach(node => {
      this.checkOnChildCases(node);
    });
    this.selectedTestSuitCases ? this.toggleSelectedNodes(this.selectedTestSuitCases) : null;

    if (this.testPlans) {
      this._database.setupTreeDataPlans(this.testPlans);
      this._database.initializePlans();
      this.treeFlattenerPlans = new MatTreeFlattener(this.transformerPlans, this.getLevelPlans, this.isExpandablePlans, this.getChildrenPlans);
      this.treeControlPlans = new FlatTreeControl<TodoItemFlatNodePlans>(this.getLevelPlans, this.isExpandablePlans);
      this.dataSourcePlans = new MatTreeFlatDataSource(this.treeControlPlans, this.treeFlattenerPlans);

      this._database.dataChangePlans.subscribe(r => {
        this.dataSourcePlans.data = r;
      });
    }
  }

  // FOR SUITS
  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.title === node.title
      ? existingNode
      : new TodoItemFlatNode();

    flatNode.title = node.title ? node.title : node[0].title;
    flatNode.id = node.id ? node.id : node[0].id;
    flatNode.level = level;
    flatNode.isCase = (node.isCase === true || node.isCase === false) ? node.isCase : node[0].isCase;
    flatNode.elem = node.elem ? node.elem : node[0].elem;
    flatNode.tesSuitRelationId = node.isCase ? node.tesSuitRelationId : null;
    if (level === 0) flatNode.open = true;
    else flatNode.open = node.open != undefined ? node.open : node[0].open;
    flatNode.parents = node.parents ? node.parents : node[0].parents;
    flatNode.children = node.children ? node.children : node[0].children;
    flatNode.expandable = (node.children ? !!node.children.length : !!node[0].children.length);
    if (flatNode.isCase) flatNode.info = node.info;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  toggleSelectedNodes(selectedTestCases: TestSuitFoldersTemplate[] | TestCase[]): void {
    if (this.type && this.type !== 'SelectFromRun') {
      selectedTestCases.forEach((selected) => {
        this.flatNodeMap.forEach((flatNode, node) => {
          if (this.type && this.type === 'SelectFromSuit') {
            selected[0].cases?.forEach(elem => { this.checkSelected(node, elem) });
          } else if (this.type && this.type === 'SelectFromPlan') {
            selected.cases?.forEach(caseItem => { this.checkSelected(node, caseItem) });
          }
        });
      });
    };

    if (this.type && this.type === 'SelectFromRun') {
      [...selectedTestCases, ...this.selectedTestPlanCases].forEach((selected) => {
        this.flatNodeMap.forEach((flatNode, node) => {
          this.checkSelectedCase(node, selected)
        });
      });
    }
  }

  checkSelected(node, item) {
    if ((node.id === item.id || item.testCaseId) && node.tesSuitRelationId === item.tesSuitRelationId) {
      this.checklistSelection.select(node);
      this.checkAllParentsSelection(node);
    };
  }

  checkSelectedCase(node, item) {
    if (node.id === item.oldTcId && node.elem?.tsId === item.tsId) {
      this.checklistSelection.select(node);
      this.checkAllParentsSelection(node);
      if (node.title !== item.title) node.info = `id: ${node.id}, oldTitle: ${item.title}`;
    };
  }

  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node).filter(elem => elem.isExistCasesOnLowLevel);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );

    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    if (currentLevel < 1) return null;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) return currentNode;
    }
    return null;
  }

  checkOnChildCases(node: TodoItemFlatNode) {
    let arr = [];
    this.treeControl.dataNodes.forEach(currentNode => {
      if (currentNode.parents.includes(node.elem as TestSuit)) arr.push(currentNode);
    })
    if (arr.length) {
      arr = arr.filter(elem => elem.isCase);
      arr.length ? node.isExistCasesOnLowLevel = true : node.isExistCasesOnLowLevel = false;
    } else if (node.isCase) node.isExistCasesOnLowLevel = true;
    else node.isExistCasesOnLowLevel = false
  }

  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node).filter(elem => elem.isExistCasesOnLowLevel);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node).filter(elem => elem.isExistCasesOnLowLevel);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: TodoItemFlatNode) {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node).filter(elem => elem.isExistCasesOnLowLevel);

    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this._deselect(descendants);

    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  private _deselect(descendants) {
    let index;
    let canBeDeselect = descendants;
    [...(this.selectedTestSuitCases || []), ...(this.selectedTestPlanCases || [])].forEach((elem: TestCase) => {
      let find = canBeDeselect.find((item, i) => {
        index = i;
        return item.elem.id === elem.oldTcId && item.elem.tsId === elem.tsId
      });
      if (find) canBeDeselect.splice(index, 1);
    })
    this.checklistSelection.deselect(...canBeDeselect);
  }
  // FOR SUITS


  // FOR PLANS
  getLevelPlans = (node: TodoItemFlatNodePlans) => node.level;
  isExpandablePlans = (node: TodoItemFlatNodePlans) => node.expandable;
  getChildrenPlans = (node: TodoItemNodePlans): TodoItemNodePlans[] => node.children;
  hasChildPlans = (_: number, _nodeData: TodoItemFlatNodePlans) => _nodeData.expandable;
  transformerPlans = (node: TodoItemNodePlans, level: number) => {
    const existingNode = this.nestedNodeMapPlans.get(node);
    const flatNode = existingNode && existingNode.title === node.title
      ? existingNode
      : new TodoItemFlatNodePlans();

    flatNode.title = node.title ? node.title : node[0].title;
    flatNode.id = node.id ? node.id : node[0].id;
    flatNode.level = level;
    flatNode.isCase = (node.isCase === true || node.isCase === false) ? node.isCase : node[0].isCase;
    flatNode.elem = node.elem ? node.elem : node[0].elem;
    flatNode.tesSuitRelationId = node.isCase ? node.tesSuitRelationId : null;
    if (level === 0) flatNode.open = true;
    else flatNode.open = node.open != undefined ? node.open : node[0].open;
    flatNode.parents = node.parents ? node.parents : node[0].parents;
    flatNode.expandable = (node.children ? !!node.children.length : !!node[0].children.length);

    this.flatNodeMapPlans.set(flatNode, node);
    this.nestedNodeMapPlans.set(node, flatNode);
    return flatNode;
  };

  checkAllParentsSelectionPlans(node: TodoItemFlatNodePlans): void {
    let parent: TodoItemFlatNodePlans | null = this.getParentNodePlans(node);
    while (parent) {
      this.checkRootNodeSelectionPlans(parent);
      parent = this.getParentNodePlans(parent);
    }
  }

  checkRootNodeSelectionPlans(node: TodoItemFlatNodePlans): void {
    const nodeSelected = this.checklistSelectionPlans.isSelected(node);
    const descendants = this.treeControlPlans.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelectionPlans.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelectionPlans.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelectionPlans.select(node);
    }
  }

  includes(node): boolean {
    let find = [...(this.selectedTestSuitCases as TestCase[]), ...(this.selectedTestPlanCases as TestCase[])].find(elem => +elem.oldTcId === +node.elem.id && +elem.tsId === +node.elem.tsId);
    if (find) return true
    return false
  }

  includesAll(node): boolean {
    let everyCase = true, everySub = true;
    if (this.getChildren(node)?.filter(elem => elem.isCase).length) {
      everyCase = this.getChildren(node).filter(elem => elem.isCase)?.every(elem => this.includes(elem));
    }
    if (this.getChildren(node)?.filter(elem => !elem.isCase).length) {
      everySub = this.getChildren(node).filter(elem => !elem.isCase).every(elem => this.includesAll(elem))
    }
    return (everyCase && everySub)
  }

  getParentNodePlans(node: TodoItemFlatNodePlans): TodoItemFlatNodePlans | null {
    const currentLevel = this.getLevelPlans(node);
    if (currentLevel < 1) return null;

    const startIndex = this.treeControlPlans.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControlPlans.dataNodes[i];
      if (this.getLevelPlans(currentNode) < currentLevel) return currentNode;
    }
    return null;
  }

  descendantsAllSelectedPlans(node: TodoItemFlatNodePlans): boolean {
    const descendants = this.treeControlPlans.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelectionPlans.isSelected(child)
    );
    return descAllSelected;
  }

  todoItemSelectionTogglePlans(node: TodoItemFlatNodePlans) {
    this.checklistSelectionPlans.toggle(node);
    const descendants = this.treeControlPlans.getDescendants(node);
    this.checklistSelectionPlans.isSelected(node)
      ? this.checklistSelectionPlans.select(...descendants)
      : this.checklistSelectionPlans.deselect(...descendants);

    descendants.forEach(child => this.checklistSelectionPlans.isSelected(child));
    this.checkAllParentsSelectionPlans(node);
  }
  // FOR PLANS
}
