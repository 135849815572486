<div class="main-wrap">
  <header class="header-main">
    <div class="header-main__row">
      <div class="header-main__column">
        <h1 class="logo">TESTYY</h1>

        <div class="creation-mode">
          <h4 class="creation-mode__title">Creation mode</h4>
          <mat-slide-toggle [disabled]="guestAccessAction" [checked]="creationMode"
            (change)="onCreationMode($event)"></mat-slide-toggle>
        </div>

        <!--        <div class="selected-project">-->
        <!--          <button (menuClosed)="selectOpen = false;" (menuOpened)="selectOpen = true" mat-button-->
        <!--            [matMenuTriggerFor]="menu">-->
        <!--            {{currentProjectName | shortName:11}}-->
        <!--            <mat-icon class="icon"-->
        <!--              [ngStyle]="{'transform': selectOpen == true ? 'rotate(180deg)' : 'rotate(0deg)'}">keyboard_arrow_down</mat-icon>-->
        <!--          </button>-->
        <!--          <mat-menu #menu="matMenu">-->
        <!--            <ng-container *ngFor="let item of projects">-->
        <!--              <button (click)="selectProject(item)" mat-menu-item>{{item.title}}</button>-->
        <!--            </ng-container>-->
        <!--          </mat-menu>-->
        <!--        </div>-->


        <div class="selected-project">
          <mat-form-field>
            <mat-select [(value)]="selectedProject" (closed)="resetSearch()">
              <mat-option>
                <mat-form-field class="example-form-field" stopPropagation>
                  <mat-label>Search Project</mat-label>
                  <input matInput type="text" [(ngModel)]="searchInputValue" (input)="searchProjectMenu()">
                  <button matSuffix mat-icon-button aria-label="Search">
                    <mat-icon>search</mat-icon>
                  </button>
                </mat-form-field>
              </mat-option>
              <mat-option style="display: none" *ngIf="selectedProject" [value]="selectedProject">{{
                selectedProject.title }}</mat-option>
              <mat-option *ngFor="let project of projectsForMenu" [value]="project"
                (click)="selectProject(project)">{{project.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>



      </div>
      <div class="header-main__column">
        <div class="header-main__user user">
          <div class="user__first-name">{{user.firstName}}</div>
          <div class="user__last-name">{{user.lastName}}</div>
        </div>
        <div class="header-main__log-out">
          <button mat-icon-button matTooltip="Log Out" (click)="logOut()">
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </header>
  <nav class="navigation">
    <mat-nav-list class="navigation__list">
      <li class="navigation__item">
        <a mat-list-item class="navigation__link" routerLink="/dashboard-page"
          routerLinkActive="navigation__link-active">
          Dashboard
          <span class="line"></span>
        </a>
      </li>
      <li class="navigation__item">
        <a mat-list-item class="navigation__link" routerLink="/projects-page"
          routerLinkActive="navigation__link-active">
          Projects
          <span class="line"></span>
        </a>
      </li>
      <li class="navigation__item">
        <a mat-list-item class="navigation__link" routerLink="/test-page" routerLinkActive="navigation__link-active">
          Test Page
          <span class="line"></span>
        </a>
      </li>
      <li class="navigation__item">
        <a mat-list-item class="navigation__link" routerLink="/test-cases-page"
          routerLinkActive="navigation__link-active">
          Test Cases
          <span class="line"></span>
        </a>
      </li>
      <li class="navigation__item">
        <a mat-list-item class="navigation__link" routerLink="/settings-page"
          routerLinkActive="navigation__link-active">
          Settings
          <span class="line"></span>
        </a>
      </li>
    </mat-nav-list>
  </nav>
  <main class="main-body">
    <ng-content></ng-content>
  </main>
</div>