<app-base-card [title]="'Users'" [icon]="'date_range'" [showAdd]="addUserAccess" (addEvent)="onAddEvent()"
  [showSearch]="true" (searchEvent)="search = $event">
  <ngx-skeleton-loader *ngIf="isLoading" [theme]="{ height: '40px', background: '#D6DBDF' }" count="3"
    appearance="line"></ngx-skeleton-loader>
  <mat-list #selectionList>
    <mat-list-item #option *ngFor="let item of (users | searchPipe: search)" class="list-card__item item-list">
      <div class="item-list__body">
        <div class="item-list__title">{{item.firstName}} {{item.lastName}}</div>
        <div class="item-list__action">
          <div class="item-list__menu" *ngIf="editUserAccess">
            <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onEditEvent(item)">
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="onDeleteEvent(item)">
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</app-base-card>