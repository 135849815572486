import { Component, OnInit, OnDestroy } from '@angular/core';
import { TestPlanService } from '../services/test-plan.service';
import { TestPlan } from '../services/test-plan.type';
import { ActivatedRoute, Router } from '@angular/router';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { TestSuitService } from '../services/test-suit.service';
import { DialogTestCasesSelectComponent } from '../dialogs/dialog-test-cases-select/dialog-test-cases-select.component';
import { TestSuit } from '../services/test-suit.type';
import { MatDialog } from '@angular/material/dialog';
import { DialogRunComponent } from '../dialogs/dialog-run/dialog-run.component';
import { TestCaseService } from '../services/test-case.service';
import { TestCase } from '../services/test-case.type';
import { TestStepService } from '../services/test-step.service'
import { TestStep } from '../services/test-step.type';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Core } from '../services/core.service';

@Component({
  selector: 'app-test-plan-details',
  templateUrl: './test-plan-details.component.html',
  styleUrls: ['./test-plan-details.component.scss'],
  animations: [
    trigger('changeSideComponentSize', [
      state('hide', style({ width: '0%' })),
      state('start', style({ width: '0%' })),
      state('small', style({ width: '45%' })),
      state('medium', style({ width: '50%' })),
      state('large', style({ width: '55%' })),
      transition('hide => small', animate('300ms ease-out')),
      transition('start => small', animate('300ms  ease-out')),
      transition('small => medium', animate('300ms ease-out')),
      transition('small => large', animate('300ms ease-out')),
      transition('medium => large', animate('300ms ease-out')),
      transition('large => medium', animate('300ms ease-out')),
      transition('medium => small', animate('300ms ease-out')),
      transition('large => hide', animate('300ms ease-out')),
      transition('large => small', animate('300ms ease-out')),
      transition('medium => hide', animate('300ms ease-out')),
      transition('small => hide', animate('300ms  ease-out'))
    ]),
  ]
})
export class TestPlanDetailsComponent implements OnInit, OnDestroy {

  testPlan: TestPlan = new TestPlan();
  testSuits: TestSuit[];
  testCases: TestCase[] = [];
  testCase: TestCase;
  testSteps: TestStep[];
  selectedTestCases: any[] = [];
  editMode: boolean;
  currentProjectId: string;
  relationId: string;
  testCaseId: string;
  showSteps = true;
  componentShowHide = 'start';

  editTestPlansForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private testPlanService: TestPlanService,
    private testSuitService: TestSuitService,
    public dialog: MatDialog,
    private router: Router,
    private testCaseService: TestCaseService,
    private testStepService: TestStepService
  ) { }

  public displayedColumns: string[] = ['id', 'title', 'testSuitName', 'createdDate', 'modifiedDate'];

  async ngOnInit() {
    // Setting init data if sidebar was opened
    const initData = Core.localStorageService.getItem('testCases');
    if (initData) {
      this.testCases = JSON.parse(initData);
      this.testPlan = JSON.parse(Core.localStorageService.getItem('testPlan'));
    }

    const tuple = this.route.paramMap.pipe(map(params => {
      this.testCaseId = params.get('testCaseId');
      return this.testPlanService.getTestPlan({ id: params.get('id') });
    }), publishReplay(1), refCount()
    );

    const testPlan$ = tuple.pipe(switchMap(t => t));
    testPlan$.subscribe(async plan => {
      this.testPlan = plan;
      this.initForm()
      this.currentProjectId = this.testPlan.projectId;

      // Fetching Test Suits for this Test Plan
      this.testSuits = await this.getTestSuits();

      // Fetching Test Cases of this Test Plan
      this.testCases = await this.getTestPlanCases(this.testPlan.id);

      if (this.testCaseId) this.openSideComponent();
    });
  }

  initForm() {
    this.editTestPlansForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.testPlan.title, [Validators.required]),
      description: new UntypedFormControl(this.testPlan.description, [Validators.required]),
    })
  }

  get f() {
    return this.editTestPlansForm.controls
  }

  async openSideComponent() {
    this.testCase = this.testCases.filter((testCase: TestCase) => {
      if (testCase.testCaseId === this.testCaseId) {
        return true
      } else {
        return false
      }
    })[0]

    this.componentShowHide = 'small';

    // Setting Active Row
    this.setActiveRow(this.testCase.testCaseId, this.testCase.tsId);

    // Getting test Steps for current testCase
    this.testSteps = await this.getTestCaseSteps(this.testCaseId);
  }

  getTestCaseSteps(testCaseId: string): Promise<TestStep[]> {
    return new Promise<TestStep[]>(resolve => {
      this.testStepService.getTestSteps(testCaseId).subscribe((steps: TestStep[]) => {
        resolve(steps);
      })
    })
  }

  getTestPlanCases(testPlanId: string): Promise<TestCase[]> {
    return new Promise<TestCase[]>(resolve => {
      this.testPlanService.getTestCasesList(testPlanId).subscribe(cases => {
        this.mapTestCases(cases);
        resolve(cases);
      });
    })
  }

  mapTestCases(testCases: TestCase[]): void {
    testCases.map((testCase: TestCase) => {
      this.testSuits.map((testSuit: TestSuit) => {
        if (testCase.tsId === testSuit.id) {
          testCase.testSuitName = testSuit.title;
        }
      })
    })
  }

  getTestSuits(): Promise<TestSuit[]> {
    return new Promise<TestSuit[]>(resolve => {
      this.testSuitService.getTestSuits({}).subscribe(async suits => {
        resolve(suits);
      });
    })
  }

  getSuitCases(testSuits: TestSuit[]): Promise<TestSuit[]> {
    return Promise.all(testSuits.map((s => {
      return new Promise<TestSuit>((resolve) => {
        this.testCaseService.getTestCasesByTestSuit({ ts_id: s.id }).subscribe(cases => {
          cases.map(c => c.testSuitId = s.id);
          s.testCases = cases;
          resolve(s);
        });
      });
    })))
  }

  deleteTestPlan(): void {
    this.testPlanService.deleteTestPlan({ id: this.testPlan.id }).subscribe(() => {
      this.router.navigate(['test-planes']).then(() => { });
    });
  }

  saveTestPlan(): void {
    this.testPlan.title = this.editTestPlansForm.value.title;
    this.testPlan.description = this.editTestPlansForm.value.description;
    this.editMode = false;
    this.testPlanService.saveTestPlan(this.testPlan).subscribe(() => {
    });
  }

  openRunDialog(): void {
    this.dialog.open(DialogRunComponent, {
      width: '650px',
      data: { type: 'plan', testPlan: this.testPlan }
    });
  }

  async openSelectCasesDialog() {

    this.testSuits = await this.getSuitCases(this.testSuits);

    const dialogRef = this.dialog.open(DialogTestCasesSelectComponent, {
      width: '650px',
      data: { testSuits: this.testSuits, selectedTestCases: this.testCases }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.testPlanService.updateTestCasesList({
          test_plan_id: this.testPlan.id,
          test_cases: result.filter(c => {
            return c.level === 1;
          }).map(c => { return { tc_id: c.id, ts_id: c.tsId }; })
        }).subscribe(() => { location.reload() })
      }
    });
  }

  goToTestPlans() {
    this.router.navigate(['test-planes']).then(() => { });
  }

  onResize(size: string): void {
    this.componentShowHide = size;
  }

  async openTestCaseDetail(testCase: TestCase) {
    this.router.navigate([`test-plan/${this.testPlan.id}/${testCase.testCaseId}`])
    this.relationId = testCase.relationId;
    this.testCase = testCase;
    if (this.componentShowHide === 'start' || 'hide') {
      this.onResize('small');
    }
    // Saving tesCases in local strorage to deisplay on init
    Core.localStorageService.setItem('testCases', JSON.stringify(this.testCases.slice(0, 15)));
    Core.localStorageService.setItem('testPlan', JSON.stringify(this.testPlan));

    // Setting Active Row
    this.setActiveRow(testCase.testCaseId, testCase.tsId);
    this.testSteps = await this.getTestCaseSteps(this.testCase.testCaseId);
  }

  setActiveRow(testCaseId: string, tsId: string) {
    this.testCases.map((testCase: TestCase) => {
      testCase.active = (testCase.testCaseId === testCaseId && testCase.tsId === tsId) ? true : false;
    })
  }

  doneAnimation() {
    // Once sideBar was closed, we redirect the user onto test-cases URL
    if (this.router.url.includes('plan') && this.componentShowHide === 'hide') {
      this.router.navigate([`test-plan/${this.testPlan.id}`]);
    }
  }

  ngOnDestroy() {
    if (!this.router.url.includes('plan')) {
      Core.localStorageService.removeItem('testCases');
      Core.localStorageService.removeItem('testPlan');
    }
  }
}
