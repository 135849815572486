import { Component, Inject } from '@angular/core';
import { TestCase } from "../../services/test-case.type";
import { TestCaseService } from "../../services/test-case.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-dialog-case-connections',
    templateUrl: './dialog-case-connections.component.html',
    styleUrls: ['./dialog-case-connections.component.scss']
})
export class DialogCaseConnectionsComponent {
    constructor(
        private testCaseService: TestCaseService,
        @Inject(MAT_DIALOG_DATA) public matDialogData: any,
        private route: ActivatedRoute,
        private dialogRef: MatDialogRef<DialogCaseConnectionsComponent>
    ) {
    }

    public relationsTestSuits: TestCase[] = [];
    public isLoading = true;
    public links = []

    ngOnInit() {
        this.create()
    }

    create() {
        this.testCaseService.getAllRelations({ tc_id: this.matDialogData.currentTestCase.id }).subscribe(v => {
            this.relationsTestSuits = v
            this.isLoading = false;

            const urlParts = this.matDialogData.location.split('?');
            const queryParams = urlParts[1];

            let params = null;
            if (queryParams) params = queryParams.split('&');
            else {
                let suitLevel = this.matDialogData.additional?.dataNodes?.find(node => +node.id === this.matDialogData.currentTestCase?.testSuitId)?.level;
                params = `?sub=${(this.matDialogData.currentTestCase as TestCase).testSuitId}&level=${suitLevel}`;
            }

            this.relationsTestSuits.forEach(item => {

                for (let i = 0; i < params?.length; i++) {
                    let param = params[i].split('=')
                    if (param[0] === 'sub') {
                        param[1] = item.id;
                        params[i] = param.join('=');
                        break;
                    }
                }

                this.links.push({
                    title: item.title,
                    link: item.id
                })
            })

        })
    }

    navigateTo(suitId) {
        const queryParams = { ...this.route.snapshot.queryParams };
        const updatedQueryParams = { ...queryParams, sub: suitId.toString() };
        this.dialogRef.close({ suitId, updatedQueryParams });
    }

}
