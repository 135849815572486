import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private base: ApiBaseService) { }

  request<T>(method: string, body: any): Observable<T> {
    // console.log(this.authenticationService.getUser().id)
    // body.auth_cookie = {auth_user_id: this.authenticationService.getUser().id};
    return this.base.request<T>(method, body);
  }

}

export class ResponseBase {
  valid: boolean;
}
