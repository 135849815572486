import {TestSuit} from './test-suit.type';
import {TestCase} from './test-case.type';

export class TestPlan {
  id: string;
  title: string;
  projectId: string;
  description: string;
  createdDate: string;
  modifiedDate: string;
  testSuits: Array<TestSuit>;
  testCases: Array<TestCase>;
  projectName: string;
  isOpen: boolean;
  creator_id: string;
  creator_name: string;
}
