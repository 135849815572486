import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartNewTestsCreatedComponent } from '../chart-new-tests-created/chart-new-tests-created.component';
import { ChartTestsChangedComponent } from '../chart-tests-changed/chart-tests-changed.component';
import { ChartTestsCompletedComponent } from '../chart-tests-completed/chartTestsCompleted.component';
import { ChartTestsEscalatedComponent } from '../chart-tests-escalated/chart-tests-escalated.component';
import { ChartTestsFailuresComponent } from '../chart-tests-failures/chart-tests-failures.component';
import { ChartDiagramsComponent } from '../chart-diagrams/chart-diagrams.component';
import { Core } from 'src/app/services/core.service';
import { ActivityTableComponent } from '../activity-table/activity-table.component';
import { TableLastRunsComponent } from '../teble-last-runs/table-last-runs.component';


@Component({
  selector: 'app-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.scss']
})
export class CardDashboardComponent implements OnInit {
  public currentProject: string = JSON.parse(Core.localStorageService.getItem('selected_project')) || 'null';

  @ViewChild('createdChart') private cardChartCreated: ChartNewTestsCreatedComponent;
  @ViewChild('changedChart') private cardChartChanged: ChartTestsChangedComponent;
  @ViewChild('complitedChart') private cardChartComplited: ChartTestsCompletedComponent;
  @ViewChild('escalatedChart') private cardChartEscalated: ChartTestsEscalatedComponent;
  @ViewChild('failuresChart') private cardChartFailed: ChartTestsFailuresComponent;
  @ViewChild('chartDiagram') private cardChardDiagram: ChartDiagramsComponent;
  @ViewChild('activityTable') private activityTable: ActivityTableComponent;
  @ViewChild('tableLastRuns') private tableLastRuns: TableLastRunsComponent;

  constructor() { }

  ngOnInit(): void {
    this.updateData();
  }

  updateData() {
    this.cardChartCreated?.updateData();
    this.cardChartChanged?.updateData();
    this.cardChartComplited?.updateData();
    this.cardChartEscalated?.updateData();
    this.cardChartFailed?.updateData();
    this.cardChardDiagram?.initData(this.currentProject);
    this.activityTable?.updateData(this.currentProject);
    this.tableLastRuns?.updateData(this.currentProject);
  }

  updateCharts() {
    this.cardChardDiagram.initData()
  }
}
