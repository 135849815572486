<div class="dialog-title">
  <h1 mat-dialog-title>Create Test subSuit for <span class="accent">{{data.testParentSuit.title | shortName :
      30}}</span></h1>
</div>
<div class="dialog-content" mat-dialog-content>
  <div *ngIf="nextStep === 1">
    <span>Do you want to add a subSuit from an existing suit or create a new subSuit?</span>
  </div>

  <div *ngIf="nextStep === 2">
    <form [formGroup]="craeteTestSuitForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Choose suit:</mat-label>
        <mat-select formControlName="suit">
          <mat-option *ngFor="let suit of testSuits" [value]="suit.id"
            [disabled]="suit.id === (data.testParentSuit.id) || (data.testParentObjIds.includes(suit.id))">{{ suit.title
            }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.suit.hasError('required')">
          Suit is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Sort order</mat-label>
        <input matInput formControlName="sortOrder">
      </mat-form-field>
    </form>
  </div>

  <div *ngIf="nextStep === 3">
    <form [formGroup]="craeteTestSuitForm">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title">
        <mat-error *ngIf="f.title.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
        <mat-error *ngIf="f.description.hasError('required')">
          Description is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Sort order</mat-label>
        <input matInput formControlName="sortOrder">
      </mat-form-field>
    </form>
  </div>
</div>

<div class="dialog-actions" mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <ng-container *ngIf="nextStep === 1">
    <button mat-raised-button color="primary" style="background: #2d64ba" (click)="nextStep = 2; initForm(nextStep)">Add
      an existing
      suit</button>
    <button mat-raised-button color="primary" style="background: #2d64ba"
      (click)="nextStep = 3; initForm(nextStep)">Create new
      subSuit</button>
  </ng-container>
  <ng-container *ngIf="nextStep === 2">
    <button mat-raised-button [disabled]="!f.suit.valid" color="primary" style="background: #2d64ba"
      (click)="addSuit()">Add</button>
  </ng-container>
  <ng-container *ngIf="nextStep === 3">
    <button mat-raised-button [disabled]="!craeteTestSuitForm.valid" color="primary" style="background: #2d64ba"
      (click)="createSubSuit()">Create</button>
  </ng-container>
</div>