import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/services/project.type';
import { JiraIntegrationSetting } from 'src/app/services/jira-integration.type';

export interface DialogData {
  action: string
  data: JiraIntegrationSetting;
}

@Component({
  selector: 'app-dialog-create-jira-integration-settings',
  templateUrl: 'dialog-create-jira-integration-settings.component.html',
  styleUrls: ['./dialog-create-jira-integration-settings.component.scss']
})
export class DialogCreateJiraIntegrationSettingsComponent implements OnInit {
  projects: Project[];
  craeteJiraForm: FormGroup;
  editJiraForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateJiraIntegrationSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.data.action === 'Create' ? this.initFormCreate() : this.initFormEdit();

    this.projectService.getProjects().subscribe(response => {
      this.projects = response;
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  initFormCreate() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const projectId = this.projectService.getSelectedProjectFromStorage();
    this.craeteJiraForm = new FormGroup({
      projectId: new FormControl((projectId === 'all' || projectId === 'null') ? null : projectId, [Validators.required]),
      projectKey: new FormControl(null, [Validators.required]),
      projectUrl: new FormControl(null, [Validators.required, Validators.pattern(reg)]),
      userEmail: new FormControl(null, [Validators.required, Validators.email]),
      token: new FormControl(null, [Validators.required])
    })
  }

  initFormEdit() {
    this.editJiraForm = new FormGroup({
      userEmail: new FormControl(this.data.data ? (this.data.data.auth_data as { email: string, token: string }).email : null, [Validators.required, Validators.email]),
      token: new FormControl(this.data.data ? (this.data.data.auth_data as { email: string, token: string }).token : null, [Validators.required])
    })
  }

  get f() {
    return this.craeteJiraForm?.controls || this.editJiraForm?.controls;
  }

  createSetting() {
    if (this.craeteJiraForm?.invalid || this.editJiraForm?.invalid) {
      this.craeteJiraForm?.markAllAsTouched();
      this.editJiraForm?.markAllAsTouched();
    } else {
      this.dialogRef.close(this.data.action === 'Create' ? {
        service_name: 'Jira',
        project_id: this.craeteJiraForm.value.projectId,
        jira_project_key: this.craeteJiraForm.value.projectKey,
        jira_project_url: this.craeteJiraForm.value.projectUrl,
        auth_data: {
          user_mail: this.craeteJiraForm.value.userEmail,
          token: this.craeteJiraForm.value.token,
        }
      } : {
        project_id: this.data.data.project_id,
        auth_data: {
          user_mail: this.editJiraForm.value.userEmail,
          token: this.editJiraForm.value.token,
        }
      });
    }
  }
}
