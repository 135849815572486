<app-base-card [title]="'Statuses'" [icon]="'date_range'" [showAdd]="addStatusAccess" (addEvent)="onAddEvent()"
  [showSearch]="true" (searchEvent)="search = $event">

  <div [style.display]="isLoading ? 'block' : 'none'">
    <mat-selection-list #selectionList>
      <mat-list-option #option *ngFor="let item of (statuses | searchPipe: search)" [value]="item"
        class="list-card__item item-list" [selected]="item.isOpen">
        <div class="item-list__body">
          <div class="item-list__title" [class.line]="item.color" [style.color]="item.color">
            {{item.name}}
            <span class="item-list__title-line" [style.background-color]="item.color"></span>
          </div>
          <div class="item-list__action">
            <div class="item-list__menu">
              <button mat-icon-button [matMenuTriggerFor]="menu" stopPropagation *ngIf="editStatusAccess">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onEditEvent(item)">
                  <span>Edit</span>
                </button>
                <ng-container *ngIf="+item.isDefault === 0">
                  <button mat-menu-item (click)="onDeleteEvent(item)">
                    <span>Delete</span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div *ngIf="!isLoading">
    <ngx-skeleton-loader [theme]="{ height: '40px', background: '#D6DBDF', margin: '5px 0px 0px 0px' }" count="3"
      appearance="line"></ngx-skeleton-loader>
  </div>

</app-base-card>