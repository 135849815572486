<app-sidenav>
  <mat-card>
    <button mat-raised-button color="primary" (click)="createCase()"><mat-icon>add_box</mat-icon>CREATE</button>
  </mat-card>
  <mat-card>
    <div>
      <mat-form-field appearance="standard">
        <mat-label>TITLE</mat-label>
        <input matInput [(ngModel)]="testCase.title">
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>TEST SUIT</mat-label>
        <mat-select [(ngModel)]="testCase.testSuitId">
          <mat-option *ngFor="let option of testSuits" [value]="option.id">{{ option.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>DESCRIPTION</mat-label>
        <textarea  matInput [(ngModel)]="testCase.description"></textarea>
      </mat-form-field>
    </div>
  </mat-card>
  <mat-card>
    <div *ngFor="let testStep of testSteps; let i = index;">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="uppercase">
              <mat-icon>library_books</mat-icon>
              {{testStep.description}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field appearance="standard" class="full-width">
            <mat-label>Description</mat-label>
            <textarea matInput [name]="'test_step_description_' + i" [(ngModel)]="testStep.description">{{testStep.description}}</textarea>
          </mat-form-field>
          <mat-form-field appearance="standard" class="full-width">
            <mat-label>Expected result</mat-label>
            <textarea matInput [name]="'test_step_expected_result_' + i" [(ngModel)]="testStep.expectedResult">{{testStep.expectedResult}}</textarea>
          </mat-form-field>
          <button mat-icon-button (click)="deleteStep(testStep)">
            <mat-icon>delete_sweep</mat-icon>
            DELETE STEP
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="new-step-div">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>library_add</mat-icon>
              CREATE TEST STEP
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field appearance="standard" class="full-width">
            <mat-label>Description</mat-label>
            <textarea matInput [(ngModel)]="testStep.description"></textarea>
          </mat-form-field>
          <mat-form-field appearance="standard" class="full-width">
            <mat-label>Expected result</mat-label>
            <textarea matInput [(ngModel)]="testStep.expectedResult"></textarea>
          </mat-form-field>
          <button mat-icon-button (click)="createStep()">
            <mat-icon>add_box</mat-icon>
            ADD STEP
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card>
</app-sidenav>
